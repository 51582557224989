import * as React from "react";
import styled from "styled-components";
import { SessionContext } from "../../../SessionContext";
import Button from "../../../../SharedComponents/Button";
import LoadingIndicator from "../../../../SharedComponents/LoadingIndicator";
import TextInput, { updateText } from "../../../../SharedComponents/TextInput";
import ErrorText from "../../../../SharedComponents/ErrorText";

import { COLORS, FONTSIZE } from "../../../../config";
import ICustomer from "../../../../ServerEntities/ICustomer";

import IUser from "../../../../ServerEntities/IUser";
import StatusBar from "../../Components/StatusBar";
import ICurrentStatus from "../../../../ServerEntities/ICurrentStatus";
import { OutsideContainer, Row } from "../../Customers/CustomerEdit";
import IPricing from "../../../../ServerEntities/IPricing";
import { getPricing, updatePricing } from "../QuotesService";
import IQuoteType from "../../../../ServerEntities/IQuoteType";
import { TitleLabel } from "../../WorkPackages/WorkPackageEdit";
import { Main, STATUS_COMMITED } from "./PricingTable";
import { getCustomerById } from "../../../../UsefulFunctions/quoteUtils";
import { getUniqueInterfaceTypes, sumProperty } from "../../../../UsefulFunctions/workPackageUtils";
import WorkPackageDetails, { SeparatorRow } from "./WorkPackageDetail";
import { SimpleScrollingContainer } from "../../../../SharedComponents/SimpleScrollingContainer";
import { SyntheticEvent } from "react";
import { QuotesSmallLogo } from "../../../MainMenu";
import SourceTag from "../../../../SharedComponents/SourceTag";
import { MoreInformation } from "../../Opportunities/QuoteDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleDown, faChevronCircleUp } from "@fortawesome/free-solid-svg-icons";
import useEffectOnSome from "../../../../CustomHooks/useEffectOnSome";

const QuoteHeader = styled.div`
    display: flex;
    flex-direction: column;
    width: 40%;
`;

const Label = styled.label`
    color: #333333;
    min-width: 140px;
    font-weight: bold;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 10%;
`;

const ContainerWithLogoOnTheSide = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
`;

const ContainerWithoutLogo = styled.div`
    width: 90%;
`;

const ContainerWithLogo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

const EffortEstimatesContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const QuoteDetailsMain = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%
`;

const PricingHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const ButtonContainer = styled.div`
    text-align: left;
    width: 90 %;
`;

const ScopingContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    align-items: center;
`;

const Value = styled.div`
    font-size: ${FONTSIZE};
`;

interface IProps {
    types: IQuoteType[],
    pricing: IPricing,
    users: IUser[],
    customers: ICustomer[],
    editable?: boolean,
    onAddComplete: () => void,
    onViewHistory: (pricing: IPricing) => void
};

const PricingEdit = (props: IProps) => {
    const { state } = React.useContext(SessionContext);
    const [loading, setLoading] = React.useState(false);
    const editable = props.editable ? props.editable : false;
    const customers = props.customers;
    const [error, setError] = React.useState("");
    const [currentStatus, setCurrentStatus] = React.useState(undefined as unknown as ICurrentStatus);
    const [pricing, setPricing] = React.useState(null as unknown as IPricing);
    const [discountPercent, setDiscountPercent] = React.useState(0);
    const [discountReason, setDiscountReason] = React.useState("");
    const [totalValue, setTotalValue] = React.useState(0);
    const [totalValueWithDiscount, setTotalValueWithDiscount] = React.useState(0);
    const [expanded, setExpanded] = React.useState(false);

    useEffectOnSome(() => {
        setLoading(true);
        getPricing(props.pricing.id, state.webToken, (p: IPricing) => {
            setLoading(false);
            setError("");
            setPricing(p);
            setDiscountPercent(p.discountPercent);
            setDiscountReason(p.discountReason);
            setTotalValue(p.totalValue);
            setTotalValueWithDiscount(p.totalValueWithDiscount);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    }, [props.pricing.id]);


    const save = (commit: boolean) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        const newPricing: IPricing = {
            id: pricing.id,
            quote: pricing.quote,
            authorisedBy: "",
            authorisedDate: 0,
            opportunity: pricing.opportunity,
            workPackage: pricing.workPackage,
            type: pricing.type,
            totalCost: pricing.totalCost,
            totalCostInterfaces: pricing.totalCostInterfaces,
            totalCostTasks: pricing.totalCostInterfaces,
            discountApplied: pricing.discountApplied,
            discountReason: discountReason,
            fundingSource: pricing.fundingSource,
            prime: pricing.prime,
            primePercent: pricing.primePercent,
            framework: pricing.framework,
            frameworkPercent: pricing.frameworkPercent,
            quoteTemplate: pricing.quoteTemplate,
            quoteRecipient: pricing.quoteRecipient,
            quoteAdditionalRecipients: pricing.quoteAdditionalRecipients,
            discountPercent: discountPercent,
            totalValue: totalValue,
            totalValueWithDiscount: totalValueWithDiscount,
            currentStatus: currentStatus,
            source: pricing.source,
            status: commit ? STATUS_COMMITED : 0,
        };
        setLoading(true);
        updatePricing(newPricing, state.webToken, () => {
            setLoading(false);
            setError("");
            props.onAddComplete();
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    }

    const navigateToHistory = () => {
        props.onViewHistory(props.pricing);
    }

    const onUpdateCurrentStatus = (status: ICurrentStatus) => {
        setCurrentStatus(status);
    }

    const calculateTotal = (discount: number, total: number) => {
        var discountAmount = (discount / 100) * total;
        return total - discountAmount;
    }

    const onChangeTotalValue = (e: SyntheticEvent<HTMLInputElement, Event>) => {
        let total = parseFloat(e.currentTarget.value);
        if (Number.isNaN(total)) {
            total = 0;
        }
        setTotalValue(total);
        setTotalValueWithDiscount(calculateTotal(discountPercent, total));
    }

    const onChangeDiscount = (e: SyntheticEvent<HTMLInputElement, Event>) => {
        let discount = parseFloat(e.currentTarget.value);
        if (Number.isNaN(discount)) {
            discount = 0;
        }
        setDiscountPercent(discount);
        setTotalValueWithDiscount(calculateTotal(discount, totalValue));
    }

    const toggleExpanded = () => {
        setExpanded(!expanded);
    }

    const sumIntArch = pricing && pricing.workPackage ? sumProperty(pricing.workPackage.interfaceDetails, "arch") : 0;
    const sumIntDev = pricing && pricing.workPackage ? sumProperty(pricing.workPackage.interfaceDetails, "dev") : 0;
    const sumIntTest = pricing && pricing.workPackage ? sumProperty(pricing.workPackage.interfaceDetails, "test") : 0;
    const sumIntGolive = pricing && pricing.workPackage ? sumProperty(pricing.workPackage.interfaceDetails, "golive") : 0;
    const sumIntOther = pricing && pricing.workPackage ? sumProperty(pricing.workPackage.interfaceDetails, "other") : 0;
    const sumTaskArch = pricing && pricing.workPackage ? sumProperty(pricing.workPackage.tasks, "arch") : 0;
    const sumTaskDev = pricing && pricing.workPackage ? sumProperty(pricing.workPackage.tasks, "dev") : 0;
    const sumTaskTest = pricing && pricing.workPackage ? sumProperty(pricing.workPackage.tasks, "test") : 0;
    const sumTaskGolive = pricing && pricing.workPackage ? sumProperty(pricing.workPackage.tasks, "golive") : 0;
    const sumTaskOther = pricing && pricing.workPackage ? sumProperty(pricing.workPackage.tasks, "other") : 0;
    const intTypes = pricing && pricing.workPackage ? getUniqueInterfaceTypes(pricing.workPackage.interfaceDetails) : "";

    return <OutsideContainer>
        <LoadingIndicator type="Linear" show={loading} />
        <Main>
            {error && <ErrorText>{error}</ErrorText>}
            {pricing && <ContainerWithLogoOnTheSide>
                <ContainerWithoutLogo>
                    <StatusBar status={currentStatus} onUpdateStatus={onUpdateCurrentStatus} />
                    <PricingHeader>
                        {pricing && pricing.quote && <QuoteHeader>
                            <TitleLabel>Quote Number {pricing.quote.quoteNumber}</TitleLabel>
                            <Row style={{ height: "15px" }} />
                            <Row>
                                <Label>Customer </Label>
                                <Value>{pricing.quote.customerId && getCustomerById(pricing.quote.customerId, customers)}</Value>
                            </Row>
                            <Row>
                                <Label>Client ID</Label>
                                <Value>{pricing.quote.customerId}</Value>
                            </Row>
                            <Row>
                                <Label htmlFor="projectName">Project Name</Label>
                                <Value>{pricing.quote.projectName}</Value>
                            </Row>
                            <Row>
                                <Label htmlFor="projectValue">Project Value</Label>
                                <Value>{pricing.quote.projectValue}</Value>
                            </Row>
                        </QuoteHeader>}
                        <ButtonContainer>
                            {editable && <Button color={COLORS.GREY_SLATE} submit={true} onClick={save(false)}>Save Pricing</Button>}
                            {editable && <Button color={COLORS.GREY_SLATE} style={{ marginLeft: "8px" }} onClick={save(true)}>Save & Commit Pricing</Button>}
                            {editable && <Button color={COLORS.GREY_SLATE} plain={true} style={{ marginLeft: "8px" }} onClick={props.onAddComplete}>Cancel</Button>}
                            <Button color={COLORS.CHANGE_HISTORY} plain={false} style={{ marginLeft: "8px" }} onClick={navigateToHistory}>View Change History</Button>
                        </ButtonContainer>
                    </PricingHeader>
                </ContainerWithoutLogo>
                <ContainerWithLogo>
                    <QuotesSmallLogo />
                    <SourceTag sourceType={pricing.source} />
                </ContainerWithLogo>
            </ContainerWithLogoOnTheSide>}
            <SimpleScrollingContainer>
                {/* {pricing && <QuoteSummaryContainer>
                <TitleContainer>
                    <h3>Quote Summary</h3>
                </TitleContainer>
                <TotalsContatiner>
                    <QuoteSummaryRow style={{ borderBottom: "2px solid #CCC", marginBottom: "30px" }}>
                        <Label>Total Cost</Label>
                        <Value>&pound;{pricing.totalCost}</Value>
                    </QuoteSummaryRow>
                    <QuoteSummaryRow>
                        <Label>Total cost of interfaces</Label>
                        <Value>&pound;{pricing.totalCostInterfaces}</Value>
                    </QuoteSummaryRow>
                    <QuoteSummaryRow>
                        <Label>Total cost of tasks</Label>
                        <Value>&pound;{pricing.totalCostTasks}</Value>
                    </QuoteSummaryRow>
                </TotalsContatiner>
                <DiscountContainer>
                    <QuoteSummaryRow>
                        <QuoteSummaryColumn>
                            <Label>Discount Applied</Label>
                            <Value>{pricing.discountApplied}</Value>
                        </QuoteSummaryColumn>
                        <QuoteSummaryColumn>
                            <Label>Prime</Label>
                            <Value>{pricing.primePercent}</Value>
                            <Value>{pricing.prime}</Value>
                        </QuoteSummaryColumn>
                        <QuoteSummaryColumn>
                            <Label>Framework</Label>
                            <Value>{pricing.frameworkPercent}</Value>
                            <Value>{pricing.framework}</Value>
                        </QuoteSummaryColumn>
                    </QuoteSummaryRow>
                    <QuoteSummaryRow>
                        <Label>Discount Reason</Label>
                        <Value>
                            <TextInput width="100%" value={discountReason} onChange={updateText(setDiscountReason)} placeholder="" />
                        </Value>
                    </QuoteSummaryRow>
                </DiscountContainer>
            </QuoteSummaryContainer>} */}



                {pricing && pricing.workPackage && <EffortEstimatesContainer>
                    <SeparatorRow />
                    <TitleContainer>
                        <h3>Effort Estimates</h3>
                    </TitleContainer>
                    <QuoteDetailsMain>
                        <Row>
                            <table width={"100%"}>
                                <tbody>
                                    <tr key={`effort-item-total-label`} >
                                        <td style={{ fontWeight: "bold" }} colSpan={4}>Total of interfaces and tasks</td>
                                    </tr>
                                    <tr key={`effort-item-total-sum`}>
                                        <td style={{ width: "50px" }}></td>
                                        <td colSpan={4} style={{ fontWeight: "bold" }}>Total days: {sumIntArch + sumIntDev + sumIntTest + sumIntGolive + sumIntOther + sumTaskArch + sumTaskDev + sumTaskTest + sumTaskGolive + sumTaskOther}</td>
                                    </tr>
                                    <tr key={`effort-item-total-interface-types-sum`}>
                                        <td style={{ width: "50px" }}></td>
                                        <td colSpan={4} style={{ fontWeight: "bold" }}>Interface types: {intTypes}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: "50px" }}></td>
                                        <td style={{ fontWeight: "bold", width: "10%" }}>% Discount</td>
                                        {editable && <td><TextInput width={"10%"} value={discountPercent.toString()} onChange={onChangeDiscount} placeholder="" /></td>}
                                        {!editable && <td>{discountPercent}</td>}
                                        <td style={{ fontWeight: "bold", width: "50%", textAlign: "right" }}>Total Value of Interfaces & Tasks</td>
                                        {editable && <td><TextInput width={"100px"} value={totalValue.toString()} onChange={onChangeTotalValue} placeholder="" /></td>}
                                        {!editable && <td>&pound;{totalValue}</td>}
                                    </tr>
                                    <tr>
                                        <td style={{ width: "50px" }}></td>
                                        <td style={{ fontWeight: "bold", width: "10%", verticalAlign: "top" }}>Discount Reason</td>
                                        {editable && <td><TextInput width={"90%"} textArea={true} value={discountReason} onChange={updateText(setDiscountReason)} placeholder="" /></td>}
                                        {!editable && <td>{discountReason}</td>}
                                        <td style={{ fontWeight: "bold", width: "50%", textAlign: "right" }}>Total Value of Interfaces & Tasks with Discount Applied</td>
                                        <td>&pound;{totalValueWithDiscount}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Row>
                    </QuoteDetailsMain>
                    <SeparatorRow />
                </EffortEstimatesContainer>}

                <ScopingContainer>
                    <h3>Scoping Information</h3>
                    <div onClick={toggleExpanded}>
                        {!expanded && <FontAwesomeIcon style={{ color: "#4c4c4c" }} icon={faChevronCircleDown} />}
                        {expanded && <FontAwesomeIcon style={{ color: "#4c4c4c" }} icon={faChevronCircleUp} />}
                    </div>
                </ScopingContainer>
                {pricing && <MoreInformation style={{ display: expanded ? "flex" : "none" }}>
                    {pricing && pricing.workPackage && <WorkPackageDetails workPackage={pricing.workPackage} />}
                    <SeparatorRow />
                </MoreInformation>}

            </SimpleScrollingContainer>
        </Main >
    </OutsideContainer >
};

export default PricingEdit;
