import * as React from "react";
import styled from "styled-components";

import { format } from "timeago.js";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import IconButton from '../SharedComponents/IconButton';

const NotificationContainer = styled.div`
display:flex;
flex-direction:row;
padding:12px;
border-bottom: 1px solid #e5e5e5;
transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
&:hover{
    background-color:rgba(24,127,186,0.05);
}
cursor:pointer;
justify-content: space-between;
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1 1 100%;
`;

const TitleText = styled.p`
    margin: 0;
    line-height: 20px;
    font-size: 14px;
    font-weight: 600;
`;

const NotificationText = styled.p`
    margin: 0px;
    line-height: 20px;
    font-size: 14px;
    flex: 1 1 auto;
`;

const TimeText = styled.p`
    margin: 0;
    line-height: 20px;
    font-size: 14px;
    color: #999;
`;

const ExpandButton = styled.a`
    font-size: 14px;
    margin: 8px 0;
    color: #187fba;
    cursor: pointer;
    &:hover {
        opacity: 0.7;
    }
`;

export interface IProps {
    id: number;
    title: string;
    text: string;
    created: number;
    seen: boolean;
    onDelete: () => void;
    onClick?: () => void;
    handleKeyDown?: (event: any) => void;
};

// Number of characters before we ellipsify a notification.
const BIG_TEXT_SIZE = 220;

const Notification = (props: IProps) => {
    const { id, title, text, created, seen, onDelete, handleKeyDown } = props;
    const [expandText, setExpandText] = React.useState(false);
    const menuButtonRef = React.useRef(null as unknown as HTMLDivElement);
    const textBig = text.length > BIG_TEXT_SIZE;

    const deleteItem = () => {
        onDelete();
    };

    const toggleExpandText = () => {
        setExpandText(!expandText);
    };

    const notificationText = textBig && !expandText ? text.substring(0, 220) + " \u2026" : text;

    return <NotificationContainer onKeyDown={handleKeyDown} className={`NotificationContainer ${seen ? "seen" : ""}`}
        id={`DeleteNotification-${id}`}>
        <TextContainer>
            <TitleText>{title}</TitleText>
            <NotificationText dangerouslySetInnerHTML={{ __html: notificationText }}></NotificationText>
            {textBig && <ExpandButton onClick={toggleExpandText}>{expandText ? "Show less" : "Show more"}</ExpandButton>}
            <TimeText>{format(new Date(created))}</TimeText>
        </TextContainer>
        <IconButton
            onClick={deleteItem}
            refObject={menuButtonRef}>
            <FontAwesomeIcon icon={"trash"} color="#187fba" />
        </IconButton>
    </NotificationContainer>
}

export default Notification;