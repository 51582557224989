export const VERIFY_ACTIVE_INTERVAL = 120000;

const DEFAULT_PORT = 443;
const DEV_BFF_PORT = 7820;

const hostname = window.location.hostname;
// const hostname = "dockerdevhome";

const isDev = window.location.port === "3000";

export const getAPIUrl = () => {
    if (isDev) {
        return `https://${hostname}:${DEV_BFF_PORT}/`;
    }
    return `https://${hostname}:${DEFAULT_PORT}/api/`;
};

export const getHostUrl = () => {
    if (isDev) {
        return `http://localhost:3000`;
    }
    return `https://test-internal.restartconsulting.com`;
};
export const TRIGGER_QUOTE_CREATED = "quote-created";
export const TRIGGER_QUOTE_AUTHORISED = "quote-authorised";

export const COLORS = {
    PRIMARY: '#4e3b6b',
    SECONDARY: '#6a437d',
    THIRD: '#624c6b',
    FOURTH: '#58597c',
    FIFTH: '#5680a2',
    SIXTH: '#1d5d90',
    SEVENTH: '#2579ba',
    PURPLE_SLATE: '#6a437d',
    PLUM_SLATE: '78, 59, 107',
    HEATHER_SLATE: '#624c6b',
    GREY_SLATE: '#58597c',
    BLUE_SLATE: '86, 128, 162',
    OPPORTUNITIES_YELLOW: '#efe012',
    ARCHITECTURE_BLUE: '#3491ef',
    QUOTES_PINK: '#e31aad',
    FINANCE_ORANGE: '#ec7813',
    PROJECTS_GREEN: '#20bb35',
    DEVELOPMENT_BLUE: '#001cd1',
    SUPPORT_RED: '#ff0000',
    CHANGE_HISTORY: '#5680A1',
    RESTART_CONTACTS: '#95EEA3',
    INTERFACE: '#3491EF',
    TASKS: '#013871',
    ADMIN_GREEN: '#36F96C'
};

export const FONTSIZE = "14px";
export const FONTSIZE_TITLE = "18px";
export const INTEGRATION_ENGINES = ["Intersystem", "Rhapsody", "QVera", "Mirth", "Other"];