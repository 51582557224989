import * as React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../config";
import { RolesContext } from "../Roles/RolesContext";
import IConfigurationComponent from "../ServerEntities/IConfigurationComponent";
import Header from "../SharedComponents/Header";
import Tools from "../SharedComponents/Tools";
import Announcements from "../SharedComponents/Announcements";
import Banner from "../SharedComponents/Banner";
import Worklist from "../SharedComponents/Worklist";
import Notifications from "../SharedComponents/Notifications";
import AdminIconImg from "../../src/images/AD.png";
import ProjectsIconImg from "../../src/images/PR.png";
import DevelopmentIconImg from "../../src/images/DE.png";
import SupportIconImg from "../../src/images/SU.png";
import OverviewIconImg from "../../src/images/OV.png";
import OpportunitiesIconImg from "../../src/images/OP.png";
import ArchitectureIconImg from "../../src/images/AR.png";
import QuotesIconImg from "../../src/images/QU.png";
import FinanceIconImg from "../../src/images/FI.png";
import OpportunitiesIconSmallImg from "../../src/images/OP_small.png";
import ArchitectureIconSmallImg from "../../src/images/AR_small.png";
import QuotesIconSmallImg from "../../src/images/QU_small.png";
import FinanceIconSmallImg from "../../src/images/FI_small.png";
import AdminIconSmallImg from "../../src/images/AD_small.png";
import OverviewIconDisabledImg from "../../src/images/OV-disabled.png";
import OpportunitiesIconDisabledImg from "../../src/images/OP-disabled.png";
import ArchitectureIconDisabledImg from "../../src/images/AR-disabled.png";
import QuotesIconDisabledImg from "../../src/images/QU-disabled.png";
import FinanceIconDisabledImg from "../../src/images/FI-disabled.png";
import AdminIconDisabledImg from "../../src/images/AD-disabled.png";
import ProjectsIconDisabledImg from "../../src/images/PR-disabled.png";
import DevelopmentIconDisabledImg from "../../src/images/DE-disabled.png";
import SupportIconDisabledImg from "../../src/images/SU-disabled.png";

export const MenuContainer = styled.div`
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    flex: 5 1 0;
    border: 3px solid grey;
    border-left: none;
    border-bottom: none; 
`;

const Menu = styled.ul`
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
`;

interface IMenuItemProps {
    $backgroundColor: string,
    $color: string,
    $borderColor: string,
    $hover: boolean
};

interface ILogoProps {
    pageName: string,
    components: IConfigurationComponent[],
};


export const MenuItem = styled.li<IMenuItemProps>`
    width: 100px;
    height: 100px;
    text-align: center;
    color: ${props => props.$color};
    background-color: ${props => props.$backgroundColor};
    border: 5px solid ${props => props.$borderColor};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 15px;
    transition: 0.3s;
    &:hover {
        cursor: pointer;
        height: ${props => props.$hover ? "110px" : "100px"};
        width: ${props => props.$hover ? "110px" : "100px"};
    }
`;

interface IMenuItemTagOrNameProps {
    $fontSize: string,
    $fontWeight: string,
};

export const MenuItemTagOrName = styled.div<IMenuItemTagOrNameProps>`
    font-size: ${props => props.$fontSize};
    font-weight: ${props => props.$fontWeight};
`;

export const UpperRow = styled.div`
    display: flex;
    align-items: stretch;
    flex: 1 1 0;

`;

export const LowerRow = styled.div`
    display: flex;
    align-items: stretch;
    flex: 2 1 0;
    min-height: 0;
`;


export const OpportunitiesSmallLogo = () => {
    return <img alt="Opportunities" width={"102px"} height={"95px"} src={OpportunitiesIconSmallImg} />
}

export const ArchitectureSmallLogo = () => {
    return <img alt="Architecture" width={"102px"} height={"95px"} src={ArchitectureIconSmallImg} />
}

export const QuotesSmallLogo = () => {
    return <img alt="Quotes" width={"102px"} height={"95px"} src={QuotesIconSmallImg} />
}

export const FinanceSmallLogo = () => {
    return <img alt="Finance" width={"102px"} height={"95px"} src={FinanceIconSmallImg} />
}

export const AdminSmallLogo = () => {
    return <img alt="Admin" width={"102px"} height={"95px"} src={AdminIconSmallImg} />
}

const MainMenu = () => {

    const { state: rolesState } = React.useContext(RolesContext);
    const navigate = useNavigate();

    const selectOption = (pageName: string, components: IConfigurationComponent[]) => () => {

        const hasComponent = components.filter(item => item.name.includes(pageName));
        if (hasComponent.length > 0) {
            navigate(pageName);
        }
    };

    const OverviewLogo = (props: ILogoProps,) => {
        const access = props.components.filter(item => item.name.includes(props.pageName));
        if (access.length > 0) {
            return <img alt="Overview" onClick={selectOption(props.pageName, props.components)} width={"144px"} height={"134px"} src={OverviewIconImg} />
        }
        return <img alt="Overview" width={"144px"} height={"134px"} src={OverviewIconDisabledImg} />
    }

    const AdminLogo = (props: ILogoProps) => {
        const access = props.components.filter(item => item.name.includes(props.pageName));
        if (access.length > 0) {
            return <img alt="Admin" onClick={selectOption(props.pageName, props.components)} width={"144px"} height={"134px"} src={AdminIconImg} />
        }
        return <img alt="Admin" width={"144px"} height={"134px"} src={AdminIconDisabledImg} />
    }

    const ProjectsLogo = (props: ILogoProps) => {
        const access = props.components.filter(item => item.name.includes(props.pageName));
        if (access.length > 0) {
            return <img alt="Projects" onClick={selectOption(props.pageName, props.components)} width={"144px"} height={"134px"} src={ProjectsIconImg} />
        }
        return <img alt="Projects" width={"144px"} height={"134px"} src={ProjectsIconDisabledImg} />
    }

    const DevelopmentLogo = (props: ILogoProps) => {
        const access = props.components.filter(item => item.name.includes(props.pageName));
        if (access.length > 0) {
            return <img alt="Development" onClick={selectOption(props.pageName, props.components)} width={"144px"} height={"134px"} src={DevelopmentIconImg} />
        }
        return <img alt="Development" width={"144px"} height={"134px"} src={DevelopmentIconDisabledImg} />
    }

    const SupportLogo = (props: ILogoProps) => {
        const access = props.components.filter(item => item.name.includes(props.pageName));
        if (access.length > 0) {
            return <img alt="Support" onClick={selectOption(props.pageName, props.components)} width={"144px"} height={"134px"} src={SupportIconImg} />
        }
        return <img alt="Support" width={"144px"} height={"134px"} src={SupportIconDisabledImg} />
    }

    const ArchitectureLogo = (props: ILogoProps) => {
        const access = props.components.filter(item => item.name.includes(props.pageName));
        if (access.length > 0) {
            return <img alt="Architecture" onClick={selectOption(props.pageName, props.components)} width={"144px"} height={"134px"} src={ArchitectureIconImg} />
        }
        return <img alt="Architecture" width={"144px"} height={"134px"} src={ArchitectureIconDisabledImg} />
    }

    const OpportunitiesLogo = (props: ILogoProps) => {
        const access = props.components.filter(item => item.name.includes(props.pageName));
        if (access.length > 0) {
            return <img alt="Opportunities" onClick={selectOption(props.pageName, props.components)} width={"144px"} height={"134px"} src={OpportunitiesIconImg} />
        }
        return <img alt="Opportunities" width={"144px"} height={"134px"} src={OpportunitiesIconDisabledImg} />
    }

    const QuotesLogo = (props: ILogoProps) => {
        const access = props.components.filter(item => item.name.includes(props.pageName));
        if (access.length > 0) {
            return <img alt="Quotes" onClick={selectOption(props.pageName, props.components)} width={"144px"} height={"134px"} src={QuotesIconImg} />
        }
        return <img alt="Quotes" width={"144px"} height={"134px"} src={QuotesIconDisabledImg} />
    }

    const FinanceLogo = (props: ILogoProps) => {
        const access = props.components.filter(item => item.name.includes(props.pageName));
        if (access.length > 0) {
            return <img alt="Finance" onClick={selectOption(props.pageName, props.components)} width={"144px"} height={"134px"} src={FinanceIconImg} />
        }
        return <img alt="Finance" width={"144px"} height={"134px"} src={FinanceIconDisabledImg} />
    };

    const components = rolesState.configuration ? rolesState.configuration.components : [];

    return <div style={{ position: "absolute", height: "100%", width: "100%", display: "flex", alignItems: "stretch", flexDirection: "column" }}>
        <Header backgroundColor={`rgba(${COLORS.PLUM_SLATE}, 1.0)`} border={COLORS.PURPLE_SLATE} />
        <main style={{ flex: "1", display: "flex", alignItems: "stretch", flexDirection: "column" }}>
            <UpperRow>
                <Tools bannerColor={COLORS.GREY_SLATE} />
                <MenuContainer>
                    <Banner $backgroundColor={COLORS.GREY_SLATE}>Apps</Banner>
                    <Menu>
                        <OverviewLogo components={components} pageName={"Overview"} />
                        <AdminLogo components={components} pageName={"Admin"} />
                        <OpportunitiesLogo components={components} pageName={"Opportunities"} />
                        <ArchitectureLogo components={components} pageName={"Architecture"} />
                        <QuotesLogo components={components} pageName={"Quotes"} />
                        <FinanceLogo components={components} pageName={"Finance"} />
                        <ProjectsLogo components={components} pageName={"Projects"} />
                        <DevelopmentLogo components={components} pageName={"Development"} />
                        <SupportLogo components={components} pageName={"Support"} />
                    </Menu>
                </MenuContainer>
            </UpperRow>
            <LowerRow>
                <Announcements />
                <Worklist />
                <Notifications />
            </LowerRow>
        </main>
    </div >;
};

export default MainMenu;
