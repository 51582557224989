import * as React from 'react';
import styled from 'styled-components';

import { SessionContext } from '../../../Views/SessionContext';

import Button from '../../../SharedComponents/Button';
import Dialog from '../../../SharedComponents/Dialog/Dialog';
import DialogActions from '../../../SharedComponents/Dialog/DialogActions';
import ErrorBox from '../../../SharedComponents/ErrorBox';
import LoadingIndicator from '../../../SharedComponents/LoadingIndicator';
import TextInput from '../../../SharedComponents/TextInput';
import ManagerTitleBar from '../../../Views/Manager/ManagerTitleBar';

import { createRole, getApplications } from './roleManagementService';
import IRole from '../../../ServerEntities/IRole';
import SectionList, { ISectionListData, ISectionListRenderItemInfo } from '../../../SharedComponents/List/SectionList';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Label from '../../../SharedComponents/Label';
import ListItem from '../../../SharedComponents/List/ListItem';
import useEffectOnSome from '../../../CustomHooks/useEffectOnSome';

const UserFieldsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
`;

const UserFieldLine = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`;
const UserFieldCell = styled.div`
    width: 100%;
`;

const StatusContainer = styled.div`
    height: 70px;
    text-align: center;
`;

const MainContainer = styled.div`
    display:flex;
    height: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: space-around;
    justify-content: center;
    align-items: center;
`;

interface IProps {
    show: boolean,
    onClose: () => void,
    onConfirmRoleAdd: () => void
}


const EmptyList = () => <div style={{ color: "#555", padding: "24px" }}>There are no more applications to add to this role.</div>

const getSections = (apps: string[]): Array<ISectionListData<{ title: string }>> => [
    { data: apps.map((app: string) => ({ title: app })) }
];

const appMatchesApp = (appToMatch: string) => (app: string) => appToMatch === app;

const CreateRoleDialog = (props: IProps) => {
    const [appsToAdd, setAppsToAdd] = React.useState([] as string[]);
    const { webToken } = React.useContext(SessionContext).state;
    const [roleValue, setRoleValue] = React.useState("");
    const [descriptionValue, setDescriptionValue] = React.useState("");
    const [applications, setApplications] = React.useState([] as unknown as string[]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [success, setSuccess] = React.useState(false);
    const [roleToAdd, setRoleToAdd] = React.useState({} as IRole);

    const closeDialog = () => {
        setRoleValue("");
        setDescriptionValue("");
        setError("");
        if (success) {
            props.onConfirmRoleAdd();
            setSuccess(false);
        }
        props.onClose();
    };

    const addRoleConfirm = () => {
        setLoading(true);
        roleToAdd.id = roleValue;
        roleToAdd.description = descriptionValue;
        roleToAdd.applications = appsToAdd;
        setRoleToAdd(roleToAdd)
        createRole(roleValue, webToken, roleToAdd,
            () => {
                setLoading(false)
                setSuccess(true);
                setError("");
            },
            (errorMessage) => {
                setLoading(false);
                setError(errorMessage);
                setSuccess(false)
                return ""
            })
    };

    const changeRoleValue = (e: React.SyntheticEvent<HTMLInputElement>) => {
        setRoleValue(e.currentTarget.value);
    };

    const changeDescriptionValue = (e: React.SyntheticEvent<HTMLInputElement>) => {
        setDescriptionValue(e.currentTarget.value);
    };

    const toggleAppToAdd = (app: string) => () => {
        const appsToAddCopy = [...appsToAdd];
        const appIndexInArray = appsToAddCopy.findIndex((appToAdd: string) => {
            return app === appToAdd;
        });
        if (appIndexInArray === -1) {
            appsToAddCopy.push(app);
        } else {
            appsToAddCopy.splice(appIndexInArray, 1);
        }
        setAppsToAdd(appsToAddCopy);
    };

    const renderUser = (info: ISectionListRenderItemInfo<{ title: string }>) =>
        <ListItem onClick={toggleAppToAdd(applications[info.index])} key={`item${info.index}`}>
            <Label>
                <h2>{info.item.title}</h2>
            </Label>
            {appsToAdd.findIndex(appMatchesApp(info.item.title)) !== -1 && <FontAwesomeIcon icon={faCheck} style={{ color: "#187fba" }} />}
        </ListItem>;

    useEffectOnSome(() => {
        setLoading(true);
        getApplications(webToken, (serverApplications: string[]) => {
            setLoading(false);
            setApplications(serverApplications);
            setError("");
        }, (errorMessage: string) => {
            setLoading(false);
            setApplications([]);
            setError("");
        })
    }, [webToken]);

    return <Dialog open={props.show} onClose={closeDialog} style={{ width: "650px", height: "620px" }}>
        <ManagerTitleBar viewName="Create role" viewDescription="Create a new role." />
        <LoadingIndicator show={loading} type="Linear" />
        {!success && <UserFieldsContainer>
            <UserFieldLine>
                <TextInput margin="10px 10px 20px 10px" value={roleValue} onChange={changeRoleValue} label="Role" />
            </UserFieldLine>
            <UserFieldLine>
                <UserFieldCell>
                    <TextInput margin="10px 10px 20px 10px" value={descriptionValue} onChange={changeDescriptionValue} label="Description" />
                </UserFieldCell>
            </UserFieldLine>
            <UserFieldLine style={{ margin: "10px 10px 20px 15px" }}>
                <UserFieldCell style={{ width: "40%" }}>
                    Applications
                </UserFieldCell>
                <UserFieldCell>
                    {error && <ErrorBox>{error}</ErrorBox>}
                    {applications.length === 0 && <EmptyList />}
                    <SectionList sections={getSections(applications)} renderItem={renderUser} />
                </UserFieldCell>
            </UserFieldLine>
            <StatusContainer>
                {error && <ErrorBox>{error}</ErrorBox>}
            </StatusContainer>
            <DialogActions>
                <Button onClick={closeDialog}>Cancel</Button>
                <Button onClick={addRoleConfirm} margin="0px 16px 0px 16px" disabled={false}>Confirm</Button>
            </DialogActions>
        </UserFieldsContainer>}
        {success && <MainContainer>
            <StatusContainer>Role created successfully </StatusContainer>
            <Button onClick={closeDialog} margin="0px 16px 0px 16px" disabled={false}>Close</Button>
        </MainContainer>}
    </Dialog>;
};

export default CreateRoleDialog;
