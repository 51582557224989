import * as React from "react";
import styled from "styled-components";
import { SessionContext } from "../../../SessionContext";
import IWorkPackage from "../../../../ServerEntities/IWorkPackage";

import { COLORS, FONTSIZE, FONTSIZE_TITLE, getAPIUrl } from "../../../../config";
import IInterfaceDetails from "../../../../ServerEntities/IInterfaceDetails";

import IDocument from "../../../../ServerEntities/IDocument";
import DocumentBox from "../../Components/DocumentBox";
import { TYPE_DIAGRAM, TYPE_DOCUMENT, getUniqueInterfaceTypes, sumProperty } from "../../../../UsefulFunctions/workPackageUtils";

import { OutsideContainer } from "../../Customers/CustomerEdit";
import ITask from "../../../../ServerEntities/ITask";
import { getPackageDocuments } from "../../WorkPackages/WorkPackageService";
import EffortDetails, { EFFORT_NUMBER_LENGTH, EFFORT_INTERFACE_NAME_LENGTH, EFFORT_INTERFACE_TYPE_LENGTH, EFFORT_NOTES_LENGTH, EFFORT_BUTTONS_LENGTH, EFFORT_TYPE_LENGTH, EFFORT_TASK_NOTES_LENGTH } from "../../WorkPackages/EffortDetails";

const Value = styled.div`
     font-size: ${FONTSIZE};
`;

const TableHeaderCell = styled.th`
    padding: 2px;
    font-size: 0.9em;
    font-weight: bold;
`;

const TableCell = styled.td`
    font-size: 0.9em;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const TitleAndButton = styled.div`
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
`;

export const SeparatorRow = styled.div`
    border-bottom: solid 3px #999;
    margin-top: 10px;
    margin-bottom: 10px;
`;

export const Label = styled.label`
    min-width: 200px;
    font-size: ${FONTSIZE};
    font-weight: bold;
`;

export const TitleLabel = styled.label`
    min-width: 200px;
    font-size: ${FONTSIZE_TITLE};
    font-weight: bold;
    line-height: 40px;
`;


export const HeaderButtonContainer = styled.div`
    text-align: left;
    width: 90%
`;

export const ButtonContainer = styled.div`
    text-align: right;
`;

export const PackageHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const FilesContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const DocumentContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-direction: column;
`;

const WorkPackageContainer = styled.div`
    text-align: left;
`;

export const QuoteContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    width: 90%
`;


interface IProps {
    workPackage: IWorkPackage,
};

const WorkPackageDetails = (props: IProps) => {
    const { state } = React.useContext(SessionContext);
    const workPackage = props.workPackage;
    const [diagrams, setDiagrams] = React.useState(undefined as unknown as IDocument[]);

    const getDocuments = (type: string) => {
        getPackageDocuments(props.workPackage.id, type, state.webToken, (files: IDocument[]) => {
            if (type === TYPE_DOCUMENT) {
                // setDocuments(files.length > 0 ? files : undefined as unknown as IDocument[]);
            } else {
                setDiagrams(files.length > 0 ? files : undefined as unknown as IDocument[])
            }
        }, (errorMessage: string) => {
        });
    }

    const renderEffort = (interfaceDetails: IInterfaceDetails | ITask, index: number) => {
        return <EffortDetails
            readOnly={true}
            effort={interfaceDetails}
            index={index} />
    }

    const sumIntArch = workPackage ? sumProperty(workPackage.interfaceDetails, "arch") : 0;
    const sumIntDev = workPackage ? sumProperty(workPackage.interfaceDetails, "dev") : 0;
    const sumIntTest = workPackage ? sumProperty(workPackage.interfaceDetails, "test") : 0;
    const sumIntGolive = workPackage ? sumProperty(workPackage.interfaceDetails, "golive") : 0;
    const sumIntOther = workPackage ? sumProperty(workPackage.interfaceDetails, "other") : 0;
    const sumTaskArch = workPackage ? sumProperty(workPackage.tasks, "arch") : 0;
    const sumTaskDev = workPackage ? sumProperty(workPackage.tasks, "dev") : 0;
    const sumTaskTest = workPackage ? sumProperty(workPackage.tasks, "test") : 0;
    const sumTaskGolive = workPackage ? sumProperty(workPackage.tasks, "golive") : 0;
    const sumTaskOther = workPackage ? sumProperty(workPackage.tasks, "other") : 0;
    const intTypes = workPackage ? getUniqueInterfaceTypes(workPackage.interfaceDetails) : "";

    const renderHtml = (html: string) => {
        return <div dangerouslySetInnerHTML={{ __html: html }} />;
    }

    return <OutsideContainer>
        {workPackage && <WorkPackageContainer>
            <Row>
                <Label htmlFor="effortInterfaces">Interfaces</Label>
            </Row>
            <Row>
                <table width={"100%"}>
                    <thead>
                        {<tr>
                            <TableHeaderCell style={{ width: EFFORT_NUMBER_LENGTH }}></TableHeaderCell>
                            <TableHeaderCell style={{ width: EFFORT_INTERFACE_NAME_LENGTH }}></TableHeaderCell>
                            <TableHeaderCell style={{ width: "40%", textAlign: "center", borderBottom: "1px solid #ccc" }} colSpan={5}>Effort in days</TableHeaderCell>
                            <TableHeaderCell style={{ width: EFFORT_INTERFACE_TYPE_LENGTH, textAlign: "center" }}></TableHeaderCell>
                            <TableHeaderCell style={{ width: EFFORT_NOTES_LENGTH, textAlign: "center" }}></TableHeaderCell>
                            <TableHeaderCell style={{ width: EFFORT_BUTTONS_LENGTH, textAlign: "center" }}></TableHeaderCell>
                        </tr>}
                        <tr style={{ borderBottom: "1px solid #ccc" }}>
                            <TableHeaderCell style={{ width: EFFORT_NUMBER_LENGTH }} >#</TableHeaderCell>
                            <TableHeaderCell style={{ width: EFFORT_INTERFACE_NAME_LENGTH, textAlign: "left" }}>Interface Name</TableHeaderCell>
                            <TableHeaderCell style={{ width: EFFORT_TYPE_LENGTH, textAlign: "center" }}>Arch</TableHeaderCell>
                            <TableHeaderCell style={{ width: EFFORT_TYPE_LENGTH, textAlign: "center" }}>Dev</TableHeaderCell>
                            <TableHeaderCell style={{ width: EFFORT_TYPE_LENGTH, textAlign: "center" }}>Test</TableHeaderCell>
                            <TableHeaderCell style={{ width: EFFORT_TYPE_LENGTH, textAlign: "center" }}>Go-live</TableHeaderCell>
                            <TableHeaderCell style={{ width: EFFORT_TYPE_LENGTH, textAlign: "center" }}>Other</TableHeaderCell>
                            <TableHeaderCell style={{ width: EFFORT_INTERFACE_TYPE_LENGTH }}>Interface type</TableHeaderCell>
                            <TableHeaderCell style={{ width: EFFORT_NOTES_LENGTH }}>Notes</TableHeaderCell>
                            <TableHeaderCell style={{ width: EFFORT_BUTTONS_LENGTH }}></TableHeaderCell>
                        </tr>
                    </thead>
                    <tbody>
                        {workPackage && workPackage.interfaceDetails && workPackage.interfaceDetails.map(renderEffort)}
                        <tr key={`effort-item-total`} >
                            <TableCell style={{ fontWeight: "bold" }} colSpan={10}>Total days: {sumIntArch + sumIntDev + sumIntTest + sumIntGolive + sumIntOther}</TableCell>
                        </tr>
                        <tr key={`effort-item-total-interface-types`} >
                            <TableCell style={{ fontWeight: "bold" }} colSpan={10}>Interface types: {intTypes}</TableCell>
                        </tr>
                    </tbody>
                </table>
            </Row>
            <Row style={{ marginTop: "30px" }}>
                <Label htmlFor="effortTasks">Tasks</Label>
            </Row>
            <Row>
                <table width={"100%"}>
                    <thead>
                        {<tr>
                            <TableHeaderCell style={{ width: EFFORT_NUMBER_LENGTH }}></TableHeaderCell>
                            <TableHeaderCell style={{ width: EFFORT_INTERFACE_NAME_LENGTH }}></TableHeaderCell>
                            <TableHeaderCell style={{ width: "40%", textAlign: "center", borderBottom: "1px solid #ccc" }} colSpan={5}>Effort in days</TableHeaderCell>
                            <TableHeaderCell style={{ width: EFFORT_INTERFACE_TYPE_LENGTH, textAlign: "center" }}></TableHeaderCell>
                            <TableHeaderCell style={{ width: EFFORT_NOTES_LENGTH, textAlign: "center" }}></TableHeaderCell>
                            <TableHeaderCell style={{ width: EFFORT_BUTTONS_LENGTH, textAlign: "center" }}></TableHeaderCell>
                        </tr>}
                        <tr style={{ borderBottom: "1px solid #ccc" }}>
                            <TableHeaderCell style={{ width: EFFORT_NUMBER_LENGTH }} >#</TableHeaderCell>
                            <TableHeaderCell style={{ width: EFFORT_INTERFACE_NAME_LENGTH, textAlign: "left" }}>Interface Name</TableHeaderCell>
                            <TableHeaderCell style={{ width: EFFORT_TYPE_LENGTH, textAlign: "center" }}>Arch</TableHeaderCell>
                            <TableHeaderCell style={{ width: EFFORT_TYPE_LENGTH, textAlign: "center" }}>Dev</TableHeaderCell>
                            <TableHeaderCell style={{ width: EFFORT_TYPE_LENGTH, textAlign: "center" }}>Test</TableHeaderCell>
                            <TableHeaderCell style={{ width: EFFORT_TYPE_LENGTH, textAlign: "center" }}>Go-live</TableHeaderCell>
                            <TableHeaderCell style={{ width: EFFORT_TYPE_LENGTH, textAlign: "center" }}>Other</TableHeaderCell>
                            <TableHeaderCell style={{ width: EFFORT_TASK_NOTES_LENGTH }}>Notes</TableHeaderCell>
                            <TableHeaderCell style={{ width: EFFORT_BUTTONS_LENGTH }}></TableHeaderCell>
                        </tr>
                    </thead>
                    <tbody>
                        {workPackage && workPackage.tasks && workPackage.tasks.map(renderEffort)}
                        <tr key={`effort-item-total`} >
                            <TableCell style={{ fontWeight: "bold" }} colSpan={10}>Total days: {sumTaskArch + sumTaskDev + sumTaskTest + sumTaskGolive + sumTaskOther}</TableCell>
                        </tr>
                    </tbody>
                </table>
            </Row>
            <SeparatorRow />
            <Row>
                <TitleLabel>Scope - Introduction</TitleLabel>
            </Row>
            <Row>
                <Value>{renderHtml(workPackage.scope)}</Value>
            </Row>
            <SeparatorRow />
            <Row>
                <TitleLabel>Project diagram</TitleLabel>
            </Row>
            <Row>
                <FilesContainer style={{ width: "100%" }}>
                    <DocumentBox
                        color={COLORS.SIXTH}
                        documents={diagrams}
                        onUpdate={getDocuments}
                        url={getAPIUrl() + `quotes/packages/${props.workPackage.id}/file`}
                        token={state.webToken}
                        type={TYPE_DIAGRAM}
                        max={1}
                    />
                </FilesContainer>
            </Row>
            <SeparatorRow />
            <Row>
                <TitleLabel>General Assumptions</TitleLabel>
            </Row>
            <Row>
                <Value>{renderHtml(workPackage.generalAssumptions)}</Value>
            </Row>
            <SeparatorRow />
            <Row>
                <TitleLabel>Project Specific Assumptions</TitleLabel>
            </Row>
            <Row>
                <Value>{renderHtml(workPackage.specificAssumptions)}</Value>
            </Row>
            <SeparatorRow />
        </WorkPackageContainer>
        }
    </OutsideContainer >
};

export default WorkPackageDetails;
