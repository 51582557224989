import * as React from "react";
import styled from "styled-components";

type TextAlign = "left" | "right" | "center";

interface IProps {
    textAlign?: TextAlign,
    width?: string,
    noBorder?: boolean,
    innerStyle?: {},
    outerStyle?: {},
    children?: React.ReactNode,
    hoverText?: string,
    paddingRight?: string,
    marginRight?: string
}

const TableCellDiv = styled.td<IProps>`
    min-height: 30px;
    overflow: hidden;
    text-align: ${props => props.textAlign ? props.textAlign : "left"};
    justify-content: ${props => {
        switch (props.textAlign) {
            case "left": return "flex-start";
            case "center": return "center";
            case "right": return "flex-end";
        }
        return "row";
    }};
    font-size: 14px;
    line-height:19px;
    border-bottom: 1px solid ${({ noBorder }: { noBorder?: boolean }) => noBorder ? "transparent" : "#e5e5e5"};
    font-weight: 500;
    color: inherit;
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    align-items: center;
    min-width: 0;
    margin-right: ${props => props.marginRight ? props.marginRight : ""};
    padding-right: ${props => props.paddingRight ? props.paddingRight : ""};
    width: ${props => props.width ? props.width : ""};
    .SortLabel {
        flex-direction: ${props => {
        switch (props.textAlign) {
            case "left": return "row";
            case "center": return "row";
            case "right": return "row-reverse";
        }
        return "row";
    }};
    }
    p {
        margin: 0;
        font-size: inherit;
        color: inherit;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .contents {
        padding: 4px 8px;
        width: 90%
    }
    &:first-child {
        .contents {
            padding: 4px 8px 4px 12px;
        }
    }
    &:last-of-type {
        .contents {
            padding: 4px 12px 4px 8px;
        }
    }
`;

const TableCell = (props: IProps) => {
    return <TableCellDiv className="tableCell" {...props} style={props.outerStyle} title={props.hoverText}>
        <span className="contents" style={props.innerStyle}>{props.children}</span>
    </TableCellDiv>;
};

export default TableCell;
