import axios, { AxiosError, AxiosResponse } from "axios";
import { getAPIUrl } from "../../../config";
import IQuote from "../../../ServerEntities/IQuote";
import IPagedQuotes from "../../../ServerEntities/IPagedQuotes";
import IQuoteType from "../../../ServerEntities/IQuoteType";
import INotificationTrigger from "../../../ServerEntities/INotificationTrigger";
import IUser from "../../../ServerEntities/IUser";
import IPricing from "../../../ServerEntities/IPricing";
import IChangeHistory from "../../../ServerEntities/IChangeHistory";


export const saveQuote = (webToken: string, initials: string, quote: IQuote, successCallback: (quote: IQuote) => void, failureCallback: (error: string) => void) => {
    axios.post(
        `${getAPIUrl()}quotes/quotes?initials=${initials}`,
        quote,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IQuote>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const createQuoteNumberFinal = (webToken: string, initials: string, quote: IQuote, successCallback: (quote: IQuote) => void, failureCallback: (error: string) => void) => {
    axios.post(
        `${getAPIUrl()}quotes/opportunities/quote/number/${initials}`,
        quote,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IQuote>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

const getQuoteTypesPromise = (webToken: string) => {
    return axios.get(
        `${getAPIUrl()}quotes/types`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    );
};

export const getQuoteTypes = (webToken: string, successCallback: (quoteTypes: IQuoteType[]) => void, failureCallback: (error: string) => void) => {
    getQuoteTypesPromise(webToken).then((response: AxiosResponse<IQuoteType[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getQuote = (quoteId: string, webToken: string, successCallback: (quote: IQuote) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}quotes/quote/${quoteId}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IQuote>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getQuoteIndependentOfArchived = (quoteId: string, webToken: string, archived: string | null, successCallback: (quote: IQuote) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}quotes/quote/${quoteId}?archived=${archived}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IQuote>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

const getQuotes = (webToken: string, limit?: number, offset?: number, archived?: string) => {
    let url = `${getAPIUrl()}quotes/quotes?`
    if (limit && offset !== undefined) {
        url += `limit=${limit}&offset=${offset}&`
    }
    if (archived !== undefined) {
        url += `archived=${archived}`
    }
    return axios.get(
        url,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    );
};

const getUsers = (webToken: string) => {
    return axios.get(
        `${getAPIUrl()}users`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    );
};

export const getQuotesAndUsers = (webToken: string, limit: number, offset: number, successCallback: (quotes: IPagedQuotes, users: IUser[], quoteTypes: IQuoteType[]) => void,
    failureCallback: (error: string) => void, archived?: string) => {
    Promise.all([
        getQuotes(webToken, limit, offset, archived),
        getUsers(webToken),
        getQuoteTypesPromise(webToken)
    ]).then(([quoteResponse, userResponse, typesResponse]) => {
        successCallback(quoteResponse.data, userResponse.data, typesResponse.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const updateQuote = (webToken: string, quote: IQuote, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.put(
        `${getAPIUrl()}quotes/quotes`,
        quote,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const deleteQuote = (webToken: string, quoteId: number, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.post(
        `${getAPIUrl()}quotes/quotes/${quoteId}/delete`,
        quoteId,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const archiveQuote = (webToken: string, quoteId: number, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.post(
        `${getAPIUrl()}quotes/quotes/${quoteId}/archive`,
        true,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const unarchiveQuote = (webToken: string, quoteId: number, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.post(
        `${getAPIUrl()}quotes/quotes/${quoteId}/archive`,
        false,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const authoriseQuote = (webToken: string, quoteId: number, user: string, successCallback: (quote: IQuote) => void, failureCallback: (error: string) => void) => {
    axios.post(
        `${getAPIUrl()}quotes/quotes/${quoteId}/authorise`,
        user,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IQuote>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getRelatedQuotes = (webToken: string, customerId: number, successCallback: (relatedQuotes: string[]) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}quotes/quotes/${customerId}/related`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<string[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getQuotesCSV = (webToken: string, typeFilter: string, orderOwnerFilter: string, quoteNumberFilter: string, enteredDateFilter: Date, customerFilter: string,
    projectFilter: string, expiryDateStartFilter: Date, expiryDateEndFilter: Date, statusFilter: string, authorisedFilter: string, archivedFilter: string) => {
    let url: string = `${getAPIUrl()}quotes/quotes/csv?bearer=${webToken}`;

    if (typeFilter) {
        url += `&type=${typeFilter}`;
    }
    if (orderOwnerFilter) {
        url += `&orderOwner=${orderOwnerFilter}`;
    }
    if (quoteNumberFilter) {
        url += `&quoteNumber=${quoteNumberFilter}`;
    }
    if (enteredDateFilter) {
        url += `&enteredDate=${enteredDateFilter.getTime()}`;
    }
    if (customerFilter) {
        url += `&customer=${customerFilter}`;
    }
    if (projectFilter) {
        url += `&project=${projectFilter}`;
    }
    if (expiryDateStartFilter) {
        url += `&expiryDateStart=${expiryDateStartFilter.getTime()}`;
    }
    if (expiryDateEndFilter) {
        url += `&expiryDateEnd=${expiryDateEndFilter.getTime()}`;
    }
    if (statusFilter) {
        url += `&status=${statusFilter}`;
    }
    if (authorisedFilter) {
        url += `&authorised=${authorisedFilter === "Yes" ? true : false}`;
    }
    if (archivedFilter) {
        url += `&archived=${archivedFilter === "Yes" ? true : false}`;
    }
    window.open(url);
};

export const createQuoteNotification = (trigger: INotificationTrigger, webToken: string, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.post(
        `${getAPIUrl()}notifications/message/publish`,
        trigger,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getNotificationEmailList = (triggerName: String, webToken: string, successCallback: (emailList: string[]) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}notifications/message/${triggerName}/emails`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<string[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getMyPricing = (webToken: string, successCallback: (orders: IPricing[]) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}quotes/pricing/user`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IPricing[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getAllPricing = (webToken: string, successCallback: (orders: IPricing[]) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}quotes/pricing`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IPricing[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getPricing = (id: number, webToken: string, successCallback: (orders: IPricing) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}quotes/pricing/${id}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IPricing>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const updatePricing = (pricing: IPricing, webToken: string, successCallback: (orders: IPricing) => void, failureCallback: (error: string) => void) => {
    axios.put(
        `${getAPIUrl()}quotes/pricing`,
        pricing,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IPricing>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getPricingHistory = (pricingId: string, webToken: string, successCallback: (changeHistory: IChangeHistory[]) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}history/pricing/${pricingId}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IChangeHistory[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};