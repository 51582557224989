import * as React from "react";
import IChangeHistory from "../../../../ServerEntities/IChangeHistory";
import IPricing from "../../../../ServerEntities/IPricing";
import IUser from "../../../../ServerEntities/IUser";
import ErrorText from "../../../../SharedComponents/ErrorText";
import LoadingIndicator from "../../../../SharedComponents/LoadingIndicator";
import { COLORS } from "../../../../config";
import { SessionContext } from "../../../SessionContext";
import ChangeHistory from "../../Components/ChangeHistory";
import { OutsideContainer } from "../../Customers/CustomerEdit";
import { Main } from "../QuotesTable";
import { getPricingHistory } from "../QuotesService";
import useEffectOnSome from "../../../../CustomHooks/useEffectOnSome";

interface IProps {
    pricing: IPricing
    users: IUser[]
};

const PricingHistory = (props: IProps) => {
    const { state } = React.useContext(SessionContext);
    const pricingId = props.pricing.id.toString();
    const [history, setHistory] = React.useState(null as unknown as IChangeHistory[]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");

    useEffectOnSome(() => {
        if (pricingId) {
            getPricingHistory(pricingId, state.webToken, (ch: IChangeHistory[]) => {
                setLoading(false);
                setError("");
                setHistory(ch);

            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
            });
        }
    }, [pricingId]);

    return <OutsideContainer>
        <LoadingIndicator type="Linear" show={loading} />
        <Main>
            {error && <ErrorText>{error}</ErrorText>}
            {history && <ChangeHistory users={props.users} color={COLORS.SIXTH} id={pricingId} changeHistory={history} />}
        </Main>
    </OutsideContainer>
};

export default PricingHistory;
