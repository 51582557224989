import * as React from "react";
import Button from "./Button";
import Dialog from "./Dialog/Dialog";
import DialogActions from "./Dialog/DialogActions";
import DialogContent from "./Dialog/DialogContent";
import DialogContentText from "./Dialog/DialogContentText";
import ErrorText from "./ErrorText";
import LoadingIndicator from "./LoadingIndicator";
import SelectItem from "./Select/SelectItem";
import { SessionContext } from "../Views/SessionContext";
import { getUsers } from "../Views/Quotes/Components/UsersService";
import IUser from "../ServerEntities/IUser";
import { tellServerUserInteractionHasOccurred } from "../Views/sessionService";
import { COLORS } from "../config";
import DialogTitle from "./Dialog/DialogTitle";
import DynamicSelect from "./Select/DynamicSelect";
import useEffectOnSome from "../CustomHooks/useEffectOnSome";

interface IUserChangeDialogProps {
    open: boolean;
    onClose: () => void;
    applicationName: string;
    refresh?: number;
    dialogRef: { current: HTMLDivElement };
};

const UserChangeDialog = (props: IUserChangeDialogProps) => {
    const { open, onClose } = props;
    const [newUser, setNewUser] = React.useState("");
    const [userChangeLoading, setUserChangeLoading] = React.useState(false);
    const sessionContext = React.useContext(SessionContext);
    const { webToken } = sessionContext.state;
    const [users, setUsers] = React.useState([] as unknown as IUser[]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");

    useEffectOnSome(() => {
        setLoading(true);
        getUsers(webToken, (serverUsers: IUser[]) => {
            setUsers(serverUsers);
            setLoading(false);
            setError("");
        }, (errorMessage: string) => {
            setUsers([]);
            setLoading(false);
            setError(errorMessage);
        });
    }, []);

    const selectedUser = users.find((item: IUser) => item.id === newUser);

    const userInteractionServerCallSuccess = (newJWT: string) => {
        sessionContext.dispatch({ type: "setWebToken", payload: newJWT });
    };

    const confirmChangeUser = () => {
        if (selectedUser) {
            setUserChangeLoading(false);
            sessionContext.dispatch({ type: "setImpersonating", payload: newUser });
            sessionContext.dispatch({ type: "setImpersonatingReason", payload: "" });
            tellServerUserInteractionHasOccurred(webToken, newUser, undefined as unknown as string, userInteractionServerCallSuccess, () => { });
            closeDialog();
        }
    };

    const closeDialog = () => {
        setNewUser("");
        setUserChangeLoading(false);
        onClose();
    };

    return <Dialog open={open} onClose={onClose}>
        <DialogTitle className="DialogTitle">Assume another user</DialogTitle>
        <LoadingIndicator show={loading} type="Linear" />
        <DialogContent className="DialogContent" style={{ height: "80px", margin: "0px 0px 24px 0px" }}>
            <DialogContentText className="DialogContentText">Please select the new user you wish to assume</DialogContentText>
            <DynamicSelect required={true} placeholder="Select a user" onChange={setNewUser} childValues={users && users.map(option => option.id)} value={newUser}>
                {users && users.map((option, index) => <SelectItem height="20px" key={`add-quote-order-owner-item-${index}`} value={option.id}>{option.id}</SelectItem>)}
            </DynamicSelect>
            {error && <ErrorText>{error}</ErrorText>}
        </DialogContent>
        <DialogActions className="DialogActions">
            <Button margin="0 15px" color={COLORS.GREY_SLATE} disabled={newUser === null} loading={userChangeLoading} onClick={confirmChangeUser} >Confirm</Button>
            <Button margin="0 15px" color={COLORS.GREY_SLATE} onClick={closeDialog} plain={true}>Cancel</Button>
        </DialogActions>
    </Dialog>;
};

export default UserChangeDialog;
