import * as React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import { SessionContext } from "../../SessionContext";
import Button from "../../../SharedComponents/Button";
import TextInput, { updateText } from "../../../SharedComponents/TextInput";
import { deleteAnnouncement, getAnnouncements, getAudienceUsers, getDepartments, getTeams, saveAnnouncement } from "./AdminService";
import { RolesContext } from "../../../Roles/RolesContext";
import { componentConfiguration } from "../../../UsefulFunctions/getComponentConfiguration";
import dateConverter from "../../../UsefulFunctions/dateConverter";
import 'react-quill/dist/quill.snow.css';
import TableCell from "../../../SharedComponents/Table/TableCell";
import Checkbox from "../../../SharedComponents/Checkbox";
import ErrorText from "../../../SharedComponents/ErrorText";
import LoadingIndicator from "../../../SharedComponents/LoadingIndicator";
import Table from "../../../SharedComponents/Table/Table";
import IAnnouncement from "../../../ServerEntities/IAnnouncement";
import { OutsideContainer } from "../Customers/CustomerEdit";
import { Main } from "../Opportunities/OpportunitiesTable";
import ICheckboxItem from "../../../ServerEntities/ICheckboxItem";
import { SeparatorRow } from "../Components/ChangeHistory";
import { COLORS } from "../../../config";
import { addDaysToDate } from "../../../UsefulFunctions/dateConverter";
import Dialog from "../../../SharedComponents/Dialog/Dialog";
import IUser from "../../../ServerEntities/IUser";
import { getUsers } from "../Components/UsersService";
import { getUserObjectById } from "../../../UsefulFunctions/quoteUtils";
import { AdminSmallLogo } from "../../MainMenu";
import useEffectOnSome from "../../../CustomHooks/useEffectOnSome";

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const Label = styled.label`
    color: #333333;
    min-width: 140px;
    font-size: 0.9em;
    font-weight: bold;
    line-height: 32px;
`;

export const Legend = styled.label`
    color: #333333;
    min-width: 140px;
    font-size: 0.9em;
    font-style: italic;
    line-height: 32px;
    margin-left: 6px;
`;

export const ButtonContainer = styled.div`
    text-align: left;
`;

export const PreviewContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1 1 100%;
    width: 80%;
`;

export const LowerContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 1 1 100%;
    margin-top: 20px;
`;

export const ActionContainer = styled.div`
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 1 1 100%;
`;

export const Value = styled.div`
    line-height: 32px;
    font-size: 0.9em;
`;

const AudienceContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex: 1 1 100%;
    flex-wrap: wrap;
`;

const AudienceItem = styled.div`
    display: flex;
    width: 250px;
    align-items: center;
`;

const OuterAudienceContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1 1 100%;
    align-content: flex-end;
    align-items: flex-start;
`;

const MainContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex: 1 1 100%;
    align-content: flex-end;
    align-items: flex-start;
`;

interface IRow {
    backgroundColor: string,
    fontColor: string
};
const ConfigurableRow = styled.div<IRow>`
    background-color: ${props => props.backgroundColor};
    color:  ${props => props.fontColor};
`;

const LeftContainer = styled.div`
    width: 90%;
`;

const RightContainer = styled.div`
    width: 10%;
`;

interface IProps {
    onAddComplete: () => void
};

const AnnouncementEdit = (props: IProps) => {
    const { state } = React.useContext(SessionContext);
    const { configuration } = React.useContext(RolesContext).state;
    const rbac = componentConfiguration("Admin", configuration);
    const [announcementToRemoveId, setAnnouncementToRemoveId] = React.useState(undefined as unknown as number);
    const [announcements, setAnnouncements] = React.useState([] as unknown as IAnnouncement[]);
    const [teams, setTeams] = React.useState([] as unknown as ICheckboxItem[]);
    const [departments, setDepartments] = React.useState([] as unknown as ICheckboxItem[]);
    const [audienceUsers, setAudienceUsers] = React.useState([] as unknown as string[]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [refresh, setRefresh] = React.useState(0);
    const [publishDate, setPublishDate] = React.useState(undefined as unknown as Date);
    const [expiryDate, setExpiryDate] = React.useState(undefined as unknown as Date);
    const [announcementText, setAnnouncementText] = React.useState("");
    const [allStaff, setAllStaff] = React.useState(false);
    const [officeStaff, setOfficeStaff] = React.useState(false);
    const [remoteStaff, setRemoteStaff] = React.useState(false);
    const [users, setUsers] = React.useState(null as unknown as IUser[]);

    useEffectOnSome(() => {
        setLoading(true);
        getAnnouncements(state.webToken, (announcements: IAnnouncement[]) => {
            setLoading(false);
            setError("");
            setAnnouncements(announcements);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
            setAnnouncements([] as unknown as IAnnouncement[]);
        });
        getTeams(state.webToken, (teamValues: string[]) => {
            setLoading(false);
            setError("");
            let newTeams: ICheckboxItem[] = [];
            teamValues.forEach(element => newTeams.push({ value: element, status: false }));
            setTeams(newTeams);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
            setTeams([]);
        });
        getDepartments(state.webToken, (departmentValues: string[]) => {
            setLoading(false);
            setError("");
            let newDepartments: ICheckboxItem[] = [];
            departmentValues.forEach(element => newDepartments.push({ value: element, status: false }));
            setDepartments(newDepartments);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
            setTeams([]);
        });
    }, [refresh]);

    useEffectOnSome(() => {
        setLoading(true);
        getUsers(state.webToken, (users: IUser[]) => {
            setLoading(false);
            setError("");
            setUsers(users);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
            setUsers(null as unknown as IUser[]);
        });
    }, []);

    const changeDate = (setter: (date: Date) => void) => (date: Date, event: React.SyntheticEvent) => {
        setter(date);
    };

    const onToggleAllStaff = (checked: boolean) => {
        setAllStaff(checked);
        calculateUsers(checked, officeStaff, remoteStaff);
    }

    const onToggleOfficeStaff = (checked: boolean) => {
        setOfficeStaff(checked);
        calculateUsers(allStaff, checked, remoteStaff);
    }

    const onToggleRemoteStaff = (checked: boolean) => {
        setRemoteStaff(checked);
        calculateUsers(allStaff, officeStaff, checked);
    }

    const onToggleIndex = (index: number, type: string) => (checked: boolean) => {

        let newItems = type === "teams" ? [...teams] : [...departments];
        newItems[index].status = checked;
        if (type === "teams") {
            setTeams(newItems);
            calculateUsers(allStaff, officeStaff, remoteStaff);
        }
        else {
            setDepartments(newItems);
            calculateUsers(allStaff, officeStaff, remoteStaff);
        }
    }

    const onRemoveAnnouncement = (index: number) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const id = announcements[index].id;

        if (id) {
            setAnnouncementToRemoveId(id);
        }
    }

    const removeAnnouncement = () => {
        deleteAnnouncement(state.webToken, announcementToRemoveId, () => {
            setLoading(false);
            setError("");
            setRefresh(refresh + 1);
            setAnnouncementToRemoveId(undefined as unknown as number);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    }

    const renderCheckboxes = (type: string) => (item: ICheckboxItem, index: number) => {
        return <AudienceItem key={`check-${type}-${index}`}>
            <Checkbox checked={item.status} onChange={onToggleIndex(index, type)} /><Label>{item.value}</Label>
        </AudienceItem>
    }

    const calculateUsers = (all: boolean, office: boolean, remote: boolean) => {
        getAudienceUsers(state.webToken, all, office, remote, getCheckedTeams(), getCheckedDepartments(), (users: string[]) => {
            setLoading(false);
            setError("");
            setAudienceUsers(users);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
            setAudienceUsers([]);
        });
    }

    const getCheckedTeams = () => {
        let teamNames: string[] = [];
        teams.filter(t => t.status).forEach(t => teamNames.push(t.value));
        return teamNames;
    }

    const getCheckedDepartments = () => {
        let departmentNames: string[] = [];
        departments.filter(d => d.status).forEach(d => departmentNames.push(d.value));
        return departmentNames;
    }

    const save = () => {
        setLoading(true);
        const user = getUserObjectById(state.loggedInUser, users);
        const newAnnouncement: IAnnouncement = {
            publishDate: publishDate ? publishDate.getTime() : new Date().getTime(),
            expiryDate: expiryDate ? expiryDate.getTime() : addDaysToDate(publishDate !== undefined ? publishDate : new Date(), 30).getTime(),
            createdDate: new Date().getTime(),
            announcement: announcementText,
            audienceAllStaff: allStaff,
            audienceOffice: officeStaff,
            audienceRemote: remoteStaff,
            teams: getCheckedTeams(),
            departments: getCheckedDepartments(),
            createdBy: state.loggedInUser,
            initials: user ? user.initials : ""
        }
        saveAnnouncement(state.webToken, newAnnouncement, () => {
            setLoading(false);
            setError("");
            setRefresh(refresh + 1);
            setPublishDate(undefined as unknown as Date);
            setExpiryDate(undefined as unknown as Date);
            setAnnouncementText("");
            setAllStaff(false);
            setRemoteStaff(false);
            setOfficeStaff(false);
            setUsers([]);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    }

    const renderAudience = (announcement: IAnnouncement) => {
        let audience = "";
        if (announcement.audienceAllStaff) {
            audience += "All; "
        }
        if (announcement.audienceOffice) {
            audience += "Office; "
        }
        if (announcement.audienceRemote) {
            audience += "Remote; "
        }
        audience += announcement.departments.join("; ");
        audience += announcement.teams.join("; ");
        return audience;
    }

    const onCloseDeleteDialog = () => {
        setAnnouncementToRemoveId(undefined as unknown as number);
    }

    return <OutsideContainer>
        <LoadingIndicator type="Linear" show={loading} />
        <Main>
            {error && <ErrorText>{error}</ErrorText>}
            <Dialog style={{ padding: "32px" }} open={announcementToRemoveId !== undefined} onClose={onCloseDeleteDialog}>
                <h3>Are you sure you want to delete?</h3>
                <ActionContainer>
                    <Button color={COLORS.SIXTH} onClick={onCloseDeleteDialog} style={{ marginRight: "10px" }}>Cancel</Button>
                    <Button onClick={removeAnnouncement} color={"#e60000"}>Delete</Button>
                </ActionContainer>
            </Dialog>
            <MainContainer>
                <LeftContainer>
                    <h3>New Announcement</h3>
                    <Row>
                        <Label>Publish Date</Label>
                        <div style={{ width: "150px" }}>
                            <DatePicker placeholderText="Select a date" dateFormat="dd-MMM-yyyy" selected={publishDate} onChange={changeDate(setPublishDate)} />
                        </div>
                        <Legend>Leave blank to publish immediately</Legend>
                    </Row>
                    <Row>
                        <Label>Expiry Date</Label>
                        <div style={{ width: "150px" }}>
                            <DatePicker placeholderText="Select a date" dateFormat="dd-MMM-yyyy" selected={expiryDate} onChange={changeDate(setExpiryDate)} />
                        </div>
                        <Legend>Leave blank to publish in 30 days</Legend>
                    </Row>
                    <Row>
                        <Label>Announcement</Label>
                        <TextInput width="65%" required={false} htmlId="announcementText" value={announcementText} onChange={updateText(setAnnouncementText)} />
                    </Row>

                    <Row style={{ alignItems: "flex-start" }}>
                        <Label>Audience</Label>
                        <OuterAudienceContainer>
                            <AudienceContainer>
                                <AudienceItem>
                                    <Checkbox checked={allStaff} onChange={onToggleAllStaff} /><Label>All Staff</Label>
                                </AudienceItem>
                                <AudienceItem>
                                    <Checkbox checked={officeStaff} onChange={onToggleOfficeStaff} /><Label>Office Staff</Label>
                                </AudienceItem>
                                <AudienceItem>
                                    <Checkbox checked={remoteStaff} onChange={onToggleRemoteStaff} /><Label>Remote Staff</Label>
                                </AudienceItem>
                            </AudienceContainer>
                            <AudienceContainer>
                                {departments && departments.map(renderCheckboxes("departments"))}
                            </AudienceContainer>
                            <AudienceContainer>
                                {teams && teams.map(renderCheckboxes("teams"))}
                            </AudienceContainer>
                        </OuterAudienceContainer>
                    </Row>
                    <LowerContainer>
                        <PreviewContainer>
                            <Row>
                                Announcements will be published to {audienceUsers.join("; ")}
                            </Row>
                            <Row>
                                Announcements will be published {publishDate ? "on " + dateConverter(publishDate) : "now"}
                            </Row>
                            <Row>
                                Announcements will expire {expiryDate ? "on " + dateConverter(expiryDate) : "on " + dateConverter(addDaysToDate(publishDate !== undefined ? publishDate : new Date(), 30))}
                            </Row>
                        </PreviewContainer>
                        <ActionContainer style={{ width: "20%" }}>
                            <Button color={COLORS.GREY_SLATE} onClick={save}>Publish Announcement</Button>
                        </ActionContainer>
                    </LowerContainer>
                </LeftContainer>
                <RightContainer>
                    <AdminSmallLogo />
                </RightContainer>
            </MainContainer>
            <SeparatorRow />
            <h3>Your previous Announcements</h3>
            <Table>
                <tbody>
                    <tr style={{ minHeight: "48px", display: "flex" }} >
                        <TableCell width={"10%"} innerStyle={{ fontWeight: "bold" }}>Date Added</TableCell>
                        <TableCell width={"10%"} innerStyle={{ fontWeight: "bold" }}>Publish Date</TableCell>
                        <TableCell width={"10%"} innerStyle={{ fontWeight: "bold" }}>Expiry Date</TableCell>
                        <TableCell width={"40%"} innerStyle={{ fontWeight: "bold" }}>Announcement Text</TableCell>
                        <TableCell width={"20%"} innerStyle={{ fontWeight: "bold" }}>Audience</TableCell>
                        <TableCell width={"10%"} innerStyle={{ display: "flex", justifyContent: "flex-end" }}></TableCell>
                    </tr>
                    {announcements && announcements.map((announcement, index) => {
                        const fontColor = announcement.publishDate < new Date().getTime() ? "black" : "blue";
                        const backgroundColor = announcement.expiryDate < new Date().getTime() ? "#C1C1C1" : "white";
                        const expired = announcement.expiryDate < new Date().getTime() ? true : false;

                        return <ConfigurableRow backgroundColor={backgroundColor} fontColor={fontColor} key={`announcements-item=${index}`} style={{ minHeight: "48px", display: "flex", flexDirection: "row" }}>
                            <TableCell width={"10%"}>{dateConverter(announcement.createdDate)}</TableCell>
                            <TableCell width={"10%"}>{dateConverter(announcement.publishDate)}</TableCell>
                            <TableCell width={"10%"}>{dateConverter(announcement.expiryDate)}</TableCell>
                            <TableCell width={"40%"}>{announcement.announcement}</TableCell>
                            <TableCell width={"20%"}>{renderAudience(announcement)}</TableCell>
                            <TableCell width={"10%"}>
                                <ButtonContainer>
                                    {rbac.update && !expired && <Button plain={true} onClick={onRemoveAnnouncement(index)}>Remove</Button>}
                                </ButtonContainer>
                            </TableCell>
                        </ConfigurableRow>;
                    })
                    }
                </tbody>
            </Table>
        </Main>
    </OutsideContainer >
};

export default AnnouncementEdit;
