import * as React from "react";
import styled from "styled-components";
import IChangeHistory from "../../../ServerEntities/IChangeHistory";
import dateConverter from "../../../UsefulFunctions/dateConverter";
import IUser from "../../../ServerEntities/IUser";
import { faChevronCircleDown, faChevronCircleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TableCell = styled.td`
    height: 24px;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: center;
    padding: 10px 0px 10px 0px;
`;

export const Title = styled.h1`
    font-size: 1.3rem;
    margin: 12px 0px 20px;
`;

export const Main = styled.main`
    padding: 50px;
    display: flex;
    flex-direction: column;
    text-align: left;
`;


export const SeparatorRow = styled.div`
    border-bottom: solid 3px #999;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const TableLabel = styled.div`
    margin: 12px 0px 30px;

`;

const Table = styled.table`
    border-collapse: collapse;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin: 10px 0px 10px;
`;

const Label = styled.label`
    color: #333333;
    line-height: 32px;
    min-width: 100px;
    font-weight: bold;
    font-size: 0.9em;
`;

const Value = styled.div`
    line-height: 32px;
     font-size: 0.9em;
`;

interface IProps {
    changeHistory: IChangeHistory[],
    id: string,
    users: IUser[],
    color: string;
};

const COLLAPSED_TEXT_LIMIT = 30;


export const getUserById = (id: string, users?: IUser[]) => {
    if (id && users) {
        const userObj = users.filter(obj => {
            return obj.id === id
        });
        return userObj[0] ? userObj[0].fullName : id;
    }
    return id;
};

const ChangeHistory = (props: IProps) => {
    const history = props.changeHistory;
    const [expanded, setExpanded] = React.useState(new Array(history.length).fill(false));

    const renderJson = (text: string, expanded: boolean) => {
        if (!text) {
            return text;
        }
        text = text.replace(/\\/g, "");
        try {
            if (expanded || text.length <= COLLAPSED_TEXT_LIMIT) {

                return JSON.stringify(JSON.parse(text), null, 2);
            } else {
                text = text.substring(0, COLLAPSED_TEXT_LIMIT) + "...";
                return JSON.stringify(JSON.parse(text));
            }
        } catch (e) {
            return text;
        }
    };

    const renderHistory = (value: IChangeHistory, index: number, array: IChangeHistory[]) => {
        const request = JSON.parse(value.request);

        const toggleExpand = () => {
            expanded[index] = !expanded[index];
            setExpanded([...expanded]);
        }

        return <tr>
            <TableCell>{dateConverter(value.date, true)}</TableCell>
            <TableCell>{value.description}</TableCell>
            <TableCell><pre>{renderJson(request, expanded[index])}</pre></TableCell>
            <TableCell style={{ padding: "0px 10px 0px 10px" }}>
                <div onClick={toggleExpand}>
                    {!expanded[index] && <FontAwesomeIcon style={{ color: "#4c4c4c" }} icon={faChevronCircleDown} />}
                    {expanded[index] && <FontAwesomeIcon style={{ color: "#4c4c4c" }} icon={faChevronCircleUp} />}
                </div></TableCell>
            <TableCell>{getUserById(value.username, props.users)} {value.impersonating ? " on behalf of " + value.impersonating : ""}</TableCell>
            <TableCell>{value.impersonatingReason}</TableCell>
        </tr>
    }

    return <div>
        <Row>
            <Label htmlFor="customerName">ID</Label>
            <Value>{props.id}</Value>
        </Row>
        <SeparatorRow />
        <Table style={{ width: "100%" }}>
            <thead>
                <tr>
                    <th style={{ height: "20px", width: "10%" }}><TableLabel>Date</TableLabel></th>
                    <th style={{ height: "20px", width: "15%" }}><TableLabel>Action</TableLabel></th>
                    <th style={{ height: "20px", width: "45%" }}><TableLabel>New Detail</TableLabel></th>
                    <th style={{ height: "20px", width: "5%" }}><TableLabel></TableLabel></th>
                    <th style={{ height: "20px", width: "15%" }}><TableLabel>Actioned By</TableLabel></th>
                    <th style={{ height: "20px", width: "10%" }}><TableLabel>Reason</TableLabel></th>
                </tr>
            </thead>
            <tbody>
                {history && expanded && history.map(renderHistory)}
            </tbody>
        </Table>

    </div>;
};

export default ChangeHistory;
