import * as React from 'react';
import styled from 'styled-components';
import { COLORS } from '../config';
import Banner from './Banner';

const WorklistContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 2.5 1 0;
    border: 3px solid grey;
    border-left: none;
    margin: 0;
`;

const Worklist = () => {

    return <WorklistContainer>
        <Banner $backgroundColor={COLORS.GREY_SLATE}>Worklist</Banner>
    </WorklistContainer>
};

export default Worklist;