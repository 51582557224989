import * as React from "react";
import { SessionContext } from "../../SessionContext";
import LoadingIndicator from "../../../SharedComponents/LoadingIndicator";
import { getOpportunityHistory, } from "./OpportunitiesService";
import ErrorText from "../../../SharedComponents/ErrorText";
import { Main } from "./OpportunitiesTable";
import IChangeHistory from "../../../ServerEntities/IChangeHistory";
import ChangeHistory from "../Components/ChangeHistory";
import IOpportunity from "../../../ServerEntities/IOpportunity";
import { COLORS } from "../../../config";
import IUser from "../../../ServerEntities/IUser";
import { OutsideContainer } from "../Customers/CustomerEdit";
import useEffectOnSome from "../../../CustomHooks/useEffectOnSome";

interface IProps {
    opportunity: IOpportunity
    users: IUser[],

};

const OpportunityHistory = (props: IProps) => {
    const { state } = React.useContext(SessionContext);
    const opportunityId = props.opportunity.id.toString();
    const [history, setHistory] = React.useState(null as unknown as IChangeHistory[]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");

    useEffectOnSome(() => {
        if (opportunityId) {
            getOpportunityHistory(opportunityId, state.webToken, (ch: IChangeHistory[]) => {
                setLoading(false);
                setError("");
                setHistory(ch);

            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
            });
        }
    }, [opportunityId]);

    return <OutsideContainer>
        <LoadingIndicator type="Linear" show={loading} />
        <Main>
            {error && <ErrorText>{error}</ErrorText>}
            {history && <ChangeHistory users={props.users} color={COLORS.SIXTH} id={opportunityId} changeHistory={history} />}
        </Main>
    </OutsideContainer>
};

export default OpportunityHistory;
