import * as React from "react";

import Button from '../../../SharedComponents/Button';
import ViewContainer from '../../../SharedComponents/ViewContainer';
import ManagerTitleBar from '../../../Views/Manager/ManagerTitleBar';
import RoleUserDetails from "./RoleUserDetails";

import createErrorMessage from '../../../UsefulFunctions/createErrorMessage';
import * as roleManagementService from "./roleManagementService";

import IRole from '../../../ServerEntities/IRole';

interface IRoleDetailsProps {
    selectedRole: IRole
    webToken: string,
    refreshRoles: () => void,
    setErrorMessage: (errorMessage: string) => void,
    currentRole: string,
    currentUser: string
};

const RoleDetails = (props: IRoleDetailsProps) => {
    const [refresh, setRefresh] = React.useState(0);
    const { webToken, selectedRole, currentRole, refreshRoles, setErrorMessage, currentUser } = props;

    const disableRole = () => {
        if (selectedRole) {
            setErrorMessage("");
            roleManagementService.disableRole(
                selectedRole.id,
                webToken,
                () => {
                    refreshRoles();
                    refreshHistory();
                },
                (serverError: string) => {
                    setErrorMessage(createErrorMessage("disabling role", serverError));
                });
        }
    };

    const enableRole = () => {
        if (selectedRole) {
            setErrorMessage("");
            roleManagementService.enableRole(
                selectedRole.id,
                webToken,
                () => {
                    refreshRoles();
                    refreshHistory();
                },
                (serverError: string) => {
                    setErrorMessage(createErrorMessage("enabling role", serverError));
                });
        }
    };

    const refreshHistory = () => {
        setRefresh(refresh + 1);
    };

    return <ViewContainer>
        <ManagerTitleBar viewName={`${selectedRole.id}`} viewDescription={`${selectedRole.description}`}>
            {!selectedRole.status && <Button color={"#4f7326"} onClick={enableRole} disabled={selectedRole && selectedRole.id === currentRole}>Enable role</Button>}
            {selectedRole.status && <Button color={"#ad0000"} onClick={disableRole} disabled={selectedRole && selectedRole.id === currentRole}>Disable role</Button>}
        </ManagerTitleBar>
        <RoleUserDetails
            selectedRole={selectedRole}
            webToken={webToken}
            currentUser={currentUser}
            currentRole={currentRole}
            setErrorMessage={setErrorMessage}
        />
    </ViewContainer>;
};

export default RoleDetails;
