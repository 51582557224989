import * as React from "react";
import styled from "styled-components";
import { SessionContext } from "../../SessionContext";
import Button from "../../../SharedComponents/Button";
import LoadingIndicator from "../../../SharedComponents/LoadingIndicator";
import TextInput, { updateText } from "../../../SharedComponents/TextInput";
import IOpportunity from "../../../ServerEntities/IOpportunity";
import { commitOpportunityNotification, deleteOpportunityFile, getOpportunity, getOpportunityDocuments, updateOpportunity } from "./OpportunitiesService";
import ErrorText from "../../../SharedComponents/ErrorText";
import { Main } from "./OpportunitiesTable";
import IMeetingInformation from "../../../ServerEntities/IMeetingInformation";
import ICustomerContactDetails from "../../../ServerEntities/ICustomerContactDetails";
import Dialog from "../../../SharedComponents/Dialog/Dialog";
import Table from "../../../SharedComponents/Table/Table";
import TableCell from "../../../SharedComponents/Table/TableCell";
import { componentConfiguration } from "../../../UsefulFunctions/getComponentConfiguration";
import { RolesContext } from "../../../Roles/RolesContext";
import dateConverter from "../../../UsefulFunctions/dateConverter";
import SelectItem from "../../../SharedComponents/Select/SelectItem";
import IQuote from "../../../ServerEntities/IQuote";
import { STATUS_CLOSED, STATUS_COMMITED, TYPE_DOCUMENT, canCommitOpportunity, getContactIdByName } from "../../../UsefulFunctions/opportunityUtils"
import { getCustomer, getCustomerContacts } from "../Customers/CustomersService";
import Select from "../../../SharedComponents/Select/Select";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { createTriggerCommitOpportunity } from "../../../UsefulFunctions/createTriggers";
import { COLORS, getAPIUrl } from "../../../config";
import ICustomer from "../../../ServerEntities/ICustomer";
import Checkbox from "../../../SharedComponents/Checkbox";
import QuoteDetails from "./QuoteDetails";
import IQuoteType from "../../../ServerEntities/IQuoteType";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import IDocument from "../../../ServerEntities/IDocument";
import IUser from "../../../ServerEntities/IUser";
import DocumentBox from "../Components/DocumentBox";
import { DocumentContainer, Label, OpportunityHeader, Row, SeparatorRow, TitleAndButton } from "./OpportunityAdd";
import TransferOpportunityDialog from "./TransferOpportunity";
import StatusBar from "../Components/StatusBar";
import ICurrentStatus from "../../../ServerEntities/ICurrentStatus";
import ImpersonatingRoadblock from "../Components/ImpersonatingRoadblock";
import ISave from "../../../ServerEntities/ISave";
import { ActionContainer, OutsideContainer } from "../Customers/CustomerEdit";
import { OpportunitiesSmallLogo } from "../../MainMenu";
import CloseOpportunityDialog from "../Components/CloseOpportunity";
import IntegrationEngineDetails from "../Components/IntegrationEngineDetails";
import IIntegrationEngine from "../../../ServerEntities/IIntegrationEngine";
import useEffectOnSome from "../../../CustomHooks/useEffectOnSome";

const ContactsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-height: 62px;
`;

const ContactContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
`;
const ContactName = styled.div`
    padding: 5px;
`;

const ButtonContainer = styled.div`
    text-align: left;
    width: 90%;
`;

const FilesContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
`;

interface IProps {
    types: IQuoteType[],
    opportunity: IOpportunity
    users: IUser[],
    customers: ICustomer[],
    onAddComplete: () => void,
    onViewHistory: (opportunity: IOpportunity) => void
};

const OpportunityEdit = (props: IProps) => {
    const { state } = React.useContext(SessionContext);
    const { configuration } = React.useContext(RolesContext).state;
    const rbac = componentConfiguration("Opportunities", configuration);
    const quoteTypes = props.types;
    const { users, customers } = props;
    const [workRequired, setWorkRequired] = React.useState("");
    const [outOfScope, setOutOfScope] = React.useState("");
    const [contacts, setContacts] = React.useState([] as ICustomerContactDetails[]);
    const [meetings, setMeetings] = React.useState([] as IMeetingInformation[]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [editingMeeting, setEditingMeeting] = React.useState(undefined as unknown as IMeetingInformation);
    const [freeText, setFreeText] = React.useState("");
    const customerId = props.opportunity.quote.customerId || 0;
    const [meetingDate, setMeetingDate] = React.useState(undefined as unknown as Date);
    const [installationRequired, setInstallationRequired] = React.useState(false);
    const [source, setSource] = React.useState("");
    const [sourceText, setSourceText] = React.useState("");
    const [sourceUser, setSourceUser] = React.useState("");
    const [documents, setDocuments] = React.useState(undefined as unknown as IDocument[]);
    const [transferOp, setTransferOp] = React.useState(false);
    const [showCloseOpportunity, setShowCloseOpportunity] = React.useState(false);
    const [currentStatus, setCurrentStatus] = React.useState(undefined as unknown as ICurrentStatus);
    const [saving, setSaving] = React.useState(undefined as unknown as ISave);
    const [status, setStatus] = React.useState(0);
    const [closeReason, setCloseReason] = React.useState("");
    const [closeRevisit, setCloseRevisit] = React.useState(undefined as unknown as number);

    const none = -1;
    const [quote, setQuote] = React.useState(undefined as unknown as IQuote);
    const [customer, setCustomer] = React.useState(undefined as unknown as ICustomer);
    const [commitingOp, setCommitingOp] = React.useState(false);

    const calculateSourceText = () => {
        if (source.includes("Other")) {
            return source.split(" - ")[1];
        }
        return "";
    }

    const calculateSourceUser = () => {
        if (source.includes("Projects")) {
            return source.split(" - ")[1];
        }
        return "";
    }

    useEffectOnSome(() => {
        getOpportunity(props.opportunity.id, state.webToken, (op: IOpportunity) => {
            setLoading(false);
            setError("");
            setQuote(op.quote);
            setWorkRequired(op.workRequired);
            setOutOfScope(op.outOfScope);
            setMeetings(op.meetingInformation);
            setInstallationRequired(op.installationRequired);
            setSource(op.source);
            setStatus(op.status);
            setCloseReason(op.closeReason);
            if (op.closeRevisit) {
                setCloseRevisit(op.closeRevisit);
            }

            setSourceText(calculateSourceText);
            setSourceUser(calculateSourceUser);
            setCurrentStatus(op.currentStatus ? op.currentStatus : undefined as unknown as ICurrentStatus)
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
        getDocuments(TYPE_DOCUMENT);

        if (customerId !== 0) {
            getCustomer(state.webToken, customerId, (c: ICustomer) => {
                setLoading(false);
                setError("");
                setCustomer(c);
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
                setCustomer(null as unknown as ICustomer);
            });
        }

        getCustomerContacts(customerId, state.webToken, (contacts: ICustomerContactDetails[]) => {
            setLoading(false);
            setError("");
            setContacts(contacts);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
            setContacts(null as unknown as ICustomerContactDetails[]);
        });
    }, []);


    const calculateSource = () => {
        if (source === "Other") {
            return "Other - " + sourceText;
        }
        if (source === "Projects") {
            return "Projects - " + sourceUser;
        }
        return source;
    }

    const save = (commit: boolean, replaceQuote?: IQuote) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        console.log("set saving: " + commit);
        setSaving({ commit: commit, replaceQuote: replaceQuote });
    }

    const executeSave = (token: string) => {
        setSaving(undefined as unknown as ISave);
        if (quote.relatedQuotes === null) {
            quote.relatedQuotes = [];
        }
        const opportunity: IOpportunity = {
            id: props.opportunity.id,
            quote: saving.replaceQuote ? saving.replaceQuote : quote,
            workRequired: workRequired,
            outOfScope: outOfScope,
            meetingInformation: meetings,
            status: saving.commit ? STATUS_COMMITED : status,
            installationRequired: installationRequired,
            source: calculateSource(),
            committedBy: props.opportunity.committedBy,
            currentStatus: currentStatus,
            closeReason: closeReason,
            closeRevisit: closeRevisit
        };
        setLoading(true);
        console.log("save");
        updateOpportunity(token ? token : state.webToken, opportunity, () => {
            setLoading(false);
            setError("");
            if (saving.commit) {
                const trigger = createTriggerCommitOpportunity(opportunity);
                commitOpportunityNotification(trigger, state.webToken, () => { }, () => { },)
            }
            setSaving(undefined as unknown as ISave);
            props.onAddComplete();
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });

    };

    const addMeeting = () => {
        const meetingCopy = [...meetings];
        const newMeeting = {
            date: 0, freeText: "",
            id: none,
            contacts: []
        };
        meetingCopy.push(newMeeting);
        setMeetings(meetingCopy);
        setEditingMeeting(newMeeting);
        setMeetingDate(undefined as unknown as Date);
        setFreeText("");
    };


    const addContact = (meetings: IMeetingInformation[], index: number) => () => {
        const contactsCopy = [...editingMeeting.contacts];
        contactsCopy.push({
            id: none,
            name: "",
            email: "",
            jobTitle: "",
            phone: "",
            customerId: 0,
            visibleToDev: false,
            source: "",
            quoteRecipient: 0
        });
        const meetingCopy = [...meetings];
        meetingCopy[index].contacts = contactsCopy;
        setMeetings(meetingCopy);
        setEditingMeeting(meetings[index]);
    }

    const onEditMeeting = (meeting: IMeetingInformation) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setMeetingDate(new Date(meeting.date));
        setFreeText(meeting.freeText);
        setEditingMeeting(meeting)
    };

    const onRemoveMeeting = (index: number) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const meetingCopy = [...meetings];
        meetingCopy.splice(index, 1);
        setMeetings(meetingCopy);
        setEditingMeeting(undefined as unknown as IMeetingInformation);
    };

    const onContactChange = (index: number) => (contactName: string) => {
        const editingMeetingCopy = { ...editingMeeting };
        editingMeetingCopy.contacts[index].name = contactName;
        editingMeetingCopy.contacts[index].id = getContactIdByName(contactName, contacts);
        setEditingMeeting(editingMeetingCopy);
    }

    const onRemoveContact = (index: number) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const editingMeetingCopy = { ...editingMeeting };
        editingMeetingCopy.contacts.splice(index, 1);
        setEditingMeeting(editingMeetingCopy);
    }

    const renderContact = (meetingId: number) => (contact: ICustomerContactDetails, index: number) => {
        if (contact.id === none) {
            return <ContactContainer>
                <Select placeholder="Select a contact" onChange={onContactChange(index)} childValues={contacts && contacts.map(option => option.name)} value={editingMeeting.contacts[index].name}>
                    {contacts.map((option, index) => <SelectItem height="20px" key={`add-contact-item-${index}`} value={option.name}>{option.name}</SelectItem>)}
                </Select>
            </ContactContainer >
        }
        return <ContactContainer>
            <ContactName>{contact.name}</ContactName>
            {editingMeeting !== undefined && meetingId === editingMeeting.id && <Button plain={true} onClick={onRemoveContact(index)}>x</Button>}
        </ContactContainer>
    }

    const onSaveMeeting = (index: number) => () => {
        editingMeeting.id = 1;
        if (meetingDate) {
            editingMeeting.date = meetingDate.getTime();
        }
        editingMeeting.freeText = freeText;
        const meetingCopy = [...meetings];
        meetingCopy[index] = { ...editingMeeting };
        setMeetings(meetingCopy);
        setEditingMeeting(undefined as unknown as IMeetingInformation);
    }

    const onCancelMeeting = (index: number) => () => {
        const meetingCopy = [...meetings];
        setMeetings(meetingCopy);
        setEditingMeeting(undefined as unknown as IMeetingInformation);
    }

    const changeDate = (setter: (date: Date) => void) => (date: Date, event: React.SyntheticEvent) => {
        setter(date);
    };

    const onInstalationRequired = (checked: boolean): void => {
        setInstallationRequired(checked);
    }

    const onUpdateQuote = (update: IQuote) => {
        setQuote(update);
    }

    const onUpdateQuoteClose = (replaceQuote: IQuote) => {
        save(false, replaceQuote)();
    }

    const getDocuments = (type: string) => {
        getOpportunityDocuments(props.opportunity.id, type, state.webToken, (files: IDocument[]) => {
            setLoading(false);
            setError("");
            setDocuments(files.length > 0 ? files : undefined as unknown as IDocument[]);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    }

    const onRemove = (id: number) => {
        deleteOpportunityFile(state.webToken, id, () => {
            setLoading(false);
            setError("");
            getDocuments(TYPE_DOCUMENT);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    }

    const validateOpportunity = () => {
        if (quote && quote.customer && quote.customer.length > 0 && quote.projectName.length > 0) {
            return true;
        }
        return false;
    }

    const navigateToHistory = () => {
        props.onViewHistory(props.opportunity);
    }

    const onTransferOpportunity = () => {
        setTransferOp(true);
    }

    const onCloseTransferOpportunity = () => {
        setTransferOp(false);
    }

    const onUpdateCurrentStatus = (status: ICurrentStatus) => {
        setCurrentStatus(status);
    }

    const onLoading = () => {
        setLoading(true);
    }

    const onFinishedLoading = () => {
        setLoading(false);
    }

    const onCloseDialog = () => {
        setShowCloseOpportunity(false);
    }

    const onCloseOpportunity = () => {
        setShowCloseOpportunity(true);
    }

    const onClose = (reason: string, revisit: number | undefined) => {
        setStatus(STATUS_CLOSED);
        setCloseReason(reason);
        if (revisit) {
            setCloseRevisit(revisit);
        }
        setSaving({ commit: false });
    }

    const onUpdateIntegrationEngine = (integrationEngine: IIntegrationEngine) => {
        quote.integrationEngine = integrationEngine.engine;
        quote.integrationEngineNotes = integrationEngine.notes;
        setQuote({ ...quote });
    }

    const onCloseCommitDialog = () => {
        setCommitingOp(false);
    }

    const onCommitingOp = () => {
        console.log("setCommitingOp");
        setCommitingOp(true);
    }

    return <OutsideContainer>
        <LoadingIndicator type="Linear" show={loading} />
        <CloseOpportunityDialog show={showCloseOpportunity} onCommit={onClose} onClose={onCloseDialog} />
        <Main>
            {error && <ErrorText>{error}</ErrorText>}
            {saving && <ImpersonatingRoadblock onCommit={executeSave}></ImpersonatingRoadblock>}
            <TransferOpportunityDialog id={props.opportunity.id} show={transferOp} users={users} onClose={onCloseTransferOpportunity} onQuoteUpdate={onUpdateQuoteClose} />

            <Dialog style={{ padding: "32px" }} open={commitingOp} onClose={onCloseCommitDialog}>
                <h3>This Opportunity will be passed to Architecture and you will not be able to make further changes.</h3>
                <ActionContainer>
                    <Button color={COLORS.SIXTH} onClick={onCloseCommitDialog} style={{ marginRight: "10px" }}>Cancel</Button>
                    <Button onClick={save(true)} color={"#e60000"}>Commit</Button>
                </ActionContainer>
            </Dialog>
            <OpportunityHeader>
                <ButtonContainer>
                    {quote && <StatusBar status={currentStatus} onUpdateStatus={onUpdateCurrentStatus} />}
                    <Button disabled={!validateOpportunity()} color={COLORS.GREY_SLATE} submit={true} onClick={save(false)}>Save opportunity</Button>
                    <Button disabled={!validateOpportunity() || !canCommitOpportunity(configuration)} color={COLORS.GREY_SLATE} style={{ marginLeft: "8px" }} onClick={onCommitingOp}>Save and commit opportunity</Button>
                    <Button color={COLORS.GREY_SLATE} plain={true} style={{ marginLeft: "8px" }} onClick={props.onAddComplete}>Cancel</Button>
                    <Button color={COLORS.GREY_SLATE} plain={false} style={{ marginLeft: "8px" }} onClick={onTransferOpportunity}>Transfer Opportunity</Button>
                    <Button color={COLORS.GREY_SLATE} plain={false} style={{ marginLeft: "8px" }} onClick={onCloseOpportunity}>Close Opportunity</Button>
                    <Button color={COLORS.CHANGE_HISTORY} plain={false} style={{ marginLeft: "8px" }} onClick={navigateToHistory}>View Change History</Button>
                </ButtonContainer>
                <OpportunitiesSmallLogo />
            </OpportunityHeader>

            {quote && <QuoteDetails onLoading={onLoading} onFinishedLoading={onFinishedLoading} editable={true} types={quoteTypes} customers={customers} users={users} quote={quote} onUpdateQuote={onUpdateQuote} />}
            <SeparatorRow />
            <Row>
                <h3>Integration Engine Details</h3>
            </Row>
            {quote && <IntegrationEngineDetails editable={true} customer={customer} quote={quote} onUpdateIntegrationEngine={onUpdateIntegrationEngine} />}
            <Row>
                <Label htmlFor="installationRequired">Installation Required</Label>
                <Checkbox checked={installationRequired} onChange={onInstalationRequired} ></Checkbox>
            </Row>
            <SeparatorRow />
            <Row>
                <h3>Work Required</h3>
            </Row>
            <Row>
                <ReactQuill style={{ width: "100%" }} value={workRequired} onChange={setWorkRequired} />
            </Row>
            <Row>
                <h3>Future Requirements and Out of Scope</h3>
            </Row>
            <Row>
                <ReactQuill style={{ width: "100%", marginBottom: "10px" }} value={outOfScope} onChange={setOutOfScope} />
            </Row>
            <SeparatorRow />
            <TitleAndButton>
                <h3 style={{ marginRight: "20px" }}>Meetings</h3>
                {(customerId && editingMeeting === undefined) && <Button color={COLORS.GREY_SLATE} onClick={addMeeting} submit={false}>Add meeting</Button>}
            </TitleAndButton>
            {meetings && <Table>
                <tbody>
                    <tr style={{ minHeight: "48px", display: "flex" }} >
                        <TableCell width={"10%"} innerStyle={{ fontWeight: "bold" }}>Date</TableCell>
                        <TableCell width={"30%"} innerStyle={{ fontWeight: "bold" }}>Free text</TableCell>
                        <TableCell width={"40%"} innerStyle={{ fontWeight: "bold" }}>Contacts</TableCell>
                        <TableCell width={"20%"} innerStyle={{ display: "flex", justifyContent: "flex-end" }}></TableCell>
                    </tr>
                    {meetings.sort((a, b) => b.date - a.date).map((meeting, index) => {
                        const id = meeting.id ? meeting.id : none;
                        if (editingMeeting && editingMeeting.id === id) {
                            return <tr key={`contact-entry=${index}`} style={{ minHeight: "48px", display: "flex" }}>
                                <TableCell width={"10%"}>
                                    <DatePicker required={true} dateFormat="dd-MMM-yyyy" selected={meetingDate} onChange={changeDate(setMeetingDate)} placeholderText={"select a date"} />
                                </TableCell>
                                <TableCell width={"30%"}>
                                    <TextInput width="100%" textArea={true} value={freeText} onChange={updateText(setFreeText)} placeholder="Free text" />
                                </TableCell>
                                <TableCell width={"40%"}>
                                    <ContactsContainer>
                                        {contacts && contacts.length > 0 && <Button color={COLORS.SIXTH} onClick={addContact(meetings, index)} style={{ marginRight: "30px" }} submit={false}>Add contact</Button>}
                                        {meeting.contacts.map(renderContact(meeting.id))}
                                        {(contacts === null || contacts.length === 0) && <ContactContainer>No contacts available for this customer.</ContactContainer>}
                                    </ContactsContainer>
                                </TableCell>
                                <TableCell width={"20%"}>
                                    <ButtonContainer>
                                        {rbac.update && <Button plain={true} onClick={onSaveMeeting(index)} submit={false} style={{ marginRight: "10px" }}>Save</Button>}
                                        {rbac.update && <Button plain={true} onClick={onCancelMeeting(index)} submit={false}>Cancel</Button>}
                                    </ButtonContainer>
                                </TableCell>
                            </tr>;
                        } else {
                            return <tr key={`contact-entry=${index}`} style={{ minHeight: "48px", display: "flex", flexDirection: "row" }}>
                                <TableCell width={"10%"}>{dateConverter(meeting.date)}</TableCell>
                                <TableCell width={"30%"}>{meeting.freeText}</TableCell>
                                <TableCell width={"40%"}>
                                    <ContactsContainer>
                                        {meeting.contacts.map(renderContact(meeting.id))}
                                    </ContactsContainer>
                                </TableCell>
                                <TableCell width={"20%"}>
                                    {editingMeeting === undefined &&
                                        <ButtonContainer>
                                            {rbac.update && <Button plain={true} onClick={onEditMeeting(meeting)} style={{ marginRight: "10px" }}>Edit</Button>}
                                            {rbac.update && <Button plain={true} onClick={onRemoveMeeting(index)}>Remove</Button>}
                                        </ButtonContainer>
                                    }
                                </TableCell>
                            </tr>;
                        }
                    })
                    }
                </tbody>
            </Table>}
            <SeparatorRow />
            <Row>
                <h3>Attachments</h3>
            </Row>
            <Row>
                <DocumentContainer>
                    <FilesContainer style={{ width: "100%" }}>
                        <DocumentBox
                            color={COLORS.SIXTH}
                            documents={documents}
                            onRemove={onRemove}
                            onUpdate={getDocuments}
                            url={getAPIUrl() + `quotes/opportunities/${props.opportunity.id}/file`}
                            token={state.webToken}
                            type={TYPE_DOCUMENT}
                        />
                    </FilesContainer>
                </DocumentContainer>
            </Row>
        </Main>
    </OutsideContainer>
};

export default OpportunityEdit;
