import axios, { AxiosError, AxiosPromise } from 'axios';

import { useState } from 'react';
import { getAPIUrl } from '../config';
import useEffectOnSome from './useEffectOnSome';

export interface IRestApiRequestStatus<T> {
    data: T,
    loading: boolean,
    error: string,
    unsupported?: boolean
}

type HttpRequestVerb = "GET" | "PUT" | "POST" | "DELETE";

const makeApiRequest = (url: string, verb: HttpRequestVerb, body?: object, webToken?: string): AxiosPromise<any> => {
    switch (verb) {
        case "POST":
            return axios.post(
                getAPIUrl() + url,
                body,
                { headers: { Authorization: `Bearer ${webToken}` } }
            ).then(result => result)
                .catch(error => {
                    throw error;
                });

        case "GET":
            return axios.get(
                getAPIUrl() + url,
                { headers: { Authorization: `Bearer ${webToken}` } }
            ).then(result => result)
                .catch(error => {
                    throw error;
                });

        case "DELETE":
            return axios.post(
                getAPIUrl() + url,
                body,
                { headers: { Authorization: `Bearer ${webToken}` } }
            ).then(result => result)
                .catch(error => {
                    throw error;
                });

        case "PUT":
            return axios.put(
                getAPIUrl() + url,
                body,
                { headers: { Authorization: `Bearer ${webToken}` } }
            ).then(result => result)
                .catch(error => {
                    throw error;
                });

    }
};

const useManagerAPI = (url: string, verb: HttpRequestVerb, body: object, webToken: string, refresh?: number | string, disabled?: boolean, clearDataOnRefresh?: boolean): IRestApiRequestStatus<any> => {
    const [data, setData] = useState(undefined as unknown as any);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(undefined as unknown as string);

    // Similar to componentDidMount and componentDidUpdate:
    useEffectOnSome(() => {
        if ((refresh === undefined || (refresh !== 0)) && !disabled) {
            setLoading(true);
            if (clearDataOnRefresh) {
                setData(undefined);
            }
            makeApiRequest(url, verb, body, webToken).then(response => {
                setData(response.data);
                setLoading(false);
            }).catch((axiosError: AxiosError) => {
                const errorText = axiosError.response && axiosError.response.data ? axiosError.message : "No error message provided";
                setError(errorText);
                setLoading(false);
            });
        }
    }, [url, refresh]);

    return { data, loading, error };
};

export default useManagerAPI;

