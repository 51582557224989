import * as React from "react";
import Description from "./Components/Description";
import Title from "./Components/Title";
import TitleBarContainer from "./Components/TitleBarContainer";

interface IProps {
    viewName: string;
    viewDescription: string;
    children?: React.ReactNode;
    backgroundColor?: string;
    style?: React.CSSProperties
};

const ManagerTitleBar = (props: IProps) => {
    const newStyle = { ...props.style, backgroundColor: props.backgroundColor }
    return <TitleBarContainer style={newStyle}>
        <div>
            <Title>{props.viewName}</Title>
            <Description>{props.viewDescription}</Description>
        </div>
        {props.children}
    </TitleBarContainer>;
};

export default ManagerTitleBar;
