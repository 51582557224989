import Axios, { AxiosError, AxiosResponse } from 'axios';
import { getAPIUrl } from '../config';

export const tellServerUserInteractionHasOccurred = (webToken: string, impersonating: string | undefined, impersonatingReason: string, successCallback: (newJWT: string) => void, failureCallback: () => void) => {
    let url = `${getAPIUrl()}session/${webToken}/update`;
    if (impersonating) {
        url += `?impersonating=${impersonating}`;
        if (impersonatingReason) {
            url += `&reason=${impersonatingReason}`;
        }
    }
    Axios.get(
        url,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "text/plain" } }
    ).then((response: AxiosResponse<string>) => {
        successCallback(response.data);
    }).catch((error: AxiosError) => {
        failureCallback();
    });
};

export const verifySessionWithServer = (webToken: string, successCallback: () => void, failureCallback: () => void) => {
    Axios.get(
        `${getAPIUrl()}session/${webToken}/verify`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "text/plain" } }
    ).then((response: AxiosResponse<string>) => {
        successCallback();
    }).catch((error: AxiosError) => {
        failureCallback();
    });
};