import * as React from 'react';
import styled from 'styled-components';
import { COLORS } from '../config';
import Banner from './Banner';
import { getUserAnnouncements } from '../Views/Quotes/Admin/AdminService';
import IAnnouncement from '../ServerEntities/IAnnouncement';
import { SessionContext } from '../Views/SessionContext';
import ErrorText from './ErrorText';
import LoadingIndicator from './LoadingIndicator';
import dateConverter from '../UsefulFunctions/dateConverter';
import useEffectOnSome from '../CustomHooks/useEffectOnSome';

const AnnouncementsContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    border: 3px solid grey;
    margin: 0;
    background-color: #CCC;
`;

const AnnouncementsScroll = styled.div`
    overflow: auto;
`;

const AnnouncementItem = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    margin: 0;
    font-size: 14px;
`;

const AnnouncementRow = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1 1 0;
    margin: 0;
    padding:10px;
    justify-content: space-between;
    background-color: white;
`;

const Announcements = () => {
    const { state } = React.useContext(SessionContext);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [announcements, setAnnouncements] = React.useState(undefined as unknown as IAnnouncement[]);

    useEffectOnSome(() => {
        setLoading(true);
        getUserAnnouncements(state.webToken, (announcements: IAnnouncement[]) => {
            setLoading(false);
            setError("");
            setAnnouncements(announcements);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
            setAnnouncements(undefined as unknown as IAnnouncement[]);
        });
    }, []);

    const renderAnnouncement = (announcement: IAnnouncement) => {
        return <AnnouncementItem>
            <AnnouncementRow style={{ backgroundColor: "#CEC9D5", padding: "5px 10px 5px 10px" }} >
                <div>{dateConverter(announcement.publishDate)}</div>
                <div>{announcement.initials}</div>
            </AnnouncementRow>
            <AnnouncementRow>
                <div>{announcement.announcement}</div>
            </AnnouncementRow>
        </AnnouncementItem>
    }

    return <AnnouncementsContainer>
        <Banner $backgroundColor={COLORS.GREY_SLATE}>Announcements</Banner>
        <LoadingIndicator show={loading} type={'Linear'} noSpace={true} />
        {error && <ErrorText>{error}</ErrorText>}
        <AnnouncementsScroll>
            {announcements && announcements.map(renderAnnouncement)}
        </AnnouncementsScroll>
    </AnnouncementsContainer>
};

export default Announcements;