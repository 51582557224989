import * as React from 'react';
import styled from 'styled-components';

import { SessionContext } from '../../SessionContext';

import Button from '../../../SharedComponents/Button';
import Dialog from '../../../SharedComponents/Dialog/Dialog';
import DialogActions from '../../../SharedComponents/Dialog/DialogActions';
import LoadingIndicator from '../../../SharedComponents/LoadingIndicator';
import TextInput from '../../../SharedComponents/TextInput';
import ManagerTitleBar from '../ManagerTitleBar';

import { createAccessComponent, } from './accessManagementService';

import IConfigurationComponent from '../../../ServerEntities/IConfigurationComponent';

const UserFieldsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
`;

const UserFieldLine = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`;

const StatusContainer = styled.div`
    height: 70px;
    text-align: center;
`;

const MainContainer = styled.div`
    display:flex;
    height: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: space-around;
    justify-content: center;
    align-items: center;
`;

interface IProps {
    show: boolean,
    roleId: string,
    onClose: () => void,
    onConfirmRoleAdd: () => void
}

const AddComponentDialog = (props: IProps) => {
    const { webToken } = React.useContext(SessionContext).state;
    const [componentValue, setComponentValue] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);

    const closeDialog = () => {
        setComponentValue("");
        if (success) {
            props.onConfirmRoleAdd();
            setSuccess(false);
        }
        props.onClose();
    };

    const addComponentConfirm = () => {
        setLoading(true);
        const component: IConfigurationComponent = {
            archive: false,
            authorise: false,
            create: false,
            delete: false,
            name: componentValue,
            priority: 0,
            read: false,
            update: false,
            unarchive: false,
            assign: false,
            management: false
        };
        createAccessComponent(props.roleId, component, webToken,
            () => {
                setLoading(false)
                setSuccess(true);
            },
            (errorMessage) => {
                setLoading(false);
                setSuccess(false);
            });
    };

    const changeComponentValue = (e: React.SyntheticEvent<HTMLInputElement>) => {
        setComponentValue(e.currentTarget.value);
    };

    return <Dialog open={props.show} onClose={closeDialog} style={{ width: "650px", height: "320px" }}>
        <ManagerTitleBar viewName="Create component" viewDescription="Create a new component." />
        <LoadingIndicator show={loading} type="Linear" />
        {!success && <UserFieldsContainer>
            <UserFieldLine>
                <TextInput margin="10px 10px 20px 10px" value={componentValue} onChange={changeComponentValue} label="Component" />
            </UserFieldLine>
            <DialogActions>
                <Button onClick={closeDialog}>Cancel</Button>
                <Button onClick={addComponentConfirm} margin="0px 16px 0px 16px" disabled={false}>Confirm</Button>
            </DialogActions>
        </UserFieldsContainer>}
        {success && <MainContainer>
            <StatusContainer>Component created successfully </StatusContainer>
            <Button onClick={closeDialog} margin="0px 16px 0px 16px" disabled={false}>Close</Button>
        </MainContainer>}
    </Dialog>;
};

export default AddComponentDialog;
