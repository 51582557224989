import * as React from "react";
import { COLORS } from "../../config";
import IExpenseForm from "../../ServerEntities/IExpenseForm";
import ErrorText from "../../SharedComponents/ErrorText";
import Header from "../../SharedComponents/Header";
import LoadingIndicator from "../../SharedComponents/LoadingIndicator";
import { useIsFinancialUser } from "../../UsefulFunctions/useRoleCheck";
import { SessionContext } from "../SessionContext";
import ExpenseHistory from "./ExpenseHistory";
import ExpensesForm from "./ExpensesForm";
import { getExpenseFormForUser } from "./ExpensesService";
import ExpensesTable from "./ExpensesTable";
import useEffectOnSome from "../../CustomHooks/useEffectOnSome";

const ExpensesIndex = () => {
    const { state } = React.useContext(SessionContext);
    const financialUser = useIsFinancialUser();
    const [isAdding, setIsAdding] = React.useState(false);
    const [viewHistory, setViewHistory] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [expenseForm, setExpenseForm] = React.useState(null as unknown as IExpenseForm);
    const [refresh, setRefresh] = React.useState(0);

    useEffectOnSome(() => {
        setLoading(true);
        getExpenseFormForUser(state.webToken, (serverExpenseForm: IExpenseForm) => {
            setLoading(false);
            setError("");
            setExpenseForm(serverExpenseForm);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
            setExpenseForm(null as unknown as IExpenseForm);
        });
    }, [refresh]);

    const openAddScreen = () => {
        setIsAdding(true);
    };

    const openHistory = () => {
        setViewHistory(true);
    };

    const closeAddScreen = () => {
        setRefresh(refresh + 1);
        setIsAdding(false);
    };

    const getComponent = (isAdding: boolean) => {
        if (isAdding) {
            return <ExpensesForm onAddComplete={closeAddScreen} formId={expenseForm ? expenseForm.id : undefined} />;
        }
        if (viewHistory) {
            return <ExpenseHistory isAdmin={financialUser} />
        }
        return <ExpensesTable onOpenHistory={openHistory} onAdd={openAddScreen} expenseForm={expenseForm} onSubmitComplete={closeAddScreen} />;
    };

    const onBack = () => {
        setIsAdding(false);
        setViewHistory(false);
    }

    return <div>
        <Header backgroundColor={COLORS.PRIMARY} border={COLORS.FIFTH} onBack={onBack} previousPageUrl={isAdding || viewHistory ? "/Expenses" : "/"} />
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorText>{error}</ErrorText>}
        {getComponent(isAdding)}
    </div>;
};

export default ExpensesIndex;