import * as React from "react";
import styled from "styled-components";
import { COLORS } from "../../../config";
import IUser from "../../../ServerEntities/IUser";
import Dialog from "../../../SharedComponents/Dialog/Dialog";
import Button from "../../../SharedComponents/Button";
import Select from "../../../SharedComponents/Select/Select";
import SelectItem from "../../../SharedComponents/Select/SelectItem";
import LoadingIndicator from "../../../SharedComponents/LoadingIndicator";
import ErrorText from "../../../SharedComponents/ErrorText";
import { SessionContext } from "../../SessionContext";
import { transferOpportunity } from "./OpportunitiesService";
import IQuote from "../../../ServerEntities/IQuote";


const ActionContainer = styled.div`
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top:100px;
`;

interface IProps {
    id: number,
    show: boolean,
    users: IUser[],
    onClose: () => void;
    onQuoteUpdate: (quote: IQuote) => void;
};

const getUserById = (id: string, users?: IUser[]) => {
    if (id && users) {
        const userObj = users.filter(obj => {
            return obj.id === id
        });
        return userObj[0] ? userObj[0] : null;
    }
    return null;
};

const getNameById = (id: string, users?: IUser[]) => {
    if (id && users) {
        const userObj = users.filter(obj => {
            return obj.id === id
        });
        return userObj[0] ? userObj[0].fullName : id;
    }
    return id;
};


const TransferOpportunityDialog = (props: IProps) => {
    const { id, show, users, onClose } = props;
    const { state } = React.useContext(SessionContext);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [user, setUser] = React.useState("");

    const onChangeUser = (newUser: string) => {
        setUser(newUser);
    }

    const onTransfer = () => {
        const quoteUser = getUserById(user, users);
        if (quoteUser) {
            transferOpportunity(id, quoteUser, state.webToken, (quote: IQuote) => {
                setLoading(false);
                setError("");
                props.onQuoteUpdate(quote);
                props.onClose();
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
            });
        }
    }
    return <Dialog style={{ padding: "32px" }} open={show} onClose={onClose}>
        <span>Select a user to transfer this opportunity to</span>
        <span>They will become the permanent owner and the quote number will be updated</span>
        <div style={{ marginTop: "30px" }}>
            <Select required={true} placeholder="Select a user" onChange={onChangeUser} childValues={users && users.map(option => option.id)} value={getNameById(user, users)}>
                {users && users.map((option, index) => <SelectItem height="20px" key={`add-quote-order-owner-item-${index}`} value={option.id}>{option.fullName}</SelectItem>)}
            </Select>
        </div>

        <ActionContainer>
            <Button onClick={onTransfer} color={COLORS.GREY_SLATE}>Transfer</Button>
            <Button color={COLORS.GREY_SLATE} onClick={props.onClose} style={{ marginRight: "10px" }}>Cancel</Button>

        </ActionContainer>
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorText>{error}</ErrorText>}
    </Dialog>

}

export default TransferOpportunityDialog;