import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import ErrorText from "../../../SharedComponents/ErrorText";
import Header from "../../../SharedComponents/Header";
import LoadingIndicator from "../../../SharedComponents/LoadingIndicator";
import { SessionContext } from "../../SessionContext";
import { getArchivedOpportunities, getOpportunities, getOpportunitiesForUser, getOpportunity } from "./OpportunitiesService";
import IOpportunity from "../../../ServerEntities/IOpportunity";
import OpportunitiesTable from "./OpportunitiesTable";
import OpportunityAdd from "./OpportunityAdd";
import OpportunityEdit from "./OpportunityEdit";
import OpportunityDetails from "./OpportunityDetails";
import { COLORS } from "../../../config";
import IQuoteType from "../../../ServerEntities/IQuoteType";
import { getUsers } from "../Components/UsersService";
import ICustomer from "../../../ServerEntities/ICustomer";
import { getCustomers } from "../Customers/CustomersService";
import OpportunityHistory from "./OpportunityHistory";
import IUser from "../../../ServerEntities/IUser";
import Banner from "../../../SharedComponents/Banner";
import Tools from "../../../SharedComponents/Tools";
import { LeftColumn, RightColumn } from "../Quotes/QuotesIndex";
import Pages from "../../../SharedComponents/Pages";
import { RolesContext } from "../../../Roles/RolesContext";
import { pageConfiguration } from "../../../UsefulFunctions/getComponentConfiguration";
import IPage from "../../../ServerEntities/IPage";
import useEffectOnSome from "../../../CustomHooks/useEffectOnSome";
import { getQuoteTypes } from "../Quotes/QuotesService";

interface IProps {
    archived?: boolean,
    mine?: boolean
};

const OpportunitiesIndex = (props: IProps) => {
    const { opportunityId } = useParams();
    const { state } = React.useContext(SessionContext);
    const { configuration } = React.useContext(RolesContext).state;
    const [isAdding, setIsAdding] = React.useState(false);
    const [isEditing, setIsEditing] = React.useState(null as unknown as IOpportunity | null);
    const [isViewing, setIsViewing] = React.useState(null as unknown as IOpportunity | null);
    const [isHistory, setIsHistory] = React.useState(null as unknown as IOpportunity | null);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [opportunities, setOpportunities] = React.useState(null as unknown as IOpportunity[]);
    const [refresh, setRefresh] = React.useState(0);
    const [pageNumber, setPageNumber] = React.useState(0);
    const [quoteTypes, setQuoteTypes] = React.useState(null as unknown as IQuoteType[]);
    const [users, setUsers] = React.useState(null as unknown as IUser[]);
    const [customers, setCustomers] = React.useState(null as unknown as ICustomer[]);
    const [viewAllOpportunities, setViewAllOpportunities] = React.useState(props.mine ? false : true);
    const [viewArchivedOpportunities, setViewArchivedOpportunities] = React.useState(props.archived ? true : false);
    const [pages, setPages] = React.useState([] as unknown as IPage[]);
    const navigate = useNavigate();

    const getAllOps = () => {
        setLoading(true);
        getOpportunities(state.webToken, (opportunities: IOpportunity[]) => {
            setLoading(false);
            setError("");
            setOpportunities(opportunities);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
            setOpportunities(null as unknown as IOpportunity[]);
        });
    };

    const getUserOps = () => {
        setLoading(true);
        getOpportunitiesForUser(state.loggedInUser, state.webToken, (opportunities: IOpportunity[]) => {
            setLoading(false);
            setError("");
            setOpportunities(opportunities);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
            setOpportunities(null as unknown as IOpportunity[]);
        });
    };

    const onBack = () => {
        setIsAdding(false);
        if (!isHistory) {
            setIsEditing(null);
        }
        setIsViewing(null);
        setIsHistory(null)
    };

    const getArchivedOps = () => {
        setLoading(true);
        getArchivedOpportunities(state.webToken, (opportunities: IOpportunity[]) => {
            setLoading(false);
            setError("");
            setOpportunities(opportunities);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
            setOpportunities(null as unknown as IOpportunity[]);
        });
    };

    useEffectOnSome(() => {
        if (viewArchivedOpportunities) {
            getArchivedOps();
        } else if (viewAllOpportunities) {
            getAllOps();
        } else {
            getUserOps();
        }
    }, [refresh, viewArchivedOpportunities, viewAllOpportunities]);

    useEffectOnSome(() => {
        const onViewAllOps = () => {
            setViewAllOpportunities(true);
            setViewArchivedOpportunities(false);
            onBack();
            getAllOps();
        };

        const onViewUserOps = () => {
            setViewAllOpportunities(false);
            setViewArchivedOpportunities(false);
            getUserOps();
            onBack();
        };

        const onViewArchivedOps = () => {
            setViewAllOpportunities(true);
            setViewArchivedOpportunities(true);
            getArchivedOps();
            onBack();
        };

        const onViewCustomers = () => {
            navigate(`/Customers`);
        };

        const onViewContacts = () => {
            navigate(`/Contacts`);
        };

        const pageList = [
            { page: "All Opportunities", onClick: onViewAllOps },
            { page: "My Opportunities", onClick: onViewUserOps },
            { page: "Archived Opportunities", onClick: onViewArchivedOps },
            { page: "Customers", onClick: onViewCustomers },
            { page: "Contacts", onClick: onViewContacts },
        ];

        let newPageList = [];
        for (let i = 0; i < pageList.length; i++) {
            let config = pageConfiguration(pageList[i].page, configuration);
            if (config !== undefined) {
                newPageList.push(pageList[i])
            }
        }
        setPages(newPageList);

        if (newPageList.filter(p => p.page.includes("Opportunities")).length === 0) {
            navigate(`/Customers`);
        }
    }, [configuration, navigate]);

    useEffectOnSome(() => {
        if (opportunityId && isViewing == null) {
            setLoading(true);
            getOpportunity(parseInt(opportunityId), state.webToken, (opportunity: IOpportunity) => {
                setLoading(false);
                setError("");
                setIsViewing(opportunity);
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
                setIsViewing(null as unknown as IOpportunity);
            });
        }
    }, [opportunityId, isViewing]);

    useEffectOnSome(() => {
        getQuoteTypes(state.webToken, (quoteTypes: IQuoteType[]) => {
            setLoading(false);
            setError("");
            setQuoteTypes(quoteTypes.filter(t => t.status));
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
            setQuoteTypes([])
        });
        getUsers(state.webToken, (users: IUser[]) => {
            setLoading(false);
            setError("");
            setUsers(users.filter(u => u.initials));
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
            setUsers(null as unknown as IUser[]);
        });
        getCustomers(state.webToken, (customers: ICustomer[]) => {
            setLoading(false);
            setError("");
            setCustomers(customers);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
            setCustomers(null as unknown as ICustomer[]);
        });
    }, []);

    const openAddScreen = () => {
        setIsAdding(true);
    };

    const openEditScreen = (opportunity: IOpportunity) => {
        closeScreen();
        setIsEditing(opportunity);
    };

    const openViewScreen = (opportunity: IOpportunity) => {
        setIsViewing(opportunity);
    };

    const openHistoryScreen = (opportunity: IOpportunity) => {
        setIsHistory(opportunity);
    };

    const onChangePageNumber = (pageNumber: number) => {
        setPageNumber(pageNumber);
        onRefresh();
    };

    const closeScreen = () => {
        onRefresh();
        setIsAdding(false);
        setIsEditing(null);
        setIsViewing(null);
    };

    const onRefresh = () => {
        setRefresh(refresh + 1);
    };

    const getComponent = (isAdding: boolean, isEditing: IOpportunity | null, isViewing: IOpportunity | null, isHistory: IOpportunity | null,) => {
        if (isHistory) {
            return <OpportunityHistory users={users} opportunity={isHistory} />;
        }
        if (isAdding) {
            return <OpportunityAdd users={users} types={quoteTypes} customers={customers} onAddComplete={closeScreen} />;
        }
        if (isViewing) {
            return <OpportunityDetails users={users} types={quoteTypes} customers={customers} opportunity={isViewing} onViewHistory={openHistoryScreen} onOpen={openEditScreen} />;
        }
        if (isEditing) {
            return <OpportunityEdit users={users} types={quoteTypes} customers={customers} onAddComplete={closeScreen} opportunity={isEditing} onViewHistory={openHistoryScreen} />;
        }

        return <OpportunitiesTable
            onRefresh={onRefresh}
            onAdd={openAddScreen}
            onEdit={openEditScreen}
            onDelete={onRefresh}
            onView={openViewScreen}
            users={users}
            opportunities={opportunities}
            viewAllOpportunities={viewAllOpportunities}
            viewArchivedOpportunities={viewArchivedOpportunities}
        />;
    };


    if (opportunities && opportunities.length === 0 && pageNumber > 0) {
        onChangePageNumber(pageNumber - 1);
    }

    const getBannerTitle = () => {
        if (isHistory) {
            return "Change History - Opportunities";
        } else if (isAdding) {
            return "Add Opportunity";
        } else
            if (isEditing) {
                return "Edit Opportunity";
            }

        if (viewArchivedOpportunities) {
            return "Archived Opportunities";
        }
        if (viewAllOpportunities) {
            return "All Opportunities";
        } else {
            return "My Opportunities";
        }

    }

    return <div style={{ position: "absolute", height: "100%", width: "100%", display: "flex", alignItems: "stretch", flexDirection: "column" }}>
        <Header backgroundColor={COLORS.GREY_SLATE} border={COLORS.OPPORTUNITIES_YELLOW} onBack={onBack} previousPageUrl={isAdding || isEditing || isHistory ? "/opportunities" : "/"} />
        <main style={{ flex: "1", display: "flex", alignItems: "stretch", overflow: "hidden" }}>
            <LeftColumn>
                <Tools bannerColor={COLORS.HEATHER_SLATE} />
                <Pages bannerColor={COLORS.HEATHER_SLATE} backgroundColor={COLORS.OPPORTUNITIES_YELLOW} pages={pages} />
            </LeftColumn>
            <RightColumn>
                <Banner $backgroundColor={COLORS.HEATHER_SLATE}>{getBannerTitle()}</Banner>
                <LoadingIndicator type="Linear" show={loading} />
                {error && <ErrorText>{error}</ErrorText>}
                {getComponent(isAdding, isEditing, isViewing, isHistory)}
            </RightColumn>
        </main>
    </div >
};

export default OpportunitiesIndex;
