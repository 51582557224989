import * as React from "react";
import { useNavigate } from "react-router-dom";
import ErrorText from "../../../../SharedComponents/ErrorText";
import Header from "../../../../SharedComponents/Header";
import LoadingIndicator from "../../../../SharedComponents/LoadingIndicator";
import { SessionContext } from "../../../SessionContext";
import { COLORS } from "../../../../config";
import IQuoteType from "../../../../ServerEntities/IQuoteType";
import { getUsers } from "../../Components/UsersService";
import ICustomer from "../../../../ServerEntities/ICustomer";
import { getCustomers } from "../../Customers/CustomersService";
import IUser from "../../../../ServerEntities/IUser";
import Banner from "../../../../SharedComponents/Banner";
import Tools from "../../../../SharedComponents/Tools";
import { LeftColumn, RightColumn } from "../QuotesIndex";
import Pages from "../../../../SharedComponents/Pages";
import PricingEdit from "./PricingEdit";
import PricingTable from "./PricingTable";
import { getAllPricing, getMyPricing } from "../QuotesService";
import IPricing from "../../../../ServerEntities/IPricing";
import PricingHistory from "./PricingHistory";
import { RolesContext } from "../../../../Roles/RolesContext";
import IPage from "../../../../ServerEntities/IPage";
import { pageConfiguration } from "../../../../UsefulFunctions/getComponentConfiguration";
import useEffectOnSome from "../../../../CustomHooks/useEffectOnSome";

const PricingIndex = () => {
    const { state } = React.useContext(SessionContext);
    const [isViewing, setIsViewing] = React.useState(null as unknown as IPricing | null);
    const [isEditing, setIsEditing] = React.useState(null as unknown as IPricing | null);
    const [isHistory, setIsHistory] = React.useState(null as unknown as IPricing | null);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [refresh, setRefresh] = React.useState(0);
    const [quoteTypes, setQuoteTypes] = React.useState(null as unknown as IQuoteType[]);
    const [users, setUsers] = React.useState(null as unknown as IUser[]);
    const [customers, setCustomers] = React.useState(null as unknown as ICustomer[]);
    const [pricing, setPricing] = React.useState(null as unknown as IPricing[]);
    const [viewMyPricing, setViewMyPricing] = React.useState(false);
    const [viewAllPricing, setViewAllPricing] = React.useState(false);
    const navigate = useNavigate();

    const { configuration } = React.useContext(RolesContext).state;
    const [pages, setPages] = React.useState(null as unknown as IPage[]);

    const onBack = () => {
        if (!isHistory) {
            setIsViewing(null);
        }
        setIsEditing(null);
        setIsHistory(null)
    };

    useEffectOnSome(() => {
        const navigateToAllQuotes = () => {
            navigate("/Quotes");
        };
    
        const navigateToMyQuotes = () => {
            navigate("/Quotes?type=mine");
        };
    
        const navigateToArchivedQuotes = () => {
            navigate("/Quotes?type=archived");
        };

        const resetToMyView = () => {
            setViewMyPricing(true);
            setViewAllPricing(false);
            onBack();
            setRefresh(refresh + 1);
        };
    
        const resetToAllView = () => {
            setViewMyPricing(false);
            setViewAllPricing(true);
            onBack();
            setRefresh(refresh + 1);
        };

        const pageList = [
            { page: "All Quotes", onClick: navigateToAllQuotes },
            { page: "Active Quotes", onClick: navigateToMyQuotes },
            { page: "Archived Quotes", onClick: navigateToArchivedQuotes },
            { page: "All Pricing", onClick: resetToAllView },
            { page: "My Pricing", onClick: resetToMyView },
        ];

        let newPageList = [];
        for (let i = 0; i < pageList.length; i++) {
            let config = pageConfiguration(pageList[i].page, configuration);
            if (config !== undefined) {
                newPageList.push(pageList[i])
            }
        }
        setPages(newPageList);
    }, [configuration, navigate, refresh]);

    useEffectOnSome(() => {
        if (!viewMyPricing && !viewAllPricing) {
            setViewMyPricing(true);
        }
        setLoading(true);
        if (viewMyPricing) {
            getMyPricing(state.webToken, (p: IPricing[]) => {
                setLoading(false);
                setError("");
                setPricing(p);
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
                setPricing(null as unknown as IPricing[]);
            });
        }
        else {
            getAllPricing(state.webToken, (p: IPricing[]) => {
                setLoading(false);
                setError("");
                setPricing(p);
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
                setPricing(null as unknown as IPricing[]);
            });
        }
    }, [refresh, viewAllPricing, viewMyPricing]);

    useEffectOnSome(() => {

        if (quoteTypes == null) {
            setQuoteTypes([{ id: 1, type: "Q", description: "Quote" }, { id: 2, type: "T", description: "Tender" }]);
        }
        getUsers(state.webToken, (users: IUser[]) => {
            setLoading(false);
            setError("");
            setUsers(users);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
            setUsers(null as unknown as IUser[]);
        });
        getCustomers(state.webToken, (customers: ICustomer[]) => {
            setLoading(false);
            setError("");
            setCustomers(customers);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
            setCustomers(null as unknown as ICustomer[]);
        });
    }, [quoteTypes]);

    const openViewScreen = (pricing: IPricing) => {
        setIsViewing(pricing);
    };

    const openEditScreen = (pricing: IPricing) => {
        setIsEditing(pricing);
    };

    const openHistoryScreen = (pricing: IPricing) => {
        setIsHistory(pricing);
    };

    const onRefresh = () => {
        setRefresh(refresh + 1);
    };

    const onAddComplete = () => {
        onBack();
        onRefresh();
    };

    const getComponent = (isEditing: IPricing | null, isHistory: IPricing | null,) => {
        if (isHistory) {
            return <PricingHistory users={users} pricing={isHistory} />;
        }
        if (isViewing) {
            return <PricingEdit users={users} types={quoteTypes} customers={customers} pricing={isViewing} onViewHistory={openHistoryScreen} onAddComplete={onAddComplete} />;
        }
        if (isEditing) {
            return <PricingEdit users={users} editable={true} types={quoteTypes} customers={customers} pricing={isEditing} onViewHistory={openHistoryScreen} onAddComplete={onAddComplete} />;
        }

        return <PricingTable
            onRefresh={onRefresh}
            onDelete={onRefresh}
            onView={openViewScreen}
            onEdit={openEditScreen}
            users={users}
            pricing={pricing}
            allPricing={viewAllPricing}
        />;
    };

    const getBannerTitle = () => {
        if (isHistory) {
            return "Change History - Pricing";
        } else
            if (isEditing) {
                return "Edit Pricing";
            }
        if (viewAllPricing) {
            return "All Pricing";
        }
        if (viewMyPricing) {
            return "My Pricing";
        }
        return "Pricing"
    }

    return <div style={{ position: "absolute", height: "100%", width: "100%", display: "flex", alignItems: "stretch", flexDirection: "column" }}>
        <Header backgroundColor={COLORS.GREY_SLATE} border={COLORS.QUOTES_PINK} onBack={onBack} previousPageUrl={isViewing || isEditing || isHistory ? "/Quotes/Pricing" : "/Quotes"} />
        <main style={{ flex: "1", display: "flex", alignItems: "stretch", overflow: "hidden" }}>
            <LeftColumn>
                <Tools bannerColor={COLORS.HEATHER_SLATE} />
                <Pages bannerColor={COLORS.HEATHER_SLATE} backgroundColor={COLORS.QUOTES_PINK} pages={pages} />
            </LeftColumn>
            <RightColumn>
                <Banner $backgroundColor={COLORS.HEATHER_SLATE}>{getBannerTitle()}</Banner>
                <LoadingIndicator type="Linear" show={loading} />
                {error && <ErrorText>{error}</ErrorText>}
                {getComponent(isEditing, isHistory)}
            </RightColumn>
        </main>
    </div >
};

export default PricingIndex;
