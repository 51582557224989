import axios, { AxiosError, AxiosResponse } from "axios";
import { getAPIUrl } from "../../../config";
import IOpportunity from "../../../ServerEntities/IOpportunity";
import INotificationTrigger from "../../../ServerEntities/INotificationTrigger";
import IQuote from "../../../ServerEntities/IQuote";
import IDocument from "../../../ServerEntities/IDocument";
import IChangeHistory from "../../../ServerEntities/IChangeHistory";
import IUser from "../../../ServerEntities/IUser";

export const getOpenQuotes = (webToken: string, successCallback: (quotes: IQuote[]) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}quotes/quotes/open`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IQuote[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getOpportunityId = (webToken: string, successCallback: (opportunity: IOpportunity) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}quotes/opportunities/id`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IOpportunity>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getOpportunity = (opportunityId: number, webToken: string, successCallback: (opportunity: IOpportunity) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}quotes/opportunities/${opportunityId}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IOpportunity>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getOpportunities = (webToken: string, successCallback: (opportunities: IOpportunity[]) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}quotes/opportunities`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IOpportunity[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getArchivedOpportunities = (webToken: string, successCallback: (opportunities: IOpportunity[]) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}quotes/opportunities/archived`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IOpportunity[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getOpportunitiesForUser = (user: string, webToken: string, successCallback: (opportunities: IOpportunity[]) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}quotes/opportunities?owner=${user}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IOpportunity[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const updateOpportunity = (webToken: string, opportunity: IOpportunity, successCallback: () => void, failureCallback: (error: string) => void) => {
    console.log("update");
    axios.put(
        `${getAPIUrl()}quotes/opportunities`,
        opportunity,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const addOpportunity = (webToken: string, initials: string, opportunity: IOpportunity, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.put(
        `${getAPIUrl()}quotes/opportunities?initials=${initials}`,
        opportunity,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const deleteOpportunity = (webToken: string, opportunityId: number, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.delete(
        `${getAPIUrl()}quotes/opportunities/${opportunityId}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const deleteOpportunityFile = (webToken: string, id: number, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.delete(
        `${getAPIUrl()}quotes/opportunities/file/${id}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const commitOpportunityNotification = (trigger: INotificationTrigger, webToken: string, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.post(
        `${getAPIUrl()}notifications/message/publish`,
        trigger,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getOpportunityDocuments = (opportunityId: number, type: string, webToken: string, successCallback: (files: IDocument[]) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}quotes/opportunities/${opportunityId}/file/${type}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IDocument[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getOpportunityHistory = (opportunityId: string, webToken: string, successCallback: (changeHistory: IChangeHistory[]) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}history/opportunities/${opportunityId}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IChangeHistory[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};


export const transferOpportunity = (opportunityId: number, user: IUser, webToken: string, successCallback: (quote: IQuote) => void, failureCallback: (error: string) => void) => {
    axios.post(
        `${getAPIUrl()}quotes/opportunities/${opportunityId}/transfer`,
        user,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IQuote>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};
