import ICustomerContactDetails from "../ServerEntities/ICustomerContactDetails";
import IInterfaceDetails from "../ServerEntities/IInterfaceDetails";
import IQuote from "../ServerEntities/IQuote";

export const TYPE_DOCUMENT = "document";
export const TYPE_DIAGRAM = "diagram";
export const STATUS = ["New Opp", "In progress", "Returned", "Scope Review", "Committed", "Closed by sales", "Revisit"];
export const STATUS_CLOSED = 5;
export const STATUS_COMMITED = 3;
export const STATUS_SCOPE_REVIEW = 4;
export const STATUS_RETURNED = 2;
export const STATUS_IN_PROGRESS = 1;
export const STATUS_ARCHIVED = 7;

export const isEditableWorkPackage = (status: number) => {
    return STATUS[status] !== "Committed" && STATUS[status] !== 'Ready for pricing'
}

export const getClientByQuoteNumber = (quoteNumber: string, quotes: IQuote[]) => {
    if (quotes) {
        const userObj = quotes.filter(obj => obj.quoteNumber === quoteNumber);
        return userObj.length && userObj[0].customerId ? userObj[0].customerId : 0;
    }
    return 0;
};

export const getContactIdByName = (contactName: string, quotes: ICustomerContactDetails[]) => {
    if (quotes) {
        const userObj = quotes.filter(obj => obj.name === contactName);
        return userObj.length && userObj[0].id ? userObj[0].id : 0;
    }
    return 0;
};

export function sumProperty(arr: any, property: string) {
    if (arr) {
        let sum = 0;
        for (let i = 0; i < arr.length; i++) {
            sum += arr[i][property];
        }
        return sum;
    }
    return 0;
}

export const getUniqueInterfaceTypes = (interfaces: IInterfaceDetails[]) => {
    const uniqueTypes = new Set();

    interfaces.forEach((obj: IInterfaceDetails) => {
        if (obj.interfaceType) {
            if (uniqueTypes)
                uniqueTypes.add(obj.interfaceType);
        }
    });

    return Array.from(uniqueTypes).join(",");
}