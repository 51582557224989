export default interface IEffort {
    id: number,
    name: string;
    arch: number;
    dev: number;
    test: number;
    golive: number;
    other: number;
    type: string;
    notes: string;
    effortType: string;
}

export const IEffortType = ["Passthrough", "Simple", "Medium", "Intermediate", "Complex", "Very Complex"];