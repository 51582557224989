import * as React from "react";
import { SessionContext } from "../../SessionContext";
import TextInput, { updateText } from "../../../SharedComponents/TextInput";
import { COLORS } from "../../../config";
import Button from "../../../SharedComponents/Button";
import Dialog from "../../../SharedComponents/Dialog/Dialog";
import DialogActions from "../../../SharedComponents/Dialog/DialogActions";
import DialogContent from "../../../SharedComponents/Dialog/DialogContent";
import DialogContentText from "../../../SharedComponents/Dialog/DialogContentText";
import DialogTitle from "../../../SharedComponents/Dialog/DialogTitle";
import { tellServerUserInteractionHasOccurred } from "../../sessionService";

interface IProps {
    onCommit: (token: string) => void
};

const ImpersonatingRoadblock = (props: IProps) => {
    const sessionContext = React.useContext(SessionContext);
    const state = sessionContext.state;
    const [reason, setReason] = React.useState("");
    const [show, setShow] = React.useState(false);

    React.useEffect(() => {
        const shouldShow = state.impersonating && !state.impersonatingReason ? true : false;
        setShow(shouldShow);
        if (!shouldShow) {
            props.onCommit("");
        }
    }, [state.impersonating, state.impersonatingReason, props]);

    const userInteractionServerCallSuccess = (newJWT: string) => {
        sessionContext.dispatch({ type: "setWebToken", payload: newJWT });
        setShow(false);
        props.onCommit(newJWT);
    };

    const userInteractionServerCallFailure = () => {
        setReason("");
        sessionContext.dispatch({ type: "setImpersonatingReason", payload: "" });
        setShow(false)
    };

    const onCommit = () => {
        const cleanedReason = reason.replace(/\r/g, "");
        sessionContext.dispatch({ type: "setImpersonatingReason", payload: cleanedReason });
        tellServerUserInteractionHasOccurred(state.webToken, state.impersonating, cleanedReason, userInteractionServerCallSuccess, userInteractionServerCallFailure);
    };

    const closeDialog = () => {
        setReason("");
        setShow(false);
    };

    return <Dialog open={show} style={{ height: "320px" }} onClose={closeDialog}>
        <DialogTitle className="DialogTitle">Reason required</DialogTitle>
        <DialogContent className="DialogContent">
            <DialogContentText className="DialogContentText">You are currently acting on behalf of another user, which requires a reason</DialogContentText>
            <DialogContentText className="DialogContentText">Add a reason and press commit to log your change on their behalf</DialogContentText>
            <div style={{ marginTop: "30px" }}>
                <TextInput textArea={true} value={reason} onChange={updateText(setReason)} />
            </div>
        </DialogContent>
        <DialogActions className="DialogActions">
            <Button margin="0 15px" color={COLORS.GREY_SLATE} onClick={onCommit}>Commit</Button>
            <Button margin="0 15px" color={COLORS.GREY_SLATE} onClick={closeDialog}>Cancel</Button>
        </DialogActions>
    </Dialog>
};

export default ImpersonatingRoadblock;
