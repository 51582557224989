import * as React from "react";


import Card from '../SharedComponents/Card';
import ErrorBox from "../SharedComponents/ErrorBox";
import LoadingIndicator from '../SharedComponents/LoadingIndicator';

import useRestAPI from '../CustomHooks/useRestAPI';
import { RolesContext } from '../Roles/RolesContext';
import { SessionContext } from '../Views/SessionContext';

import { changePreferredRole } from '../Roles/RolesService';

import IConfiguration from '../ServerEntities/IConfiguration';
import styled from "styled-components";
import { getInternalConfigurationForRole } from "./Login/loginService";

const InstructionText = styled.div`
font-size: 18px;
color: #575757;
line-height:19px;
justify-content: center;
text-align:center;
padding:36px;
`;

const RoleContainer = styled.div`
font-size: 24px
font-wieght:300;
letter-spacing: 1px;
padding:24px;
color: #555;
opacity:1;
&:hover{
    opacity: 0.6;
}
`;

const Grid = styled.div`
display:flex;
flex-direction:row;
flex-wrap: wrap;
justify-content:space-between;
`;

const ErrorText = styled.p`
display:flex;
flex-direction:row;
color: red;
line-height:21px;
font-size:16px;
justify-content:center;
align-items:center;
`;

interface IProps {
    applicationName: string
};

const PreferredRolePicker = (props: IProps) => {
    const applicationName = props.applicationName;
    const sessionContext = React.useContext(SessionContext);
    const { webToken } = sessionContext.state;
    const { dispatch } = React.useContext(RolesContext);
    const [roleChangeLoading, setRoleChangeLoading] = React.useState(false);
    const [initialToken] = React.useState(webToken);
    const [roleChangeError, setRoleChangeError] = React.useState("");
    const [error, setError] = React.useState("");
    const { data, loading } = useRestAPI(
        `dashboard/${applicationName}/roles`,
        "GET",
        {},
        initialToken
    );

    const roles = data || [];

    const onRoleChangeSuccess = (newRole: string) => (jwt: string) => {
        setRoleChangeLoading(false);
        sessionContext.dispatch({ type: "setWebToken", payload: jwt });
        dispatch({ type: "setRole", payload: newRole });
        if (applicationName === "InternalSystem") {
            getInternalConfigurationForRole(newRole, jwt, updateConfiguration, handleConfigurationError);
        }
    };

    const updateConfiguration = (configuration: IConfiguration) => {
        dispatch({ type: "setConfiguration", payload: configuration });
    };

    const handleConfigurationError = (errorMessage: string) => {
        setError(errorMessage);
    };

    const onRoleChangeFailure = (errorMessage: string) => {
        setRoleChangeError(errorMessage);
        setRoleChangeLoading(false);
    };

    const setPreferredRole = (role: string) => () => {
        setRoleChangeError("");
        if (role) {
            setRoleChangeLoading(true);
            changePreferredRole(role, applicationName, webToken, onRoleChangeSuccess(role), onRoleChangeFailure);
        }
    };

    if (roles.length > 0 && roles.length === 1 && roleChangeLoading === false) {
        const role = roles[0];
        if (role) {
            setRoleChangeLoading(true);
            changePreferredRole(role, applicationName, webToken, onRoleChangeSuccess(role), onRoleChangeFailure);
        }
    };

    return <div>
        {error && <ErrorBox>{error}</ErrorBox>}
        {loading && <LoadingIndicator show={loading || roleChangeLoading} type="Radial" />}
        {roles.length === 0 && !roleChangeLoading && <InstructionText>Sorry, you have no roles available.</InstructionText>}
        {roles.length > 0 && !roleChangeLoading && <InstructionText>Please select your role for using Slate</InstructionText>}
        {roles.length > 0 && !roleChangeLoading && <Grid style={{ justifyContent: "center" }}>
            {roles.map((role: string) => <Card key={role} margin="24px" onClick={setPreferredRole(role)} rippleColor="#187fba">
                <RoleContainer>
                    {role}
                </RoleContainer>
            </Card>)}
        </Grid>}
        <ErrorText>{roleChangeError}</ErrorText>
    </div>;
};

export default PreferredRolePicker;
