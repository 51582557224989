import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import styled from "styled-components";
import setFocus from "../UsefulFunctions/SetFocus";
import IconButton from "./IconButton";
import Menu from "./Menu/Menu";
import MenuItem from "./Menu/MenuItem";
import { SessionContext } from "../Views/SessionContext";

interface IUserMenuProps {
    role?: string,
    username: string,
    impersonating?: string,
    onSignOut: () => void,
    onChangeRole: () => void,
    onChangeUser: () => void
};

const UserContainer = styled.div`
    padding: 8px 16px 12px 16px;
    border-bottom: 1px solid #e5e5e5;
    user-interaction: none;
    cursor: default;
`;

const TopLabel = styled.p`
    margin: 0;
    font-size: 14px;
    line-height: 19px;
    color: #555555;
`;

const UsernameLabel = styled.p`
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    color: #000000;
`;

const RoleLabel = styled.p`
    margin: 0;
    font-size: 14px;
    line-height: 22px;
`;

const UserMenu = (props: IUserMenuProps) => {
    const { role, username, impersonating, onSignOut, onChangeRole, onChangeUser } = props;
    const [navMenuOpen, setNavMenuOpen] = React.useState(false);
    const menuButtonRef = React.useRef(null as unknown as HTMLDivElement);
    const menuRef = React.useRef(null as unknown as HTMLDivElement);
    const sessionContext = React.useContext(SessionContext);

    const toggleNavMenu = () => {
        if (!navMenuOpen) {
            setNavMenuOpen(true);
            setFocus(menuRef);
        } else {
            setNavMenuOpen(false);
        }
    };

    const closeNavMenu = () => {
        setNavMenuOpen(false);
    };

    const handleKeyDown = (isFirst: boolean, isLast: boolean, enterFunction: () => void) => (event: any) => {
        const key = event.key || event.keyCode;
        if (key === "Enter" || key === " ") {
            enterFunction();
        }
        if (key === "Escape") {
            menuButtonRef.current.focus();
            setNavMenuOpen(false);
        }
        if (key === "Tab" && event.shiftKey && isFirst) {
            menuButtonRef.current.focus();
            setNavMenuOpen(false);
        }
        if (key === "Tab" && !event.shiftKey && isLast) {
            menuButtonRef.current.focus();
            setNavMenuOpen(false);
        }
    };

    const isHead = () => {
        if (role && role.toLocaleLowerCase().includes("head")) {
            return true;
        }
        return false;
    }

    const onStopChangeUser = () => {
        sessionContext.dispatch({ type: "setImpersonating", payload: "" });
        sessionContext.dispatch({ type: "setImpersonatingReason", payload: "" });
    }

    return <span>
        <IconButton onClick={toggleNavMenu} refObject={menuButtonRef} key="userMenuIcon">
            <FontAwesomeIcon color="white" icon="user" />
        </IconButton>
        {navMenuOpen && <Menu open={true} menuContainerRef={menuRef} onClose={closeNavMenu} anchorElement={menuButtonRef} width={300}>
            <UserContainer>
                <TopLabel>Signed in as</TopLabel>
                <UsernameLabel>{username}</UsernameLabel>
                {impersonating && <RoleLabel>on behalf of {impersonating}</RoleLabel>}
                <RoleLabel>{role}</RoleLabel>
            </UserContainer>
            {isHead() && !impersonating && <MenuItem key="change-user-item" onKeyDown={handleKeyDown(true, false, onChangeUser)} onClick={onChangeUser}>
                Assume user identity
            </MenuItem>}
            {isHead() && impersonating && <MenuItem key="stop-change-user-item" onKeyDown={handleKeyDown(true, false, onStopChangeUser)} onClick={onStopChangeUser}>
                Stop assuming user entity
            </MenuItem>}
            <MenuItem key="change-role-item" onKeyDown={handleKeyDown(true, false, onChangeRole)} onClick={onChangeRole}>
                Change role
            </MenuItem>
            <MenuItem key="sign-out-item" onKeyDown={handleKeyDown(false, true, onSignOut)} onClick={onSignOut}>
                Sign out
            </MenuItem>
        </Menu>}
    </span>;
};

export default UserMenu;
