import styled from "styled-components";

const DialogContentText = styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 1.75;
    color: #000;
    margin: 0;
    padding: 5px 0px 5px 0px;
`;

export default DialogContentText;