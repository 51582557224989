import * as React from 'react';
import styled from 'styled-components';

import Button from '../../../SharedComponents/Button';
import Dialog from '../../../SharedComponents/Dialog/Dialog';
import DialogActions from '../../../SharedComponents/Dialog/DialogActions';
import DialogContent from '../../../SharedComponents/Dialog/DialogContent';
import DialogTitle from '../../../SharedComponents/Dialog/DialogTitle';
import EmptyStateSplashContainer from '../../../SharedComponents/EmptyStateSplash';
import Label from '../../../SharedComponents/Label';
import ListItem from '../../../SharedComponents/List/ListItem';
import LoadingIndicator from '../../../SharedComponents/LoadingIndicator';
import ViewContainer from '../../../SharedComponents/ViewContainer';
import ManagerTitleBar from '../ManagerTitleBar';

import transparentRoleIcon from '../../../images/customIcons/transparentRole.png';

import { RolesContext } from '../../../Roles/RolesContext';
import { SessionContext } from '../../SessionContext';

import useManagerAPI from '../../../CustomHooks/useManagerAPI';

import IRole from '../../../ServerEntities/IRole';
import * as accessManagementService from "./accessManagementService";

import AccessDetails from './AccessDetails';
import AddComponentDialog from './AddComponentDialog';
import createErrorMessage from '../../../UsefulFunctions/createErrorMessage';

interface IProps {
    tablabel: string
}

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

const RoleListContainer = styled.div`
    border-right: 1px solid #e5e5e5;
    background-color: white;
    max-width: 320px;
    min-width: 320px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
`;


const RoleList = styled.div`
    overflow-y: auto;
`;

const roleHasId = (id?: string) => (role: IRole) => id === role.id;

const AccessTab = (props: IProps) => {
    const [selectedRoleId, setSelectedRoleId] = React.useState(undefined as unknown as string | undefined);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [refresh, setRefresh] = React.useState(0);
    const [showAddComponentDialog, setShowAddComponentDialog] = React.useState(false);

    const { webToken, loggedInUser } = React.useContext(SessionContext).state;
    const currentRole = React.useContext(RolesContext).state.role || "";

    const roleListContainerReference = React.useRef(null as unknown as HTMLDivElement);

    const { data, loading, error } = useManagerAPI("roles", "GET", {}, webToken, refresh);
    const { data: disabledData, loading: disabledLoading, error: disabledError } = useManagerAPI("roles/disabled", "GET", {}, webToken, refresh);

    const roles = data ? [...data] : [];
    const disabledRoles = disabledData ? [...disabledData] : [];

    React.useEffect(() => {
        setRefresh(1);
    }, []);

    const changeSelectedRole = (role: IRole) => () => {
        setSelectedRoleId(role.id);
    };

    const refreshRoles = () => {
        setRefresh(refresh + 1);
    };

    const closeErrorDialog = () => {
        setErrorMessage("");
    };

    const buildRoleItem = (role: IRole, index: number) => {
        return <ListItem style={{ marginLeft: "8px" }} selected={selectedRoleId !== undefined && selectedRoleId === role.id} key={role.id + index} onClick={changeSelectedRole(role)}>
            <Label>
                <h2>{role.id}</h2>
                <h3 style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{role.description}</h3>
            </Label>
        </ListItem>;
    };

    const selectedRole = roles.concat(disabledRoles).find(roleHasId(selectedRoleId));

    const openAddComponentDialog = () => {
        setShowAddComponentDialog(true);
    };

    const closeAddComponenDialog = () => {
        setShowAddComponentDialog(false);
    };

    const closeAddComponenDialogAndRefresh = () => {
        setShowAddComponentDialog(false);
        refreshRoles();
        setSelectedRoleId("");
    };

    const refreshPermissions = () => {
        accessManagementService.refreshPermissions(
            webToken,
            () => {
            },
            (serverError: string) => {
                setErrorMessage(createErrorMessage("refreshing permissions", serverError));
            });
        accessManagementService.refreshConfiguration(
            webToken,
            () => {
            },
            (serverError: string) => {
                setErrorMessage(createErrorMessage("refreshing configuration", serverError));
            });
    };

    return <ViewContainer style={{ backgroundColor: "#fafafa" }}>
        {selectedRole && <AddComponentDialog
            roleId={selectedRole.id}
            show={showAddComponentDialog}
            onClose={closeAddComponenDialog}
            onConfirmRoleAdd={closeAddComponenDialogAndRefresh}
        />}
        <ErrorDialog message={errorMessage} onClose={closeErrorDialog} />
        <ManagerTitleBar viewName="Access" viewDescription="Here you can enable/disable role access to a specific functionality.">
            <ButtonContainer>
                <Button onClick={openAddComponentDialog} style={{ marginRight: "20px" }}>Add Component</Button>
                <Button onClick={refreshPermissions}>Refresh permissions</Button>
            </ButtonContainer>
        </ManagerTitleBar>
        <ViewContainer style={{ flexDirection: "row" }}>
            <RoleListContainer>
                <LoadingIndicator type="Linear" show={loading || disabledLoading} />
                <RoleList ref={roleListContainerReference} style={{ flex: "1 1 auto" }}>
                    {data && roleListContainerReference.current ? <div>
                        {roles.map(buildRoleItem)}
                    </div> : null}
                    {error && error}
                    {disabledError && disabledError}
                </RoleList>

            </RoleListContainer>
            {!selectedRole && <EmptyState />}
            {selectedRole && <AccessDetails
                key={selectedRoleId}
                selectedRole={selectedRole}
                webToken={webToken}
                currentUser={loggedInUser}
                refreshRoles={refreshRoles}
                currentRole={currentRole}
                setErrorMessage={setErrorMessage} />}

        </ViewContainer>
    </ViewContainer>
};

const EmptyState = () => <EmptyStateSplashContainer>
    <img alt="No role selected" src={transparentRoleIcon} style={{ marginBottom: "8px", opacity: 0.3 }} />
    <h1>No role selected</h1>
    <h2>Select a role on the left to view the role&apos;s Slate functionality.</h2>
</EmptyStateSplashContainer>;

const ErrorDialog = ({ message, onClose }: { message: string, onClose: () => void }) => <Dialog open={message !== ""} onClose={onClose}>
    <DialogTitle>Error</DialogTitle>
    <DialogContent style={{ lineHeight: "25px" }}>{message}</DialogContent>
    <DialogActions><Button onClick={onClose}>OK</Button></DialogActions>
</Dialog>;

export default AccessTab;
