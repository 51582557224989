import * as React from 'react';
import styled from 'styled-components';
import { COLORS } from '../config';
import Banner from './Banner';

const ToolsContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    align-items: stretch;
    border: 3px solid grey; 
    border-bottom: none;
`;

interface IItemsContainer {
    $backgroundColor: string,
};


const ItemsContainer = styled.div<IItemsContainer>`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background: ${props => props.$backgroundColor};
    height: 100%;
    width: 100%;
`;

interface IItem {
    $hoverBackground: string
};


const Item = styled.div<IItem>`
    height: 100%;
    padding: 5px;
    padding-left: 5px;
    transition: 0.3s;
    font-weight: bolder;
    font-size: 20px;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid #ABABAB;
    &:hover {
        cursor: pointer;
        background: ${props => props.$hoverBackground};
    }
`;

interface IProps {
    bannerColor: string
};

const Tools = (props: IProps) => {

    const toolItems = ["My Timesheets", "My Expenses", "My Leave", "My Tickets", "My Team", "My Training", "Staff Information"]
    const { bannerColor } = props;

    return <ToolsContainer>
        <Banner $backgroundColor={bannerColor}>Tools</Banner>
        <ItemsContainer $backgroundColor={`rgba(${COLORS.PLUM_SLATE}, 0.3)`}>
            {toolItems.map((item, index) => <Item key={`Item-${index}`} $hoverBackground={`rgba(${COLORS.BLUE_SLATE}, 0.3)`}>{item}</Item>)}
        </ItemsContainer>
    </ToolsContainer>
};

export default Tools;