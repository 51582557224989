import * as React from "react";
import styled from "styled-components";
import ICustomer from "../../../ServerEntities/ICustomer";
import IIntegrationEngine from "../../../ServerEntities/IIntegrationEngine";
import { Label, Row } from "../Opportunities/OpportunityAdd";
import { Value } from "../Customers/CustomerEdit";
import Select from "../../../SharedComponents/Select/Select";
import SelectItem from "../../../SharedComponents/Select/SelectItem";
import IQuote from "../../../ServerEntities/IQuote";
import useEffectOnSome from "../../../CustomHooks/useEffectOnSome";

const IntegrationEngineContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    padding-bottom: 5px;
    align-items: stretch;
`;

interface IProps {
    customer: ICustomer,
    quote?: IQuote,
    editable: boolean,
    onUpdateIntegrationEngine: (integrationEngine: IIntegrationEngine) => void
};


const IntegrationEngineDetails = (props: IProps) => {
    const { editable, customer, quote } = props;
    const integrationEngines = customer && customer.integrationEngines ? customer.integrationEngines.filter(i => i.active) : [];
    const [integrationEngine, setIntegrationEngine] = React.useState("");
    const [integrationEngineNotes, setIntegrationEngineNotes] = React.useState("");

    useEffectOnSome(() => {
        if (quote && quote.integrationEngine) {
            setIntegrationEngine(quote.integrationEngine);
            setIntegrationEngineNotes(quote.integrationEngineNotes);
        } else if (customer && integrationEngines.length === 1) {
            setIntegrationEngine(integrationEngines[0].engine);
            setIntegrationEngineNotes(integrationEngines[0].notes);
            props.onUpdateIntegrationEngine(integrationEngines[0]);
        }
    }, [customer, quote]);

    const onChangeIntegrationEngine = (value: string) => {
        const index = parseInt(value);
        setIntegrationEngine(integrationEngines[index].engine);
        setIntegrationEngineNotes(integrationEngines[index].notes);
        props.onUpdateIntegrationEngine(integrationEngines[index]);
    }

    return <IntegrationEngineContainer>
        <Row>
            <Label htmlFor="integrationEngine">Integration Engine</Label>
            {(!editable || integrationEngines.length <= 1) && <Value>{integrationEngine}</Value>}
            {editable && integrationEngines.length > 1 && <Value>
                <Select placeholder="Select Integration Engine" onChange={onChangeIntegrationEngine} childValues={integrationEngines && integrationEngines.map(option => option.engine)} value={integrationEngine}>
                    {integrationEngines && integrationEngines.map((option, index) => <SelectItem height="20px" key={`integration-engine-${index}`} value={index}>{option.engine}</SelectItem>)}
                </Select>
            </Value>}
        </Row>
        <Row>
            <Label htmlFor="additionalInfo">Additional Info</Label>
            <Value>{integrationEngineNotes}</Value>
        </Row>
    </IntegrationEngineContainer>
};

export default IntegrationEngineDetails;
