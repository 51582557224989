import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { COLORS } from "../../../config";
import IPagedQuotes from "../../../ServerEntities/IPagedQuotes";
import IQuote from "../../../ServerEntities/IQuote";
import IQuoteType from "../../../ServerEntities/IQuoteType";
import ErrorText from "../../../SharedComponents/ErrorText";
import Header from "../../../SharedComponents/Header";
import LoadingIndicator from "../../../SharedComponents/LoadingIndicator";
import { useIsFinancialUser } from "../../../UsefulFunctions/useRoleCheck";
import { SessionContext } from "../../SessionContext";
import QuoteAdd from "./QuoteAdd";
import QuoteDetails from "./QuoteDetails";
import QuoteEdit from "./QuoteEdit";
import { getQuotesAndUsers } from "./QuotesService";
import QuotesTable from "./QuotesTable";
import IUser from "../../../ServerEntities/IUser";
import styled from "styled-components";
import Tools from "../../../SharedComponents/Tools";
import Pages from "../../../SharedComponents/Pages";
import Banner from "../../../SharedComponents/Banner";

import { getQuote } from "./QuotesService";
import { pageConfiguration } from "../../../UsefulFunctions/getComponentConfiguration";
import IPage from "../../../ServerEntities/IPage";
import { RolesContext } from "../../../Roles/RolesContext";
import useEffectOnSome from "../../../CustomHooks/useEffectOnSome";


export const LeftColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    align-items: stretch;
    box-sizing: border-box;
`;

export const RightColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex: 5 1 0;
    border: 3px solid grey;
    border-left: none;
    height: 100%;
    max-height: 100%;
    box-sizing: border-box;
`;

const QuotesIndex = () => {
    const { quoteId } = useParams();
    const { state } = React.useContext(SessionContext);

    const [isAdding, setIsAdding] = React.useState(false);
    const [isEditing, setIsEditing] = React.useState(null as unknown as IQuote | null);
    const [isViewing, setIsViewing] = React.useState(null as unknown as IQuote | null);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [quotes, setQuotes] = React.useState(null as unknown as IPagedQuotes);
    const [quoteTypes, setQuoteTypes] = React.useState(null as unknown as IQuoteType[]);
    const [users, setUsers] = React.useState(null as unknown as IUser[]);
    const [refresh, setRefresh] = React.useState(0);
    const [pageNumber, setPageNumber] = React.useState(0);
    const navigate = useNavigate();
    const pageSize = 10;
    const [viewType, setViewType] = React.useState("");

    const financialUser = useIsFinancialUser();

    const { configuration } = React.useContext(RolesContext).state;
    const [pages, setPages] = React.useState(null as unknown as IPage[]);

    React.useEffect(() => {
        const navigateToAllPricing = () => {
            navigate("/Quotes/Pricing");
        };

        const pageList = [
            { page: "All Quotes", onClick: resetIndex },
            { page: "Active Quotes", onClick: resetToActiveView },
            { page: "Archived Quotes", onClick: resetToArchiveView },
            { page: "All Pricing", onClick: navigateToAllPricing },
            { page: "My Pricing", onClick: resetToActiveView },
        ];

        let newPageList = [];
        for (let i = 0; i < pageList.length; i++) {
            let config = pageConfiguration(pageList[i].page, configuration);
            if (config !== undefined) {
                newPageList.push(pageList[i])
            }
        }
        setPages(newPageList);
    }, [configuration, navigate]);

    useEffectOnSome(() => {
        setLoading(true);
        getQuotesAndUsers(state.webToken, 100000, 0, (quotes: IPagedQuotes, users: IUser[], types: IQuoteType[]) => {
            setLoading(false);
            setError("");
            setQuotes(quotes);
            setUsers(users);
            setQuoteTypes(types);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
            setQuotes(null as unknown as IPagedQuotes);
            setUsers(null as unknown as IUser[]);
            setQuoteTypes(null as unknown as IQuoteType[]);
        }, "both");
    }, [pageNumber, refresh]);

    useEffectOnSome(() => {
        if (quoteId && isViewing == null) {
            setLoading(true);
            getQuote(quoteId, state.webToken, (quote: IQuote) => {
                setLoading(false);
                setError("");
                setIsViewing(quote);
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
                setIsViewing(null as unknown as IQuote);
            });
        }
    }, [isViewing, quoteId]);

    const openAddScreen = () => {
        setIsAdding(true);
    };

    const openEditScreen = (quote: IQuote) => {
        setIsEditing(quote);
    };

    const openViewScreen = (quote: IQuote) => {
        setIsViewing(quote);
    };

    const onChangePageNumber = (pageNumber: number) => {
        setPageNumber(pageNumber);
        onRefresh();
    };

    const closeAddScreen = () => {
        setRefresh(refresh + 1);
        setIsAdding(false);
        setIsEditing(null);
        setIsViewing(null);
    };

    const onRefresh = () => {
        setRefresh(refresh + 1);
    };

    const getComponent = (isAdding: boolean, isEditing: IQuote | null, isViewing: IQuote | null, viewType: string) => {
        if (isAdding) {
            return <QuoteAdd onBack={onBack} onAddComplete={closeAddScreen} types={quoteTypes} users={users} />;
        }
        else if (isEditing) {
            return <QuoteEdit onBack={onBack} onAddComplete={closeAddScreen} types={quoteTypes} users={users} quote={isEditing} />;
        }
        else if (isViewing) {
            return <QuoteDetails onDelete={onBack} onRefresh={onRefresh} onEdit={openEditScreen} users={users} quoteId={isViewing.id} />;
        }
        return <QuotesTable isAdmin={financialUser}
            pageNumber={pageNumber}
            onChangePageNumber={onChangePageNumber}
            onRefresh={onRefresh}
            onAdd={openAddScreen}
            onEdit={openEditScreen}
            onDelete={onRefresh}
            onView={openViewScreen}
            quotes={quotes}
            users={users}
            types={quoteTypes}
            pageSize={4}
            viewType={viewType}
        />;
    };


    if (quotes && quotes.data.length === 0 && pageNumber > 0) {
        onChangePageNumber(pageNumber - 1);
    }

    const onBack = () => {
        setIsAdding(false);
        setIsEditing(null);
        setIsViewing(null);
    }

    const determineBannerTitle = (isAdding: boolean, isEditing: IQuote | null, isViewing: IQuote | null, viewType: string) => {
        if (isAdding) {
            return 'Add Quote';
        }
        else if (isEditing) {
            return 'Edit Quote';
        }
        else if (isViewing) {
            return 'Quote Details';
        } else if (viewType === "archived") {
            return 'Archived Quotes';
        } else if (viewType === "active") {
            return 'Active Quotes';
        }
        return 'All Quotes'
    }

    const resetIndex = () => {
        setIsAdding(false);
        setIsEditing(null);
        setIsViewing(null);
        setViewType("");
    }

    const resetToArchiveView = () => {
        setIsAdding(false);
        setIsEditing(null);
        setIsViewing(null);
        setViewType("archived");
    }

    const resetToActiveView = () => {
        setIsAdding(false);
        setIsEditing(null);
        setIsViewing(null);
        setViewType("active");
    }

    if (quotes && quotes.data.length === 0 && pageNumber > 0) {
        onChangePageNumber(pageNumber - 1);
    }

    return <div style={{ position: "absolute", width: "100%", height: "100%", maxHeight: "100%", maxWidth: "100%", display: "flex", alignItems: "stretch", flexDirection: "column", boxSizing: "border-box" }}>
        <Header backgroundColor={COLORS.GREY_SLATE} border={COLORS.QUOTES_PINK} onBack={onBack} previousPageUrl={isAdding || isEditing || isViewing ? "/quotes" : "/"} />
        <main style={{ flex: "1", display: "flex", alignItems: "stretch", height: "100%", maxHeight: "100%" }}>
            <LeftColumn>
                <Tools bannerColor={COLORS.HEATHER_SLATE} />
                <Pages bannerColor={COLORS.HEATHER_SLATE} backgroundColor={COLORS.QUOTES_PINK} pages={pages} />
            </LeftColumn>
            <RightColumn>
                <Banner $backgroundColor={COLORS.HEATHER_SLATE}>{determineBannerTitle(isAdding, isEditing, isViewing, viewType)}</Banner>
                <LoadingIndicator type="Linear" show={loading} />
                {error && <ErrorText style={{ paddingLeft: "10px" }}>{error}</ErrorText>}
                {users && getComponent(isAdding, isEditing, isViewing, viewType)}
            </RightColumn>
        </main>
    </div>;
};

export default QuotesIndex;
