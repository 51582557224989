import * as React from "react";
import styled from "styled-components";
import { SessionContext } from "../../SessionContext";
import Button from "../../../SharedComponents/Button";
import LoadingIndicator from "../../../SharedComponents/LoadingIndicator";
import IWorkPackage from "../../../ServerEntities/IWorkPackage";

import ErrorText from "../../../SharedComponents/ErrorText";

import { COLORS, FONTSIZE, FONTSIZE_TITLE, getAPIUrl } from "../../../config";
import { getWorkPackage, updateWorkPackage, commitWorkPackageNotification, deletePackageFile, getPackageDocuments } from "./WorkPackageService";
import { Main } from "./WorkPackageTable";
import { createWorkPackageCommitOpportunity } from "../../../UsefulFunctions/createTriggers";
import InterfaceDetails from "./InterfaceDetails";
import IInterfaceDetails from "../../../ServerEntities/IInterfaceDetails";
import EffortDetails, { EFFORT_BUTTONS_LENGTH, EFFORT_INTERFACE_NAME_LENGTH, EFFORT_INTERFACE_TYPE_LENGTH, EFFORT_NOTES_LENGTH, EFFORT_NUMBER_LENGTH, EFFORT_TASK_NOTES_LENGTH, EFFORT_TYPE_LENGTH, isInterfaceDetails } from "./EffortDetails";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import QuoteDetails from "../Opportunities/QuoteDetails";
import ICustomer from "../../../ServerEntities/ICustomer";
import IQuoteType from "../../../ServerEntities/IQuoteType";
import IUser from "../../../ServerEntities/IUser";
import IDocument from "../../../ServerEntities/IDocument";
import DocumentBox from "../Components/DocumentBox";
import { STATUS_COMMITED, STATUS_IN_PROGRESS, STATUS_RETURNED, TYPE_DIAGRAM, TYPE_DOCUMENT, getContactIdByName, getUniqueInterfaceTypes, sumProperty } from "../../../UsefulFunctions/workPackageUtils";
import ImpersonatingRoadblock from "../Components/ImpersonatingRoadblock";
import ICurrentStatus from "../../../ServerEntities/ICurrentStatus";
import ISave from "../../../ServerEntities/ISave";
import StatusBar from "../Components/StatusBar";
import IMeetingInformation from "../../../ServerEntities/IMeetingInformation";
import Table from "../../../SharedComponents/Table/Table";
import TextInput, { updateText } from "../../../SharedComponents/TextInput";
import dateConverter from "../../../UsefulFunctions/dateConverter";
import ICustomerContactDetails from "../../../ServerEntities/ICustomerContactDetails";
import Select from "../../../SharedComponents/Select/Select";
import SelectItem from "../../../SharedComponents/Select/SelectItem";
import { componentConfiguration } from "../../../UsefulFunctions/getComponentConfiguration";
import { RolesContext } from "../../../Roles/RolesContext";
import { getCustomerContacts } from "../Customers/CustomersService";
import { OutsideContainer } from "../Customers/CustomerEdit";
import ITask from "../../../ServerEntities/ITask";
import TaskDetails from "./TaskDetails";
import { ArchitectureSmallLogo } from "../../MainMenu";
import useEffectOnSome from "../../../CustomHooks/useEffectOnSome";


const SourceContainer = styled.div`
    color: "#000";
    line-height: 20px;
    display: flex;
    flex: 0 0 auto;
    border-radius: 24px;
    font-size: 0.8em;
    font-weight: bold;
    padding: 5px 20px;
    height: 20px;
    background-color: ${COLORS.OPPORTUNITIES_YELLOW};
    margin-left: 50px;
`;

const Value = styled.div`
     font-size: ${FONTSIZE};
`;

const TableHeaderCell = styled.th`
    padding: 2px;
    font-size: 0.9em;
    font-weight: bold;
`;

const TableCell = styled.td`
    font-size: 0.9em;
`;

const TableRow = styled.tr`
    font-Size: ${FONTSIZE};
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const TitleAndButton = styled.div`
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
`;

export const SeparatorRow = styled.div`
    border-bottom: solid 3px #999;
    margin-top: 10px;
    margin-bottom: 10px;
`;

export const Label = styled.label`
    min-width: 200px;
    font-size: ${FONTSIZE};
    font-weight: bold;
`;

export const TitleLabel = styled.label`
    min-width: 200px;
    font-size: ${FONTSIZE_TITLE};
    font-weight: bold;
    line-height: 40px;
`;

const ContactsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

export const HeaderButtonContainer = styled.div`
    text-align: left;
    width: 90%
`;

export const ButtonContainer = styled.div`
    text-align: right;
`;

export const PackageHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const FilesContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const DocumentContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-direction: column;
`;

const WorkPackageContainer = styled.div`
    text-align: left;
`;

const InterfaceDetailsContainer = styled.div`
    display:flex;
    flex-direction: column;
    flex-wrap:wrap;
    width: 100%;
`;

const ContactContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    padding: 10px;
`;
const ContactName = styled.div`
    padding: 5px;
`;

export const QuoteContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    width: 90%
`;

const CommentContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background-color: #C1C1C1;
    padding: 10px;
`;

interface IProps {
    onAddComplete: () => void,
    workPackage: IWorkPackage,
    types: IQuoteType[],
    users: IUser[],
    customers: ICustomer[],
    review: boolean,
    onViewHistory: (workPackage: IWorkPackage) => void
};

const WorkPackageEdit = (props: IProps) => {
    const { state } = React.useContext(SessionContext);
    const { types, users, customers } = props;
    const { configuration } = React.useContext(RolesContext).state;
    const rbac = componentConfiguration("Packages", configuration);
    const [opportunityId, setOpportunityId] = React.useState(0);
    const [generalAssumptions, setGeneralAssumptions] = React.useState("");
    const [specificAssumptions, setSpecificAssumptions] = React.useState("");
    const [technicalDetail, setTechnicalDetail] = React.useState("");
    const [scope, setScope] = React.useState("");
    const [dateAdded, setDateAdded] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [workPackage, setWorkPackage] = React.useState(undefined as unknown as IWorkPackage);
    const [isEditingInterface, setIsEditingInterface] = React.useState(undefined as unknown as IInterfaceDetails);
    const [isEditingTask, setIsEditingTask] = React.useState(undefined as unknown as ITask);
    const [isEditingEffort, setIsEditingInterfaceEffort] = React.useState(undefined as unknown as IInterfaceDetails | ITask);
    const [diagrams, setDiagrams] = React.useState(undefined as unknown as IDocument[]);
    const [documents, setDocuments] = React.useState(undefined as unknown as IDocument[]);
    const none = -1;
    const [saving, setSaving] = React.useState(undefined as unknown as ISave);
    const [currentStatus, setCurrentStatus] = React.useState(undefined as unknown as ICurrentStatus);
    const [contacts, setContacts] = React.useState([] as ICustomerContactDetails[]);
    const [meetings, setMeetings] = React.useState([] as IMeetingInformation[]);
    const [editingMeeting, setEditingMeeting] = React.useState(undefined as unknown as IMeetingInformation);
    const [freeText, setFreeText] = React.useState("");
    const customerId = props.workPackage.opportunity.quote.customerId || -1;
    const [meetingDate, setMeetingDate] = React.useState(undefined as unknown as Date);
    const [comments, setComments] = React.useState("");

    useEffectOnSome(() => {
        getWorkPackage(props.workPackage.id, state.webToken, (op: IWorkPackage) => {
            setLoading(false);
            setError("");
            setWorkPackage(op);
            setOpportunityId(op.opportunityId);
            setScope(op.scope);
            setGeneralAssumptions(op.generalAssumptions);
            setSpecificAssumptions(op.specificAssumptions);
            setTechnicalDetail(op.technicalDetail);
            setDateAdded(op.dateAdded);
            setMeetings(op.meetingInformation);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
        getDocuments(TYPE_DIAGRAM);
        getDocuments(TYPE_DOCUMENT);
    }, [props.workPackage.id]);

    useEffectOnSome(() => {
        if (customerId !== 0)
            getCustomerContacts(customerId, state.webToken, (contacts: ICustomerContactDetails[]) => {
                setLoading(false);
                setError("");
                setContacts(contacts);
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
                setContacts(null as unknown as ICustomerContactDetails[]);
            });
    }, [customerId]);

    const getDocuments = (type: string) => {
        getPackageDocuments(props.workPackage.id, type, state.webToken, (files: IDocument[]) => {
            setLoading(false);
            setError("");
            if (type === TYPE_DOCUMENT) {
                setDocuments(files.length > 0 ? files : undefined as unknown as IDocument[]);
            } else {
                setDiagrams(files.length > 0 ? files : undefined as unknown as IDocument[])
            }
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    }

    const save = (commit: boolean) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        setSaving({ commit: commit });
    }

    const saveScopingRequired = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        const newWorkPackage: IWorkPackage = {
            id: props.workPackage.id,
            scope: scope,
            generalAssumptions: generalAssumptions,
            specificAssumptions: specificAssumptions,
            technicalDetail: technicalDetail,
            dateAdded: dateAdded,
            status: STATUS_RETURNED,
            interfaceDetails: workPackage.interfaceDetails,
            tasks: workPackage.tasks,
            opportunityId: opportunityId,
            opportunity: props.workPackage.opportunity,
            meetingInformation: meetings

        };
        if (newWorkPackage.opportunity.quote.relatedQuotes === null) {
            newWorkPackage.opportunity.quote.relatedQuotes = [];
        }
        setLoading(true);
        updateWorkPackage(state.webToken, newWorkPackage, () => {
            setLoading(false);
            setError("");
            props.onAddComplete();
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    }

    const executeSave = (token: string) => {
        setSaving(undefined as unknown as ISave);
        const newWorkPackage: IWorkPackage = {
            id: props.workPackage.id,
            scope: scope,
            generalAssumptions: generalAssumptions,
            specificAssumptions: specificAssumptions,
            technicalDetail: technicalDetail,
            dateAdded: dateAdded,
            status: saving.commit ? STATUS_COMMITED : STATUS_IN_PROGRESS,
            interfaceDetails: workPackage.interfaceDetails,
            tasks: workPackage.tasks,
            opportunityId: opportunityId,
            opportunity: props.workPackage.opportunity,
            meetingInformation: meetings

        };
        if (newWorkPackage.opportunity.quote.relatedQuotes === null) {
            newWorkPackage.opportunity.quote.relatedQuotes = [];
        }
        setLoading(true);
        updateWorkPackage(token ? token : state.webToken, newWorkPackage, () => {
            setLoading(false);
            setError("");
            if (saving.commit) {
                const trigger = createWorkPackageCommitOpportunity(newWorkPackage);
                commitWorkPackageNotification(trigger, state.webToken, () => { }, () => { },)
            }
            props.onAddComplete();
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    };

    const renderInterfaceDetail = (isEditing: IInterfaceDetails) => (interfaceDetails: IInterfaceDetails, index: number) => {
        return <InterfaceDetails
            interfaceDetails={interfaceDetails}
            isEditing={isEditing}
            index={index}
            onUpdate={onUpdateInterface}
            onCancel={onCancelInterface}
            onDelete={onDeleteInterface} />
    }

    const renderTask = (isEditing: ITask) => (task: ITask, index: number) => {
        return <TaskDetails
            taskDetails={task}
            isEditing={isEditing}
            index={index}
            onUpdate={onUpdateTask}
            onCancel={onCancelTask}
            onDelete={onDeleteTask} />
    }

    const onUpdateInterface = (interfaceDetails: IInterfaceDetails, index: number) => {
        workPackage.interfaceDetails[index] = interfaceDetails;
        setIsEditingInterface(undefined as unknown as IInterfaceDetails);
        setWorkPackage({ ...workPackage });
    }

    const onCancelInterface = (index: number) => {
        if (workPackage.interfaceDetails[index].id === none) {
            workPackage.interfaceDetails.splice(index, 1);
        }
        setIsEditingInterface(undefined as unknown as IInterfaceDetails);
        setWorkPackage({ ...workPackage });
    }

    const onDeleteInterface = (index: number) => {
        workPackage.interfaceDetails.splice(index, 1);
        setIsEditingInterface(undefined as unknown as IInterfaceDetails);
        setWorkPackage({ ...workPackage });
    }

    const onUpdateTask = (task: ITask, index: number) => {
        workPackage.tasks[index] = task;
        setIsEditingTask(undefined as unknown as ITask);
        setWorkPackage({ ...workPackage });
    }

    const onCancelTask = (index: number) => {
        if (workPackage.tasks[index].id === none) {
            workPackage.interfaceDetails.splice(index, 1);
        }
        setIsEditingTask(undefined as unknown as ITask);
        setWorkPackage({ ...workPackage });
    }

    const onDeleteTask = (index: number) => {
        workPackage.tasks.splice(index, 1);
        setIsEditingTask(undefined as unknown as ITask);
        setWorkPackage({ ...workPackage });
    }

    const addInterface = () => {
        const newInterface: IInterfaceDetails = {
            id: none,
            name: "",
            direction: "",
            sourceSystem: "",
            sourceDataType: "",
            sourceMessageType: "",
            sourceDeliveryMechanism: "",
            inboundPort: "",
            destinationSystem: "",
            destinationDataType: "",
            destinationMessageType: "",
            destinationAddressPort: "",
            transformationRequired: "",
            notes: "",
            description: "",
            arch: 0,
            dev: 0,
            test: 0,
            golive: 0,
            other: 0,
            interfaceType: ""
        }

        workPackage.interfaceDetails.push(newInterface);
        setIsEditingInterface(newInterface);
        setWorkPackage({ ...workPackage })
    }

    const addTask = () => {
        const newTask: ITask = {
            id: none,
            name: "",
            notes: "",
            description: "",
            arch: 0,
            dev: 0,
            test: 0,
            golive: 0,
            other: 0,
            interfaceType: ""
        }

        workPackage.tasks.push(newTask);
        setIsEditingTask(newTask);
        setWorkPackage({ ...workPackage })
    }

    const renderEffort = (isEditing: IInterfaceDetails | ITask) => (interfaceDetails: IInterfaceDetails | ITask, index: number) => {
        return <EffortDetails
            effort={interfaceDetails}
            isEditing={isEditingEffort}
            index={index}
            onUpdate={onUpdateEffort}
            onCancel={onCancelEffort}
            onDelete={onDeleteEffort} />
    };

    const onUpdateEffort = (effort: IInterfaceDetails | ITask, index: number) => {
        if (isInterfaceDetails(effort)) {
            workPackage.interfaceDetails[index] = effort;
            setIsEditingInterfaceEffort(undefined as unknown as IInterfaceDetails);
        }
        else {
            workPackage.tasks[index] = effort;
        }
        setWorkPackage({ ...workPackage });
    }

    const onCancelEffort = (effort: IInterfaceDetails | ITask, index: number) => {
        if (isInterfaceDetails(effort)) {
            if (workPackage.interfaceDetails[index].id === none) {
                workPackage.interfaceDetails.splice(index, 1);
                setIsEditingInterfaceEffort(undefined as unknown as IInterfaceDetails);
            }
        } else {
            if (workPackage.tasks[index].id === none) {
                workPackage.tasks.splice(index, 1);
                setIsEditingInterfaceEffort(undefined as unknown as IInterfaceDetails);
            }
        }
        setWorkPackage({ ...workPackage });
    }

    const onDeleteEffort = (effort: IInterfaceDetails | ITask, index: number) => {
        if (isInterfaceDetails(effort)) {
            workPackage.interfaceDetails.splice(index, 1);
            setIsEditingInterfaceEffort(undefined as unknown as IInterfaceDetails | ITask);
        } else {
            workPackage.tasks.splice(index, 1);
            setIsEditingInterfaceEffort(undefined as unknown as IInterfaceDetails | ITask);
        }
        setWorkPackage({ ...workPackage });
    }


    const sumIntArch = workPackage ? sumProperty(workPackage.interfaceDetails, "arch") : 0;
    const sumIntDev = workPackage ? sumProperty(workPackage.interfaceDetails, "dev") : 0;
    const sumIntTest = workPackage ? sumProperty(workPackage.interfaceDetails, "test") : 0;
    const sumIntGolive = workPackage ? sumProperty(workPackage.interfaceDetails, "golive") : 0;
    const sumIntOther = workPackage ? sumProperty(workPackage.interfaceDetails, "other") : 0;
    const sumTaskArch = workPackage ? sumProperty(workPackage.tasks, "arch") : 0;
    const sumTaskDev = workPackage ? sumProperty(workPackage.tasks, "dev") : 0;
    const sumTaskTest = workPackage ? sumProperty(workPackage.tasks, "test") : 0;
    const sumTaskGolive = workPackage ? sumProperty(workPackage.tasks, "golive") : 0;
    const sumTaskOther = workPackage ? sumProperty(workPackage.tasks, "other") : 0;
    const intTypes = workPackage ? getUniqueInterfaceTypes(workPackage.interfaceDetails) : "";

    const onRemove = (id: number) => {
        deletePackageFile(state.webToken, id, () => {
            setLoading(false);
            setError("");
            getPackageDocuments(props.workPackage.id, "document", state.webToken, (files: IDocument[]) => {
                setLoading(false);
                setError("");
                setDocuments(files.length > 0 ? files : undefined as unknown as IDocument[]);
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
            });

        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    }

    const renderHtml = (html: string) => {
        return <div dangerouslySetInnerHTML={{ __html: html }} />;
    }


    const navigateToHistory = () => {
        props.onViewHistory(props.workPackage);
    }

    const onUpdateCurrentStatus = (status: ICurrentStatus) => {
        setCurrentStatus(status);
    }

    const addContact = (meetings: IMeetingInformation[], index: number) => () => {
        const contactsCopy = [...editingMeeting.contacts];
        contactsCopy.push({
            id: none,
            name: "",
            email: "",
            jobTitle: "",
            phone: "",
            customerId: 0,
            visibleToDev: false,
            source: "",
            quoteRecipient: 0
        });
        const meetingCopy = [...meetings];
        meetingCopy[index].contacts = contactsCopy;
        setMeetings(meetingCopy);
        setEditingMeeting(meetings[index]);
    }

    const onEditMeeting = (meeting: IMeetingInformation) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setMeetingDate(new Date(meeting.date));
        setFreeText(meeting.freeText);
        setEditingMeeting(meeting)
    };

    const onRemoveMeeting = (index: number) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const meetingCopy = [...meetings];
        meetingCopy.splice(index, 1);
        setMeetings(meetingCopy);
        setEditingMeeting(undefined as unknown as IMeetingInformation);
    };

    const onContactChange = (index: number) => (contactName: string) => {
        const editingMeetingCopy = { ...editingMeeting };
        editingMeetingCopy.contacts[index].name = contactName;
        editingMeetingCopy.contacts[index].id = getContactIdByName(contactName, contacts);
        setEditingMeeting(editingMeetingCopy);
    }

    const onRemoveContact = (index: number) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const editingMeetingCopy = { ...editingMeeting };
        editingMeetingCopy.contacts.splice(index, 1);
        setEditingMeeting(editingMeetingCopy);
    }

    const renderContact = (meetingId: number) => (contact: ICustomerContactDetails, index: number) => {
        if (contact.id === none) {
            return <ContactContainer>
                <Select placeholder="Select a contact" onChange={onContactChange(index)} childValues={contacts && contacts.map(option => option.name)} value={editingMeeting.contacts[index].name}>
                    {contacts.map((option, index) => <SelectItem height="20px" key={`add-contact-item-${index}`} value={option.name}>{option.name}</SelectItem>)}
                </Select>
            </ContactContainer >
        }
        return <ContactContainer>
            <ContactName>{contact.name}</ContactName>
            {editingMeeting !== undefined && meetingId === editingMeeting.id && <Button plain={true} onClick={onRemoveContact(index)}>x</Button>}
        </ContactContainer>
    }

    const onSaveMeeting = (index: number) => () => {
        editingMeeting.id = 1;
        if (meetingDate) {
            editingMeeting.date = meetingDate.getTime();
        }
        editingMeeting.freeText = freeText;
        const meetingCopy = [...meetings];
        meetingCopy[index] = { ...editingMeeting };
        setMeetings(meetingCopy);
        setEditingMeeting(undefined as unknown as IMeetingInformation);
    }

    const onCancelMeeting = (index: number) => () => {
        const meetingCopy = [...meetings];
        setMeetings(meetingCopy);
        setEditingMeeting(undefined as unknown as IMeetingInformation);
    }

    const changeDate = (setter: (date: Date) => void) => (date: Date, event: React.SyntheticEvent) => {
        setter(date);
    };

    const addMeeting = () => {
        const meetingCopy = [...meetings];
        const newMeeting = {
            date: 0, freeText: "",
            id: none,
            contacts: []
        };
        meetingCopy.push(newMeeting);
        setMeetings(meetingCopy);
        setEditingMeeting(newMeeting);
        setMeetingDate(undefined as unknown as Date);
        setFreeText("");
    };

    const onLoading = () => {
        setLoading(true);
    }

    const onFinishedLoading = () => {
        setLoading(false);
    }

    const validateReview = () => {
        if (comments && comments.length > 0) {
            return true;
        }
        return false;
    }

    return <OutsideContainer>
        <LoadingIndicator type="Linear" show={loading} />
        <Main>
            {error && <ErrorText>{error}</ErrorText>}
            {saving && <ImpersonatingRoadblock onCommit={executeSave}></ImpersonatingRoadblock>}
            <PackageHeader>
                <HeaderButtonContainer>
                    {workPackage && workPackage.opportunity.quote && <StatusBar status={currentStatus} onUpdateStatus={onUpdateCurrentStatus} />}
                    {!props.review && <Button color={COLORS.GREY_SLATE} submit={true} onClick={save(false)}>Save scope</Button>}
                    {!props.review && <Button color={COLORS.GREY_SLATE} style={{ marginLeft: "8px" }} onClick={save(true)}>Save and commit scope</Button>}
                    {props.review && <Button color={COLORS.GREY_SLATE} submit={true} onClick={save(true)}>Send for Pricing</Button>}
                    {props.review && <Button disabled={!validateReview()} color={COLORS.GREY_SLATE} style={{ marginLeft: "8px" }} onClick={saveScopingRequired}>Additional Scoping Required</Button>}
                    <Button color={COLORS.GREY_SLATE} plain={true} style={{ marginLeft: "8px" }} onClick={props.onAddComplete}>Cancel</Button>
                    <Button color={COLORS.CHANGE_HISTORY} plain={false} style={{ marginLeft: "8px" }} onClick={navigateToHistory}>Change History</Button>
                </HeaderButtonContainer>
                <ArchitectureSmallLogo />
            </PackageHeader>
            {workPackage && <WorkPackageContainer>
                <QuoteContainer><TitleLabel>Quote Number {workPackage.opportunity.quote.quoteNumber}</TitleLabel><SourceContainer>{workPackage.opportunity.quote.sourceType}</SourceContainer></QuoteContainer>
                {workPackage.opportunity.quote && <QuoteDetails onLoading={onLoading} onFinishedLoading={onFinishedLoading} editable={false} quote={workPackage.opportunity.quote} types={types} customers={customers} users={users} />}
                <SeparatorRow />
                {props.review && <CommentContainer>
                    <Row>
                        <h3 style={{ marginRight: "50px" }}>Comments</h3>
                    </Row>
                    <Row style={{ width: "100%" }}>
                        <ReactQuill style={{ width: "100%" }} value={comments} onChange={setComments} />
                    </Row>

                </CommentContainer>}
                {props.review && <SeparatorRow />}
                <Row>
                    <h3>Integration Engine Details</h3>
                </Row>
                <Row>
                    <Label htmlFor="integrationEngine">Integration engine</Label>
                    <Value>{workPackage.opportunity.quote.integrationEngine}</Value>
                </Row>
                <Row>
                    <Label htmlFor="additionalInfo">Additional info</Label>
                    <Value>{workPackage.opportunity.quote.integrationEngineNotes}</Value>
                </Row>
                <Row>
                    <Label htmlFor="workRequired">Installation required</Label>
                    <Value>{workPackage.opportunity.installationRequired ? "yes" : "no"}</Value>
                </Row>

                <SeparatorRow />
                <Row>
                    <TitleLabel htmlFor="workRequired">Work Required</TitleLabel>
                </Row>
                <Row>
                    <Value>{renderHtml(workPackage.opportunity.workRequired)}</Value>
                </Row>
                <Row>
                    <TitleLabel htmlFor="outOfScope">Future Requirements and Out of Scope</TitleLabel>
                </Row>
                <Row>
                    <Value>{renderHtml(workPackage.opportunity.outOfScope)}</Value>
                </Row>
                <SeparatorRow />

                <form>
                    <Row>
                        <TitleLabel>Scope - Introduction</TitleLabel>
                    </Row>
                    <Row>
                        <ReactQuill style={{ WebkitBorderRadius: "10px", width: "100%" }} value={scope} onChange={setScope} />
                    </Row>
                    <SeparatorRow />

                </form>
                <Row>
                    <TitleLabel style={{ color: COLORS.INTERFACE }}>Scope - Interfaces</TitleLabel>
                </Row>
                <Row>
                    <InterfaceDetailsContainer>
                        {workPackage && workPackage.interfaceDetails && workPackage.interfaceDetails.map(renderInterfaceDetail(isEditingInterface))}
                    </InterfaceDetailsContainer>
                </Row>
                <Row>
                    <Button color={COLORS.INTERFACE} submit={false} onClick={addInterface}>Add Interface</Button>
                </Row>
                <Row>
                    <TitleLabel style={{ color: COLORS.TASKS }}>Scope - Tasks</TitleLabel>
                </Row>
                <Row>
                    <InterfaceDetailsContainer>
                        {workPackage && workPackage.tasks && workPackage.tasks.map(renderTask(isEditingTask))}
                    </InterfaceDetailsContainer>
                </Row>
                <Row>
                    <Button color={COLORS.TASKS} submit={false} onClick={addTask}>Add Task</Button>
                </Row>
                <SeparatorRow />
                <Row>
                    <TitleLabel>Project diagram</TitleLabel>
                </Row>
                <Row>
                    <FilesContainer style={{ width: "100%" }}>
                        <DocumentBox
                            color={COLORS.SIXTH}
                            documents={diagrams}
                            onRemove={onRemove}
                            onUpdate={getDocuments}
                            url={getAPIUrl() + `quotes/packages/${props.workPackage.id}/file`}
                            token={state.webToken}
                            type={TYPE_DIAGRAM}
                            max={1}
                        />
                    </FilesContainer>
                </Row>
                <SeparatorRow />
                <Row>
                    <TitleLabel>Additional Technical detail</TitleLabel>
                </Row>
                <Row>
                    <ReactQuill style={{ width: "100%" }} value={technicalDetail} onChange={setTechnicalDetail} />
                </Row>
                <SeparatorRow />
                <Row>
                    <TitleLabel htmlFor="attachments">Attachments</TitleLabel>
                </Row>
                <Row>
                    <FilesContainer style={{ width: "100%" }}>
                        <DocumentBox
                            color={COLORS.SIXTH}
                            documents={documents}
                            onRemove={onRemove}
                            onUpdate={getDocuments}
                            url={getAPIUrl() + `quotes/packages/${props.workPackage.id}/file`}
                            token={state.webToken}
                            type={TYPE_DOCUMENT}
                        />
                    </FilesContainer>
                </Row>
                <SeparatorRow />
                <Row>
                    <TitleLabel>General Assumptions</TitleLabel>
                </Row>
                <Row>
                    <ReactQuill style={{ width: "100%" }} value={generalAssumptions} onChange={setGeneralAssumptions} />
                </Row>
                <SeparatorRow />
                <Row>
                    <TitleLabel>Project Specific Assumptions</TitleLabel>
                </Row>
                <Row>
                    <ReactQuill style={{ width: "100%" }} value={specificAssumptions} onChange={setSpecificAssumptions} />
                </Row>
                <SeparatorRow />
                <Row>
                    <TitleLabel>Effort and budget</TitleLabel>
                </Row>
                <Row style={{ marginTop: "30px" }}>
                    <Label htmlFor="effortInterfaces">Interfaces</Label>
                </Row>
                <Row>
                    <table width={"100%"}>
                        <thead>
                            {<tr>
                                <TableHeaderCell style={{ width: EFFORT_NUMBER_LENGTH }}></TableHeaderCell>
                                <TableHeaderCell style={{ width: EFFORT_INTERFACE_NAME_LENGTH }}></TableHeaderCell>
                                <TableHeaderCell style={{ width: "40%", textAlign: "center", borderBottom: "1px solid #ccc" }} colSpan={5}>Effort in days</TableHeaderCell>
                                <TableHeaderCell style={{ width: EFFORT_INTERFACE_TYPE_LENGTH, textAlign: "center" }}></TableHeaderCell>
                                <TableHeaderCell style={{ width: EFFORT_NOTES_LENGTH, textAlign: "center" }}></TableHeaderCell>
                                <TableHeaderCell style={{ width: EFFORT_BUTTONS_LENGTH, textAlign: "center" }}></TableHeaderCell>
                            </tr>}
                            <tr style={{ borderBottom: "1px solid #ccc" }}>
                                <TableHeaderCell style={{ width: EFFORT_NUMBER_LENGTH }} >#</TableHeaderCell>
                                <TableHeaderCell style={{ width: EFFORT_INTERFACE_NAME_LENGTH, textAlign: "left" }}>Interface Name</TableHeaderCell>
                                <TableHeaderCell style={{ width: EFFORT_TYPE_LENGTH, textAlign: "center" }}>Arch</TableHeaderCell>
                                <TableHeaderCell style={{ width: EFFORT_TYPE_LENGTH, textAlign: "center" }}>Dev</TableHeaderCell>
                                <TableHeaderCell style={{ width: EFFORT_TYPE_LENGTH, textAlign: "center" }}>Test</TableHeaderCell>
                                <TableHeaderCell style={{ width: EFFORT_TYPE_LENGTH, textAlign: "center" }}>Go-live</TableHeaderCell>
                                <TableHeaderCell style={{ width: EFFORT_TYPE_LENGTH, textAlign: "center" }}>Other</TableHeaderCell>
                                <TableHeaderCell style={{ width: EFFORT_INTERFACE_TYPE_LENGTH }}>Interface type</TableHeaderCell>
                                <TableHeaderCell style={{ width: EFFORT_NOTES_LENGTH }}>Notes</TableHeaderCell>
                                <TableHeaderCell style={{ width: EFFORT_BUTTONS_LENGTH }}></TableHeaderCell>
                            </tr>
                        </thead>
                        <tbody>
                            {workPackage && workPackage.interfaceDetails && workPackage.interfaceDetails.map(renderEffort(isEditingEffort))}
                            <tr key={`effort-item-total`} >
                                <TableCell style={{ fontWeight: "bold" }} colSpan={10}>Total days: {sumIntArch + sumIntDev + sumIntTest + sumIntGolive + sumIntOther}</TableCell>
                            </tr>
                            <tr key={`effort-item-total-interface-types`} >
                                <TableCell style={{ fontWeight: "bold" }} colSpan={10}>Interface types: {intTypes}</TableCell>
                            </tr>
                        </tbody>
                    </table>
                </Row>
                <Row style={{ marginTop: "30px" }}>
                    <Label htmlFor="effortTasks">Tasks</Label>
                </Row>
                <Row>
                    <table width={"100%"}>
                        <thead>
                            {<tr>
                                <TableHeaderCell style={{ width: EFFORT_NUMBER_LENGTH }}></TableHeaderCell>
                                <TableHeaderCell style={{ width: EFFORT_INTERFACE_NAME_LENGTH }}></TableHeaderCell>
                                <TableHeaderCell style={{ width: "40%", textAlign: "center", borderBottom: "1px solid #ccc" }} colSpan={5}>Effort in days</TableHeaderCell>
                                <TableHeaderCell style={{ width: EFFORT_INTERFACE_TYPE_LENGTH, textAlign: "center" }}></TableHeaderCell>
                                <TableHeaderCell style={{ width: EFFORT_NOTES_LENGTH, textAlign: "center" }}></TableHeaderCell>
                                <TableHeaderCell style={{ width: EFFORT_BUTTONS_LENGTH, textAlign: "center" }}></TableHeaderCell>
                            </tr>}
                            <tr style={{ borderBottom: "1px solid #ccc" }}>
                                <TableHeaderCell style={{ width: EFFORT_NUMBER_LENGTH }} >#</TableHeaderCell>
                                <TableHeaderCell style={{ width: EFFORT_INTERFACE_NAME_LENGTH, textAlign: "left" }}>Interface Name</TableHeaderCell>
                                <TableHeaderCell style={{ width: EFFORT_TYPE_LENGTH, textAlign: "center" }}>Arch</TableHeaderCell>
                                <TableHeaderCell style={{ width: EFFORT_TYPE_LENGTH, textAlign: "center" }}>Dev</TableHeaderCell>
                                <TableHeaderCell style={{ width: EFFORT_TYPE_LENGTH, textAlign: "center" }}>Test</TableHeaderCell>
                                <TableHeaderCell style={{ width: EFFORT_TYPE_LENGTH, textAlign: "center" }}>Go-live</TableHeaderCell>
                                <TableHeaderCell style={{ width: EFFORT_TYPE_LENGTH, textAlign: "center" }}>Other</TableHeaderCell>
                                <TableHeaderCell style={{ width: EFFORT_TASK_NOTES_LENGTH }}>Notes</TableHeaderCell>
                                <TableHeaderCell style={{ width: EFFORT_BUTTONS_LENGTH }}></TableHeaderCell>
                            </tr>
                        </thead>
                        <tbody>
                            {workPackage && workPackage.tasks && workPackage.tasks.map(renderEffort(isEditingEffort))}
                            <tr key={`effort-item-total`} >
                                <TableCell style={{ fontWeight: "bold" }} colSpan={10}>Total days: {sumTaskArch + sumTaskDev + sumTaskTest + sumTaskGolive + sumTaskOther}</TableCell>
                            </tr>
                        </tbody>
                    </table>
                </Row>

                <Row style={{ marginTop: "20px" }}>
                    <table width={"100%"}>
                        <tbody>
                            <tr key={`effort-item-total-label`} >
                                <TableCell style={{ fontWeight: "bold" }} colSpan={2}>Total of interfaces and tasks</TableCell>
                            </tr>
                            <tr key={`effort-item-total-sum`}>
                                <TableCell style={{ width: "50px" }}></TableCell>
                                <TableCell style={{ fontWeight: "bold" }}>Total days: {sumIntArch + sumIntDev + sumIntTest + sumIntGolive + sumIntOther + sumTaskArch + sumTaskDev + sumTaskTest + sumTaskGolive + sumTaskOther}</TableCell>
                            </tr>
                            <tr key={`effort-item-total-interface-types-sum`}>
                                <TableCell style={{ width: "50px" }}></TableCell>
                                <TableCell style={{ fontWeight: "bold" }}>Interface types: {intTypes}</TableCell>
                            </tr>
                        </tbody>
                    </table>
                </Row>
                <SeparatorRow />
                <Row>
                    <TitleAndButton>
                        <TitleLabel htmlFor="meetings">Meetings</TitleLabel>
                        {rbac.update && <Button color={COLORS.GREY_SLATE} submit={false} onClick={addMeeting}>Add meeting</Button>}
                    </TitleAndButton>
                </Row>
                {meetings && <Table style={{ marginTop: "10px" }}>
                    <tbody>
                        <tr style={{ fontSize: FONTSIZE, fontWeight: "bold", display: "flex" }} >
                            <TableCell width={"10%"}>Date</TableCell>
                            <TableCell width={"30%"}>Notes</TableCell>
                            <TableCell width={"40%"}>Contacts</TableCell>
                            <TableCell width={"20%"}></TableCell>
                        </tr>
                        {meetings.map((meeting, index) => {
                            const id = meeting.id ? meeting.id : none;
                            if (editingMeeting && editingMeeting.id === id) {
                                return <TableRow key={`contact-entry=${index}`}>
                                    <TableCell width={"10%"}>
                                        <DatePicker required={true} dateFormat="dd-MMM-yyyy" selected={meetingDate} onChange={changeDate(setMeetingDate)} placeholderText={"select a date"} />
                                    </TableCell>
                                    <TableCell width={"30%"}>
                                        <TextInput width="90%" textArea={true} value={freeText} onChange={updateText(setFreeText)} placeholder="Free text" />
                                    </TableCell>
                                    <TableCell width={"40%"}>
                                        <ContactsContainer>
                                            {contacts && contacts.length > 0 && <Button color={COLORS.GREY_SLATE} onClick={addContact(meetings, index)} style={{ marginRight: "30px" }} submit={false}>Add contact</Button>}
                                            {meeting.contacts.map(renderContact(meeting.id))}
                                            {(contacts === null || contacts.length === 0) && <ContactContainer>No contacts available for this customer.</ContactContainer>}
                                        </ContactsContainer>
                                    </TableCell>
                                    <TableCell width={"20%"}>
                                        <ButtonContainer>
                                            <Button plain={true} onClick={onSaveMeeting(index)} submit={false} style={{ marginRight: "10px" }}>Save</Button>
                                            <Button plain={true} onClick={onCancelMeeting(index)} submit={false}>Cancel</Button>
                                        </ButtonContainer>
                                    </TableCell>
                                </TableRow>;
                            } else {
                                return <TableRow key={`contact-entry=${index}`}>
                                    <TableCell width={"10%"}>{dateConverter(meeting.date)}</TableCell>
                                    <TableCell width={"30%"}>{meeting.freeText}</TableCell>
                                    <TableCell width={"40%"}>
                                        <ContactsContainer>
                                            {meeting.contacts.map(renderContact(meeting.id))}
                                        </ContactsContainer>
                                    </TableCell>
                                    <TableCell width={"20%"}>
                                        {editingMeeting === undefined &&
                                            <ButtonContainer>
                                                {rbac.update && <Button plain={true} onClick={onEditMeeting(meeting)} style={{ marginRight: "10px" }}>Edit</Button>}
                                                {rbac.update && <Button plain={true} onClick={onRemoveMeeting(index)}>Delete</Button>}
                                            </ButtonContainer>
                                        }
                                    </TableCell>
                                </TableRow>;
                            }
                        })
                        }
                    </tbody>
                </Table>}


            </WorkPackageContainer>
            }
        </Main >
    </OutsideContainer >
};

export default WorkPackageEdit;
