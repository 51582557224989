import * as React from 'react';
import styled from 'styled-components';
import Banner from './Banner';
import { hexToRgb } from '../UsefulFunctions/hexToRGB';
import IPage from '../ServerEntities/IPage';

interface IProps {
    bannerColor: string,
    backgroundColor: string,
    pages: IPage[]
};

const PagesContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 2 1 0;
    border: 3px solid grey;
`;

const ItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

interface IItem {
    $background: string;
    $borderColor: string;
};

const Item = styled.div<IItem>`
    padding: 2px;
    padding-left: 5px;
    transition: 0.3s;
    font-weight: 600;
    font-size: 20px;
    background: ${props => props.$background};
    border-left: 5px ${props => props.$borderColor} solid;
    &:hover {
        cursor: pointer;
        
    }
`;

const Pages = (props: IProps) => {

    const { bannerColor, pages, backgroundColor } = props

    const isOdd = (num: number) => { return num % 2 }

    return <PagesContainer>
        <Banner $backgroundColor={bannerColor}>Pages</Banner>
        <ItemsContainer>
            {pages && pages.map((item, index) => {
                let color = isOdd(index) ? `rgba(${hexToRgb(backgroundColor)}, 0.2)` : `rgba(${hexToRgb(backgroundColor)}, 0.3)`
                return <Item key={`Item-${index}`} onClick={item.onClick} $background={color} $borderColor={backgroundColor}>{item.page}</Item>
            })}
        </ItemsContainer>
    </PagesContainer>
};

export default Pages;