import * as React from "react";
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import ViewContainer from '../../../SharedComponents/ViewContainer';
import ManagerBanner from '../../../Views/Manager/Components/ManagerBanner';
import RolesTab from '../../../Views/Manager/UserManagement/RolesTab';
import UsersTab from '../../../Views/Manager/UserManagement/UsersTab';
import RoleChangeDialog from '../../../Views/RoleChangeDialog';
import AccessTab from "./AccessTab";

interface IProps {
    componentNames: string[],
    pageName?: string
};

const NavContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0 12px;
    border-bottom: 1px solid #e5e5e5;
`;

const NavLink = styled.a`
    margin: 12px 12px;
    font-size: 14px;
    color: #575757;
    padding: 4px 12px;
    font-weight: 400;
    cursor: pointer;
    &.selected {
        color: #ffffff;
        background: #4e3b6b;
        border-radius: 16px;
    }
`;

const UserManagementIndex = (props: IProps) => {
    const componentNames = props.componentNames;
    const pageName = props.pageName || "Users";
    const [roleChangeDialogOpen, setRoleChangeDialogOpen] = React.useState(false);
    const roleDialogRef = React.useRef(null as unknown as HTMLDivElement);
    const navigate = useNavigate();

    const closeRoleChangeDialog = () => {
        setRoleChangeDialogOpen(false);
    };

    const changePage = (pageName: string) => () => {
        navigate(`/Manager/${pageName}`);
    };

    const getComponentForPageName = (pageName: string) => {
        switch (pageName) {
            case "Access":
                return <AccessTab tablabel="Access" />;
            case "Roles":
                return <RolesTab tablabel="Roles" />;
            case "Users":
            default:
                return <UsersTab tablabel="Users" />;
        }
    };

    const getNavLinkForComponentName = (componentName: string) => {
        switch (componentName) {
            case "Access":
                return <NavLink key="nav-link-access" onClick={changePage("Access")} className={pageName === "Access" ? "selected" : "deselected"}>Access</NavLink>;
            case "Roles":
                return <NavLink key="nav-link-roles" onClick={changePage("Roles")} className={pageName === "Roles" ? "selected" : "deselected"}>Roles</NavLink>;
            case "Users":
            default:
                return <NavLink key="nav-link-users" onClick={changePage("Users")} className={pageName === "Users" ? "selected" : "deselected"}>Users</NavLink>;
        }
    };

    return <ViewContainer>
        <RoleChangeDialog dialogRef={roleDialogRef} open={roleChangeDialogOpen} onClose={closeRoleChangeDialog} applicationName="Manager" />
        <ViewContainer>
            <ManagerBanner setRoleChangeDialogOpen={setRoleChangeDialogOpen} />
            <NavContainer>
                {componentNames.map(getNavLinkForComponentName)}
            </NavContainer>
            {getComponentForPageName(pageName)}
        </ViewContainer>
    </ViewContainer>;
};

export default UserManagementIndex;
