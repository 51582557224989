import IConfiguration from "../ServerEntities/IConfiguration";
import ICustomer from "../ServerEntities/ICustomer";
import IQuote from "../ServerEntities/IQuote";
import IQuoteType from "../ServerEntities/IQuoteType";
import IUser from "../ServerEntities/IUser";
import { pageConfiguration } from "./getComponentConfiguration";


export const getCustomerById = (customerId: number, customers: ICustomer[]) => {
    if (customers) {
        const userObj = customers.filter(obj => {
            return obj.id === customerId
        });
        return userObj ? userObj[0].customerName : "";
    }
    return "";
}

export const getCustomerIdBy = (customer: string, customers: ICustomer[]) => {
    if (customers) {
        const userObj = customers.filter(obj => {
            return obj.customerName === customer
        });
        return userObj ? userObj[0].id : 0;
    }
};

export const getUserIdBy = (user: string, users: IUser[]) => {
    const userObj = users.filter(obj => {
        return obj.id === user
    });
    return userObj && userObj.length ? userObj[0].id : "";
};


export const getTypeId = (type: string, quoteTypes: IQuoteType[]) => {
    return quoteTypes.filter(obj => {
        return obj.description === type
    })[0].id;
};

export const getUserById = (id?: string, users?: IUser[]) => {
    if (id && users) {
        const userObj = users.filter(obj => {
            return obj.id === id
        });
        return userObj[0] ? userObj[0].fullName : id;
    }
    return id ? id : "";
};

export const getUserObjectById = (id?: string, users?: IUser[]) => {
    if (id && users) {
        const userObj = users.filter(obj => {
            return obj.id === id
        });
        return userObj[0];
    }
    return null;
};

export const canViewPricingDetail = (allPricing: boolean, configuration: IConfiguration | undefined) => {
    const pageConfig = pageConfiguration("Pricing Detail (limited to the logged in user pricing)", configuration);
    if (pageConfig && allPricing) {
        return false;
    }
    return true;
}

export const getTypeById = (typeId: number, quoteTypes: IQuoteType[]) => {
    return quoteTypes.filter(obj => {
        return obj.id === typeId
    })[0].type;
};
