import * as React from "react";
import ErrorText from "../../../SharedComponents/ErrorText";
import Header from "../../../SharedComponents/Header";
import LoadingIndicator from "../../../SharedComponents/LoadingIndicator";
import { SessionContext } from "../../SessionContext";
import { COLORS } from "../../../config";
import IQuoteType from "../../../ServerEntities/IQuoteType";
import { getUsers } from "../Components/UsersService";
import ICustomer from "../../../ServerEntities/ICustomer";
import { getCustomers } from "../Customers/CustomersService";
import IUser from "../../../ServerEntities/IUser";
import Banner from "../../../SharedComponents/Banner";
import Tools from "../../../SharedComponents/Tools";
import { LeftColumn, RightColumn } from "../Quotes/QuotesIndex";
import Pages from "../../../SharedComponents/Pages";
import IOrder from "../../../ServerEntities/IOrder";
import { getPendingdOrders, getReceivedOrders } from "./FinanceService";
import OrderTable from "./OrderTable";
import OrderEdit from "./OrderEdit";
import useEffectOnSome from "../../../CustomHooks/useEffectOnSome";
import { getQuoteTypes } from "../Quotes/QuotesService";

const OpportunitiesIndex = () => {
    const { state } = React.useContext(SessionContext);
    const [isEditing, setIsEditing] = React.useState(null as unknown as IOrder | null);
    const [isViewing, setIsViewing] = React.useState(null as unknown as IOrder | null);
    const [isHistory, setIsHistory] = React.useState(null as unknown as IOrder | null);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [refresh, setRefresh] = React.useState(0);
    const [quoteTypes, setQuoteTypes] = React.useState(null as unknown as IQuoteType[]);
    const [users, setUsers] = React.useState(null as unknown as IUser[]);
    const [customers, setCustomers] = React.useState(null as unknown as ICustomer[]);
    const [orders, setOrders] = React.useState(null as unknown as IOrder[]);
    const [viewReceivedOrders, setViewReceivedOrders] = React.useState(false);
    const [viewPendingOrders, setViewPendingOrders] = React.useState(false);

    useEffectOnSome(() => {
        if (!viewPendingOrders && !viewReceivedOrders) {
            setViewReceivedOrders(true);
        }
        setLoading(true);
        if (viewReceivedOrders) {
            getReceivedOrders(state.webToken, (orders: IOrder[]) => {
                setLoading(false);
                setError("");
                setOrders(orders);
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
                setOrders(null as unknown as IOrder[]);
            });
        } else {
            getPendingdOrders(state.webToken, (orders: IOrder[]) => {
                setLoading(false);
                setError("");
                setOrders(orders);
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
                setOrders(null as unknown as IOrder[]);
            });
        }
    }, [viewPendingOrders, viewReceivedOrders, refresh]);


    useEffectOnSome(() => {
        getQuoteTypes(state.webToken, (quoteTypes: IQuoteType[]) => {
            setLoading(false);
            setError("");
            setQuoteTypes(quoteTypes.filter(t => t.status));
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
            setQuoteTypes([])
        });
        getUsers(state.webToken, (users: IUser[]) => {
            setLoading(false);
            setError("");
            setUsers(users);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
            setUsers(null as unknown as IUser[]);
        });
        getCustomers(state.webToken, (customers: ICustomer[]) => {
            setLoading(false);
            setError("");
            setCustomers(customers);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
            setCustomers(null as unknown as ICustomer[]);
        });
    }, []);

    const openViewScreen = (order: IOrder) => {
        setIsViewing(order);
    };

    const openHistoryScreen = (order: IOrder) => {
        setIsHistory(order);
    };

    const onRefresh = () => {
        setRefresh(refresh + 1);
    };

    const closeScreen = () => {
        setRefresh(refresh + 1);
        setIsEditing(null);
        setIsViewing(null);
    };

    const getComponent = (isEditing: IOrder | null, isViewing: IOrder | null, isHistory: IOrder | null,) => {
        // if (isHistory) {
        //     return <OpportunityHistory users={users} opportunity={isHistory} />;
        // }
        if (isViewing) {
            return <OrderEdit users={users} types={quoteTypes} customers={customers} order={isViewing} onViewHistory={openHistoryScreen} onAddComplete={closeScreen} />;
        }


        return <OrderTable
            onRefresh={onRefresh}
            onDelete={onRefresh}
            onView={openViewScreen}
            users={users}
            types={quoteTypes}
            orders={orders} />
    };

    const onBack = () => {
        if (!isHistory) {
            setIsEditing(null);
        }
        setIsViewing(null);
        setIsHistory(null)
    }

    const getBannerTitle = () => {
        if (isHistory) {
            return "Change History - Orders";
        } else
            if (isEditing) {
                return "Edit Order";
            }
        if (viewPendingOrders) {
            return "Pending Orders";
        }
        if (viewReceivedOrders) {
            return "Received Orders";
        }
        return "Orders"
    }

    const onViewReceivedOrders = () => {
        setViewReceivedOrders(true);
        setViewPendingOrders(false);
        onBack();
        setRefresh(refresh + 1);
    }

    const onViewPendingOrders = () => {
        setViewReceivedOrders(false);
        setViewPendingOrders(true);
        onBack();
        setRefresh(refresh + 1);
    }

    const pages = [
        { page: "Received Orders", onClick: onViewReceivedOrders },
        { page: "Pending Orders", onClick: onViewPendingOrders },
    ]

    return <div style={{ position: "absolute", height: "100%", width: "100%", display: "flex", alignItems: "stretch", flexDirection: "column" }}>
        <Header backgroundColor={COLORS.GREY_SLATE} border={COLORS.FINANCE_ORANGE} onBack={onBack} previousPageUrl={isViewing || isEditing || isHistory ? "/Finance" : "/"} />
        <main style={{ flex: "1", display: "flex", alignItems: "stretch", overflow: "hidden" }}>
            <LeftColumn>
                <Tools bannerColor={COLORS.HEATHER_SLATE} />
                <Pages bannerColor={COLORS.HEATHER_SLATE} backgroundColor={COLORS.FINANCE_ORANGE} pages={pages} />
            </LeftColumn>
            <RightColumn>
                <Banner $backgroundColor={COLORS.HEATHER_SLATE}>{getBannerTitle()}</Banner>
                <LoadingIndicator type="Linear" show={loading} />
                {error && <ErrorText>{error}</ErrorText>}
                {getComponent(isEditing, isViewing, isHistory)}
            </RightColumn>
        </main>
    </div >
};

export default OpportunitiesIndex;
