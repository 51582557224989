import * as React from "react";
import { useState } from "react";
import styled, { keyframes } from "styled-components"

import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useKeyDown from "../../CustomHooks/useKeyDown";
import Modal from "../Modal";
import useOnClickOutsideSelect from "../../CustomHooks/useOnClickOutsideSelect";

const errorColor = "red";

const openSelectAnimation = keyframes`
    0% {
        opacity: 0;
        transform: scale(0.7);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
`;

interface IContainerStyleProps {
    margin?: string,
    width?: string,
    height?: string
};

const SelectContainer = styled.div<IContainerStyleProps>`
    display: flex;
    outline: none;
    cursor: pointer;
    position: relative;
    border: 0 none;
    border-radius: 3px;
    padding: 0px 10px 0px 10px;
    margin: ${props => props.margin ? props.margin : ""};
    background-color: white;
    transition: border-color .15s cubic-bezier(.4, 0, .2, 1);
    &.focused {
        border-color: defaultColor;
    }
    &.error {
        border-color: ${errorColor};
    }
    &.open {
        border-radius: 3px 3px 0px 0px;
    }
    width: ${props => props.width};
    height: ${props => props.height};
    border: 1px solid #e5e5e5;
`;

interface IProps {
    value: any,
    onChange: (value: any) => void,
    margin?: string,
    width?: string,
    placeholder?: string,
    children: React.ReactNode[],
    childValues: string[],
    selectRef?: { current: HTMLDivElement },
    required?: boolean,
    fontSize?: string,
    height?: string
};

interface IListStyleProps {
    leftOffset: number,
    topOffset: number,
    width: number,
    fontSize?: string;
};

interface IFontProps {
    fontSize?: string;
};

const OptionsContainer = styled.ul<IListStyleProps>`
    position: absolute;
    list-style: none;
    padding: 0;
    margin: 0;
    border-radius: 0px 0px 3px 3px;
    border 0px solid transparent;
    z-index: 2;
    left: ${props => props.leftOffset}px;
    top: ${props => props.topOffset}px;
    background-color: white;
    width: ${props => props.width - 1}px;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    animation:${openSelectAnimation} cubic-bezier(0, 0, .2, 1) 200ms forwards;
    will-change: transform, opacity;
    transform: scale(.8);
    z-index: -100;
    opacity: 0;
    &.open{
        transform: scale(1);
        opacity: 1;
        z-index: 2;
    }
`;

const ValueContainer = styled.div<IFontProps>`
    line-height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    font-size: ${props => props.fontSize ? props.fontSize : "0.9em"};
    width: 100%;
`;

const ArrowContainer = styled.div`
    margin-left: 4px;
    transition: transform 200ms;
    &.open{
        transform: rotate(180deg);
    }
    flex: 0 0 auto;
`;

const OptionWrapper = styled.span<IFontProps>`
    &:hover {
        background-color: rgba(24, 127, 186, 0.05);
    }
    &.selected > div {
        background-color: rgba(24, 127, 186, 0.05);
    }
   font-size: ${props => props.fontSize ? props.fontSize : "0.9em"};
`;

const AssistiveTextRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    left: 0;
`;

interface IAssistiveTextProps {
    fontSize?: string,
    error?: boolean
};

const AssistiveText = styled.p<IAssistiveTextProps>`
    font-size: ${props => props.fontSize ? props.fontSize : "0.8em"};
    color: ${props => props.error ? errorColor : "#62676B"};
    margin: 2px 0;
`;

const Select = (props: IProps) => {
    const { value, childValues, onChange, children, margin, placeholder, selectRef, required, fontSize } = props;
    const [leftOffset, setLeftOffset] = React.useState(0);
    const [topOffset, setTopOffset] = React.useState(0);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [focus, setFocus] = useState(false);
    const [keysPressed, setKeysPressed] = useState("");
    const [timeoutId, setTimeoutId] = useState(0);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    let ref = React.useRef(null as unknown as HTMLDivElement);
    if (selectRef) {
        ref = selectRef;
    }
    const optionsContainerRef = React.useRef(null as unknown as HTMLUListElement);

    const closeBox = () => {
        setOpen(false);
        setFocus(false);
        setHighlightedIndex(-1);
        setKeysPressed("");
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (!focus) {
            return;
        }
        const key = event.key || event.keyCode;
        switch (key) {
            case "ArrowDown":
            case "Down":
                setOpen(true);
                if (highlightedIndex < childValues.length - 1) {
                    setHighlightedIndex(highlightedIndex + 1);
                }
                event.stopPropagation();
                event.preventDefault();
                break;
            case "ArrowUp":
            case "Up":
                if (highlightedIndex === 0) {
                    setOpen(false);
                }
                if (highlightedIndex >= 0) {
                    setHighlightedIndex(highlightedIndex - 1);
                }
                event.stopPropagation();
                event.preventDefault();
                break;
            case "Tab":
                setFocus(false);
                setOpen(false);
                if (open && highlightedIndex >= 0) {
                    onItemSelect(childValues[highlightedIndex])();
                }
                event.stopPropagation();
                event.preventDefault();
                break;
            case "Enter":
                if (open && highlightedIndex >= 0) {
                    onItemSelect(childValues[highlightedIndex])();
                }
                event.stopPropagation();
                event.preventDefault();
                break;
            default:
                if (key.toString().length === 1) {
                    setKeysPressed(keysPressed + key);
                    window.clearTimeout(timeoutId);
                    setTimeoutId(window.setTimeout(() => { setKeysPressed(""); }, 1000));
                    const index = findIndexForInput(keysPressed + key);
                    if (index >= 0) {
                        onChange(childValues[index]);
                    }
                    event.stopPropagation();
                    event.preventDefault();
                }
        }
    };

    useOnClickOutsideSelect(ref, optionsContainerRef, closeBox);
    useKeyDown(handleKeyDown);

    React.useEffect(() => {
        setLeftOffset(calculateLeftOffset());
        setTopOffset(calculateTopOffset());
    }, [open]);

    const calculateLeftOffset = () => {
        if (ref.current) {
            const windowWidth = window.innerWidth;
            const elementWidth = ref.current.getBoundingClientRect().width;
            const elementLeft = ref.current.getBoundingClientRect().left;
            if (elementLeft + elementWidth > windowWidth) {
                return windowWidth - elementWidth - 16;
            } else {
                return elementLeft;
            }
        }
        return 0;
    };

    const calculateTopOffset = () => {
        if (ref.current && optionsContainerRef.current) {
            const windowHeight = window.innerHeight;
            const menuHeight = optionsContainerRef.current.getBoundingClientRect().height;
            const elementBottom = ref.current.getBoundingClientRect().bottom;
            if (elementBottom + menuHeight > windowHeight) {
                return elementBottom - menuHeight - 16;
            } else {
                return elementBottom;
            }
        }
        return 0;
    };

    const cancelContainerChangeEvent = (e: React.SyntheticEvent<any>) => {
        e.stopPropagation();
    };

    const toggleBoxOpen = () => {
        if (open) {
            setFocus(false);
            setOpen(false);
            setHighlightedIndex(-1);
            setKeysPressed("");
        } else {
            setFocus(true);
            setOpen(true);
        }
    };

    const registerFocus = () => {
        setFocus(true);
    };

    const onItemSelect = (selectValue: any) => () => {
        setError(false);
        setOpen(false);
        setHighlightedIndex(-1);
        setKeysPressed("");
        onChange(selectValue);
    };

    const findIndexForInput = (input: string): number => {
        if (input.length === 0) {
            return -1;
        }
        const index = childValues.findIndex((item) => !!item && item.toLowerCase().startsWith(input.toLowerCase()));
        if (index > -1) {
            return index;
        }
        return findIndexForInput(input.slice(0, -1));
    };

    const calculateWidth = () => {
        if (ref.current && optionsContainerRef.current) {
            const boxWidth = ref.current.getBoundingClientRect().width;
            const optionsWidth = optionsContainerRef.current.clientWidth;
            if (boxWidth > optionsWidth) {
                return boxWidth;
            }
            return optionsWidth;
        }
        return 0;
    };

    return <div><SelectContainer
        ref={ref}
        className={`SelectBox ${focus && "focused"} ${error && "error"} ${open && "open"}`}
        onChange={cancelContainerChangeEvent}
        onClick={toggleBoxOpen}
        onFocus={registerFocus}
        tabIndex={0}
        margin={margin}
        width={props.width}
        height={props.height}
    >
        <ValueContainer fontSize={props.fontSize} className="ValueContainer">
            <span style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellispsis" }}>{value || placeholder || <span />}</span>
            <ArrowContainer className={open ? "open" : ""}><FontAwesomeIcon icon={faCaretDown} /></ArrowContainer>
        </ValueContainer>

        <Modal style={{ backgroundColor: "transparent" }} show={open} >
            <OptionsContainer
                ref={optionsContainerRef}
                className={`OptionsContainer ${open && "open"} `}
                width={calculateWidth()}
                leftOffset={leftOffset}
                topOffset={topOffset}
            >
                {
                    children && children.length > 0 && React.Children.toArray(children).map(
                        (child: any, index: number) => {
                            return <OptionWrapper
                                key={child.key ? child.key : `option${index} `}
                                className={highlightedIndex === index ? "select-item selected" : "select-item"}
                                onClick={onItemSelect(child.props.value)}>
                                {child}
                            </OptionWrapper>;
                        })
                }
            </OptionsContainer>
        </Modal>

    </SelectContainer>
        <AssistiveTextRow className="AssistiveTextRow">
            {(required) ? <AssistiveText fontSize={fontSize} className="AssistiveText" error={error}>*Required</AssistiveText> : null}
        </AssistiveTextRow>
    </div>
};

export default Select;
