import * as React from 'react';
import styled from 'styled-components';
import { debounce } from 'throttle-debounce';

import Button from '../../../SharedComponents/Button';
import Card from '../../../SharedComponents/Card';
import ErrorBox from '../../../SharedComponents/ErrorBox';
import IconButton from '../../../SharedComponents/IconButton';
import Label from '../../../SharedComponents/Label';
import LoadingIndicator from '../../../SharedComponents/LoadingIndicator';
import TextInput from '../../../SharedComponents/TextInput';

import createErrorMessage from '../../../UsefulFunctions/createErrorMessage';
import * as roleManagementService from "./roleManagementService";
import * as userManagementService from "./userManagementService";

import IRole from '../../../ServerEntities/IRole';
import IUser from '../../../ServerEntities/IUser';

import AddUserToRoleDialog from './AddUserDialog';
import useEffectOnSome from '../../../CustomHooks/useEffectOnSome';


const TitleBar = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px;
`;

const Title = styled.span`
    font-size: 18px;
    letter-spacing: 0.25px;
    line-height: 24px;
    font-weight: 600;
`;

const UserSearchBoxContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const UserHeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 12px 24px;
    justify-content: space-between;
`;

const Grid = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const NoUsersText = styled.div`
    display: flex;
    flex-direction: row;
    color: #555;
    justify-content: center;
    align-items: center;
    padding: 24px;
`;

interface IProps {
    selectedRole: IRole,
    webToken: string,
    currentUser: string,
    currentRole: string,
    setErrorMessage: (errorMessage: string) => void
};

const RoleUserDetails = (props: IProps) => {
    const { selectedRole, webToken, setErrorMessage, currentUser, currentRole } = props
    const [userFilterText, setUserFilterText] = React.useState("");
    const [showAddUserDialog, setShowAddUserDialog] = React.useState(false);
    const [users, setUsers] = React.useState([] as IUser[]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");

    const searchUsers = (searchText: string) => {
        if (searchText) {
            roleManagementService.searchForUsers(selectedRole.id, searchText, webToken, (usersFromServer: IUser[]) => {
                setLoading(false);
                setUsers(usersFromServer);
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
            });
        } else {
            roleManagementService.getTopHundredUsers(selectedRole.id, webToken, (usersFromServer: IUser[]) => {
                setLoading(false);
                setUsers(usersFromServer);
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
            });
        }
    };

    const delayedSearch = React.useRef(debounce(250, searchUsers)).current;

    const changeUserFilterText = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        setLoading(true);
        delayedSearch(value);
        setUserFilterText(value);
    };

    const openAddUserDialog = () => {
        setShowAddUserDialog(true);
    };

    const closeAddUserDialog = () => {
        setShowAddUserDialog(false);
    };

    const closeAddUserDialogAndRefresh = () => {
        setShowAddUserDialog(false);
        refreshUsers();
    };

    const refreshUsers = () => {
        searchUsers(userFilterText);
    };

    const removeUserFromRole = (user: IUser) => () => {
        setErrorMessage("");
        userManagementService.removeRoleFromUser(
            selectedRole.id,
            user.id,
            webToken,
            refreshUsers,
            (serverError: string) => {
                setErrorMessage(createErrorMessage("removing a role from a user", serverError));
            });
    };

    useEffectOnSome(() => {
        searchUsers("");
    }, [searchUsers]);

    return <div style={{ overflowY: "auto" }}>
        <AddUserToRoleDialog
            show={showAddUserDialog}
            onClose={closeAddUserDialog}
            onConfirmUserAdd={closeAddUserDialogAndRefresh}
            role={selectedRole} />
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorBox>{error}</ErrorBox>}
        <TitleBar>
            <Title>Users</Title>
        </TitleBar>
        <UserHeaderContainer>
            <UserSearchBoxContainer>
                <TextInput margin="0 24px 0 0" value={userFilterText} onChange={changeUserFilterText} placeholder="Search" />
            </UserSearchBoxContainer>
            <Button onClick={openAddUserDialog}>Add user(s)</Button>
        </UserHeaderContainer>

        <Grid style={{ padding: "0px 12px" }}>
            {users.map((user) => <Card key={user.id} style={{ width: "320px", margin: "12px", padding: "2px 2px 0px 0px" }}>
                <Label style={{ padding: "6px 8px 6px 16px", wordWrap: "normal" }}>
                    <h2>{user.id}</h2>
                    <h3>{user.fullName}</h3>
                </Label>
                {(user.id !== currentUser || currentRole !== selectedRole.id) &&
                    <IconButton onClick={removeUserFromRole(user)}>×</IconButton>}
            </Card>)}
            {users.length === 0 && <NoUsersText>There are no {userFilterText ? "matching " : ""}users currently associated with this role.</NoUsersText>}
        </Grid>
        {error && <ErrorBox>{error}</ErrorBox>}
    </div>;
};

export default RoleUserDetails;
