import * as React from 'react';
import styled from "styled-components";
import { COLORS } from '../config';
import LoadingIndicator from './LoadingIndicator';

const defaultButtonColor = COLORS.PRIMARY;
const disabledButtonColor = "rgba(0,0,0,0.1)";

interface IButtonProps {
    $plain?: boolean,
    disabled?: boolean,
    $color?: string,
    $loading?: boolean,
    margin?: string,
    $height?: number
};

const ButtonContainer = styled.button<IButtonProps>`
    overflow: hidden;
    padding: 0;
    background-color: ${props => {
        if (props.$plain) {
            return "transparent";
        }
        if (props.disabled) {
            return disabledButtonColor;
        }
        if (props.$color) {
            return props.$color;
        }
        return defaultButtonColor;
    }
    };

    &:hover, &:focus {
        opacity: 0.7;
        background-color: ${props => {
        if (props.$plain) {
            return "rgba(0,0,0,0.1)";
        }
        return "auto";
    }};
    }
    cursor: pointer;
    border-radius: 4px;
    border: ${props => {
        if (props.disabled) {
            return "1px solid " + disabledButtonColor;
        }
        if (props.$color) {
            return "1px solid " + props.$color;
        }
        return "0";
    }};
    color: ${props => {
        let calculatedTextColor = "white";
        if (props.$plain && props.disabled) {
            return disabledButtonColor;
        }
        if (props.$plain) {
            calculatedTextColor = '#187fba';
        }
        if (props.$plain && props.$color) {
            calculatedTextColor = props.$color;
        }
        return calculatedTextColor;
    }};
    line-height: ${props => {
        if (props.$height) {
            return props.$height + "px";
        }
        return "36px";
    }};
    height:  ${props => {
        if (props.$height) {
            return props.$height + "px";
        }
        return "36px";
    }};
    flex-direction: row;
    pointer-events: ${props => props.disabled || props.$loading ? "none" : "all"};
    flex: 0 0 auto;
    margin: ${props => props.margin ? props.margin : ""};
    transition: opacity .2s cubic-bezier(.35,0,.25,1),background-color .2s cubic-bezier(.35,0,.25,1);
`;

const ButtonInner = styled.div<IButtonProps>`
    padding:${props => props.$loading ? "3px 16px" : "0px 16px"};
    user-select: none;
    text-align: center;
`;

interface IProps {
    onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    children?: React.ReactNode,
    color?: string,
    plain?: boolean,
    disabled?: boolean,
    margin?: string,
    submit?: boolean,
    loading?: boolean,
    refObject?: React.MutableRefObject<any>,
    style?: React.CSSProperties
    height?: number
};

const Button = (props: IProps) => {
    const { onClick, children, color, plain, loading, refObject, style, height } = props;

    const localOnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (onClick) {
            onClick(e);
        }
    };

    return <ButtonContainer
        onClick={localOnClick}
        $plain={plain}
        $color={color}
        disabled={props.disabled}
        $loading={loading}
        margin={props.margin}
        className="Button"
        style={style}
        $height={height}
        ref={refObject}>
        <ButtonInner $loading={loading}>
            {!loading && children}
            {loading && <LoadingIndicator type="Radial" color="white" show={true} style={{ height: "20px", marginTop: "8px" }} />}
        </ButtonInner>
    </ButtonContainer>;
};

export default Button;
