import * as React from "react";
import { COLORS } from "../../../config";
import ICustomer from "../../../ServerEntities/ICustomer";
import ErrorText from "../../../SharedComponents/ErrorText";
import Header from "../../../SharedComponents/Header";
import LoadingIndicator from "../../../SharedComponents/LoadingIndicator";
import { SessionContext } from "../../SessionContext";
import CustomerAdd from "./CustomerAdd";
import CustomerEdit from "./CustomerEdit";
import { getCustomers } from "./CustomersService";
import CustomersTable from "./CustomersTable";
import { useNavigate } from "react-router-dom";
import Banner from "../../../SharedComponents/Banner";
import Pages from "../../../SharedComponents/Pages";
import Tools from "../../../SharedComponents/Tools";
import { LeftColumn, RightColumn } from "../Quotes/QuotesIndex";
import { getUsers } from "../Components/UsersService";
import IUser from "../../../ServerEntities/IUser";
import CustomerHistory from "./CustomerHistory";
import { RolesContext } from "../../../Roles/RolesContext";
import IPage from "../../../ServerEntities/IPage";
import { pageConfiguration } from "../../../UsefulFunctions/getComponentConfiguration";
import useEffectOnSome from "../../../CustomHooks/useEffectOnSome";

const CustomersIndex = () => {
    const { state } = React.useContext(SessionContext);
    const [isAdding, setIsAdding] = React.useState(false);
    const [isViewing, setIsViewing] = React.useState(null as unknown as ICustomer | null);
    const [isEditing, setIsEditing] = React.useState(null as unknown as ICustomer | null);
    const [isHistory, setIsHistory] = React.useState(null as unknown as ICustomer | null);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [customers, setCustomers] = React.useState(null as unknown as ICustomer[]);
    const [users, setUsers] = React.useState(null as unknown as IUser[]);
    const [refresh, setRefresh] = React.useState(0);
    const [pageNumber, setPageNumber] = React.useState(0);
    const navigate = useNavigate();

    const { configuration } = React.useContext(RolesContext).state;
    const [pages, setPages] = React.useState(null as unknown as IPage[]);

    useEffectOnSome(() => {
        const onViewAllOps = () => {
            navigate(`/Opportunities`);
        };

        const onViewUserOps = () => {
            navigate(`/MyOpportunities`);
        };
    
        const onViewArchivedOps = () => {
            navigate(`/ArchivedOpportunities`);
        };

        const onViewCustomers = () => {
            setIsEditing(null as unknown as ICustomer)
            navigate(`/Customers`);
        };
    
        const onViewContacts = () => {
            navigate(`/Contacts`);
        };
    
        const pageList = [
            { page: "All Opportunities", onClick: onViewAllOps },
            { page: "My Opportunities", onClick: onViewUserOps },
            { page: "Archived Opportunities", onClick: onViewArchivedOps },
            { page: "Customers", onClick: onViewCustomers },
            { page: "Contacts", onClick: onViewContacts },
        ];

        let newPageList = [];
        for (let i = 0; i < pageList.length; i++) {
            let config = pageConfiguration(pageList[i].page, configuration);
            if (config !== undefined) {
                newPageList.push(pageList[i])
            }
        }
        setPages(newPageList);
    }, [configuration, navigate]);

    useEffectOnSome(() => {
        setLoading(true);
        getCustomers(state.webToken, (customers: ICustomer[]) => {
            setLoading(false);
            setError("");
            setCustomers(customers);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
            setCustomers(null as unknown as ICustomer[]);
        });
        getUsers(state.webToken, (users: IUser[]) => {
            setLoading(false);
            setError("");
            setUsers(users);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
            setUsers(null as unknown as IUser[]);
        });
    }, [refresh]);

    const openAddScreen = () => {
        setIsAdding(true);
    };

    const onChangePageNumber = (pageNumber: number) => {
        setPageNumber(pageNumber);
        onRefresh();
    };

    const openEditScreen = (customer: ICustomer) => {
        setIsEditing(customer);
    };

    const openViewScreen = (customer: ICustomer) => {
        setIsViewing(customer);
    };

    const closeAddScreen = () => {
        setRefresh(refresh + 1);
        setIsAdding(false);
        setIsEditing(null);
        setIsViewing(null);
    };

    const openHistoryScreen = (customer: ICustomer) => {
        setIsHistory(customer);
    };

    const onRefresh = () => {
        setRefresh(refresh + 1);
    };

    const getComponent = (isAdding: boolean, isEditing: ICustomer | null, isViewing: ICustomer | null, isHistory: ICustomer | null) => {
        if (isHistory) {
            return <CustomerHistory users={users} customer={isHistory} />;
        }
        if (isAdding) {
            return <CustomerAdd users={users} onAddComplete={closeAddScreen} />;
        }
        else if (isEditing) {
            return <CustomerEdit users={users} onAddComplete={closeAddScreen} customer={isEditing} onViewHistory={openHistoryScreen} />;
        }

        return <CustomersTable onRefresh={onRefresh} onAdd={openAddScreen} onEdit={openEditScreen} onView={openViewScreen} onDelete={onRefresh} customers={customers} />;
    };


    if (customers && customers.length === 0 && pageNumber > 0) {
        onChangePageNumber(pageNumber - 1);
    }

    const getBannerTitle = () => {
        if (isAdding) {
            return "Add Customer";
        } else
            if (isEditing) {
                return "Customer Details";
            }
        return "Customers"
    }

    const onBack = () => {
        setIsAdding(false);
        setIsEditing(null);
        setIsViewing(null);
    }

    return <div style={{ position: "absolute", height: "100%", width: "100%", display: "flex", alignItems: "stretch", flexDirection: "column" }}>
        <Header backgroundColor={COLORS.GREY_SLATE} border={COLORS.OPPORTUNITIES_YELLOW} onBack={onBack} previousPageUrl={isAdding || isEditing || isViewing ? "/customers" : "/"} />
        <main style={{ flex: "1", display: "flex", alignItems: "stretch", overflow: "hidden" }}>
            <LeftColumn>
                <Tools bannerColor={COLORS.HEATHER_SLATE} />
                <Pages bannerColor={COLORS.HEATHER_SLATE} backgroundColor={COLORS.OPPORTUNITIES_YELLOW} pages={pages} />
            </LeftColumn>
            <RightColumn>
                <Banner $backgroundColor={COLORS.HEATHER_SLATE}>{getBannerTitle()}</Banner>
                <LoadingIndicator type="Linear" show={loading} />
                {error && <ErrorText>{error}</ErrorText>}
                {getComponent(isAdding, isEditing, isViewing, isHistory)}
            </RightColumn>
        </main>
    </div >
};

export default CustomersIndex;