import * as React from "react";
import IConfiguration from "../ServerEntities/IConfiguration";
import Button from "../SharedComponents/Button";
import Dialog from "../SharedComponents/Dialog/Dialog";
import DialogActions from "../SharedComponents/Dialog/DialogActions";
import DialogContent from "../SharedComponents/Dialog/DialogContent";
import DialogContentText from "../SharedComponents/Dialog/DialogContentText";
import DialogTitle from "../SharedComponents/Dialog/DialogTitle";
import ErrorText from "../SharedComponents/ErrorText";
import LoadingIndicator from "../SharedComponents/LoadingIndicator";
import Select from "../SharedComponents/Select/Select";
import SelectItem from "../SharedComponents/Select/SelectItem";
import { getInternalConfigurationForRole } from "../Views/Login/loginService";
import { SessionContext } from "../Views/SessionContext";
import { RolesContext } from "./RolesContext";
import { changePreferredRole, getRoles } from "./RolesService";
import useEffectOnSome from "../CustomHooks/useEffectOnSome";

interface IRoleChangeDialogProps {
    open: boolean;
    onClose: () => void;
    applicationName: string;
    refresh?: number;
    dialogRef: { current: HTMLDivElement };
    resetUrl?: string;
};

const RoleChangeDialog = (props: IRoleChangeDialogProps) => {
    const { applicationName, open, onClose, dialogRef, resetUrl } = props;
    const [newRole, setNewRole] = React.useState("");
    const [roleChangeLoading, setRoleChangeLoading] = React.useState(false);
    const [roleChangeError, setRoleChangeError] = React.useState("");
    const sessionContext = React.useContext(SessionContext);
    const roleContext = React.useContext(RolesContext);
    const { webToken } = sessionContext.state;
    const [roles, setRoles] = React.useState([] as unknown as string[]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");

    useEffectOnSome(() => {
        setLoading(true);
        getRoles(applicationName, webToken, (serverRoles: string[]) => {
            setRoles(serverRoles);
            setLoading(false);
            setError("");
        }, (errorMessage: string) => {
            setRoles([]);
            setLoading(false);
            setError(errorMessage);
        });
    }, [applicationName]);

    const selectedRole = roles.find((item: string) => item === newRole);

    const updateConfiguration = (configuration: IConfiguration) => {
        roleContext.dispatch({ type: "setConfiguration", payload: configuration });
    };

    const handleConfigurationError = (serverError: string) => {
        setError(serverError);
    };

    const onRoleChangeSuccess = (jwt: string) => {
        if (newRole !== null) {
            sessionContext.dispatch({ type: "setWebToken", payload: jwt });
            if (applicationName === "InternalSystem") {
                getInternalConfigurationForRole(newRole, jwt, updateConfiguration, handleConfigurationError);
            }
            if (resetUrl) {
                window.location.href = resetUrl;
            }
            roleContext.dispatch({ type: "setRole", payload: newRole });
        }
        setRoleChangeLoading(false);
        closeDialog();
    };

    const onRoleChangeFailure = (serverError: string) => {
        setRoleChangeError(serverError);
        setRoleChangeLoading(false);
    };

    const confirmChangeRole = () => {
        if (selectedRole) {
            setRoleChangeLoading(true);
            changePreferredRole(selectedRole, applicationName, webToken, onRoleChangeSuccess, onRoleChangeFailure);
        }
    };

    const closeDialog = () => {
        setNewRole("");
        setRoleChangeLoading(false);
        onClose();
    };

    return <Dialog open={open} onClose={onClose}>
        <DialogTitle className="DialogTitle">Change current role</DialogTitle>
        <LoadingIndicator show={loading} type="Linear" />
        <DialogContent className="DialogContent" style={{ margin: "0px 0px 24px 0px" }}>
            <DialogContentText className="DialogContentText">Please select the new role you wish to use for SLATE</DialogContentText>
            <Select
                selectRef={dialogRef}
                childValues={roles}
                placeholder="Choose role"
                margin="4px 24px 0px 0px"
                value={selectedRole || ""}
                onChange={setNewRole}
                width="200px"
            >
                {roles.map((role: string) => <SelectItem key={`select-item-${role}`} value={role}>
                    {role}
                </SelectItem>)}
            </Select>
            {error && <ErrorText>{error}</ErrorText>}
            <ErrorText>{roleChangeError}</ErrorText>
        </DialogContent>
        <DialogActions className="DialogActions">
            <Button margin="0 4px" onClick={closeDialog} plain={true}>Cancel</Button>
            <Button margin="0 4px" disabled={newRole === null} loading={roleChangeLoading} onClick={confirmChangeRole} >Confirm</Button>
        </DialogActions>
    </Dialog>;
};

export default RoleChangeDialog;
