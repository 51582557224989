const handleKeyboardSelect = (onClick: () => void, spaceIsClick: boolean) => (event: any) => {
    const key = event.key || event.keyCode;
    if (key === "Enter" || (spaceIsClick && key === " ")) {
        event.stopPropagation();
        event.preventDefault();
        onClick();
    }
};

export default handleKeyboardSelect;
