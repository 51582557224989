import styled from "styled-components";
import { COLORS } from "../../config";

const DialogTitle = styled.h1`
    flex: 0 0 auto;
    margin: 0;
    padding: 5px 20px 5px;
    color: #fff;
    background-color: ${COLORS.GREY_SLATE};
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.0075em;
    flex: 0 0 auto;
`;

export default DialogTitle;
