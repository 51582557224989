import * as React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import { SessionContext } from "../../SessionContext";
import Button from "../../../SharedComponents/Button";
import LoadingIndicator from "../../../SharedComponents/LoadingIndicator";
import TextInput, { updateText } from "../../../SharedComponents/TextInput";
import ICustomer from "../../../ServerEntities/ICustomer";
import { getCustomers, saveCustomer } from "./CustomersService";
import ErrorText from "../../../SharedComponents/ErrorText";
import { Main, Title } from "./CustomersTable";
import DynamicSelect from "../../../SharedComponents/Select/DynamicSelect";
import SelectItem from "../../../SharedComponents/Select/SelectItem";
import ICustomerReplaced from "../../../ServerEntities/ICustomerReplaced";
import { getCustomerBy, getCustomerIdBy } from "../../../UsefulFunctions/customerUtils";
import { COLORS, FONTSIZE, INTEGRATION_ENGINES } from "../../../config";
import { SeparatorRow } from "../Opportunities/OpportunityAdd";
import { ButtonContainer, TitleAndButton } from "../WorkPackages/WorkPackageEdit";
import { ContactCellLabel, ContactRow, CustomerHeader, CustomerInformation, IntegrationEngineCell, IntegrationEngineRow, LinkedCustomerContainer, LinkedCustomerRow, MainNoteContainer, NoteContainer, NoteRow, NoteText, OutsideContainer, RestartContacts, UserAndDateContainer, Value } from "./CustomerEdit";
import Select from "../../../SharedComponents/Select/Select";
import ReactQuill from "react-quill";
import ICustomerNote from "../../../ServerEntities/ICustomerNote";
import dateConverter from "../../../UsefulFunctions/dateConverter";
import IUser from "../../../ServerEntities/IUser";
import { getUserById } from "../../../UsefulFunctions/quoteUtils";
import { OpportunitiesSmallLogo } from "../../MainMenu";
import IIntegrationEngine from "../../../ServerEntities/IIntegrationEngine";
import useEffectOnSome from "../../../CustomHooks/useEffectOnSome";

const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin: 12px 0px 30px;
`;

const Label = styled.label`
    color: #333333;
    line-height: 32px;
    min-width: 140px;
`;

const renderHtml = (html: string) => {
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
}

interface IProps {
    users: IUser[],
    onAddComplete: () => void
};

const CustomerAdd = (props: IProps) => {
    const { state } = React.useContext(SessionContext);
    const users = props.users;
    const [customerName, setCustomerName] = React.useState("");
    const [shortName, setShortName] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [isEditing, setIsEditing] = React.useState(false);
    const [replaces, setReplaces] = React.useState([] as unknown as ICustomerReplaced[]);
    const [linkedCustomers] = React.useState([] as unknown as ICustomer[]);
    const [customers, setCustomers] = React.useState(null as unknown as ICustomer[])
    const [existingCustomers, setExistingCustomers] = React.useState([] as string[]);
    const [existingLinkedCustomers, setExistingLinkedCustomers] = React.useState([] as string[]);
    const [integrationEngines, setIntegrationEngines] = React.useState([] as unknown as IIntegrationEngine[]);
    const [addressForQuotes, setAddressForQuotes] = React.useState("");
    const [salesUser, setSalesUser] = React.useState("");
    const [archUser, setArchUser] = React.useState("");
    const [managerUser, setManagerUser] = React.useState("");
    const [devUser, setDevUser] = React.useState("");
    const [notes, setNotes] = React.useState([] as unknown as ICustomerNote[]);
    const [noteDate, setNoteDate] = React.useState(null as unknown as Date);
    const [noteNote, setNoteNote] = React.useState("");

    const changeDate = (setter: (date: Date) => void) => (date: Date, event: React.SyntheticEvent) => {
        setter(date);
    };

    const save = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        existingCustomers.forEach((customer: string) => { replaces.push({ id: getCustomerIdBy(customer, customers), name: customer }) });
        existingLinkedCustomers.forEach((customer: string) => {
            const customerObject = getCustomerBy(customer, customers);
            if (customerObject) {
                linkedCustomers.push(customerObject)
            }
        })
        const customer: ICustomer = {
            customerName: customerName,
            shortName: shortName,
            clientId: "",
            id: 0,
            status: true,
            linkedCustomers: linkedCustomers,
            replaces: replaces,
            restartContacts: { sales: salesUser, architect: archUser, projectManager: managerUser, leadDeveloper: devUser },
            integrationEngines: integrationEngines,
            addressForQuotes: addressForQuotes,
            customerNotes: notes
        };
        setLoading(true);
        saveCustomer(state.webToken, customer, () => {
            setLoading(false);
            setError("");
            setIsEditing(false);
            props.onAddComplete();
        }, (errorMessage: string) => {
            setLoading(false);
            setIsEditing(false);
            setError(errorMessage);
        });
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    const removeLinkedCustomer = (id: number) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (replaces) {
            let newReplaces = [...replaces];
            newReplaces = newReplaces.filter(obj => obj.id !== id);
            setReplaces(newReplaces);
        }
    };

    useEffectOnSome(() => {
        setLoading(true);
        getCustomers(state.webToken, (customers: ICustomer[]) => {
            setLoading(false);
            setError("");
            setCustomers(customers);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
            setCustomers(null as unknown as ICustomer[]);
        });
    }, [existingCustomers]);

    const onChangeCustomer = (index: number) => (customer: string) => {
        const existingCustomersCopy = [...existingCustomers];
        existingCustomersCopy[index] = customer;
        setExistingCustomers(existingCustomersCopy);
    };

    const renderReplaceCustomerItem = (existingCustomer: string, index: number) => {
        return <DynamicSelect required={false} placeholder="Select a customer" onChange={onChangeCustomer(index)} childValues={customers && customers.map(option => option.customerName)} value={existingCustomers[index]}>
            {customers && customers.filter((obj: { status: boolean; }) => { return obj.status === true }).map((option, index) => <SelectItem height="20px" key={`add-quote-customer-item-${index}`} value={option.customerName}>{option.customerName}</SelectItem>)
            }
        </DynamicSelect>;
    };

    const addCustomerToReplace = () => {
        const existingCustomersCopy = [...existingCustomers];
        existingCustomersCopy.push("");
        setExistingCustomers(existingCustomersCopy);
    };

    const renderReplaces = (customer: ICustomerReplaced) => {
        return <LinkedCustomerContainer>
            <div>Replaces {customer.id} - {customer.name}</div>
            <Button color={COLORS.FOURTH} onClick={removeLinkedCustomer(customer.id)}>Remove</Button>
        </LinkedCustomerContainer>;
    };


    const onChangeSalesUser = (user: string) => {
        setSalesUser(user);
    }

    const onChangeProjectManagerUser = (user: string) => {
        setManagerUser(user);
    }

    const onChangeArchUser = (user: string) => {
        setArchUser(user);
    }

    const onChangeDevUser = (user: string) => {
        setDevUser(user);
    }


    const saveNote = (index: number) => () => {
        const date = noteDate ? noteDate.getTime() : 0;
        notes[index] = { user: getUserById(state.loggedInUser, users), note: noteNote, date: date, dirty: false };
        let notesCopy = [...notes];
        setNotes(notesCopy);
    }

    const removeNote = (index: number) => () => {
        notes.splice(index, 1);
        let notesCopy = [...notes];
        setNotes(notesCopy);
    }


    const renderNotes = (note: ICustomerNote, index: number) => {
        return <NoteRow>
            {!note.dirty && <MainNoteContainer>
                <UserAndDateContainer>
                    <NoteText>{note.user}</NoteText>
                    <NoteText>{dateConverter(note.date, false, "")}</NoteText>
                    {isEditing && <Button plain={true} onClick={removeNote(index)}>Remove</Button>}
                </UserAndDateContainer>
                <NoteContainer>
                    <NoteText style={{ fontWeight: "normal" }}>{renderHtml(note.note)}</NoteText>
                </NoteContainer>
            </MainNoteContainer>}
            {note.dirty && <MainNoteContainer>
                <UserAndDateContainer>
                    <NoteText>{note.user}</NoteText>
                    <NoteText><DatePicker dateFormat="dd-MMM-yyyy" placeholderText="Select a date" selected={noteDate} onChange={changeDate(setNoteDate)} /></NoteText>
                </UserAndDateContainer>
                <NoteContainer>
                    <ReactQuill style={{ width: "100%" }} value={noteNote} onChange={setNoteNote} />
                    <Button plain={true} onClick={saveNote(index)}>Save</Button>
                </NoteContainer>
            </MainNoteContainer>}
        </NoteRow>
    }

    const AddNote = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (e) {
            e.preventDefault();
        }

        const newNote: ICustomerNote = {
            user: "",
            date: 0,
            note: "",
            dirty: true
        };
        setNoteDate(null as unknown as Date);
        setNoteNote(newNote.note);

        let notesCopy = [...notes];
        notesCopy.push(newNote);
        setNotes(notesCopy);
    };


    const onChangeIntegrationEngine = (index: number) => (value: string) => {
        const integrationEnginesCopy = [...integrationEngines];
        integrationEnginesCopy[index].engine = value;
        setIntegrationEngines(integrationEnginesCopy);
    }

    const onChangeIntegrationEngineNotes = (index: number) => (e: React.SyntheticEvent<HTMLInputElement>) => {
        const integrationEnginesCopy = [...integrationEngines];
        integrationEnginesCopy[index].notes = e.currentTarget.value;
        setIntegrationEngines(integrationEnginesCopy);
    }

    const addNewIE = () => {
        const integrationEnginesCopy = [...integrationEngines];
        integrationEnginesCopy.push({ engine: "", notes: "", date: new Date().getTime(), active: true });
        setIntegrationEngines(integrationEnginesCopy);
    };

    const retireIntegrationEngine = (index: number) => () => {
        let integrationEnginesCopy = [...integrationEngines];
        integrationEnginesCopy[index].active = false;
        integrationEnginesCopy[index].date = new Date().getTime();
        setIntegrationEngines(integrationEnginesCopy);
    }

    const renderIntegrationEngine = (integrationEngine: IIntegrationEngine, index: number) => {
        return <IntegrationEngineRow>
            <IntegrationEngineCell>
                <Label htmlFor="integrationEngine">IE</Label>
                <Select fontSize={FONTSIZE} placeholder="Select an integration engine" onChange={onChangeIntegrationEngine(index)} childValues={INTEGRATION_ENGINES && INTEGRATION_ENGINES.map(option => option)} value={integrationEngine.engine}>
                    {INTEGRATION_ENGINES.map((option, index) => <SelectItem height="20px" key={`select-integration-engine-item-${index}`} value={option}>{option}</SelectItem>)}
                </Select>
            </IntegrationEngineCell>
            <IntegrationEngineCell style={{ width: "50%" }}>
                <Label htmlFor="integrationEngineNotes">Notes</Label>
                <TextInput width="65%" required={false} htmlId="integrationEngineNotes" value={integrationEngine.notes} onChange={onChangeIntegrationEngineNotes(index)} />
            </IntegrationEngineCell>
            <IntegrationEngineCell >
                {integrationEngine.active && <Button plain={true} onClick={retireIntegrationEngine(index)}>Retire</Button>}
                {!integrationEngine.active && <div>Retired {dateConverter(integrationEngine.date)}</div>}
            </IntegrationEngineCell>
        </IntegrationEngineRow>
    }

    const addLinkedCustomer = () => {
        const existingLinkedCustomersCopy = [...existingLinkedCustomers];
        existingLinkedCustomersCopy.push("");
        setExistingLinkedCustomers(existingLinkedCustomersCopy);
    };

    const onChangeLinkedCustomer = (index: number) => (customer: string) => {
        const existingLinkedCustomersCopy = [...existingLinkedCustomers];
        existingLinkedCustomersCopy[index] = customer;
        setExistingLinkedCustomers(existingLinkedCustomersCopy);
    };

    const renderLinkedCustomerItem = (existingLinkedCustomer: string, index: number) => {
        return <DynamicSelect required={false} placeholder="Select a customer" onChange={onChangeLinkedCustomer(index)} childValues={customers && customers.map(option => option.customerName)} value={existingLinkedCustomers[index]}>
            {customers && customers.filter((obj: { status: boolean; }) => { return obj.status === true }).map((option, index) => <SelectItem height="20px" key={`add-replace-customer-item-${index}`} value={option.customerName}>{option.customerName}</SelectItem>)
            }
        </DynamicSelect>;
    };

    const renderLinkedCustomer = (customer: ICustomer) => {
        return <LinkedCustomerRow>
            <div>{customer.id} - {customer.customerName}</div>
            {isEditing && <Button color={COLORS.FOURTH} onClick={removeLinkedCustomer(customer.id ? customer.id : 0)}>Remove</Button>}
        </LinkedCustomerRow>;
    };


    return <OutsideContainer>
        <LoadingIndicator type="Linear" show={loading} />
        <Main>
            {error && <ErrorText>{error}</ErrorText>}
            <CustomerHeader>
                <CustomerInformation>
                    <TitleAndButton>
                        <h3 style={{ marginRight: "20px" }}>Customer Details</h3>
                        <ButtonContainer>
                            <Button color={COLORS.FOURTH} onClick={save}>Save Customer</Button>
                            <Button plain={true} style={{ marginLeft: "8px" }} onClick={props.onAddComplete}>Cancel</Button>
                        </ButtonContainer>
                    </TitleAndButton>
                    <form>
                        <Row>
                            <Label htmlFor="customerName">Customer Name</Label>
                            <TextInput htmlId="customerName" value={customerName} onChange={updateText(setCustomerName)} placeholder="Customer name" />
                        </Row>
                        <Row>
                            <Label htmlFor="shortName">Short Name</Label>
                            <TextInput htmlId="shortName" value={shortName} onChange={updateText(setShortName)} placeholder="Short name" />
                        </Row>
                        <Row>
                            <Label htmlFor="clientId">Client ID</Label>
                            <Value></Value>
                        </Row>
                    </form>
                </CustomerInformation>
                <RestartContacts>
                    <ContactRow><span style={{ fontSize: "1.7em", fontWeight: "bold" }}>Main Restart Contacts</span></ContactRow>
                    <ContactRow style={{ marginTop: "10px" }}>
                        <ContactCellLabel>Sales</ContactCellLabel>
                        <Select height="24px" fontSize={"0.7em"} required={false} placeholder="Select a user" onChange={onChangeSalesUser} childValues={users && users.map(option => option.id)} value={getUserById(salesUser, users)}>
                            {users && users.map((option, index) => <SelectItem fontSize="0.7em" height="24px" key={`restart-contacts-user-sales-${index}`} value={option.id}>{option.fullName}</SelectItem>)}
                        </Select>
                    </ContactRow>
                    <ContactRow>
                        <ContactCellLabel>Project Manager</ContactCellLabel>
                        <Select height="24px" fontSize={"0.7em"} required={false} placeholder="Select a user" onChange={onChangeProjectManagerUser} childValues={users && users.map(option => option.id)} value={getUserById(managerUser, users)}>
                            {users && users.map((option, index) => <SelectItem height="20px" key={`restart-contacts-user-manager-${index}`} value={option.id}>{option.fullName}</SelectItem>)}
                        </Select>
                    </ContactRow>
                    <ContactRow>
                        <ContactCellLabel>Architect</ContactCellLabel>
                        <Select height="24px" fontSize={"0.7em"} required={false} placeholder="Select a user" onChange={onChangeArchUser} childValues={users && users.map(option => option.id)} value={getUserById(archUser, users)}>
                            {users && users.map((option, index) => <SelectItem height="20px" key={`restart-contacts-user-arch-${index}`} value={option.id}>{option.fullName}</SelectItem>)}
                        </Select>
                    </ContactRow>
                    <ContactRow>
                        <ContactCellLabel>Lead developer</ContactCellLabel>
                        <Select height="24px" fontSize={"0.7em"} required={false} placeholder="Select a user" onChange={onChangeDevUser} childValues={users && users.map(option => option.id)} value={getUserById(devUser, users)}>
                            {users && users.map((option, index) => <SelectItem height="20px" key={`restart-contacts-user-dev-${index}`} value={option.id}>{option.fullName}</SelectItem>)}
                        </Select>
                    </ContactRow>
                </RestartContacts>
                <OpportunitiesSmallLogo />
            </CustomerHeader>

            <SeparatorRow />
            <Row>
                <TitleAndButton>
                    <h3 style={{ marginRight: "20px" }}>Linked Customers</h3>
                    <Button color={COLORS.GREY_SLATE} onClick={addLinkedCustomer}>Add Linked Customer</Button>
                </TitleAndButton>
            </Row>
            <Row>
                <LinkedCustomerContainer>
                    {linkedCustomers && linkedCustomers.map(renderLinkedCustomer)}
                    {existingLinkedCustomers.map(renderLinkedCustomerItem)}
                </LinkedCustomerContainer>
            </Row>
            <SeparatorRow />
            <Row>
                <TitleAndButton>
                    <h3 style={{ marginRight: "20px" }}>Replaces</h3>
                    <Button color={COLORS.GREY_SLATE} onClick={addCustomerToReplace}>Add customer to replace</Button>
                </TitleAndButton>
            </Row>
            <Row>
                <LinkedCustomerContainer>
                    {replaces && replaces.map(renderReplaces)}
                    {/* {customer.replacedBy && renderReplaced(customer.replacedBy)} */}
                    {existingCustomers.map(renderReplaceCustomerItem)}
                </LinkedCustomerContainer>
            </Row>
            <SeparatorRow />
            <Row>
                <Title>Integration Engine Details</Title>
            </Row>
            <Row>
                <table width={"100%"}>
                    {integrationEngines && integrationEngines.map(renderIntegrationEngine)}
                </table>
            </Row>
            <Row>
                <Button color={COLORS.GREY_SLATE} onClick={addNewIE}>Add New IE</Button>
            </Row>
            <SeparatorRow />

            {/* 
             not for this release
            <Row>
                <Title>Call Off Contracts</Title>
            </Row>
            <Row>
                <table style={{ width: "60%" }}>
                    <tbody>
                        <tr style={{ fontWeight: "bold", display: "flex" }} >
                            <TableCell innerStyle={{ fontWeight: "bold" }} noBorder={true} width={"20%"}>Quote</TableCell>
                            <TableCell innerStyle={{ textAlign: "center", fontWeight: "bold" }} noBorder={true} width={"30%"}>Original Pot Size</TableCell>
                            <TableCell innerStyle={{ textAlign: "center", fontWeight: "bold" }} noBorder={true} width={"30%"}>Days Remaining</TableCell>
                            <TableCell innerStyle={{ textAlign: "center", fontWeight: "bold" }} noBorder={true} width={"20%"}>% Remaining</TableCell>
                        </tr>
                        {customer.callOffContracts && customer.callOffContracts.map((contract, index) => {
                            return <tr key={`contact-entry=${index}`} style={{ display: "flex", borderTop: "1px solid grey" }}>
                                <TableCell noBorder={true} width={"20%"}>{contract.quoteNumber}</TableCell>
                                <TableCell innerStyle={{ textAlign: "center" }} noBorder={true} width={"30%"}>{contract.originalPotSize}</TableCell>
                                <TableCell innerStyle={{ textAlign: "center" }} noBorder={true} width={"30%"}>{contract.daysRemaining}</TableCell>
                                <TableCell innerStyle={{ textAlign: "center" }} noBorder={true} width={"20%"}>{contract.percentRemaining}</TableCell>
                            </tr>
                        })}
                    </tbody>
                </table>
            </Row> 
                    <SeparatorRow />
            */}

            <Row>
                <Title>Address for Quotes</Title>
            </Row>
            <Row>
                <Label htmlFor="integrationEngineDetails">Address</Label>
                <TextInput required={false} textArea={true} htmlId="addressForQuotes" value={addressForQuotes} onChange={updateText(setAddressForQuotes)} />
            </Row>
            <SeparatorRow />
            <Row>
                <TitleAndButton>
                    <h3 style={{ marginRight: "20px" }}>Notes</h3>
                    <Button color={COLORS.GREY_SLATE} onClick={AddNote}>Add Note</Button>
                </TitleAndButton>
            </Row>
            <Row style={{ display: "flex", flexDirection: "column", width: "100%", marginBottom: "50px" }}>
                {notes.map(renderNotes)}
            </Row>
        </Main>
    </OutsideContainer>
};

export default CustomerAdd;
