import * as React from 'react';
import { useParams } from 'react-router';
import useEffectOnSome from '../../CustomHooks/useEffectOnSome';

import ErrorBox from '../../SharedComponents/ErrorBox';
import ViewContainer from '../../SharedComponents/ViewContainer';
import UserManagementIndex from '../../Views/Manager/UserManagement/UserManagementIndex';
import PreferredRolePicker from '../../Views/PreferredRolePicker';

import { RolesContext } from '../../Roles/RolesContext';
import { getManagerConfigurationForRole, getPreferredRole, getRoles } from '../../Roles/RolesService';
import { SessionContext } from '../../Views/SessionContext';

import IRoleAndJwt from "../../Roles/IRoleAndJwt";
import { IManagerConfiguration } from '../../ServerEntities/IConfiguration';

const ManagerIndex = () => {
    const pageName = useParams<"pageName">().pageName;
    const { dispatch, state } = React.useContext(RolesContext);
    const role = state.role;
    const sessionContext = React.useContext(SessionContext);
    const { webToken } = sessionContext.state;
    const [rolesLoading, setRolesLoading] = React.useState(false);
    const [roles, setRoles] = React.useState([] as unknown as string[]);
    const [error, setError] = React.useState("");

    const updateManagerRoles = (rolesData: any) => {
        setRoles(rolesData || []);
        setRolesLoading(false);
    };

    const noRoles = (rolesData: any) => {
        setRolesLoading(false);
        return <ErrorBox>No roles.</ErrorBox>
    };

    const updateRole = (response: IRoleAndJwt) => {
        dispatch({ type: "setRole", payload: response.role });
        getManagerConfigurationForRole(response.role, response.jwt, updateConfiguration, handleConfigurationError);
        sessionContext.dispatch({ type: "setWebToken", payload: response.jwt });
    };

    const setNoPreferredRole = () => {
    };

    const updateConfiguration = (configuration: IManagerConfiguration) => {
        dispatch({ type: "setManagerConfiguration", payload: configuration });
    };

    const handleConfigurationError = (serverError: string) => {
        setError(serverError);
    };

    useEffectOnSome(() => {
        if (role) {
            // The role we've used to get here is not a manager role - probably it's from patient view.
            if (roles.length === 0 && !rolesLoading) {
                setRolesLoading(true);
                getRoles("Manager", webToken, updateManagerRoles, noRoles);
            } else if (!rolesLoading) {
                getPreferredRole("Manager", webToken, updateRole, setNoPreferredRole);
            }
        }
    }, [webToken]);

    if (error) {
        return <ErrorBox>{error}</ErrorBox>;
    }

    if (role && state.managerConfiguration) {
        return <ViewContainer>
            <UserManagementIndex pageName={pageName} componentNames={state.managerConfiguration.components} />
        </ViewContainer>;
    } else {
        return <PreferredRolePicker applicationName="Manager" />
    }
};

export default ManagerIndex;
