import * as React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";

import DataSorter from "../../../UsefulFunctions/DataSorter";
import dateConverter from "../../../UsefulFunctions/dateConverter";
import { componentConfiguration } from "../../../UsefulFunctions/getComponentConfiguration";
import Button from "../../../SharedComponents/Button";
import TableSortLabel from "../../../SharedComponents/Table/TableSortLabel";

import "react-datepicker/dist/react-datepicker.css";
import { RolesContext } from "../../../Roles/RolesContext";
import Select from "../../../SharedComponents/Select/Select";
import SelectItem from "../../../SharedComponents/Select/SelectItem";
import TextInput, { updateText } from "../../../SharedComponents/TextInput";
import { COLORS } from "../../../config";
import IUser from "../../../ServerEntities/IUser";
import { ButtonContainer } from "../WorkPackages/WorkPackageEdit";
import IOrder from "../../../ServerEntities/IOrder";
import { OpportunityHeader } from "../Opportunities/OpportunityAdd";
import IQuoteType from "../../../ServerEntities/IQuoteType";
import { FinanceSmallLogo } from "../../MainMenu";

export const Main = styled.main`
    padding: 20px;
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    flex: 5 1 0;
`;

export const Title = styled.h1`
    font-size: 1.3rem;
    margin: 12px 0px 20px;
`;

const ActionContainer = styled.div`
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

const Table = styled.table`
    border-collapse: collapse;
`;

const TableCell = styled.td`
    height: 24px;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

interface IProps {
    onView: (order: IOrder) => void,
    users: IUser[],
    types: IQuoteType[],
    onDelete: () => void,
    onRefresh: () => void,
    orders: IOrder[]
};

export const STATUS = ["Opportunity", "Scoping", "Pricing", "Priced", "Authorised"];

const OrderTable = (props: IProps) => {
    const { configuration } = React.useContext(RolesContext).state;
    const rbac = componentConfiguration("Finance", configuration);
    const types = props.types;
    const orders = props.orders ? props.orders : [];
    const [sortByColumn, setSortByColumn] = React.useState(2);
    const [sortDescending, setSortDescending] = React.useState(true);

    const [orderTypeFilter, setOrderTypeFilter] = React.useState("");
    const [orderOwnerFilter, setOrderOwnerFilter] = React.useState("");
    const [quoteNumberFilter, setQuoteNumberFilter] = React.useState("");
    const [enteredDateFilter, setEnteredDateFilter] = React.useState(undefined as unknown as Date);
    const [customerFilter, setCustomerFilter] = React.useState("");
    const [projectNameFilter, setProjectNameFilter] = React.useState("");
    const [projectValueFilter, setProjectValueFilter] = React.useState("");
    const [expiryFilter, setExpiryFilter] = React.useState(undefined as unknown as Date);
    const [statusFilter, setStatusFilter] = React.useState("");
    const [sourceFilter, setSourceFilter] = React.useState("");

    const renderType = (typeId: number) => {
        if (types && types.length > 0) {
            for (let i = 0; i < types.length; i++) {
                if (types[i].id === typeId) {
                    return types[i].description;
                }
            }
        }
        return typeId;
    };

    const viewOrder = (orderId: number) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const order = orders.find((order: IOrder) => order.id === orderId)
        if (order) {
            props.onView(order);
        }
    };

    const changeSortColumn = (columnIndex: number) => () => {
        if (columnIndex === sortByColumn) {
            setSortDescending(!sortDescending);
        } else {
            setSortDescending(true);
        }
        setSortByColumn(columnIndex);
    };

    const filterOrders = (order: IOrder) => {
        if (orderTypeFilter.length > 0 && !order.type.toLowerCase().includes(orderTypeFilter.toLowerCase())) {
            return false;
        }
        if (orderOwnerFilter.length > 0 && !order.quote.orderOwner.toLowerCase().includes(orderOwnerFilter.toLowerCase())) {
            return false;
        }
        if (quoteNumberFilter.length > 0 && !order.quote.quoteNumber.toLowerCase().includes(quoteNumberFilter.toLowerCase())) {
            return false;
        }
        if (enteredDateFilter && !dateConverter(order.quote.enteredDate).toLowerCase().includes(dateConverter(enteredDateFilter).toLowerCase())) {
            return false;
        }
        if (customerFilter.length > 0 && !order.quote.customer.toLowerCase().includes(customerFilter.toLowerCase())) {
            return false;
        }
        if (projectNameFilter.length > 0 && !order.quote.projectName.toLowerCase().includes(projectNameFilter.toLowerCase())) {
            return false;
        }
        if (projectValueFilter.length > 0 && !order.quote.projectValue.toLowerCase().includes(projectValueFilter.toLowerCase())) {
            return false;
        }
        if (expiryFilter && !dateConverter(order.quote.expiryDate).toLowerCase().includes(dateConverter(expiryFilter).toLowerCase())) {
            return false;
        }
        if (statusFilter.length > 0 && !STATUS[order.status].toLowerCase().includes(statusFilter.toLowerCase())) {
            return false;
        }
        if (sourceFilter.length > 0 && !order.source.toLocaleLowerCase().includes(sourceFilter.toLowerCase())) {
            return false;
        }
        return true;
    }

    const orderToArray = (order: IOrder, index: number): [number, number, string, string, number, string, string, string, number, number, number, string, number] => [
        order.id,
        order.quote ? order.quote.typeId : 0,
        order.quote ? order.quote.orderOwner : "",
        order.quote ? order.quote.quoteNumber : "",
        order.quote ? order.quote.enteredDate : 0,
        order.quote ? order.quote.customer : "",
        order.quote ? order.quote.projectName : "",
        order.quote ? order.quote.projectValue : "",
        order.quote ? order.quote.expiryDate : 0,
        order.status ? order.status : 0,
        order.authorisedDate,
        order.source,
        index
    ];

    const onClearFilters = () => {
        setOrderTypeFilter("");
        setOrderOwnerFilter("");
        setQuoteNumberFilter("");
        setEnteredDateFilter(undefined as unknown as Date);
        setCustomerFilter("");
        setProjectNameFilter("");
        setProjectValueFilter("");
        setStatusFilter("");
        setExpiryFilter(undefined as unknown as Date);
        setSourceFilter("");
    };

    const changeDate = (setter: (date: Date) => void) => (date: Date, event: React.SyntheticEvent) => {
        setter(date);
    };

    const ORDER_ID = 0
    const ORDER_TYPE = 1;
    const ORDER_OWNER = 2;
    const ORDER_QUOTE_NUMBER = 3;
    const ORDER_ENTERED_DATE = 4;
    const ORDER_CUSTOMER = 5;
    const ORDER_PROJECT_NAME = 6;
    const ORDER_PROJECT_VALUE = 7;
    const ORDER_EXPIRY = 8;
    const ORDER_STATUS = 9;
    const ORDER_SOURCE = 11;

    const onExportCSV = () => {

    }

    return <Main>
        <OpportunityHeader>
            <ButtonContainer>
                {/* {rbac.create && <Button color={COLORS.GREY_SLATE} plain={false} style={{ marginLeft: "8px" }} onClick={onExportCSV}>Export CSV</Button>} */}
            </ButtonContainer>
            <FinanceSmallLogo />
        </OpportunityHeader>
        <Table>
            <thead>
                <tr>
                    <th style={{ width: "6%" }}><TableSortLabel active={sortByColumn === ORDER_TYPE} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(ORDER_TYPE)}>Type</TableSortLabel></th>
                    <th style={{ width: "6%" }}><TableSortLabel active={sortByColumn === ORDER_OWNER} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(ORDER_OWNER)}>Order Owner</TableSortLabel></th>
                    <th style={{ width: "6%" }}><TableSortLabel active={sortByColumn === ORDER_QUOTE_NUMBER} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(ORDER_QUOTE_NUMBER)}>Quote Number</TableSortLabel></th>
                    <th style={{ width: "6%" }}><TableSortLabel active={sortByColumn === ORDER_ENTERED_DATE} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(ORDER_ENTERED_DATE)}>Entered Date</TableSortLabel></th>
                    <th style={{ width: "15%" }}><TableSortLabel active={sortByColumn === ORDER_CUSTOMER} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(ORDER_CUSTOMER)}>Customer</TableSortLabel></th>
                    <th style={{ width: "15%" }}><TableSortLabel active={sortByColumn === ORDER_PROJECT_NAME} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(ORDER_PROJECT_NAME)}>Project Name</TableSortLabel></th>
                    <th style={{ width: "6%" }}><TableSortLabel active={sortByColumn === ORDER_PROJECT_VALUE} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(ORDER_PROJECT_VALUE)}>Project Value</TableSortLabel></th>
                    <th style={{ width: "6%" }}><TableSortLabel active={sortByColumn === ORDER_EXPIRY} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(ORDER_EXPIRY)}>Expiry</TableSortLabel></th>
                    <th style={{ width: "6%" }}><TableSortLabel active={sortByColumn === ORDER_STATUS} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(ORDER_STATUS)}>Status</TableSortLabel></th>
                    <th style={{ width: "6%" }}><TableSortLabel active={sortByColumn === ORDER_SOURCE} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(ORDER_SOURCE)}>Source</TableSortLabel></th>
                    <th style={{ width: "6%" }}></th>
                </tr>
                <tr>
                    <th><TextInput value={orderTypeFilter} onChange={updateText(setOrderTypeFilter)}></TextInput></th>
                    <th><TextInput value={orderOwnerFilter} onChange={updateText(setOrderOwnerFilter)}></TextInput></th>
                    <th><TextInput value={quoteNumberFilter} onChange={updateText(setQuoteNumberFilter)}></TextInput></th>
                    <th>
                        <div style={{ border: "1px solid #e5e5e5", borderRadius: "3px", lineHeight: "30px" }}>
                            <DatePicker dateFormat="dd-MMM-yyyy" selected={enteredDateFilter} onChange={changeDate(setEnteredDateFilter)} />
                        </div>
                    </th>
                    <th><TextInput value={customerFilter} onChange={updateText(setCustomerFilter)}></TextInput></th>
                    <th><TextInput value={projectNameFilter} onChange={updateText(setProjectNameFilter)}></TextInput></th>
                    <th><TextInput value={projectValueFilter} onChange={updateText(setProjectValueFilter)}></TextInput></th>
                    <th>
                        <div style={{ border: "1px solid #e5e5e5", borderRadius: "3px", lineHeight: "30px" }}>
                            <DatePicker dateFormat="dd-MMM-yyyy" selected={expiryFilter} onChange={changeDate(setExpiryFilter)} />
                        </div>
                    </th>
                    <th>
                        <Select onChange={setStatusFilter} childValues={STATUS.map(option => option)} value={statusFilter}>
                            {STATUS.map((option, index) => <SelectItem key={`select-order-status-item-${index}`} value={option}>{option}</SelectItem>)}
                        </Select>
                    </th>
                    <th>
                        <Select onChange={setSourceFilter} childValues={STATUS.map(option => option)} value={sourceFilter}>
                            <SelectItem key={`select-order-source-item-0`} value={"Sales"}>Sales</SelectItem>
                            <SelectItem key={`select-order-source-item-0`} value={"Projects"}>Projects</SelectItem>
                        </Select>
                    </th>
                    <th style={{ textAlign: "center" }}> <Button plain={true} onClick={onClearFilters} >clear filters</Button></th>
                </tr>
            </thead>

            <tbody>
                {orders
                    .filter(filterOrders)
                    .map(orderToArray)
                    .sort(DataSorter(sortByColumn, sortDescending))
                    .map((order, index: any) => {
                        return <tr key={`order-table-row-${index}`} >
                            <TableCell>{renderType(order[ORDER_TYPE])}</TableCell>
                            <TableCell>{order[ORDER_OWNER]}</TableCell>
                            <TableCell>{order[ORDER_QUOTE_NUMBER]}</TableCell>
                            <TableCell>{dateConverter(order[ORDER_ENTERED_DATE])}</TableCell>
                            <TableCell>{order[ORDER_CUSTOMER]}</TableCell>
                            <TableCell>{order[ORDER_PROJECT_NAME]}</TableCell>
                            <TableCell>{order[ORDER_PROJECT_VALUE]}</TableCell>
                            <TableCell>{dateConverter(order[ORDER_EXPIRY])}</TableCell>
                            <TableCell>{STATUS[order[ORDER_STATUS]]}</TableCell>
                            <TableCell>{order[ORDER_SOURCE]}</TableCell>
                            <TableCell>
                                <ActionContainer>
                                    {rbac.read && <Button plain={true} onClick={viewOrder(order[ORDER_ID])} style={{ marginRight: "10px" }}>View</Button>}
                                </ActionContainer>
                            </TableCell>
                        </tr>;
                    })}


            </tbody>
        </Table>
    </Main >
};

export default OrderTable;
