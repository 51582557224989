import axios, { AxiosError, AxiosResponse } from "axios";

import { getAPIUrl } from '../../../config';
import IRole from "../../../ServerEntities/IRole";
import IUser from '../../../ServerEntities/IUser';
import { NETWORK_ERROR } from '../../../UsefulFunctions/createErrorMessage';

const handleError = (error: AxiosError, failureCallback: (errorMessage: string) => void) => {
    let errorMessage = "Unknown error";
    if (error.response) {
        if (error.response.status === 401 && error.response.data === undefined) {
            errorMessage = "Unauthorised to perform this request."
        }
    }
    if (error.message === "Network Error") {
        errorMessage = NETWORK_ERROR;
    }
    failureCallback(errorMessage as string);
};

export const disableRole = (roleId: string, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.put(
        `${getAPIUrl()}roles/${roleId}/disable`,
        {},
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<any>) => {
            successCallback();
        })
        .catch((error: AxiosError) => {
            handleError(error, failureCallback);
        });
};

export const enableRole = (roleId: string, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.put(
        `${getAPIUrl()}roles/${roleId}/enable`,
        {},
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<any>) => {
            successCallback();
        })
        .catch((error: AxiosError) => {
            handleError(error, failureCallback);
        });
};

export const getTopHundredUsers = (roleId: string, webToken: string, successCallback: (users: IUser[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}roles/${roleId}/users`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<IUser[]>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError) => {
            handleError(error, failureCallback);
        });
};

export const searchForUsers = (roleId: string, searchTerm: string, webToken: string, successCallback: (users: IUser[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}roles/${roleId}/users/${searchTerm}`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<IUser[]>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError) => {
            handleError(error, failureCallback);
        });
};

export const getTopHundredUsersToAdd = (roleId: string, webToken: string, successCallback: (users: IUser[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}roles/${roleId}/users/available`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<IUser[]>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError) => {
            handleError(error, failureCallback);
        });
};

export const searchForUsersToAdd = (roleId: string, searchTerm: string, webToken: string, successCallback: (users: IUser[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}roles/${roleId}/users/available/${searchTerm}`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<IUser[]>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError) => {
            handleError(error, failureCallback);
        });
};

export const addUsersToRole = (users: IUser[], roleId: string, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    users.forEach((user: IUser) => {
        axios.put(
            `${getAPIUrl()}users/${user.id}/${roleId}/enable`,
            {},
            { headers: { Authorization: `Bearer ${webToken}` } }
        )
            .then((response: AxiosResponse<any>) => {
                successCallback();
            })
            .catch((error: AxiosError) => {
                handleError(error, failureCallback);
            });
    })
};

export const createRole = (id: string, webToken: string, role: IRole, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.post(
        `${getAPIUrl()}roles/${id}/create`,
        role,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<any>) => {
            successCallback();
        })
        .catch((error: AxiosError) => {
            handleError(error, failureCallback);
        });
};

export const getApplications = (webToken: string, successCallback: (applications: string[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(`${getAPIUrl()}roles/applications`, { headers: { Authorization: `Bearer ${webToken}` } })
        .then((response: AxiosResponse<string[]>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError) => {
            handleError(error, failureCallback);
        });
};


export const getTopHundredUsersToAssign = (webToken: string, successCallback: (users: IUser[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}roles/users`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<IUser[]>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError) => {
            handleError(error, failureCallback);
        });
};

export const searchForUsersToAssign = (searchTerm: string, webToken: string, successCallback: (users: IUser[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}roles/users/${searchTerm}`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<IUser[]>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError) => {
            handleError(error, failureCallback);
        });
};


export const setRoleCanEdit = (roleId: string, canEdit: boolean, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.post(
        `${getAPIUrl()}roles/${roleId}/edit/${canEdit}`,
        {},
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<any>) => {
            successCallback();
        })
        .catch((error: AxiosError) => {
            handleError(error, failureCallback);
        });
};