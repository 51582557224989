import axios, { AxiosError, AxiosResponse } from "axios";
import { getAPIUrl } from "../../../config";
import IWorkPackage from "../../../ServerEntities/IWorkPackage";
import INotificationTrigger from "../../../ServerEntities/INotificationTrigger";
import IQuote from "../../../ServerEntities/IQuote";
import IWorkPackageDocument from "../../../ServerEntities/IDocument";
import IDocument from "../../../ServerEntities/IDocument";
import IChangeHistory from "../../../ServerEntities/IChangeHistory";
import { STATUS_ARCHIVED } from "../../../UsefulFunctions/workPackageUtils";

export const getOpenQuotes = (webToken: string, successCallback: (quotes: IQuote[]) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}quotes/packages/open`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IQuote[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};


export const saveWorkPackage = (webToken: string, WorkPackage: IWorkPackage, successCallback: (WorkPackage: IWorkPackage) => void, failureCallback: (error: string) => void) => {
    axios.post(
        `${getAPIUrl()}quotes/packages`,
        WorkPackage,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IWorkPackage>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getWorkPackage = (WorkPackageId: number, webToken: string, successCallback: (WorkPackage: IWorkPackage) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}quotes/packages/${WorkPackageId}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IWorkPackage>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getWorkPackages = (webToken: string, successCallback: (WorkPackages: IWorkPackage[]) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}quotes/packages`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IWorkPackage[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getUserWorkPackages = (user: string, webToken: string, successCallback: (WorkPackages: IWorkPackage[]) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}quotes/packages?owner=${user}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IWorkPackage[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getArchivedWorkPackages = (webToken: string, successCallback: (WorkPackages: IWorkPackage[]) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}quotes/packages?status=${STATUS_ARCHIVED}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IWorkPackage[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const updateWorkPackage = (webToken: string, WorkPackage: IWorkPackage, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.put(
        `${getAPIUrl()}quotes/packages`,
        WorkPackage,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const deleteWorkPackage = (webToken: string, WorkPackageId: number, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.delete(
        `${getAPIUrl()}quotes/packages/${WorkPackageId}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const commitWorkPackageNotification = (trigger: INotificationTrigger, webToken: string, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.post(
        `${getAPIUrl()}notifications/message/publish`,
        trigger,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getWorkPackageDiagram = (WorkPackageId: number, webToken: string, successCallback: (files: IWorkPackageDocument[]) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}quotes/packages/${WorkPackageId}/file/diagram`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IWorkPackageDocument[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const deletePackageFile = (webToken: string, id: number, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.delete(
        `${getAPIUrl()}quotes/packages/file/${id}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getPackageDocuments = (packageId: number, type: string, webToken: string, successCallback: (files: IDocument[]) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}quotes/packages/${packageId}/file/${type}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IDocument[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getWorkPackageHistory = (workPackageId: string, webToken: string, successCallback: (changeHistory: IChangeHistory[]) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}history/packages/${workPackageId}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IChangeHistory[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const assignWorkPackage = (user: string, workPackageId: number, webToken: string, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.post(
        `${getAPIUrl()}quotes/packages/${workPackageId}/assign`,
        user,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};
