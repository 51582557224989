import * as React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";

import DataSorter from "../../../UsefulFunctions/DataSorter";
import Button from "../../../SharedComponents/Button";
import Dialog from "../../../SharedComponents/Dialog/Dialog";
import ErrorText from "../../../SharedComponents/ErrorText";
import LoadingIndicator from "../../../SharedComponents/LoadingIndicator";
import { SessionContext } from "../../SessionContext";
import TableSortLabel from "../../../SharedComponents/Table/TableSortLabel";

import IOpportunity from "../../../ServerEntities/IOpportunity";

import dateConverter from "../../../UsefulFunctions/dateConverter";
import "react-datepicker/dist/react-datepicker.css";
import { RolesContext } from "../../../Roles/RolesContext";
import { componentConfiguration } from "../../../UsefulFunctions/getComponentConfiguration";
import { deleteOpportunity } from "./OpportunitiesService";
import Select from "../../../SharedComponents/Select/Select";
import SelectItem from "../../../SharedComponents/Select/SelectItem";
import TextInput, { updateText } from "../../../SharedComponents/TextInput";
import { canEditOpportunity, isEditableOpportunity, STATUS } from "../../../UsefulFunctions/opportunityUtils";
import { COLORS } from "../../../config";
import { getUserById } from "../Components/ChangeHistory";
import IUser from "../../../ServerEntities/IUser";
import { ButtonContainer } from "../WorkPackages/WorkPackageEdit";
import { OpportunityHeader } from "./OpportunityAdd";
import { OpportunitiesSmallLogo } from "../../MainMenu";
import IQuoteType from "../../../ServerEntities/IQuoteType";

export const Main = styled.main`
    padding: 20px;
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    flex: 5 1 0;
    overflow: auto;
`;

export const Title = styled.h1`
    font-size: 1.3rem;
    margin: 12px 0px 20px;
`;

const ActionContainer = styled.div`
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

const Table = styled.table`
    border-collapse: collapse;
`;

const TableCell = styled.td`
    height: 24px;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

interface IProps {
    onAdd: () => void,
    onEdit: (opportunity: IOpportunity) => void,
    onView: (opportunity: IOpportunity) => void,
    users: IUser[],
    onDelete: () => void,
    onRefresh: () => void,
    viewAllOpportunities: boolean,
    viewArchivedOpportunities: boolean,
    opportunities: IOpportunity[]
};


const OpportunitiesTable = (props: IProps) => {
    const { state } = React.useContext(SessionContext);
    const { configuration } = React.useContext(RolesContext).state;
    const rbac = componentConfiguration("Opportunities", configuration);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const users = props.users;
    const opportunities = props.opportunities ? props.opportunities : [];
    const [opportunityToDelete, setOpportunityToDelete] = React.useState(0);
    const [sortByColumn, setSortByColumn] = React.useState(2);
    const [sortDescending, setSortDescending] = React.useState(true);
    const [quoteNumberFilter, setQuoteNumberFilter] = React.useState("");
    const [customerFilter, setCustomerFilter] = React.useState("");
    const [projectNameFilter, setProjectNameFilter] = React.useState("");
    const [statusFilter, setStatusFilter] = React.useState("");
    const [dateAddedFilter, setDateAddedFilter] = React.useState(undefined as unknown as Date);
    const [orderOwnerFilter, setOrderOwnerFilter] = React.useState("");
    const [revisitDateFilter, setRevisitDateFilter] = React.useState(undefined as unknown as Date);


    const editOpportunity = (opportunityId: number) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const opportunity = opportunities.find((opportunity: IOpportunity) => opportunity.id === opportunityId)
        if (opportunity) {
            props.onEdit(opportunity);
        }
    };

    const viewOpportunity = (opportunityId: number) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const opportunity = opportunities.find((opportunity: IOpportunity) => opportunity.id === opportunityId)
        if (opportunity) {
            props.onView(opportunity);
        }
    };

    const onCloseDeleteDialog = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setOpportunityToDelete(0);
    };

    const onShowDeleteDialog = (opportunityId: number) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setOpportunityToDelete(opportunityId);
    };

    const onDelete = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setLoading(true);
        setOpportunityToDelete(0);
        deleteOpportunity(state.webToken, opportunityToDelete, () => {
            setLoading(false);
            setError("");
            props.onDelete();
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    const changeSortColumn = (columnIndex: number) => () => {
        if (columnIndex === sortByColumn) {
            setSortDescending(!sortDescending);
        } else {
            setSortDescending(true);
        }
        setSortByColumn(columnIndex);
    };

    const OPPORTUNITY_ID = 0;
    const OPPORTUNITY_QUOTE_NUMBER = 1;
    const OPPORTUNITY_DATE_ADDED = 2;
    const OPPORTUNITY_CUSTOMER = 3;
    const OPPORTUNITY_PROJECT_NAME = 4;
    const OPPORTUNITY_STATUS = 5;
    const OPPORTUNITY_OWNER = 6;
    const OPPORTUNITY_OWNER_id = 7;
    const OPPORTUNITY_REVISIT_DATE = 8;

    const opportunityToArray = (opportunity: IOpportunity, index: number): [number, string, number, string, string, number, string, string, string, number] => [
        opportunity.id,
        opportunity.quote && opportunity.quote.quoteNumberFinal ? opportunity.quote.quoteNumberFinal : "",
        opportunity.quote ? opportunity.quote.enteredDate : 0,
        opportunity.quote ? opportunity.quote.customer : "",
        opportunity.quote ? opportunity.quote.projectName : "",
        opportunity.status,
        opportunity.quote ? getUserById(opportunity.quote.orderOwner, users) : "",
        opportunity.quote.orderOwner,
        opportunity.closeRevisit ? dateConverter(opportunity.closeRevisit) : "Never",
        index
    ];

    const filterOpportunities = (opportunity: IOpportunity) => {
        if (quoteNumberFilter.length > 0 && !opportunity.quote.quoteNumber.toLowerCase().includes(quoteNumberFilter.toLowerCase())) {
            return false;
        }
        if (orderOwnerFilter.length > 0 && !opportunity.quote.orderOwner.toLowerCase().includes(orderOwnerFilter.toLowerCase())) {
            return false;
        }
        if (dateAddedFilter && !dateConverter(opportunity.quote.enteredDate).toLowerCase().includes(dateConverter(dateAddedFilter).toLowerCase())) {
            return false;
        }
        if (customerFilter.length > 0 && (opportunity.quote.customer && opportunity.quote.customer.length > 0 && !opportunity.quote.customer.toLowerCase().includes(customerFilter.toLowerCase()))) {
            return false;
        }
        if (projectNameFilter.length > 0 && (opportunity.quote.projectName && opportunity.quote.projectName.length > 0 && !opportunity.quote.projectName.toLowerCase().includes(projectNameFilter.toLowerCase()))) {
            return false;
        }
        if (statusFilter.length > 0 && !STATUS[opportunity.status].toLocaleLowerCase().includes(statusFilter.toLowerCase())) {
            return false;
        }
        if (revisitDateFilter && opportunity.closeRevisit && !dateConverter(opportunity.closeRevisit).toLowerCase().includes(dateConverter(revisitDateFilter).toLowerCase())) {
            return false;
        }
        return true;
    }

    const onClearFilters = () => {
        setQuoteNumberFilter("");
        setCustomerFilter("");
        setProjectNameFilter("");
        setStatusFilter("");
        setRevisitDateFilter(undefined as unknown as Date);
        setDateAddedFilter(undefined as unknown as Date);
    };

    const changeDate = (setter: (date: Date) => void) => (date: Date, event: React.SyntheticEvent) => {
        setter(date);
    };

    return <Main>
        <OpportunityHeader>
            <ButtonContainer>
                {rbac.create && <Button color={COLORS.GREY_SLATE} plain={false} style={{ marginLeft: "8px" }} onClick={props.onAdd}>Add new opportunity</Button>}
            </ButtonContainer>
            <OpportunitiesSmallLogo />
        </OpportunityHeader>
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorText>{error}</ErrorText>}
        <Dialog style={{ padding: "32px" }} open={opportunityToDelete > 0} onClose={onCloseDeleteDialog}>
            <h3>Are you sure you want to delete?</h3>
            <ActionContainer>
                <Button color={COLORS.SIXTH} onClick={onCloseDeleteDialog} style={{ marginRight: "10px" }}>Cancel</Button>
                <Button onClick={onDelete} color={"#e60000"}>Delete</Button>
            </ActionContainer>
        </Dialog>
        <Table>
            <thead>
                <tr>
                    <th style={{ width: "10%" }}><TableSortLabel active={sortByColumn === OPPORTUNITY_QUOTE_NUMBER} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(OPPORTUNITY_QUOTE_NUMBER)}>Quote number</TableSortLabel></th>
                    <th style={{ width: "5%" }}><TableSortLabel active={sortByColumn === OPPORTUNITY_OWNER} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(OPPORTUNITY_OWNER)}>Opportunity Owner</TableSortLabel></th>
                    <th style={{ width: "10%" }}><TableSortLabel active={sortByColumn === OPPORTUNITY_DATE_ADDED} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(OPPORTUNITY_DATE_ADDED)}>Date added</TableSortLabel></th>
                    <th style={{ width: "20%" }}><TableSortLabel active={sortByColumn === OPPORTUNITY_CUSTOMER} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(OPPORTUNITY_CUSTOMER)}>Customer</TableSortLabel></th>
                    <th style={{ width: "20%" }}><TableSortLabel active={sortByColumn === OPPORTUNITY_PROJECT_NAME} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(OPPORTUNITY_PROJECT_NAME)}>Project name</TableSortLabel></th>
                    <th style={{ width: "5%" }}><TableSortLabel active={sortByColumn === OPPORTUNITY_STATUS} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(OPPORTUNITY_STATUS)}>Status</TableSortLabel></th>
                    {props.viewArchivedOpportunities && <th style={{ width: "5%" }}><TableSortLabel active={sortByColumn === OPPORTUNITY_REVISIT_DATE} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(OPPORTUNITY_REVISIT_DATE)}>Revisit Date</TableSortLabel></th>}
                    <th style={{ width: "10%" }}></th>
                </tr>
                <tr>
                    <th><TextInput value={quoteNumberFilter} onChange={updateText(setQuoteNumberFilter)}></TextInput></th>
                    <th><TextInput value={orderOwnerFilter} onChange={updateText(setOrderOwnerFilter)}></TextInput></th>
                    <th><div style={{
                        border: "1px solid #e5e5e5",
                        borderRadius: "3px",
                        lineHeight: "30px"
                    }}>
                        <DatePicker dateFormat="dd-MMM-yyyy" selected={dateAddedFilter} onChange={changeDate(setDateAddedFilter)} />
                    </div>
                    </th>
                    <th><TextInput value={customerFilter} onChange={updateText(setCustomerFilter)}></TextInput></th>
                    <th><TextInput value={projectNameFilter} onChange={updateText(setProjectNameFilter)}></TextInput></th>
                    <th><Select onChange={setStatusFilter} childValues={STATUS.map(option => option)} value={statusFilter}>
                        {STATUS.map((option, index) => <SelectItem key={`select-op-status-item-${index}`} value={option}>{option}</SelectItem>)}
                    </Select>
                    </th>
                    {props.viewArchivedOpportunities && <th><div style={{
                        border: "1px solid #e5e5e5",
                        borderRadius: "3px",
                        lineHeight: "30px"
                    }}>
                        <DatePicker dateFormat="dd-MMM-yyyy" selected={revisitDateFilter} onChange={changeDate(setRevisitDateFilter)} />
                    </div>
                    </th>}
                    <th style={{ textAlign: "center" }}> <Button plain={true} onClick={onClearFilters} >clear filters</Button></th>
                </tr>
            </thead>

            <tbody>
                {opportunities && opportunities
                    .filter(filterOpportunities)
                    .map(opportunityToArray)
                    .sort(DataSorter(sortByColumn, sortDescending))
                    .map((opportunity, index: any) => {
                        return <tr key={`opportunity-table-row-${index}`} >
                            <TableCell>{opportunity[OPPORTUNITY_QUOTE_NUMBER]}</TableCell>
                            <TableCell>{opportunity[OPPORTUNITY_OWNER]}</TableCell>
                            <TableCell>{dateConverter(opportunity[OPPORTUNITY_DATE_ADDED])}</TableCell>
                            <TableCell style={{ maxHeight: "100px" }} >{opportunity[OPPORTUNITY_CUSTOMER]}</TableCell>
                            <TableCell style={{ maxHeight: "100px" }} >{opportunity[OPPORTUNITY_PROJECT_NAME]}</TableCell>
                            <TableCell>{STATUS[opportunity[OPPORTUNITY_STATUS]]}</TableCell>
                            {props.viewArchivedOpportunities && <TableCell>{opportunity[OPPORTUNITY_REVISIT_DATE]}</TableCell>}
                            <TableCell>
                                <ActionContainer>
                                    {rbac.read && <Button plain={true} onClick={viewOpportunity(opportunity[OPPORTUNITY_ID])} style={{ marginRight: "10px" }}>View</Button>}
                                    {rbac.update && canEditOpportunity(props.viewAllOpportunities, props.viewArchivedOpportunities, configuration) && isEditableOpportunity(opportunity[OPPORTUNITY_STATUS], opportunity[OPPORTUNITY_OWNER_id], state.loggedInUser) && <Button plain={true} onClick={editOpportunity(opportunity[OPPORTUNITY_ID])} style={{ marginRight: "10px" }}>Edit</Button>}
                                    {rbac.delete && canEditOpportunity(props.viewAllOpportunities, props.viewArchivedOpportunities, configuration) && isEditableOpportunity(opportunity[OPPORTUNITY_STATUS], opportunity[OPPORTUNITY_OWNER_id], state.loggedInUser) && <Button plain={true} style={{ marginLeft: "8px" }} onClick={onShowDeleteDialog(opportunity[OPPORTUNITY_ID])}>Delete</Button>}
                                </ActionContainer>
                            </TableCell>
                        </tr>;
                    })}


            </tbody>
        </Table>
    </Main >
};

export default OpportunitiesTable;
