import * as React from "react";
import styled from "styled-components";
import { SessionContext } from "../../SessionContext";
import LoadingIndicator from "../../../SharedComponents/LoadingIndicator";
import IOpportunity from "../../../ServerEntities/IOpportunity";
import { getOpportunity, getOpportunityDocuments, } from "./OpportunitiesService";
import ErrorText from "../../../SharedComponents/ErrorText";
import { Main } from "./OpportunitiesTable";
import IMeetingInformation from "../../../ServerEntities/IMeetingInformation";
import ICustomerContactDetails from "../../../ServerEntities/ICustomerContactDetails";
import TableCell from "../../../SharedComponents/Table/TableCell";
import dateConverter from "../../../UsefulFunctions/dateConverter";
import "react-datepicker/dist/react-datepicker.css";
import QuoteDetails from "./QuoteDetails";
import IQuoteType from "../../../ServerEntities/IQuoteType";
import ICustomer from "../../../ServerEntities/ICustomer";
import { COLORS, getAPIUrl } from "../../../config";
import IDocument from "../../../ServerEntities/IDocument";
import Button from "../../../SharedComponents/Button";
import IUser from "../../../ServerEntities/IUser";
import DocumentBox from "../Components/DocumentBox";
import { Label, OpportunityHeader, Row, SeparatorRow, TitleAndButton } from "./OpportunityAdd";
import { OutsideContainer } from "../Customers/CustomerEdit";
import { STATUS_CLOSED, TYPE_DOCUMENT } from "../../../UsefulFunctions/opportunityUtils";
import IQuote from "../../../ServerEntities/IQuote";
import { OpportunitiesSmallLogo } from "../../MainMenu";
import useEffectOnSome from "../../../CustomHooks/useEffectOnSome";


const ClosedOppPanel = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #C1C1C1;
    padding: 10px;
`;

const Value = styled.div`
    line-height: 32px;
`;

const ContactsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-height: 62px;
`;

const ButtonContainer = styled.div`
    text-align: left;
`;

const ContactContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
`;
const ContactName = styled.div`
    padding: 5px;
`;

const FilesContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

interface IProps {
    opportunity: IOpportunity,
    types: IQuoteType[],
    users: IUser[],
    customers: ICustomer[],
    editable?: boolean,
    onViewHistory: (opportunity: IOpportunity) => void
    onOpen: (opportunity: IOpportunity) => void
};

const OpportunityDetails = (props: IProps) => {
    const { state } = React.useContext(SessionContext);
    const { opportunity, types, users, customers } = props;
    const [workRequired, setWorkRequired] = React.useState("");
    const [meetings, setMeetings] = React.useState([] as IMeetingInformation[]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [source, setSource] = React.useState("");
    const [documents, setDocuments] = React.useState(undefined as unknown as IDocument[]);
    const [quote, setQuote] = React.useState(undefined as unknown as IQuote);

    useEffectOnSome(() => {
        const getDocuments = (type: string) => {
            getOpportunityDocuments(opportunity.id, type, state.webToken, (files: IDocument[]) => {
                setLoading(false);
                setError("");
                setDocuments(files.length > 0 ? files : undefined as unknown as IDocument[]);
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
            });
        };

        getOpportunity(opportunity.id, state.webToken, (op: IOpportunity) => {
            setLoading(false);
            setError("");
            setQuote(op.quote);
            setWorkRequired(op.workRequired);
            setMeetings(op.meetingInformation);
            setSource(op.source);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
        getDocuments(TYPE_DOCUMENT);
    }, [opportunity.id]);

    const renderContact = (contact: ICustomerContactDetails) => {
        return <ContactContainer key={`contact-container-${contact.id}`}>
            <ContactName>{contact.name}</ContactName>
        </ContactContainer>;
    };

    const renderHtml = (html: string) => {
        return <div dangerouslySetInnerHTML={{ __html: html }} />;
    }

    const navigateToHistory = () => {
        props.onViewHistory(opportunity);
    }

    const onLoading = () => {
        setLoading(true);
    }

    const onFinishedLoading = () => {
        setLoading(false);
    }

    const reopen = () => {
        opportunity.status = 0;
        props.onOpen(opportunity);
    }

    return <OutsideContainer>
        <LoadingIndicator type="Linear" show={loading} />
        <Main>
            {error && <ErrorText>{error}</ErrorText>}
            <OpportunityHeader>
                <ButtonContainer>
                    <Button color={COLORS.GREY_SLATE} plain={false} style={{ marginLeft: "8px" }} onClick={navigateToHistory}>Change History</Button>
                    {opportunity.status === STATUS_CLOSED && <Button color={COLORS.GREY_SLATE} plain={false} style={{ marginLeft: "8px" }} onClick={reopen}>Reopen Opportunity</Button>}
                </ButtonContainer>
                <OpportunitiesSmallLogo />
            </OpportunityHeader>
            {quote && <QuoteDetails onLoading={onLoading} onFinishedLoading={onFinishedLoading} editable={false} quote={quote} types={types} customers={customers} users={users} />}
            {opportunity.status === STATUS_CLOSED && <ClosedOppPanel>
                <Row>
                    <Label htmlFor="closureReason">Closure Reason</Label>
                    <Value>{opportunity.closeReason}</Value>
                </Row>
                <Row>
                    <Label htmlFor="closureReason">Revisit Date</Label>
                    {opportunity.closeRevisit && <Value>{dateConverter(opportunity.closeRevisit)}</Value>}
                </Row>

            </ClosedOppPanel>}
            {opportunity.status === STATUS_CLOSED && <SeparatorRow />}
            <form>
                <Row>
                    <h3>Integration Engine Details</h3>
                </Row>
                <Row>
                    <Label htmlFor="integrationEngine">Integration Engine</Label>
                    {quote && <Value>{quote.integrationEngine}</Value>}
                </Row>
                <Row>
                    <Label htmlFor="additionalInfo">Additional Info</Label>
                    {quote && <Value>{quote.integrationEngineNotes}</Value>}
                </Row>
                <SeparatorRow />
                <Row>
                    <Label htmlFor="workRequired">Scope of work</Label>
                    <Value>{renderHtml(workRequired)}</Value>
                </Row>
                <Row>
                    <Label htmlFor="source">Source</Label>
                    <Value>{source}</Value>
                </Row>
                <SeparatorRow />
            </form>
            <TitleAndButton>
                <h3 style={{ marginRight: "20px" }}>Meetings</h3>
            </TitleAndButton>
            {meetings && <table>
                <tbody>
                    <tr style={{ minHeight: "48px", display: "flex" }} >
                        <TableCell width={"10%"}>Date</TableCell>
                        <TableCell width={"30%"}>Free text</TableCell>
                        <TableCell width={"40%"}>Contacts</TableCell>
                        <TableCell width={"20%"}></TableCell>
                    </tr>
                    {meetings.map((meeting, index) => {
                        return <tr key={`contact-entry=${index}`} style={{ minHeight: "48px", display: "flex", flexDirection: "row" }}>
                            <TableCell width={"10%"}>{dateConverter(meeting.date)}</TableCell>
                            <TableCell width={"30%"}>{meeting.freeText}</TableCell>
                            <TableCell width={"40%"}>
                                <ContactsContainer>
                                    {meeting.contacts.map(renderContact)}
                                </ContactsContainer>
                            </TableCell>
                            <TableCell width={"20%"}></TableCell>
                        </tr>;
                    })
                    }
                </tbody>
            </table>}
            <SeparatorRow />
            <Row>
                <Label htmlFor="attachments">Attachments</Label>
                <FilesContainer style={{ width: "100%" }}>
                    <DocumentBox
                        color={COLORS.SIXTH}
                        documents={documents}
                        url={getAPIUrl() + `quotes/opportunities/${props.opportunity.id}/file`}
                        token={state.webToken}
                        type={"document"}
                    />
                </FilesContainer>
            </Row>
        </Main>
    </OutsideContainer>
};

export default OpportunityDetails;
