import * as React from "react";
import styled from "styled-components";

import loginBackground from '../../images/loginBackground.png';
import Button from "../../SharedComponents/Button";
import Card from "../../SharedComponents/Card";
import ErrorBox from "../../SharedComponents/ErrorBox";
import Overlay from "../../SharedComponents/Overlay";

import ViewContainer from '../../SharedComponents/ViewContainer';
import { getAuthenticationUrl } from "./loginService";

const backgroundStyle = {
    backgroundAttachment: "fixed",
    backgroundImage: `url("${loginBackground}")`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
};

const Logo = styled.img`
    width: 200px;
    margin: 36px 36px 24px 36px;
    user-select: none;
`;

const TitleText = styled.h3`
    font-size: 20px;
    font-weight: 500;
    color: #404040;
    margin: 0px 36px 20px 36px;
`;

const LoginFadeContainer = styled.div`
    position: relative;
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 1;
    &.invisible {
        user-interaction: none;
        opacity: 0;
    }
`;

const LoginBoxContainer = styled.div`
    position: relative;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const SignInContainer = styled.div`
    padding: 30px;
    text-align: center;
`;

const LoginForm = () => {
    const [authorisationUrl, setAuthorisationUrl] = React.useState("");
    const [errorText, setErrorText] = React.useState("");
    localStorage.setItem('requestedPath', window.location.pathname);

    React.useEffect(() => {
        getAuthenticationUrl((url: string) => {
            setAuthorisationUrl(url);
        }, (errorMessage: string) => {
            setErrorText(errorMessage);
        });
    }, []);


    const authenticate = () => {
        window.location.href = authorisationUrl;
    }

    return <ViewContainer className="Background" style={backgroundStyle}>
        <Overlay>
            <Card>
                <LoginBoxContainer className="LoginBoxContainer">
                    <Logo className="logo" src="/images/slate-plum.png" />
                    <LoginFadeContainer className={`LoginFadeContainer `}>
                        <TitleText className="titleText">Please sign in to continue</TitleText>
                        <SignInContainer>
                            {errorText && <ErrorBox>{errorText}</ErrorBox>}
                            <Button onClick={authenticate}>Sign in</Button>
                        </SignInContainer>
                    </LoginFadeContainer>
                </LoginBoxContainer>
            </Card>
        </Overlay>
    </ViewContainer>;
};

export default LoginForm;
