import axios, { AxiosError, AxiosResponse } from "axios";
import { getAPIUrl } from "../../../config";
import ICustomer from "../../../ServerEntities/ICustomer";
import ICustomerContactDetails from "../../../ServerEntities/ICustomerContactDetails";
import ISupportContract from "../../../ServerEntities/ISupportContract";
import IChangeHistory from "../../../ServerEntities/IChangeHistory";


export const saveCustomer = (webToken: string, customer: ICustomer, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.post(
        `${getAPIUrl()}quotes/customers`,
        customer,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getCustomer = (webToken: string, id: number, successCallback: (customer: ICustomer) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}quotes/customers/${id}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<ICustomer>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getCustomers = (webToken: string, successCallback: (customers: ICustomer[]) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}quotes/customers`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<ICustomer[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const updateCustomer = (webToken: string, customer: ICustomer, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.put(
        `${getAPIUrl()}quotes/customers`,
        customer,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const archiveCustomer = (webToken: string, customerId: number, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.delete(
        `${getAPIUrl()}quotes/customers/${customerId}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const unarchiveCustomer = (webToken: string, customerId: number, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.put(
        `${getAPIUrl()}quotes/customers/${customerId}/unarchive`,
        {},
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getCustomersCSV = (webToken: string) => {
    let url: string = `${getAPIUrl()}quotes/customers/csv`;
    url += `?bearer=${webToken}`
    window.open(url);

};

export const getCustomerContacts = (customerId: number, webToken: string, successCallback: (contacts: ICustomerContactDetails[]) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}quotes/customers/${customerId}/contacts`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<ICustomerContactDetails[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const updateDetails = (webToken: string, contact: ICustomerContactDetails, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.put(
        `${getAPIUrl()}quotes/customers/contacts`,
        contact,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const addDetails = (webToken: string, contact: ICustomerContactDetails, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.post(
        `${getAPIUrl()}quotes/customers/contacts`,
        contact,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const deleteCustomerContactDetails = (webToken: string, contactDetailsId: number, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.delete(
        `${getAPIUrl()}quotes/customers/contacts/${contactDetailsId}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};


export const getSupportContract = (customerId: number, webToken: string, successCallback: (supportContract: ISupportContract[]) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}quotes/customers/${customerId}/support`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<ISupportContract[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getCustomerHistory = (customerId: string, webToken: string, successCallback: (changeHistory: IChangeHistory[]) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}history/customers/${customerId}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IChangeHistory[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};
