import * as React from "react";
import styled from "styled-components";
import { SessionContext } from "../../SessionContext";
import LoadingIndicator from "../../../SharedComponents/LoadingIndicator";
import ErrorText from "../../../SharedComponents/ErrorText";
import Button from "../../../SharedComponents/Button";
import ICustomer from "../../../ServerEntities/ICustomer";
import { archiveCustomer, getCustomersCSV, unarchiveCustomer } from "./CustomersService";
import TextInput, { updateText } from "../../../SharedComponents/TextInput";
import Dialog from "../../../SharedComponents/Dialog/Dialog";
import { RolesContext } from "../../../Roles/RolesContext";
import { componentConfiguration } from "../../../UsefulFunctions/getComponentConfiguration";
import ICustomerReplaced from "../../../ServerEntities/ICustomerReplaced";
import { COLORS } from "../../../config";
import { OpportunityHeader } from "../Opportunities/OpportunityAdd";
import TableSortLabel from "../../../SharedComponents/Table/TableSortLabel";
import DataSorter from "../../../UsefulFunctions/DataSorter";
import { OpportunitiesSmallLogo } from "../../MainMenu";

export const Main = styled.main`
    padding: 20px;
    display: flex;
    flex-direction: column;
    text-align: left;
    overflow-y: auto;
`;

export const Title = styled.h1`
    font-size: 1.3rem;
    margin: 12px 0px 20px;
`;

const ActionContainer = styled.div`
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 1 1 100%;
`;

const ButtonContainer = styled.div`
    text-align: right;
`;

const Table = styled.table`
    border-collapse: collapse;
`;

const TableRow = styled.tr`
  &.deceased {
        background-color: lightgray;
    }
`;

const TableCell = styled.td`
    height: 24px;
`;

interface IProps {
    onAdd: () => void,
    onEdit: (quote: ICustomer) => void,
    onView: (quote: ICustomer) => void,
    onDelete: () => void,
    onRefresh: () => void,
    customers: ICustomer[]
};

const CustomersTable = (props: IProps) => {
    const { state } = React.useContext(SessionContext);
    const { configuration } = React.useContext(RolesContext).state;
    const rbac = componentConfiguration("Opportunities", configuration);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [archiving, setArchiving] = React.useState(-1);
    const [showArchiveDialog, setShowArchiveDialog] = React.useState(false);
    const none = -1;
    const [customerNameFilter, setCustomerNameFilter] = React.useState("");
    const [shortNameFilter, setShortNameFilter] = React.useState("");
    const [clientIdFilter, setClientIdFilter] = React.useState("");
    const [replacesFilter, setReplacesFilter] = React.useState("");
    const [sortByColumn, setSortByColumn] = React.useState(1);
    const [sortDescending, setSortDescending] = React.useState(false);

    const customers = props.customers ? props.customers : [];

    const editCustomer = (customerId: number) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const customer = customers.find((customer: ICustomer) => customer.id === customerId)
        if (customer) {
            props.onEdit(customer);
        }
    }

    const onCloseArchiveDialog = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setShowArchiveDialog(false);
    }

    const onArchive = (id: number) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setShowArchiveDialog(true);
        setArchiving(id);
    }

    const archive = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setLoading(true);
        setShowArchiveDialog(false);
        archiveCustomer(state.webToken, archiving, () => {
            setLoading(false);
            setError("");
            setArchiving(none);
            props.onDelete();
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    };

    const unarchive = (id: number) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setLoading(true);
        setShowArchiveDialog(false);
        unarchiveCustomer(state.webToken, id, () => {
            setLoading(false);
            setError("");
            setArchiving(none);
            props.onDelete();
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    };

    const onExport = () => {
        getCustomersCSV(state.webToken);
    };

    const renderReplaces = (customer: ICustomerReplaced) => {
        return <div key={`customer-replaces-${customer.id}`}>Replaces {customer.id} - {customer.name}</div>
    }

    const renderReplaced = (customer: ICustomerReplaced) => {
        return <div key={`customer-replaced-${customer.id}`}>Replaced by {customer.id} - {customer.name}</div>
    }

    const onClearFilters = () => {
        setCustomerNameFilter("");
        setShortNameFilter("");
        setClientIdFilter("");
        setReplacesFilter("");
    };

    const filterCustomers = (customer: ICustomer) => {
        if (customerNameFilter.length > 0 && !customer.customerName.toLowerCase().includes(customerNameFilter.toLowerCase())) {
            return false;
        }
        if (shortNameFilter.length > 0 && !customer.shortName.toLowerCase().includes(shortNameFilter.toLowerCase())) {
            return false;
        }
        if (clientIdFilter.length > 0 && customer.clientId && !customer.clientId.toLowerCase().includes(clientIdFilter.toLowerCase())) {
            return false;
        }

        if (replacesFilter.length > 0 && customer.replaces && !customer.replaces.map(obj => obj.name).join(",").toLowerCase().includes(replacesFilter.toLowerCase())) {
            return false;
        }
        return true;
    }

    const changeSortColumn = (columnIndex: number) => () => {
        if (columnIndex === sortByColumn) {
            setSortDescending(!sortDescending);
        } else {
            setSortDescending(true);
        }
        setSortByColumn(columnIndex);
    };

    const customerToArray = (customer: ICustomer, index: number): [number, string, string, string, boolean, number] => [
        customer.id ? customer.id : 0,
        customer.customerName,
        customer.shortName,
        customer.clientId,
        customer.status,
        index
    ];

    const CUSTOMER_ID = 0;
    const CUSTOMER_NAME = 1;
    const CUSTOMER_SHORT_NAME = 2;
    const CUSTOMER_CLIENT_ID = 3;
    const CUSTOMER_STATUS = 4;

    const activeCustomers = customers.filter(c => c.status).filter(filterCustomers).map(customerToArray).sort(DataSorter(sortByColumn, sortDescending))
    const archivedCustomers = customers.filter(c => !c.status).filter(filterCustomers).map(customerToArray).sort(DataSorter(sortByColumn, sortDescending))

    const renderCustomer = (customer: any, index: any) => {
        const customerObject = customers.find((c: ICustomer) => c.id === customer[CUSTOMER_ID])

        return <TableRow key={`customer-table-row-${index}`} className={`TableRow ${!customer[CUSTOMER_STATUS] ? "deceased" : ""} `} >
            <TableCell>{customer[CUSTOMER_NAME]}</TableCell>
            <TableCell>{customer[CUSTOMER_SHORT_NAME]}</TableCell>
            <TableCell>{customer[CUSTOMER_CLIENT_ID]}</TableCell>
            <TableCell>
                {customerObject && customerObject.replaces && customerObject.replaces.map(renderReplaces)}
                {customerObject && customerObject.replacedBy && renderReplaced(customerObject.replacedBy)}
            </TableCell>
            <TableCell>
                <ButtonContainer>
                    <Button plain={true} onClick={editCustomer(customer[CUSTOMER_ID])} style={{ marginRight: "10px" }}>Details</Button>
                    {rbac.archive && customer[CUSTOMER_STATUS] && <Button plain={true} onClick={onArchive(customer[CUSTOMER_ID])}>Archive</Button>}
                    {rbac.unarchive && !customer[CUSTOMER_STATUS] && <Button plain={true} onClick={unarchive(customer[CUSTOMER_ID])}>Unarchive</Button>}
                </ButtonContainer>
            </TableCell>
        </TableRow>;
    }

    return <Main>
        <OpportunityHeader>
            <ButtonContainer>
                {rbac.create && <Button color={COLORS.GREY_SLATE} plain={false} onClick={props.onAdd}>Add new customer</Button>}
                <Button color={COLORS.GREY_SLATE} plain={false} style={{ marginLeft: "8px" }} onClick={onExport}>Export customer list</Button>
            </ButtonContainer>
            <OpportunitiesSmallLogo />
        </OpportunityHeader>
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorText>{error}</ErrorText>}
        <Dialog style={{ padding: "32px" }} open={showArchiveDialog} onClose={onCloseArchiveDialog}>
            <h3>Are you sure you want to archive?</h3>
            <ActionContainer>
                <Button onClick={onCloseArchiveDialog} style={{ marginRight: "10px" }}>Cancel</Button>
                <Button onClick={archive} color={"#e60000"}>Archive</Button>
            </ActionContainer>
        </Dialog>

        <Table style={{ fontSize: "12px", marginTop: "40px" }}>
            <thead>
                <tr>
                    <th style={{ width: "30%" }}><TableSortLabel active={sortByColumn === CUSTOMER_NAME} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(CUSTOMER_NAME)}>Customer name</TableSortLabel></th>
                    <th style={{ width: "10%" }}><TableSortLabel active={sortByColumn === CUSTOMER_SHORT_NAME} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(CUSTOMER_SHORT_NAME)}>Short name</TableSortLabel></th>
                    <th style={{ width: "10%" }}><TableSortLabel active={sortByColumn === CUSTOMER_CLIENT_ID} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(CUSTOMER_CLIENT_ID)}>Client ID</TableSortLabel></th>
                    <th style={{ width: "30%" }}><TableCell>Linked customers</TableCell></th>
                    <th style={{ width: "20%" }}></th>
                </tr>
                <tr>
                    <th><TextInput value={customerNameFilter} onChange={updateText(setCustomerNameFilter)}></TextInput></th>
                    <th><TextInput value={shortNameFilter} onChange={updateText(setShortNameFilter)}></TextInput></th>
                    <th><TextInput value={clientIdFilter} onChange={updateText(setClientIdFilter)}></TextInput></th>
                    <th><TextInput value={replacesFilter} onChange={updateText(setReplacesFilter)}></TextInput></th>
                    <th style={{ textAlign: "center" }}> <Button plain={true} onClick={onClearFilters} >clear filters</Button></th>
                </tr>
            </thead>
            <tbody>
                {activeCustomers.map(renderCustomer)}
                {archivedCustomers.map(renderCustomer)}
            </tbody>
        </Table>
    </Main >
};

export default CustomersTable;