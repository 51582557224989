import * as React from 'react';
import styled from "styled-components";
import { COLORS } from "../config";

const SourceContainer = styled.div`
    color: "#000";
    line-height: 20px;
    display: flex;
    flex: 0 0 auto;
    border-radius: 24px;
    font-size: 0.8em;
    font-weight: bold;
    padding: 5px 20px;
    height: 20px;
`;

interface IProps {
    sourceType: string
}

const SourceTag = (props: IProps) => {
    let color = COLORS.PROJECTS_GREEN;

    if (props.sourceType === "Sales") {
        color = COLORS.OPPORTUNITIES_YELLOW;
    }

    return <SourceContainer style={{ backgroundColor: color }}>{props.sourceType}</SourceContainer>
}

export default SourceTag;