import React from 'react';
import { useEffect } from "react";
import "./App.css";

import styled from "styled-components";
import PageIndex from "./Views/ViewsIndex";

import { library } from '@fortawesome/fontawesome-svg-core'
import { faBell, faTimesCircle, faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { faPlus, faTag, faTimes, faUser } from '@fortawesome/free-solid-svg-icons'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { faHistory } from '@fortawesome/free-solid-svg-icons'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

library.add(faArrowLeft as any);
library.add(faArrowUp as any);
library.add(faCaretDown as any);
library.add(faEllipsisV as any);
library.add(faHistory as any);
library.add(faPlus as any);
library.add(faUser as any);
library.add(faTrash as any);
library.add(faTimesCircle as any);
library.add(faStar as any);
library.add(faTag as any);
library.add(faTrashAlt);
library.add(faTimes);
library.add(faBell);

const AppContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
`;

const TitledApp = () => {
    useEffect(() => {
        document.title = "Restart internal";
    }, []);

    return <AppContainer className="App">
        <PageIndex />
    </AppContainer>
};

export default TitledApp;
