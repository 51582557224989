import * as React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import { SessionContext } from "../../SessionContext";
import Button from "../../../SharedComponents/Button";
import TextInput, { updateText } from "../../../SharedComponents/TextInput";
import ICustomer from "../../../ServerEntities/ICustomer";
import { addDetails, deleteCustomerContactDetails, getCustomer, getCustomerContacts, getCustomers, getSupportContract, updateCustomer, updateDetails } from "./CustomersService";
import ICustomerContactDetails from "../../../ServerEntities/ICustomerContactDetails";
import { RolesContext } from "../../../Roles/RolesContext";
import { componentConfiguration } from "../../../UsefulFunctions/getComponentConfiguration";
import ICustomerReplaced from "../../../ServerEntities/ICustomerReplaced";
import DynamicSelect from "../../../SharedComponents/Select/DynamicSelect";
import SelectItem from "../../../SharedComponents/Select/SelectItem";
import { getCustomerBy, calculateSource, getCustomerIdBy } from "../../../UsefulFunctions/customerUtils";
import { COLORS, FONTSIZE, INTEGRATION_ENGINES } from "../../../config";
import ISupportContract from "../../../ServerEntities/ISupportContract";
import IUser from "../../../ServerEntities/IUser";
import Select from "../../../SharedComponents/Select/Select";
import { getUserById } from "../../../UsefulFunctions/quoteUtils";
import ICustomerNote from "../../../ServerEntities/ICustomerNote";
import dateConverter from "../../../UsefulFunctions/dateConverter";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import IIntegrationEngine from "../../../ServerEntities/IIntegrationEngine";
import { Main, SeparatorRow, Title } from "../Components/ChangeHistory";
import TableCell from "../../../SharedComponents/Table/TableCell";
import handleKeyboardSelect from "../../../CustomHooks/handleKeyboardSelect";
import Checkbox from "../../../SharedComponents/Checkbox";
import Dialog from "../../../SharedComponents/Dialog/Dialog";
import ErrorText from "../../../SharedComponents/ErrorText";
import LoadingIndicator from "../../../SharedComponents/LoadingIndicator";
import Table from "../../../SharedComponents/Table/Table";
import { OpportunitiesSmallLogo } from "../../MainMenu";
import { TitleAndButton } from "../WorkPackages/WorkPackageEdit";
import useEffectOnSome from "../../../CustomHooks/useEffectOnSome";

export const OutsideContainer = styled.div`
  width: 100%;
  margin: 0px;
  padding: 0px;
  overflow: auto;
`;

export const CustomerHeader = styled.div`
    display: flex;
    flex-direction: row;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
`;

export const CustomerInformation = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
`;

export const RestartContacts = styled.div`
    display: flex;
    flex-direction: column;
    width: 30%;
    border-radius: 5px;
    background-color: ${COLORS.RESTART_CONTACTS};
    padding: 10px;
    height: 140px;
    width: 300px;
`;

export const ContactRow = styled.div`
    line-height: 30px;
    font-size: 0.9em;
    display: flex;
    width: 100%;
    height: 30px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const ContactCell = styled.div`
    line-height: 24px;
    font-size: 0.8em;
`;

export const ContactCellLabel = styled.div`
    line-height: 24px;
    font-size: 0.8em;
    font-weight: bold;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const Label = styled.label`
    color: #333333;
    min-width: 140px;
    font-size: 0.9em;
    font-weight: bold;
    line-height: 32px;
`;

export const ButtonContainer = styled.div`
    text-align: left;
`;

export const ActionContainer = styled.div`
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 1 1 100%;
`;

export const Value = styled.div`
    line-height: 32px;
    font-size: 0.9em;
`;

export const HalfRow = styled.div`
    width: 50%;
    display: flex;
`;

export const NoteRow = styled.div`
    width: 100%;
    display: flex;
`;

export const NoteText = styled.div`
    font-size: ${FONTSIZE};
    font-weight: bold;
    align-items: center;
`;

export const LinkedCustomerContainer = styled.div`
    line-height: 42px;
     display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: space-between;
`;


export const LinkedCustomerRow = styled.div`
    line-height: 42px;
     display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    justify-content: space-between;
`;


export const SupportStatusContainer = styled.div`
    line-height: 20px;
    display: flex;
    flex: 0 0 auto;
    background-color: green;
    flex: 0 0 auto;
    border-radius: 24px;
    color: white;
    font-size: 0.8em;
    padding: 5px 20px;
    height: 20px;
    background-color: green;
    margin-left: 50px;
`;

export const UserAndDateContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 200px;
    align-items: flex-start;
    justify-content: center;
`;

export const NoteContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

export const MainNoteContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

export const IntegrationEngineCell = styled.td`
    display: flex;
    flex-direction: row;
    width: 25%;
`;


export const IntegrationEngineRow = styled.tr`
    display: flex;
    flex-direction: row;
`;


const renderHtml = (html: string) => {
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
}

interface IProps {
    users: IUser[],
    onAddComplete: () => void,
    onViewHistory: (customer: ICustomer) => void,
    customer: ICustomer
};

const CustomerEdit = (props: IProps) => {
    const { state } = React.useContext(SessionContext);
    const { configuration, role } = React.useContext(RolesContext).state;
    const rbac = componentConfiguration("Opportunities", configuration);
    const { customer, users } = props;
    const [customerName, setCustomerName] = React.useState("");
    const [shortName, setShortName] = React.useState("");
    const [clientId, setClientId] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [editing, setEditing] = React.useState(-1);
    const [contactName, setContactName] = React.useState("");
    const [contactTitle, setContactTitle] = React.useState("");
    const [contactEmail, setContactEmail] = React.useState("");
    const [contactPhone, setContactPhone] = React.useState("");
    const [contactVisibleToDev, setContactVisibleToDev] = React.useState(false);
    const [contactSource, setContactSource] = React.useState("");
    const [contactQuoteRecipient, setContactQuoteRecipient] = React.useState(false);
    const [contactId, setContactId] = React.useState(0);
    const contactCustomerId = customer.id || 0;
    const [refresh, setRefresh] = React.useState(0);
    const [contactDetails, setContactDetails] = React.useState(null as unknown as ICustomerContactDetails[]);
    const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
    const [deleting, setDeleting] = React.useState(-1);
    const [isEditing, setIsEditing] = React.useState(false);
    const [replaces, setReplaces] = React.useState(customer.replaces as ICustomerReplaced[]);
    const [linkedCustomers, setLinkedCustomers] = React.useState(customer.linkedCustomers as ICustomer[]);
    const [customers, setCustomers] = React.useState(null as unknown as ICustomer[])
    const [existingCustomers, setExistingCustomers] = React.useState([] as string[]);
    const [existingLinkedCustomers, setExistingLinkedCustomers] = React.useState([] as string[]);
    const none = -1;
    const newItem = -2;
    const [integrationEngines, setIntegrationEngines] = React.useState(null as unknown as IIntegrationEngine[]);
    const [addressForQuotes, setAddressForQuotes] = React.useState("");
    const [support, setSupport] = React.useState(null as unknown as ISupportContract);
    const [salesUser, setSalesUser] = React.useState("");
    const [archUser, setArchUser] = React.useState("");
    const [managerUser, setManagerUser] = React.useState("");
    const [devUser, setDevUser] = React.useState("");
    const [notes, setNotes] = React.useState([] as unknown as ICustomerNote[]);
    const [noteDate, setNoteDate] = React.useState(null as unknown as Date);
    const [noteNote, setNoteNote] = React.useState("");

    const changeDate = (setter: (date: Date) => void) => (date: Date, event: React.SyntheticEvent) => {
        setter(date);
    };

    const onCancel = () => {
        setRefresh(refresh + 1);
        setIsEditing(false);
    };

    useEffectOnSome(() => {
        setCustomerName(customer.customerName);
        setShortName(customer.shortName);
        setClientId(customer.clientId);
        setContactId(customer.id || 0);
        setAddressForQuotes(customer.addressForQuotes);
        if (customer.restartContacts) {
            setSalesUser(customer.restartContacts.sales);
            setArchUser(customer.restartContacts.architect);
            setManagerUser(customer.restartContacts.projectManager);
            setDevUser(customer.restartContacts.leadDeveloper);
        }

        if (customer.id) {
            getCustomer(state.webToken, customer.id, (c: ICustomer) => {
                setLoading(false);
                customer.callOffContracts = c.callOffContracts;
                customer.customerNotes = c.customerNotes ? c.customerNotes : [];
                setIntegrationEngines(c.integrationEngines);
                setNotes(customer.customerNotes);
                if (c.linkedCustomers) {
                    setLinkedCustomers(c.linkedCustomers);
                }

            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
            });
            getCustomerContacts(customer.id, state.webToken, (contacts: ICustomerContactDetails[]) => {
                setLoading(false);
                setError("");
                setContactDetails(contacts);
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
                setContactDetails(null as unknown as ICustomerContactDetails[]);
            });
            getSupportContract(customer.id, state.webToken, (supportContract: ISupportContract[]) => {
                setLoading(false);
                setError("");
                setSupport(supportContract[0]);
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
                setSupport(null as unknown as ISupportContract);
            });
        }
    }, [customer, refresh]);

    const save = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        existingCustomers.forEach((customer: string) => { replaces.push({ id: getCustomerIdBy(customer, customers), name: customer }) })
        existingLinkedCustomers.forEach((customer: string) => {
            const customerObject = getCustomerBy(customer, customers);
            if (customerObject) {
                linkedCustomers.push(customerObject)
            }
        })
        const customer: ICustomer = {
            customerName: customerName,
            shortName: shortName,
            clientId: clientId,
            id: contactId,
            status: true,
            replaces: replaces,
            linkedCustomers: linkedCustomers,
            restartContacts: { sales: salesUser, architect: archUser, projectManager: managerUser, leadDeveloper: devUser },
            integrationEngines: integrationEngines,
            addressForQuotes: addressForQuotes,
            customerNotes: notes
        };
        setLoading(true);
        updateCustomer(state.webToken, customer, () => {
            setLoading(false);
            setError("");
            setIsEditing(false);
        }, (errorMessage: string) => {
            setLoading(false);
            setIsEditing(false);
            setError(errorMessage);
        });
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    const editContact = (contact: ICustomerContactDetails) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setEditing(contact.id)
        setContactName(contact.name);
        setContactTitle(contact.jobTitle);
        setContactEmail(contact.email);
        setContactPhone(contact.phone);
        setContactId(contact.id);
        setContactQuoteRecipient(contact.quoteRecipient ? true : false);
        setContactSource(contact.source);
        setContactVisibleToDev(contact.visibleToDev);
    };

    const saveContact = (contact: ICustomerContactDetails) => {
        if (editing === newItem) {
            addDetails(state.webToken, contact, () => {
                setLoading(false);
                setError("");
                setRefresh(refresh + 1);
                setEditing(none);
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
                setEditing(none);
            });
        }
        else {
            updateDetails(state.webToken, contact, () => {
                setLoading(false);
                setError("");
                setRefresh(refresh + 1);
                setEditing(none);
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
                setEditing(none);
            });
        }
    };

    const saveContactDetails = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setLoading(true);
        const contact: ICustomerContactDetails = {
            name: contactName,
            jobTitle: contactTitle,
            email: contactEmail,
            phone: contactPhone,
            id: contactId,
            customerId: contactCustomerId,
            visibleToDev: contactVisibleToDev,
            source: contactSource,
            quoteRecipient: contactQuoteRecipient ? 1 : 0
        };
        saveContact(contact);
    };

    const addContactDetails = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (e) {
            e.preventDefault();
        }

        setEditing(newItem);
        const newContact: ICustomerContactDetails = {
            name: "",
            jobTitle: "",
            email: "",
            phone: "",
            id: newItem,
            customerId: contactCustomerId,
            visibleToDev: false,
            source: "",
            quoteRecipient: 0
        };
        setEditing(newContact.id)
        setContactName(newContact.name);
        setContactTitle(newContact.jobTitle);
        setContactEmail(newContact.email);
        setContactPhone(newContact.phone);
        setContactId(newContact.id);
        setContactQuoteRecipient(newContact.quoteRecipient ? true : false);
        setContactSource(calculateSource(role ? role : ""));
        setContactVisibleToDev(newContact.visibleToDev);
        let contactDetailsCopy = [...contactDetails];
        contactDetailsCopy.push(newContact);
        setContactDetails(contactDetailsCopy);
    };

    const cancelEditContact = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setEditing(none);
        setRefresh(refresh + 1)
    };

    const onRemove = (id: number) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setShowDeleteDialog(true);
        setDeleting(id);
    };

    const onCloseDeleteDialog = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setShowDeleteDialog(false);
    };

    const onDelete = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setLoading(true);
        setShowDeleteDialog(false);
        deleteCustomerContactDetails(state.webToken, deleting, () => {
            setLoading(false);
            setError("");
            setDeleting(none);
            setRefresh(refresh + 1);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    };

    const editCustomer = () => {
        setIsEditing(true);
    };


    const removeLinkedCustomer = (id: number) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (linkedCustomers) {
            let newLinkedCustomers = [...linkedCustomers];
            newLinkedCustomers = newLinkedCustomers.filter(obj => obj.id !== id);
            setLinkedCustomers(newLinkedCustomers);
        }
    };

    const removeReplacedCustomer = (id: number) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (replaces) {
            let newReplaces = [...replaces];
            newReplaces = newReplaces.filter(obj => obj.id !== id);
            setReplaces(newReplaces);
        }
    };

    useEffectOnSome(() => {
        setLoading(true);
        getCustomers(state.webToken, (customers: ICustomer[]) => {
            setLoading(false);
            setError("");
            setCustomers(customers);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
            setCustomers(null as unknown as ICustomer[]);
        });
    }, [existingCustomers]);

    const onChangeCustomer = (index: number) => (customer: string) => {
        const existingCustomersCopy = [...existingCustomers];
        existingCustomersCopy[index] = customer;
        setExistingCustomers(existingCustomersCopy);
    };

    const addLinkedCustomer = () => {
        const existingLinkedCustomersCopy = [...existingLinkedCustomers];
        existingLinkedCustomersCopy.push("");
        setExistingLinkedCustomers(existingLinkedCustomersCopy);
    };

    const onChangeLinkedCustomer = (index: number) => (customer: string) => {
        const existingLinkedCustomersCopy = [...existingLinkedCustomers];
        existingLinkedCustomersCopy[index] = customer;
        setExistingLinkedCustomers(existingLinkedCustomersCopy);
    };

    const renderLinkedCustomerItem = (existingLinkedCustomer: string, index: number) => {
        return <DynamicSelect required={false} placeholder="Select a customer" onChange={onChangeLinkedCustomer(index)} childValues={customers && customers.map(option => option.customerName)} value={existingLinkedCustomers[index]}>
            {customers && customers.filter((obj: { status: boolean; }) => { return obj.status === true }).map((option, index) => <SelectItem height="20px" key={`add-replace-customer-item-${index}`} value={option.customerName}>{option.customerName}</SelectItem>)
            }
        </DynamicSelect>;
    };

    const renderLinkedCustomer = (customer: ICustomer) => {
        return <LinkedCustomerRow>
            <div>{customer.id} - {customer.customerName}</div>
            {isEditing && <Button color={COLORS.FOURTH} onClick={removeLinkedCustomer(customer.id ? customer.id : 0)}>Remove</Button>}
        </LinkedCustomerRow>;
    };

    const renderReplaceCustomerItem = (existingCustomer: string, index: number) => {
        return <DynamicSelect required={false} placeholder="Select a customer" onChange={onChangeCustomer(index)} childValues={customers && customers.map(option => option.customerName)} value={existingCustomers[index]}>
            {customers && customers.filter((obj: { status: boolean; }) => { return obj.status === true }).map((option, index) => <SelectItem height="20px" key={`add-replace-customer-item-${index}`} value={option.customerName}>{option.customerName}</SelectItem>)
            }
        </DynamicSelect>
    };

    const addCustomerToReplace = () => {
        const existingCustomersCopy = [...existingCustomers];
        existingCustomersCopy.push("");
        setExistingCustomers(existingCustomersCopy);
    };

    const renderReplaces = (customer: ICustomerReplaced) => {
        return <LinkedCustomerRow key={`customer-replaces-${customer.id}`}>
            <div>Replaces {customer.id} - {customer.name}</div>
            {isEditing && <Button color={COLORS.FOURTH} onClick={removeReplacedCustomer(customer.id)}>Remove</Button>}
        </LinkedCustomerRow>
    };

    const renderReplaced = (customer: ICustomerReplaced) => {
        return <div key={`customer-replaced-${customer.id}`}>Replaced by {customer.id} - {customer.name}</div>;
    };

    const timeRemainingMs = support ? support.endDate - Date.now() : 0;
    const timeRemainingYears = Math.floor(timeRemainingMs / (365 * 24 * 60 * 60 * 1000));

    const onChangeSalesUser = (user: string) => {
        setSalesUser(user);
    }

    const onChangeProjectManagerUser = (user: string) => {
        setManagerUser(user);
    }

    const onChangeArchUser = (user: string) => {
        setArchUser(user);
    }

    const onChangeDevUser = (user: string) => {
        setDevUser(user);
    }

    const navigateToHistory = () => {
        props.onViewHistory(customer);
    }

    const onChangeContactVisibleToDev = (checked: boolean) => {
        setContactVisibleToDev(checked);
    };

    const onChangeContactQuoteRecipient = (checked: boolean) => {
        setContactQuoteRecipient(checked);
    };

    const saveNote = (index: number) => () => {
        const date = noteDate ? noteDate.getTime() : 0;
        notes[index] = { user: getUserById(state.loggedInUser, users), note: noteNote, date: date, dirty: false };
        let notesCopy = [...notes];
        setNotes(notesCopy);
    }

    const removeNote = (index: number) => () => {
        notes.splice(index, 1);
        let notesCopy = [...notes];
        setNotes(notesCopy);
    }


    const renderNotes = (note: ICustomerNote, index: number) => {
        return <NoteRow>
            {!note.dirty && <MainNoteContainer>
                <UserAndDateContainer>
                    <NoteText>{note.user}</NoteText>
                    <NoteText>{dateConverter(note.date, false, "")}</NoteText>
                    {isEditing && <Button plain={true} onClick={removeNote(index)}>Remove</Button>}
                </UserAndDateContainer>
                <NoteContainer>
                    <NoteText style={{ fontWeight: "normal" }}>{renderHtml(note.note)}</NoteText>
                </NoteContainer>
            </MainNoteContainer>}
            {note.dirty && <MainNoteContainer>
                <UserAndDateContainer>
                    <NoteText>{note.user}</NoteText>
                    <NoteText><DatePicker dateFormat="dd-MMM-yyyy" placeholderText="Select a date" selected={noteDate} onChange={changeDate(setNoteDate)} /></NoteText>
                </UserAndDateContainer>
                <NoteContainer>
                    <ReactQuill style={{ width: "100%" }} value={noteNote} onChange={setNoteNote} />
                    <Button plain={true} onClick={saveNote(index)}>Save</Button>
                </NoteContainer>
            </MainNoteContainer>}
        </NoteRow>
    }

    const AddNote = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (e) {
            e.preventDefault();
        }

        setEditing(newItem);
        const newNote: ICustomerNote = {
            user: "",
            date: 0,
            note: "",
            dirty: true
        };
        setNoteDate(null as unknown as Date);
        setNoteNote(newNote.note);

        let notesCopy = [...notes];
        notesCopy.push(newNote);
        setNotes(notesCopy);
    };

    const onChangeIntegrationEngine = (index: number) => (value: string) => {
        const integrationEnginesCopy = [...integrationEngines];
        integrationEnginesCopy[index].engine = value;
        setIntegrationEngines(integrationEnginesCopy);
    }

    const onChangeIntegrationEngineNotes = (index: number) => (e: React.SyntheticEvent<HTMLInputElement>) => {
        const integrationEnginesCopy = [...integrationEngines];
        integrationEnginesCopy[index].notes = e.currentTarget.value;
        setIntegrationEngines(integrationEnginesCopy);
    }

    const addNewIE = () => {
        const integrationEnginesCopy = [...integrationEngines];
        integrationEnginesCopy.push({ engine: "", notes: "", date: new Date().getTime(), active: true });
        setIntegrationEngines(integrationEnginesCopy);
    };

    const retireIntegrationEngine = (index: number) => () => {
        let integrationEnginesCopy = [...integrationEngines];
        integrationEnginesCopy[index].active = false;
        integrationEnginesCopy[index].date = new Date().getTime();
        setIntegrationEngines(integrationEnginesCopy);
    }

    const renderIntegrationEngine = (integrationEngine: IIntegrationEngine, index: number) => {
        return <IntegrationEngineRow>
            <IntegrationEngineCell>
                <Label htmlFor="integrationEngine">IE</Label>
                {!isEditing && <Value>{integrationEngine.engine}</Value>}
                {isEditing && <Select fontSize={FONTSIZE} placeholder="Select engine" onChange={onChangeIntegrationEngine(index)} childValues={INTEGRATION_ENGINES && INTEGRATION_ENGINES.map(option => option)} value={integrationEngine.engine}>
                    {INTEGRATION_ENGINES.map((option, index) => <SelectItem height="20px" key={`select-integration-engine-item-${index}`} value={option}>{option}</SelectItem>)}
                </Select>}
            </IntegrationEngineCell>
            <IntegrationEngineCell style={{ width: "50%" }}>
                <Label htmlFor="integrationEngineNotes">Notes</Label>
                {!isEditing && <Value>{integrationEngine.notes}</Value>}
                {isEditing && <TextInput width="65%" required={false} htmlId="integrationEngineNotes" value={integrationEngine.notes} onChange={onChangeIntegrationEngineNotes(index)} />}
            </IntegrationEngineCell>
            <IntegrationEngineCell >
                {integrationEngine.active && <Button plain={true} onClick={retireIntegrationEngine(index)}>Retire</Button>}
                {!integrationEngine.active && <Value>Retired {dateConverter(integrationEngine.date)}</Value>}
            </IntegrationEngineCell>
        </IntegrationEngineRow>
    }

    return <OutsideContainer>
        <LoadingIndicator type="Linear" show={loading} />
        <Main>
            {error && <ErrorText>{error}</ErrorText>}
            <CustomerHeader>
                <CustomerInformation>
                    <TitleAndButton>
                        <h3 style={{ marginRight: "20px" }}>Customer Details</h3>
                        <ButtonContainer>
                            {!isEditing && rbac.update && customer.status && <Button color={COLORS.FOURTH} onClick={editCustomer}>Edit Customer</Button>}
                            {isEditing && rbac.update && <Button color={COLORS.FOURTH} onClick={save}>Save Customer</Button>}
                            {isEditing && rbac.update && <Button plain={true} style={{ marginLeft: "8px" }} onClick={onCancel}>Cancel</Button>}
                            <Button color={COLORS.CHANGE_HISTORY} plain={false} style={{ marginLeft: "8px" }} onClick={navigateToHistory}>Change History</Button>
                        </ButtonContainer>
                    </TitleAndButton>

                    <form>
                        <Row>
                            <Label htmlFor="customerName">Customer Name</Label>
                            {!isEditing && <Value>{customerName}</Value>}
                            {isEditing && <TextInput htmlId="customerName" value={customerName} onChange={updateText(setCustomerName)} placeholder="Customer name" />}
                        </Row>
                        <Row>
                            <Label htmlFor="shortName">Short Name</Label>
                            {!isEditing && <Value>{shortName}</Value>}
                            {isEditing && <TextInput htmlId="shortName" value={shortName} onChange={updateText(setShortName)} placeholder="Short name" />}
                        </Row>
                        <Row>
                            <Label htmlFor="clientId">Client ID</Label>
                            <Value>{clientId}</Value>
                        </Row>
                    </form>
                </CustomerInformation>
                <RestartContacts>
                    <ContactRow><span style={{ fontSize: "1.7em", fontWeight: "bold" }}>Main Restart Contacts</span></ContactRow>
                    <ContactRow style={{ marginTop: "10px" }}>
                        <ContactCellLabel>Sales</ContactCellLabel>
                        {!isEditing && <ContactCell>{getUserById(salesUser, users)}</ContactCell>}
                        {isEditing && <Select height="24px" fontSize={"0.7em"} required={false} placeholder="Select a user" onChange={onChangeSalesUser} childValues={users && users.map(option => option.id)} value={getUserById(salesUser, users)}>
                            {users && users.map((option, index) => <SelectItem fontSize="0.7em" height="24px" key={`restart-contacts-user-sales-${index}`} value={option.id}>{option.fullName}</SelectItem>)}
                        </Select>}
                    </ContactRow>
                    <ContactRow>
                        <ContactCellLabel>Project Manager</ContactCellLabel>
                        {!isEditing && <ContactCell>{getUserById(managerUser, users)}</ContactCell>}
                        {isEditing && <Select height="24px" fontSize={"0.7em"} required={false} placeholder="Select a user" onChange={onChangeProjectManagerUser} childValues={users && users.map(option => option.id)} value={getUserById(managerUser, users)}>
                            {users && users.map((option, index) => <SelectItem height="20px" key={`restart-contacts-user-manager-${index}`} value={option.id}>{option.fullName}</SelectItem>)}
                        </Select>}
                    </ContactRow>
                    <ContactRow>
                        <ContactCellLabel>Architect</ContactCellLabel>
                        {!isEditing && <ContactCell>{getUserById(archUser, users)}</ContactCell>}
                        {isEditing && <Select height="24px" fontSize={"0.7em"} required={false} placeholder="Select a user" onChange={onChangeArchUser} childValues={users && users.map(option => option.id)} value={getUserById(archUser, users)}>
                            {users && users.map((option, index) => <SelectItem height="20px" key={`restart-contacts-user-arch-${index}`} value={option.id}>{option.fullName}</SelectItem>)}
                        </Select>}
                    </ContactRow>
                    <ContactRow>
                        <ContactCellLabel>Lead developer</ContactCellLabel>
                        {!isEditing && <ContactCell>{getUserById(devUser, users)}</ContactCell>}
                        {isEditing && <Select height="24px" fontSize={"0.7em"} required={false} placeholder="Select a user" onChange={onChangeDevUser} childValues={users && users.map(option => option.id)} value={getUserById(devUser, users)}>
                            {users && users.map((option, index) => <SelectItem height="20px" key={`restart-contacts-user-dev-${index}`} value={option.id}>{option.fullName}</SelectItem>)}
                        </Select>}
                    </ContactRow>
                </RestartContacts>
                <OpportunitiesSmallLogo />
            </CustomerHeader>

            <Dialog style={{ padding: "32px" }} open={showDeleteDialog} onClose={onCloseDeleteDialog}>
                <h3>Are you sure you want to delete?</h3>
                <ActionContainer>
                    <Button color={COLORS.FOURTH} onClick={onCloseDeleteDialog} style={{ marginRight: "10px" }}>Cancel</Button>
                    <Button onClick={onDelete} color={"#e60000"}>Delete</Button>
                </ActionContainer>
            </Dialog>

            <SeparatorRow />
            <Row>
                <TitleAndButton>
                    <h3 style={{ marginRight: "20px" }}>Linked Customers</h3>
                    {isEditing && <Button color={COLORS.GREY_SLATE} onClick={addLinkedCustomer}>Add Linked Customer</Button>}
                </TitleAndButton>
            </Row>
            <Row>
                <LinkedCustomerContainer>
                    {linkedCustomers && linkedCustomers.map(renderLinkedCustomer)}
                    {existingLinkedCustomers.map(renderLinkedCustomerItem)}
                </LinkedCustomerContainer>
            </Row>
            <SeparatorRow />
            <Row>
                <TitleAndButton>
                    <h3 style={{ marginRight: "20px" }}>Replaces</h3>
                    {isEditing && <Button color={COLORS.GREY_SLATE} onClick={addCustomerToReplace}>Add customer to replace</Button>}
                </TitleAndButton>
            </Row>
            <Row>
                <LinkedCustomerContainer>
                    {replaces && replaces.map(renderReplaces)}
                    {customer.replacedBy && renderReplaced(customer.replacedBy)}
                    {existingCustomers.map(renderReplaceCustomerItem)}
                </LinkedCustomerContainer>
            </Row>
            <SeparatorRow />
            <Row>
                <Title>Integration Engine Details</Title>
            </Row>
            <Row>
                <table width={"100%"}>
                    {integrationEngines && integrationEngines.map(renderIntegrationEngine)}
                </table>
            </Row>
            <Row>
                {isEditing && <Button color={COLORS.GREY_SLATE} onClick={addNewIE}>Add New IE</Button>}
            </Row>
            <SeparatorRow />
            <Row>
                <Title>Support Contract Details</Title>
                {timeRemainingYears <= 0 && timeRemainingMs < 0 && <SupportStatusContainer style={{ backgroundColor: "#FF0000" }}>Expired</SupportStatusContainer>}
                {timeRemainingYears === 0 && timeRemainingMs > 0 && <SupportStatusContainer style={{ backgroundColor: "#E4D00A" }}>Less than 1 Year Remaining</SupportStatusContainer>}
                {timeRemainingYears > 1 && <SupportStatusContainer>{timeRemainingYears}+ Year Remaining</SupportStatusContainer>}
            </Row>
            <Row>
                <Label htmlFor="integrationEngineDetails">Contract Type</Label>
                {support && <Value>{support.contractType}</Value>}
            </Row>
            <Row>
                <Label htmlFor="integrationEngineDetails">Start Date</Label>
                {support && <Value>{dateConverter(support.startDate)}</Value>}
            </Row>
            <Row>
                <Label htmlFor="integrationEngineDetails">End Date</Label>
                {support && <Value>{dateConverter(support.endDate)}</Value>}
            </Row>
            <SeparatorRow />

            {/* 
             not for this release
            <Row>
                <Title>Call Off Contracts</Title>
            </Row>
            <Row>
                <table style={{ width: "60%" }}>
                    <tbody>
                        <tr style={{ fontWeight: "bold", display: "flex" }} >
                            <TableCell innerStyle={{ fontWeight: "bold" }} noBorder={true} width={"20%"}>Quote</TableCell>
                            <TableCell innerStyle={{ textAlign: "center", fontWeight: "bold" }} noBorder={true} width={"30%"}>Original Pot Size</TableCell>
                            <TableCell innerStyle={{ textAlign: "center", fontWeight: "bold" }} noBorder={true} width={"30%"}>Days Remaining</TableCell>
                            <TableCell innerStyle={{ textAlign: "center", fontWeight: "bold" }} noBorder={true} width={"20%"}>% Remaining</TableCell>
                        </tr>
                        {customer.callOffContracts && customer.callOffContracts.map((contract, index) => {
                            return <tr key={`contact-entry=${index}`} style={{ display: "flex", borderTop: "1px solid grey" }}>
                                <TableCell noBorder={true} width={"20%"}>{contract.quoteNumber}</TableCell>
                                <TableCell innerStyle={{ textAlign: "center" }} noBorder={true} width={"30%"}>{contract.originalPotSize}</TableCell>
                                <TableCell innerStyle={{ textAlign: "center" }} noBorder={true} width={"30%"}>{contract.daysRemaining}</TableCell>
                                <TableCell innerStyle={{ textAlign: "center" }} noBorder={true} width={"20%"}>{contract.percentRemaining}</TableCell>
                            </tr>
                        })}
                    </tbody>
                </table>
            </Row> 
                    <SeparatorRow />
            */}

            <Row>
                <TitleAndButton>
                    <h3 style={{ marginRight: "20px" }}>Contacts</h3>
                    {isEditing && <Button color={COLORS.FOURTH} onClick={addContactDetails}>Add Contact</Button>}
                </TitleAndButton>
            </Row>
            <Row>
                {contactDetails && <Table>
                    <tbody>
                        <tr style={{ display: "flex" }} >
                            <TableCell innerStyle={{ fontWeight: "bold" }} noBorder={true} width={"15%"}>Name</TableCell>
                            <TableCell innerStyle={{ fontWeight: "bold" }} noBorder={true} width={"10%"}>Job title</TableCell>
                            <TableCell innerStyle={{ fontWeight: "bold" }} noBorder={true} width={"10%"}>Email</TableCell>
                            <TableCell innerStyle={{ fontWeight: "bold" }} noBorder={true} width={"8%"}>Phone</TableCell>
                            <TableCell innerStyle={{ fontWeight: "bold" }} noBorder={true} width={"14%"}>Visible to Dev</TableCell>
                            <TableCell innerStyle={{ fontWeight: "bold" }} noBorder={true} width={"8%"}>Source</TableCell>
                            <TableCell innerStyle={{ fontWeight: "bold" }} noBorder={true} width={"15%"}>Quote Recipients</TableCell>
                            <TableCell innerStyle={{ fontWeight: "bold" }} noBorder={true} width={"20%"}></TableCell>
                        </tr>
                        {contactDetails.map((contact, index) => {
                            const id = contact.id ? contact.id : none;
                            if (editing === id) {
                                return <tr key={`contact-entry=${index}`} style={{ display: "flex" }}>
                                    <TableCell noBorder={true} width={"15%"}>
                                        <TextInput width="80" onKeyDown={handleKeyboardSelect(saveContactDetails, false)} value={contactName} onChange={updateText(setContactName)} placeholder="Name" />
                                    </TableCell>
                                    <TableCell noBorder={true} width={"10%"}>
                                        <TextInput onKeyDown={handleKeyboardSelect(saveContactDetails, false)} value={contactTitle} onChange={updateText(setContactTitle)} placeholder="Job title" />
                                    </TableCell>
                                    <TableCell noBorder={true} width={"10%"}>
                                        <TextInput onKeyDown={handleKeyboardSelect(saveContactDetails, false)} value={contactEmail} onChange={updateText(setContactEmail)} placeholder="Email" />
                                    </TableCell>
                                    <TableCell noBorder={true} width={"8%"}>
                                        <TextInput onKeyDown={handleKeyboardSelect(saveContactDetails, false)} value={contactPhone} onChange={updateText(setContactPhone)} placeholder="Phone" />
                                    </TableCell>
                                    <TableCell noBorder={true} width={"14%"}>
                                        <Checkbox checked={contactVisibleToDev} onChange={onChangeContactVisibleToDev} />
                                    </TableCell>
                                    <TableCell noBorder={true} width={"8%"}>{contactSource}</TableCell>
                                    <TableCell noBorder={true} width={"15%"}>
                                        <Checkbox checked={contactQuoteRecipient} onChange={onChangeContactQuoteRecipient} />
                                    </TableCell>
                                    <TableCell noBorder={true} width={"20%"}>
                                        <ButtonContainer>
                                            {rbac.update && <Button plain={true} onClick={saveContactDetails} style={{ marginRight: "10px" }}>Save</Button>}
                                            {rbac.update && <Button plain={true} onClick={cancelEditContact}>Cancel</Button>}
                                        </ButtonContainer>
                                    </TableCell>
                                </tr>;
                            } else {
                                return <tr key={`contact-entry=${index}`} style={{ display: "flex" }}>
                                    <TableCell noBorder={true} width={"15%"}>{contact.name}</TableCell>
                                    <TableCell noBorder={true} width={"10%"}>{contact.jobTitle}</TableCell>
                                    <TableCell noBorder={true} width={"10%"}>{contact.email}</TableCell>
                                    <TableCell noBorder={true} width={"8%"}>{contact.phone}</TableCell>
                                    <TableCell noBorder={true} width={"14%"}>{contact.visibleToDev ? "Yes" : "No"}</TableCell>
                                    <TableCell noBorder={true} width={"8%"}>{contact.source}</TableCell>
                                    <TableCell noBorder={true} width={"15%"}>{contact.quoteRecipient ? "Primary" : "Additional"}</TableCell>
                                    <TableCell noBorder={true} width={"20%"}>
                                        {editing === none &&
                                            <ButtonContainer>
                                                {rbac.update && isEditing && <Button plain={true} onClick={editContact(contact)} style={{ marginRight: "10px" }}>Edit</Button>}
                                                {rbac.update && isEditing && <Button plain={true} onClick={onRemove(id)}>Remove</Button>}
                                            </ButtonContainer>
                                        }
                                    </TableCell>
                                </tr>;
                            }
                        })}
                    </tbody>
                </Table>}
            </Row>
            <SeparatorRow />
            <Row>
                <Title>Address for Quotes</Title>
            </Row>
            <Row>
                <Label htmlFor="integrationEngineDetails">Address</Label>
                {!isEditing && <Value>{addressForQuotes}</Value>}
                {isEditing && <TextInput required={false} textArea={true} htmlId="addressForQuotes" value={addressForQuotes || ""} onChange={updateText(setAddressForQuotes)} />}
            </Row>
            <SeparatorRow />
            <Row>
                <TitleAndButton>
                    <h3 style={{ marginRight: "20px" }}>Notes</h3>
                    {isEditing && <Button color={COLORS.GREY_SLATE} onClick={AddNote}>Add Note</Button>}
                </TitleAndButton>
            </Row>
            <Row style={{ display: "flex", flexDirection: "column", width: "100%", marginBottom: "50px" }}>
                {notes.map(renderNotes)}
            </Row>
        </Main>
    </OutsideContainer >
};

export default CustomerEdit;
