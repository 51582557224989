import * as React from "react";
import styled from "styled-components";
import { SessionContext } from "../../SessionContext";


import ErrorText from "../../../SharedComponents/ErrorText";
import DynamicSelect from "../../../SharedComponents/Select/DynamicSelect";
import Select from "../../../SharedComponents/Select/Select";
import SelectItem from "../../../SharedComponents/Select/SelectItem";
import TextInput from "../../../SharedComponents/TextInput";

import ICustomer from "../../../ServerEntities/ICustomer";

import IQuote from "../../../ServerEntities/IQuote";
import IQuoteType from "../../../ServerEntities/IQuoteType";
import { getCustomerIdBy, getTypeById, getTypeId } from "../../../UsefulFunctions/opportunityUtils";
import dateConverter from "../../../UsefulFunctions/dateConverter";
import { createQuoteNumberFinal, getRelatedQuotes, saveQuote } from "../Quotes/QuotesService";

import IUser from "../../../ServerEntities/IUser";
import { getUserById, getUserObjectById } from "../../../UsefulFunctions/quoteUtils";
import { COLORS } from "../../../config";
import { ButtonContainer } from "../Customers/CustomerEdit";
import IRelatedQuote from "../../../ServerEntities/IRelatedQuote";
import Button from "../../../SharedComponents/Button";
import { SeparatorRow } from "./OpportunityAdd";
import useEffectOnSome from "../../../CustomHooks/useEffectOnSome";

export const MoreInformation = styled.div`
    display: flex;
    flex-direction: column;
`;

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

const Label = styled.label`
    line-height: 32px;
    min-width: 200px;
    font-weight: bold;
    font-size: 14px;
`;

const Value = styled.div`
    line-height: 32px;
    font-size: 12px;
`;

const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

interface IProps {
    types: IQuoteType[]
    customers: ICustomer[],
    users: IUser[],
    quote: IQuote,
    editable?: boolean,
    onUpdateQuote?: (quote: IQuote) => void,
    onLoading: () => void,
    onFinishedLoading: () => void
};

const QuoteDetails = (props: IProps) => {
    const { state } = React.useContext(SessionContext);
    const [quote, setQuote] = React.useState(props.quote);
    const quoteTypes = props.types;
    const { customers, users, editable } = props;
    const [customer, setCustomer] = React.useState(quote.customer);
    const [projectName, setProjectName] = React.useState(quote.projectName);
    const [type, setType] = React.useState(getTypeById(quote.typeId, quoteTypes));
    const [projectValue, setProjectValue] = React.useState(quote.projectValue);
    const [relatedQuotes, setRelatedQuotes] = React.useState(quote.relatedQuotes);
    const [relatedQuote, setRelatedQuote] = React.useState("");
    const [quotes, setQuotes] = React.useState(null as unknown as string[]);
    const enteredDate = quote.enteredDate;
    const [error, setError] = React.useState("");

    useEffectOnSome(() => {
        const customerId = getCustomerIdBy(customer, customers);
        if (customerId !== undefined) {
            props.onLoading();
            getRelatedQuotes(state.webToken, customerId, (quotes: string[]) => {
                props.onFinishedLoading();
                setError("");
                setQuotes(quotes);
            }, (errorMessage: string) => {
                props.onFinishedLoading();
                setError(errorMessage);
                setQuotes([]);
            });
        }
    }, [customer]);

    const updateQuote = (updatedQuote: IQuote) => {
        setQuote(updatedQuote);
        if (props.onUpdateQuote) {
            props.onUpdateQuote(updatedQuote);
        }
    }

    const onChangeCustomer = (customer: string) => {
        setCustomer(customer);
        quote.customer = customer;
        const customerId = getCustomerIdBy(customer, customers);
        quote.customerId = customerId;
        updateQuote({ ...quote })
    };

    const onChangeType = (type: string) => {
        setType(type);
        quote.typeId = getTypeId(type, quoteTypes);
        updateQuote({ ...quote })
    };

    const onChangeRelatedQuote = (quoteId: string) => {
        setRelatedQuote(quoteId)
        if ((quoteId !== "") && (relatedQuotes.filter((relQuote) => relQuote.quoteNumber === quoteId).length === 0)) {
            const newRelQuote: IRelatedQuote = { "id": 0, "parentQuoteId": 0, "quoteNumber": quoteId, "quoteDetails": "" }
            relatedQuotes.push(newRelQuote);
            quote.relatedQuotes = relatedQuotes;
            updateQuote({ ...quote })
        }
    }

    const onChangeRelatedQuoteDetail = (quoteId: string) => (e: React.SyntheticEvent<HTMLInputElement>) => {
        const newRelatedQuotes: IRelatedQuote[] = relatedQuotes.map((relQuote: IRelatedQuote) => {
            if ((relQuote.quoteNumber) === quoteId) {
                return { ...relQuote, quoteDetails: e.currentTarget.value }
            } else { return relQuote }
        });
        setRelatedQuotes(newRelatedQuotes);
        quote.relatedQuotes = newRelatedQuotes;
        updateQuote({ ...quote })
    }

    const onRemoveRelatedQuote = (quoteId: String) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const newRelatedQuotes: IRelatedQuote[] = relatedQuotes.filter((relQuote: IRelatedQuote) => relQuote.quoteNumber !== quoteId);
        setRelatedQuotes(newRelatedQuotes);
        quote.relatedQuotes = newRelatedQuotes;
        updateQuote({ ...quote })
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    const onChangeProjectName = (e: React.SyntheticEvent<HTMLInputElement>) => {
        setProjectName(e.currentTarget.value);
        quote.projectName = e.currentTarget.value;
        updateQuote({ ...quote })
    }

    const onChangeProjectValue = (e: React.SyntheticEvent<HTMLInputElement>) => {
        setProjectValue(e.currentTarget.value);
        quote.projectValue = e.currentTarget.value;
        updateQuote({ ...quote })
    }

    const onCreateQuote = () => {
        const owner = getUserObjectById(state.loggedInUser, users);
        if (quote.quoteNumber) {
            quote.status = "Saved";
            setQuote({ ...quote });
            updateQuote({ ...quote })
            createQuoteNumberFinal(state.webToken, owner ? owner.initials : "", quote, (createdQuote: IQuote) => {
                setError("");
                setQuote(createdQuote);
                updateQuote(createdQuote)
            }, (errorMessage: string) => {
                setError(errorMessage);
            });
        }
        else {
            saveQuote(state.webToken, owner ? owner.initials : "", quote, (createdQuote: IQuote) => {
                setError("");
                createdQuote.status = "Saved";
                setQuote(createdQuote);
                updateQuote(createdQuote)
            }, (errorMessage: string) => {
                setError(errorMessage);
            });
        }
    }

    const validate = () => {
        if (quote.customerId && quote.typeId) {
            return true;
        }
        return false;
    };

    return <MainContainer>
        {error && <ErrorText>{error}</ErrorText>}
        <ColumnContainer>
            <Row>
                <Label htmlFor="customer">Customer</Label>
                {editable && <DynamicSelect placeholder="Select a customer" onChange={onChangeCustomer} childValues={customers && customers.map(option => option.customerName)} value={customer}>
                    {customers && customers.filter((obj: { status: boolean; }) => { return obj.status === true }).map((option, index) => <SelectItem height="20px" key={`add-quote-customer-item-${index}`} value={option.customerName}>{option.customerName}</SelectItem>)}
                </DynamicSelect>}
                {!editable && <Value>{customer}</Value>}
            </Row>
            <Row>
                <Label htmlFor="projectName">Project name</Label>
                {editable && <TextInput width={"500px"} fontSize="12px" htmlId="location" value={projectName} onChange={onChangeProjectName} placeholder="Project name" />}
                {!editable && <Value>{projectName}</Value>}
            </Row>
            <Row>
                <Label htmlFor="type">Type</Label>
                {editable && <Select placeholder="Select a type" onChange={onChangeType} childValues={quoteTypes && quoteTypes.map(option => option.description)} value={type}>
                    {quoteTypes && quoteTypes.map((option, index) => <SelectItem height="20px" key={`add-quote-type-item-${index}`} value={option.description}>{option.description}</SelectItem>)}
                </Select>}
                {!editable && <Value>{type}</Value>}
            </Row>
            <Row>
                <Label htmlFor="orderOwner">Opportunity owner</Label>
                <Value>{getUserById(quote.orderOwner, users)}</Value>
            </Row>
            <Row>
                <Label htmlFor="dateAdded">Date Added</Label>
                <Value>{dateConverter(enteredDate)}</Value>
            </Row>


            {editable && quote.status === "temp" && <Row>
                <SeparatorRow />
                <ColumnContainer style={{ width: "50%" }}>
                    {/* <Row>
                        <Label htmlFor="type">Opportunity ID</Label>
                        <Value>{quote.quoteNumber}</Value>
                    </Row> */}
                    <Row>
                        <Label htmlFor="projectValue">Project value</Label>
                        {editable && <TextInput width={"200px"} fontSize="12px" htmlId="projectValue" value={projectValue} onChange={onChangeProjectValue} placeholder="Project value" />}
                        {!editable && <Value>{projectValue}</Value>}
                    </Row>
                    <Row>
                        <Button disabled={!validate()} submit={false} color={COLORS.GREY_SLATE} plain={false} onClick={onCreateQuote}>Add Quote for this Opp</Button>

                    </Row>
                </ColumnContainer>
            </Row>}
            {quote.status !== "temp" && <MoreInformation>
                <ColumnContainer style={{ width: "100%" }}>
                    <Row>
                        <h3>Quote Details</h3>
                    </Row>
                </ColumnContainer>
                <ColumnContainer style={{ width: "100%" }}>
                    <Row>
                        <Label htmlFor="type">Quote number</Label>
                        <Value>{quote.quoteNumberFinal}</Value>
                    </Row>
                    <Row>
                        <Label htmlFor="projectValue">Project value</Label>
                        {editable && <TextInput width={"200px"} fontSize="12px" htmlId="projectValue" value={projectValue} onChange={onChangeProjectValue} placeholder="Project value" />}
                        {!editable && <Value>{projectValue}</Value>}
                    </Row>
                </ColumnContainer>
                {editable && <ColumnContainer style={{ width: "100%" }}>
                    <SeparatorRow />
                    <Row>
                        <Label>Related Quotes</Label>
                    </Row>
                    <Row>
                        <Value>
                            <DynamicSelect placeholder="Select a related quote" onChange={onChangeRelatedQuote} childValues={quotes && quotes.map(option => option)} value={relatedQuote}>
                                {quotes && quotes.map((option, index) => <SelectItem height="20px" key={`add-related-quote-item-${index}`} value={option}>{option}</SelectItem>)}
                            </DynamicSelect>
                        </Value>
                    </Row>
                    <Row style={{ height: "15px" }} />
                    {relatedQuotes.length === 0 &&
                        <Row><Value>No related quotes have been added</Value></Row>
                    }
                    {relatedQuotes.length > 0 &&
                        <Row>
                            <Label style={{ flex: "1 1 0" }}>Quote Number</Label>
                            <Label style={{ flex: "4 1 0" }}>Reason for linking this quote</Label>
                            <Label style={{ flex: "1 1 0" }}></Label>
                        </Row>
                    }
                    {relatedQuotes.map((relQuote) => {
                        return <Row key={`related-quote-row-${relQuote.quoteNumber}`}>
                            <Label style={{ flex: "1 1 0", fontSize: "12px", fontWeight: "400", alignItems: "center" }}>{relQuote.quoteNumber}</Label>
                            <Value style={{ flex: "4 1 0" }}>
                                <div style={{ display: "flex", height: "100%", width: "100%", alignItems: "center", textAlign: "center" }}>
                                    <TextInput width="90%" fontSize="inherit" required={false} value={relQuote.quoteDetails} onChange={onChangeRelatedQuoteDetail(relQuote.quoteNumber)} placeholder="Quote details" />
                                </div>
                            </Value>
                            <Value style={{ flex: "1 1 0" }}>
                                <ButtonContainer>
                                    <Button color={COLORS.GREY_SLATE} onClick={onRemoveRelatedQuote(relQuote.quoteNumber)}>Remove</Button>
                                </ButtonContainer>
                            </Value>
                        </Row>
                    })}
                </ColumnContainer>}
                {!editable && relatedQuotes.length > 0 && <ColumnContainer>
                    <Row>
                        <Label>Related Quotes</Label>
                    </Row>
                    <Row>
                        <Label>Quote Number</Label>
                        <Label>Details</Label>
                    </Row>
                    {relatedQuotes.map((relQuote) => {
                        return <Row><Label style={{ fontWeight: "400" }}>{relQuote.quoteNumber}</Label><Value>{relQuote.quoteDetails}</Value></Row>
                    })}
                </ColumnContainer>}
            </MoreInformation>}
        </ColumnContainer>
    </MainContainer >
};

export default QuoteDetails;