import * as React from "react";
import { useParams } from "react-router-dom";
import ErrorText from "../../../SharedComponents/ErrorText";
import Header from "../../../SharedComponents/Header";
import LoadingIndicator from "../../../SharedComponents/LoadingIndicator";
import { SessionContext } from "../../SessionContext";
import IWorkPackage from "../../../ServerEntities/IWorkPackage";
import WorkPackageEdit from "./WorkPackageEdit";
import { COLORS } from "../../../config";
import { getWorkPackages, getWorkPackage, getArchivedWorkPackages, getUserWorkPackages } from "./WorkPackageService";
import WorkPackagesTable from "./WorkPackageTable";
import ICustomer from "../../../ServerEntities/ICustomer";
import IQuoteType from "../../../ServerEntities/IQuoteType";
import { getCustomers } from "../Customers/CustomersService";
import { getQuoteTypes } from "../Quotes/QuotesService";
import { getUsers } from "../Components/UsersService";
import IUser from "../../../ServerEntities/IUser";
import Banner from "../../../SharedComponents/Banner";
import Pages from "../../../SharedComponents/Pages";
import Tools from "../../../SharedComponents/Tools";
import { LeftColumn, RightColumn } from "../Quotes/QuotesIndex";
import WorkPackageHistory from "./WorkPackageHistory";
import { pageConfiguration } from "../../../UsefulFunctions/getComponentConfiguration";
import { RolesContext } from "../../../Roles/RolesContext";
import IPage from "../../../ServerEntities/IPage";
import useEffectOnSome from "../../../CustomHooks/useEffectOnSome";

const WorkPackagesIndex = () => {
    const { workPackageId } = useParams();
    const { state } = React.useContext(SessionContext);
    const [isAdding, setIsAdding] = React.useState(false);
    const [isEditing, setIsEditing] = React.useState(null as unknown as IWorkPackage | null);
    const [isViewing, setIsViewing] = React.useState(null as unknown as IWorkPackage | null);
    const [isReviewing, setIsReviewing] = React.useState(null as unknown as IWorkPackage | null);
    const [isHistory, setIsHistory] = React.useState(null as unknown as IWorkPackage | null);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [workPackages, setWorkPackages] = React.useState(null as unknown as IWorkPackage[]);
    const [quoteTypes, setQuoteTypes] = React.useState(null as unknown as IQuoteType[]);
    const [users, setUsers] = React.useState(null as unknown as IUser[]);
    const [customers, setCustomers] = React.useState(null as unknown as ICustomer[]);
    const [refresh, setRefresh] = React.useState(0);
    const [viewAllScoping, setViewAllScoping] = React.useState(false);
    const [viewArchivedScoping, setViewArchivedScoping] = React.useState(false);

    const { configuration } = React.useContext(RolesContext).state;
    const [pages, setPages] = React.useState(null as unknown as IPage[]);

    const onBack = () => {
        setIsAdding(false);
        setIsEditing(null);
        setIsViewing(null);
        setIsReviewing(null);
    };

    React.useEffect(() => {
        const onViewAllScoping = () => {
            setViewAllScoping(true);
            setViewArchivedScoping(false);
            onBack();
            setRefresh(refresh + 1);
        };

        const onViewUserScoping = () => {
            setViewAllScoping(false);
            setViewArchivedScoping(false);
            onBack();
            setRefresh(refresh + 1);
        };

        const onViewArchivedScoping = () => {
            setViewArchivedScoping(true);
            onBack();
            setRefresh(refresh + 1);
        };

        const pageList = [
            { page: "All Scoping", onClick: onViewAllScoping },
            { page: "Archived Scoping", onClick: onViewArchivedScoping },
            { page: "My Scoping", onClick: onViewUserScoping }
        ];

        let newPageList = [];
        for (let i = 0; i < pageList.length; i++) {
            let config = pageConfiguration(pageList[i].page, configuration);
            if (config !== undefined) {
                newPageList.push(pageList[i])
            }
        }
        setPages(newPageList);
    }, [configuration, refresh]);

    useEffectOnSome(() => {
        setLoading(true);
        if (viewArchivedScoping) {
            getArchivedScoping();
        } else if (viewAllScoping) {
            getAllScoping();
        }
        else {
            getUserScoping();
        }
    }, [refresh, state.loggedInUser, viewAllScoping, viewArchivedScoping]);

    const getAllScoping = () => {
        getWorkPackages(state.webToken, (WorkPackages: IWorkPackage[]) => {
            setLoading(false);
            setError("");
            setWorkPackages(WorkPackages);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
            setWorkPackages(null as unknown as IWorkPackage[]);
        });
    }

    const getArchivedScoping = () => {
        getArchivedWorkPackages(state.webToken, (WorkPackages: IWorkPackage[]) => {
            setLoading(false);
            setError("");
            setWorkPackages(WorkPackages);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
            setWorkPackages(null as unknown as IWorkPackage[]);
        });
    }

    const getUserScoping = () => {
        getUserWorkPackages(state.loggedInUser, state.webToken, (WorkPackages: IWorkPackage[]) => {
            setLoading(false);
            setError("");
            setWorkPackages(WorkPackages);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
            setWorkPackages(null as unknown as IWorkPackage[]);
        });
    }

    React.useEffect(() => {
        if (workPackageId && isViewing == null) {
            setLoading(true);
            getWorkPackage(parseInt(workPackageId), state.webToken, (WorkPackage: IWorkPackage) => {
                setLoading(false);
                setError("");
                setIsViewing(WorkPackage);
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
                setIsViewing(null as unknown as IWorkPackage);
            });
        }
    }, [workPackageId, isViewing]);

    useEffectOnSome(() => {
        if (quoteTypes == null) {
            setLoading(true);
            getQuoteTypes(state.webToken, (types: IQuoteType[]) => {
                setLoading(false);
                setError("");
                setQuoteTypes(types);
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
                setQuoteTypes(null as unknown as IQuoteType[]);
            });
        }
        getUsers(state.webToken, (users: IUser[]) => {
            setLoading(false);
            setError("");
            setUsers(users);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
            setUsers(null as unknown as IUser[]);
        });
        getCustomers(state.webToken, (customers: ICustomer[]) => {
            setLoading(false);
            setError("");
            setCustomers(customers);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
            setCustomers(null as unknown as ICustomer[]);
        });
    }, [quoteTypes]);

    const openAddScreen = () => {
        setIsAdding(true);
    };

    const openEditScreen = (WorkPackage: IWorkPackage) => {
        setIsEditing(WorkPackage);
    };

    const openReviewScreen = (WorkPackage: IWorkPackage) => {
        setIsReviewing(WorkPackage);
    };

    const openHistoryScreen = (workPackage: IWorkPackage) => {
        setIsHistory(workPackage);
    };

    const openViewScreen = (WorkPackage: IWorkPackage) => {
        setIsViewing(WorkPackage);
    };

    const closeScreen = () => {
        setRefresh(refresh + 1);
        setIsAdding(false);
        setIsReviewing(null);
        setIsEditing(null);
        setIsViewing(null);
    };

    const onRefresh = () => {
        setRefresh(refresh + 1);
    };

    const getComponent = (isEditing: IWorkPackage | null, isViewing: IWorkPackage | null, isHistory: IWorkPackage | null,) => {
        if (isHistory) {
            return <WorkPackageHistory users={users} workPackage={isHistory} />;
        }
        else if (isViewing) {
            return <WorkPackageEdit review={false} users={users} types={quoteTypes} customers={customers} onAddComplete={closeScreen} workPackage={isViewing} onViewHistory={openHistoryScreen} />;
        }
        else if (isEditing) {
            return <WorkPackageEdit review={false} users={users} types={quoteTypes} customers={customers} onAddComplete={closeScreen} workPackage={isEditing} onViewHistory={openHistoryScreen} />;
        }

        else if (isReviewing) {
            return <WorkPackageEdit review={true} users={users} types={quoteTypes} customers={customers} onAddComplete={closeScreen} workPackage={isReviewing} onViewHistory={openHistoryScreen} />;
        }
        return <WorkPackagesTable
            onRefresh={onRefresh}
            onAdd={openAddScreen}
            onEdit={openEditScreen}
            onReview={openReviewScreen}
            onDelete={onRefresh}
            onView={openViewScreen}
            users={users}
            workPackages={workPackages} />;
    };

    const getBannerTitle = () => {
        if (isAdding) {
            return "Add Scoping";
        } else
            if (isEditing) {
                return "Edit Scoping";
            } else
                if (isHistory) {
                    return "Change History - Scoping";
                }
        return "Scoping"
    };





    return <div style={{ position: "absolute", height: "100%", width: "100%", display: "flex", alignItems: "stretch", flexDirection: "column" }}>
        <Header border={COLORS.ARCHITECTURE_BLUE} backgroundColor={COLORS.GREY_SLATE} onBack={onBack} previousPageUrl={isAdding || isEditing || isViewing ? "/Architecture" : "/"} />
        <main style={{ flex: "1", display: "flex", alignItems: "stretch", overflow: "hidden" }}>
            <LeftColumn>
                <Tools bannerColor={COLORS.HEATHER_SLATE} />
                <Pages bannerColor={COLORS.HEATHER_SLATE} backgroundColor={COLORS.ARCHITECTURE_BLUE} pages={pages} />
            </LeftColumn>
            <RightColumn>
                <Banner $backgroundColor={COLORS.HEATHER_SLATE}>{getBannerTitle()}</Banner>
                <LoadingIndicator type="Linear" show={loading} />
                {error && <ErrorText>{error}</ErrorText>}
                {getComponent(isEditing, isViewing, isHistory)}
            </RightColumn>
        </main>
    </div >
};

export default WorkPackagesIndex;
