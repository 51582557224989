import * as React from "react";
import styled from "styled-components";
import { COLORS } from "../../../config";
import { RolesContext } from "../../../Roles/RolesContext";
import ICustomer from "../../../ServerEntities/ICustomer";
import IQuote from "../../../ServerEntities/IQuote";
import Button from "../../../SharedComponents/Button";
import Checkbox from "../../../SharedComponents/Checkbox";
import ErrorText from "../../../SharedComponents/ErrorText";
import LoadingIndicator from "../../../SharedComponents/LoadingIndicator";
import { componentConfiguration } from "../../../UsefulFunctions/getComponentConfiguration";
import dateConverter from "../../../UsefulFunctions/dateConverter";
import { SessionContext } from "../../SessionContext";
import { getCustomers } from "../Customers/CustomersService";
import { getCustomerById, getUserById } from "../../../UsefulFunctions/quoteUtils";
import IUser from "../../../ServerEntities/IUser";
import { MenuItemTagOrName, MenuItem } from "../../MainMenu";
import Dialog from "../../../SharedComponents/Dialog/Dialog";
import { archiveQuote, deleteQuote, getQuoteIndependentOfArchived, unarchiveQuote } from "./QuotesService";
import TextInput from "../../../SharedComponents/TextInput";
import useEffectOnSome from "../../../CustomHooks/useEffectOnSome";

export const Main = styled.main`
    display: flex;
    flex-direction: column;
    flex: 5 1 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

`;

export const FormHeader = styled.div`
    display: flex;
    align-items: center;
    text-align: left;
    font-weight: 700;
    padding: 5px;
`

interface IDepartment {
    backgroundColor: string,
};

export const Department = styled.div<IDepartment>`
    background: ${props => props.backgroundColor};
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 5px;
    padding-left: 40px;
    padding-right: 40px;
`

interface IStatusBlockedContainer {
    backgroundColor: string
};

export const StatusBlockedContainer = styled.div<IStatusBlockedContainer>`
    display: flex;
    background: ${props => props.backgroundColor};
    border-radius: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    align-items: stretch;
`

export const Status = styled.div`
    display: flex;
    width: 100%;
    justify-content: center
    align-items: stretch;
    flex: 4 1 0;
`

export const StatusLabel = styled.label`
    flex: 1 1 0;
    margin-left: 16px;
    color: white; 
    font-weight: 600; 
    font-size: 0.9em;
    display: flex;
    width: 175px;
    padding: 2px;
    padding-left: 5px;
    padding-right: 5px;
`

export const StatusValue = styled.input`
    height: 100%;
    justify-content: center; 
    display: flex;
    flex: 12 1 0;
    background: white; 
    border-radius: 5px; 
    padding: 0;
    padding-left: 10px;
    font-size: 0.9em;
`;

export const Blocked = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex: 1 1 0;
`
export const BlockedLabel = styled.label`
display: flex;
padding: 2px;
padding-left: 5px;
padding-right: 5px;
display: flex;
color: white;
font-weight: 600; 
margin-right: 10px; 
width: auto; 
font-size: 0.9em;            
`

export const Actions = styled.div`
    display: flex;
    padding-top: 10px;
`

export const Label = styled.label`
    color: #333333;
    display: flex;
    width: 175px;
    padding: 2px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 14px;
    font-weight: 700;
    display: flex;
`;

export const Value = styled.div`
    flex: 1 1 0;
    height: 100%;
    font-size: 14px;
    padding: 2px;
    padding-left: 5px;
    padding-right: 5px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-right: 15px;
`;

export const ColumnsContainer = styled.div`
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    flex: 8 1 0;
    overflow-y: scroll;
    margin-bottom: 10px;
    box-sizing: border-box;
    flex-wrap: wrap;
`;

export const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    box-sizing: border-box;
    margin-bottom: 10px;
`;

export const Row = styled.div`
    display: flex;
    width: 100%;
    align-items: stretch;
`;

const QuoteDetailsForm = styled.form`
    display: flex; 
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
    max-height: 100%;
    align-items: stretch;
    margin: 5px;
`

export const ActionContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
`;

interface IProps {
    onEdit: (quote: IQuote) => void,
    onRefresh: () => void,
    onDelete: () => void,
    quoteId: number,
    users: IUser[]
};

const QuoteDetails = (props: IProps) => {
    const { state } = React.useContext(SessionContext);
    const { configuration } = React.useContext(RolesContext).state;
    const rbac = componentConfiguration("Quotes", configuration);
    const { quoteId, users, onRefresh } = props;
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [customers, setCustomers] = React.useState(null as unknown as ICustomer[])
    const [quoteToArchive, setQuoteToArchive] = React.useState(0);
    const [quoteToUnarchive, setQuoteToUnarchive] = React.useState(0);
    const [quoteToDelete, setQuoteToDelete] = React.useState(0);
    const [quote, setQuote] = React.useState(null as unknown as IQuote | null);
    const labelValueFontSize = "14px";

    useEffectOnSome(() => {
        getCustomers(state.webToken, (customers: ICustomer[]) => {
            setLoading(false);
            setError("");
            setCustomers(customers);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
            setCustomers(null as unknown as ICustomer[]);
        });
        if (quoteId) {
            setLoading(true);
            getQuoteIndependentOfArchived(quoteId.toString(), state.webToken, "both", (quote: IQuote) => {
                setLoading(false);
                setError("");
                setQuote(quote);
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
            });
        }
    }, [quoteToArchive, quoteToUnarchive, quoteToDelete]);

    const edit = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (quote) {
            props.onEdit(quote);
        }
    };

    const doNothing = () => { };

    const onCloseDeleteDialog = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setQuoteToDelete(0);
    }

    const onShowDeleteDialog = (quoteId: number) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setQuoteToDelete(quoteId);
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    const onDelete = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setLoading(true);
        deleteQuote(state.webToken, quoteToDelete, () => {
            setLoading(false);
            setError("");
            onRefresh();
            onCloseDeleteDialog();
            props.onDelete();
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    const onCloseArchiveDialog = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setQuoteToArchive(0);
    }

    const onShowArchiveDialog = (quoteId: number) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setQuoteToArchive(quoteId);
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    const onArchive = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setLoading(true);
        archiveQuote(state.webToken, quoteToArchive, () => {
            setLoading(false);
            setError("");
            onRefresh();
            onCloseArchiveDialog();
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    const onCloseUnarchiveDialog = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setQuoteToUnarchive(0);
    }

    const onShowUnarchiveDialog = (quoteId: number) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setQuoteToUnarchive(quoteId);
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    const onUnarchive = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setLoading(true);
        unarchiveQuote(state.webToken, quoteToUnarchive, () => {
            setLoading(false);
            setError("");
            onRefresh();
            onCloseUnarchiveDialog();
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    const determineSourceTypeColour = (sourceType: string | undefined) => {
        switch (sourceType) {
            case "Sales":
                return "yellow";
            case "Projects":
                return "green";
            default:
                return "grey";
        }
    }


    return <Main>
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorText>{error}</ErrorText>}
        <Dialog style={{ padding: "32px" }} open={quoteToArchive > 0} onClose={onCloseArchiveDialog}>
            <h3>Are you sure you want to archive?</h3>
            <ActionContainer>
                <Button color={COLORS.FIFTH} onClick={onCloseArchiveDialog} style={{ marginRight: "10px" }}>Cancel</Button>
                <Button onClick={onArchive} color={"#e60000"}>Archive</Button>
            </ActionContainer>
        </Dialog>
        <Dialog style={{ padding: "32px" }} open={quoteToUnarchive > 0} onClose={onCloseUnarchiveDialog}>
            <h3>Are you sure you want to unarchive?</h3>
            <ActionContainer>
                <Button color={COLORS.FIFTH} onClick={onCloseUnarchiveDialog} style={{ marginRight: "10px" }}>Cancel</Button>
                <Button onClick={onUnarchive} color={"#e60000"}>Unarchive</Button>
            </ActionContainer>
        </Dialog>
        <Dialog style={{ padding: "32px" }} open={quoteToDelete > 0} onClose={onCloseDeleteDialog}>
            <h3>Are you sure you want to delete?</h3>
            <ActionContainer>
                <Button color={COLORS.FIFTH} onClick={onCloseDeleteDialog} style={{ marginRight: "10px" }}>Cancel</Button>
                <Button onClick={onDelete} color={"#e60000"}>Delete</Button>
            </ActionContainer>
        </Dialog>
        {quote && <QuoteDetailsForm>
            <div style={{ display: "flex", flex: "1 1 0" }}>
                <div style={{ display: "flex", flexDirection: "column", flex: "7 1 0", alignItems: "stretch" }}>
                    <StatusBlockedContainer backgroundColor={COLORS.GREY_SLATE}>
                        <Status>
                            <StatusLabel>Status</StatusLabel>
                            <StatusValue readOnly={true} value={quote.currentStatus ? quote.currentStatus.text : ""}></StatusValue>
                        </Status>
                        <Blocked>
                            <BlockedLabel>Blocked</BlockedLabel>

                            <Checkbox colors={["white", "white", "white"]}
                                checked={quote.currentStatus ? quote.currentStatus.blocked : false} disabled={true} onChange={doNothing}></Checkbox>

                        </Blocked>
                    </StatusBlockedContainer>
                    <Actions>
                        <ButtonContainer>
                            {rbac.update && <Button color={COLORS.GREY_SLATE} onClick={edit}>Edit Quote</Button>}
                        </ButtonContainer>
                        {!quote.archived && <ButtonContainer>
                            {rbac.update && <Button color={COLORS.GREY_SLATE} onClick={onShowArchiveDialog(quote.id)}>Archive Quote</Button>}
                        </ButtonContainer>}
                        {quote.archived && <ButtonContainer>
                            {rbac.update && <Button color={COLORS.GREY_SLATE} onClick={onShowUnarchiveDialog(quote.id)}>Unarchive Quote</Button>}
                        </ButtonContainer>}
                        <ButtonContainer>
                            {rbac.update && <Button color={COLORS.GREY_SLATE} onClick={onShowDeleteDialog(quote.id)}>Delete Quote</Button>}
                        </ButtonContainer>
                        <ButtonContainer>
                            {rbac.update && <Button color={COLORS.FIFTH} onClick={doNothing}>View Change History</Button>}
                        </ButtonContainer>
                    </Actions>
                </div>
                <div style={{ display: "flex", flex: "1 1 0", justifyContent: "center", alignItems: "center" }} >
                    <MenuItem style={{ maxHeight: "60px", maxWidth: "60px" }} $hover={false} key="quotes-menu-item" onClick={() => null} $backgroundColor={COLORS.GREY_SLATE} $borderColor={COLORS.QUOTES_PINK} $color="#ffffff">
                        <MenuItemTagOrName $fontSize="2em" $fontWeight="600">Qu</MenuItemTagOrName>
                    </MenuItem>
                </div>
            </div>
            <div style={{ alignItems: "center", display: "flex", width: "100%", flex: "1 1 0" }}>
                <FormHeader style={{ width: "49%" }}>Quote Number <span style={{ marginLeft: "30px", }} />{quote.quoteNumberFinal ? quote.quoteNumberFinal : quote.quoteNumber}</FormHeader>
                <Department backgroundColor={determineSourceTypeColour(quote.sourceType)}>{quote.sourceType}</Department>
            </div>
            <ColumnsContainer>
                <ColumnContainer>
                    <Row>
                        <FormHeader>Quote Details</FormHeader>
                    </Row>
                    <Row>
                        <Label>Customer </Label>
                        <Value>{quote.customerId && getCustomerById(quote.customerId, customers)}</Value>
                    </Row>
                    <Row>
                        <Label>Client ID</Label>
                        <Value>{quote.customerId}</Value>
                    </Row>
                    <Row>
                        <Label htmlFor="projectName">Project Name</Label>
                        <Value>{quote.projectName}</Value>
                    </Row>
                    <Row style={{ height: "15px" }} />
                    <Row>
                        <Label htmlFor="projectValue">Project Value</Label>
                        <Value>{quote.projectValue}</Value>
                    </Row>
                    <Row style={{ height: "15px" }} />
                    <Row>
                        <Label>Entered Date</Label>
                        <Value>{dateConverter(quote.enteredDate)}</Value>
                    </Row>
                    <Row>
                        <Label>Expiry</Label>
                        <Value>{dateConverter(quote.expiryDate)}</Value>
                    </Row>
                    <Row style={{ height: "15px" }} />
                    <Row>
                        <Label>Order Owner</Label>
                        <Value>{getUserById(quote.orderOwner, users)}</Value>
                    </Row>
                    <Row>
                        <Label>Order Entered By</Label>
                        <Value>{getUserById(quote.orderEnteredBy, users)}</Value>
                    </Row>
                    <Row style={{ height: "15px" }} />
                    <Row>
                        <Label>Work Authorised By</Label>
                        <Value>{getUserById(quote.workAuthorisedBy, users)}</Value>
                    </Row>
                    <Row>
                        <Label>Work Authorised Date</Label>
                        <Value>{quote.workAuthorisedDate ? dateConverter(quote.workAuthorisedDate) : ""}</Value>
                    </Row>
                    <Row style={{ height: "15px" }} />
                    <Row>
                        <Label>Win Status</Label>
                        <Value>{quote.winStatus}</Value>
                    </Row>
                    <Row>
                        <Label htmlFor="closed">Closed</Label>
                        <div style={{ paddingLeft: "3px", display: "flex", flexDirection: "row", justifyContent: "flex-start", flex: "2 1 0" }}>
                            <Checkbox htmlId="closed" checked={quote.closed} onChange={doNothing} />
                        </div>
                    </Row>
                    <Row>
                        <Label htmlFor="closedDate">Closed Date</Label>
                        <Value>{quote.closedDate && dateConverter(quote.closedDate)}</Value>
                    </Row>
                    <Row />
                </ColumnContainer>
                <ColumnContainer>

                    <Row>
                        <FormHeader>Related Quotes</FormHeader>
                    </Row>
                    <Row>
                        <Label>Quote Number</Label>
                        <Label>Reason for linking this quote</Label>
                    </Row>
                    {quote.relatedQuotes.map((relQuote) => {
                        return <Row key={`${relQuote.quoteNumber}-related-quote`}><Label style={{ fontWeight: "400" }}>{relQuote.quoteNumber}</Label><Value>{relQuote.quoteDetails}</Value></Row>
                    })}
                </ColumnContainer>
                <ColumnContainer style={{ width: "100%" }}>
                    <Row style={{ border: "grey solid 1px", marginLeft: "5px", width: "80%" }} />
                    <Row>
                        <FormHeader>Integration Engine Details</FormHeader>
                    </Row>
                    <Row>
                        <Label>Integration Engine</Label>
                        <Value>{quote.integrationEngine}</Value>
                    </Row>
                    <Row>
                        <Label >Notes</Label>
                        <Value>{quote.integrationEngineNotes}</Value>
                    </Row>
                </ColumnContainer>
                <ColumnContainer style={{ width: "80%" }}>
                    <Row style={{ border: "grey solid 1px", marginLeft: "5px" }} />
                    <Row>
                        <FormHeader>Work Required</FormHeader>
                    </Row>
                    <Row>
                        <Value>
                            <TextInput fontSize={labelValueFontSize} onChange={doNothing} height="150px" disabled={true}
                                noOpacityChange={true} textArea={true} htmlId="workRequired" value={quote.workRequired || ""}></TextInput>
                        </Value>
                    </Row>
                </ColumnContainer>
            </ColumnsContainer>
        </QuoteDetailsForm>
        }
    </Main >
};

export default QuoteDetails;
