import * as React from "react";
import { COLORS } from "../../../config";
import Header from "../../../SharedComponents/Header";
import Banner from "../../../SharedComponents/Banner";
import Pages from "../../../SharedComponents/Pages";
import Tools from "../../../SharedComponents/Tools";
import { LeftColumn, RightColumn } from "../Quotes/QuotesIndex";
import AnnouncementEdit from "./AnnouncementEdit";

const AdminIndex = () => {
    const [isAnnouncements, setIsAnnouncements] = React.useState(true);
    const [refresh, setRefresh] = React.useState(0);

    const openAnnouncements = () => {
        setIsAnnouncements(true);
    };

    const closeAddScreen = () => {
        setRefresh(refresh + 1);
        setIsAnnouncements(false);
    };

    const getComponent = (isAnnouncements: boolean) => {
        if (isAnnouncements) {
            return <AnnouncementEdit onAddComplete={closeAddScreen} />;
        }
    };

    const getBannerTitle = () => {
        if (isAnnouncements) {
            return "Announcements Admin";
        }
        return "Admin"
    }

    const onBack = () => {
        setIsAnnouncements(false);
    }

    const pages = [
        { page: "Announcements Admin", onClick: openAnnouncements }
    ]

    return <div style={{ position: "absolute", height: "100%", width: "100%", display: "flex", alignItems: "stretch", flexDirection: "column" }}>
        <Header backgroundColor={COLORS.GREY_SLATE} border={COLORS.ADMIN_GREEN} onBack={onBack} previousPageUrl={isAnnouncements ? "/" : "/"} />
        <main style={{ flex: "1", display: "flex", alignItems: "stretch", overflow: "hidden" }}>
            <LeftColumn>
                <Tools bannerColor={COLORS.HEATHER_SLATE} />
                <Pages bannerColor={COLORS.HEATHER_SLATE} backgroundColor={COLORS.ADMIN_GREEN} pages={pages} />
            </LeftColumn>
            <RightColumn>
                <Banner $backgroundColor={COLORS.HEATHER_SLATE}>{getBannerTitle()}</Banner>
                {getComponent(isAnnouncements)}
            </RightColumn>
        </main>
    </div >
};

export default AdminIndex;