import * as React from "react";
import { useState } from "react";
import * as ReactDOM from "react-dom";
import styled from "styled-components";

type TooltipPlacement = "bottom-start" | "bottom-end" | "top-start" | "top-end";

interface IProps {
    title: string;
    placement: TooltipPlacement;
    children: React.ReactNode;
    disabled?: boolean;
}

const tooltipRoot = document.getElementById('tooltip-root');

interface ITooltipTextProps {
    x: string,
    y: string
}

const TooltipText = styled.span<ITooltipTextProps>`
    position: absolute;
    background-color: #888888;
    white-space: nowrap;
    border-radius: 3px;
    color: #fff;
    padding: 5px;
    font-size: 11px;
    z-index: 1;
    opacity: 0;
    transform: translate(${props => props.x}, ${props => props.y});
    transition: opacity 0.075s;
    top: 0;
    left: 0;
    visibility: visible;
    &.hover {
        opacity: 1;
        transition: opacity 0.15s;
        transition-delay: 300ms;
    }
    &.disabled {
        opacity: 0;
    }
`;

const TooltipContainer = styled.div`
`;

const calculateX = (containerRef: HTMLDivElement, textRef: HTMLDivElement, placement: TooltipPlacement) => {
    if (containerRef) {
        if (placement === "bottom-start" || placement === "top-start") {
            return containerRef.getBoundingClientRect().left + "px"
        }
        if (placement === "top-end" || placement === "bottom-end") {
            return (containerRef.getBoundingClientRect().right - textRef.getBoundingClientRect().width) + "px"
        }
    }

    return "";
}

const calculateY = (containerRef: HTMLDivElement, placement: TooltipPlacement) => {
    if (containerRef) {
        if (placement === "top-start" || placement === "top-end") {
            return containerRef.getBoundingClientRect().top + 24 + "px"
        }
        if (placement === "bottom-end" || placement === "bottom-start") {
            return containerRef.getBoundingClientRect().bottom + 12 + "px"
        }
    }

    return "";
}

interface ITooltipTextElementProps {
    textRef: React.MutableRefObject<HTMLDivElement>,
    disabled?: boolean,
    title: string,
    containerRef: HTMLDivElement
    placement: TooltipPlacement
    hover: boolean
}

const TooltipTextElement = (props: ITooltipTextElementProps) => {
    if (tooltipRoot) {
        return ReactDOM.createPortal(<TooltipText
            x={calculateX(props.containerRef, props.textRef.current, props.placement)}
            y={calculateY(props.containerRef, props.placement)}
            ref={props.textRef}
            className={`tooltip-text ${props.disabled ? "disabled" : ""} ${props.hover ? "hover" : ""}`}>
            {props.title}
        </TooltipText>, tooltipRoot)
    }
    return null;
}

const Tooltip = (props: IProps) => {
    const [containerRef, setContainerRef] = useState(null as unknown as HTMLDivElement);
    const textRef = React.useRef(null as unknown as HTMLDivElement);
    const [hover, setHover] = useState(false);

    function holdContainerReference(reference: HTMLDivElement) {
        if (reference && containerRef !== reference) {
            setContainerRef(reference);
        }
    }


    function flagHover() {
        setHover(true);
    }

    function unflagHover() {
        setHover(false);
    }



    return <TooltipContainer
        ref={holdContainerReference}
        className={`Tooltip ${props.disabled ? "disabled" : ""} ${hover}`}
    >
        <TooltipTextElement
            textRef={textRef}
            title={props.title}
            disabled={props.disabled}
            placement={props.placement}
            containerRef={containerRef}
            hover={hover}
        />
        <div onMouseEnter={flagHover} onMouseLeave={unflagHover}>{props.children}</div>

    </TooltipContainer>
}

export default Tooltip;
