import axios, { AxiosError, AxiosResponse } from "axios";
import { getAPIUrl } from "../../../config";
import { IDiagram } from "../../../ServerEntities/IDiagram";


export const addDiagram = (webToken: string, diagram: IDiagram, successCallback: (diagram: IDiagram) => void, failureCallback: (error: string) => void) => {
    axios.post(
        `${getAPIUrl()}projects/diagrams`,
        diagram,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IDiagram>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const deleteDiagram = (webToken: string, id: number, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.delete(
        `${getAPIUrl()}projects/diagrams/${id}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(() => {
        successCallback();
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};


export const getCustomerDiagrams = (webToken: string, customerId: number, successCallback: (diagrams: IDiagram[]) => void, failureCallback: (error: string) => void) => {
    return axios.get(
        `${getAPIUrl()}projects/diagrams/${customerId}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IDiagram[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};
