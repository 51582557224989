import * as React from "react";
import styled from "styled-components";
import { SessionContext } from "../../SessionContext";
import Button from "../../../SharedComponents/Button";
import LoadingIndicator from "../../../SharedComponents/LoadingIndicator";
import IOpportunity from "../../../ServerEntities/IOpportunity";
import { addOpportunity, commitOpportunityNotification, deleteOpportunityFile, getOpportunityDocuments, getOpportunityId } from "./OpportunitiesService";
import ErrorText from "../../../SharedComponents/ErrorText";
import { Main } from "./OpportunitiesTable";
import IMeetingInformation from "../../../ServerEntities/IMeetingInformation";
import ICustomerContactDetails from "../../../ServerEntities/ICustomerContactDetails";
import TableCell from "../../../SharedComponents/Table/TableCell";
import { componentConfiguration } from "../../../UsefulFunctions/getComponentConfiguration";
import { RolesContext } from "../../../Roles/RolesContext";
import dateConverter from "../../../UsefulFunctions/dateConverter";
import SelectItem from "../../../SharedComponents/Select/SelectItem";
import IQuote from "../../../ServerEntities/IQuote";
import { STATUS_COMMITED, TYPE_DOCUMENT, canCommitOpportunity, getContactIdByName, getTypeId } from "../../../UsefulFunctions/opportunityUtils";
import Select from "../../../SharedComponents/Select/Select";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { createTriggerCommitOpportunity } from "../../../UsefulFunctions/createTriggers";
import { COLORS, getAPIUrl } from "../../../config";
import ICustomer from "../../../ServerEntities/ICustomer";
import Checkbox from "../../../SharedComponents/Checkbox";
import IQuoteType from "../../../ServerEntities/IQuoteType";
import QuoteDetails from "./QuoteDetails";
import IDocument from "../../../ServerEntities/IDocument";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import IUser from "../../../ServerEntities/IUser";
import { getUserObjectById } from "../../../UsefulFunctions/quoteUtils";
import DocumentBox from "../Components/DocumentBox";
import ISave from "../../../ServerEntities/ISave";
import ImpersonatingRoadblock from "../Components/ImpersonatingRoadblock";
import { ActionContainer, OutsideContainer } from "../Customers/CustomerEdit";
import { OpportunitiesSmallLogo } from "../../MainMenu";
import { getCustomer } from "../Customers/CustomersService";
import IntegrationEngineDetails from "../Components/IntegrationEngineDetails";
import IIntegrationEngine from "../../../ServerEntities/IIntegrationEngine";
import useEffectOnSome from "../../../CustomHooks/useEffectOnSome";
import Dialog from "../../../SharedComponents/Dialog/Dialog";

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin: 2px 0px px;
    align-items: center;
`;

export const TitleAndButton = styled.div`
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
`;

export const SeparatorRow = styled.div`
    border-bottom: solid 3px #999;
    margin-top: 20px;
    margin-bottom: 20px;
`;

export const Label = styled.label`
    line-height: 32px;
    min-width: 200px;
    font-size: 14px;
    font-weight: bold;
`;

const ContactsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-height: 62px;
`;

const ContactContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
`;
const ContactName = styled.div`
    padding: 5px;
`;


const ButtonContainer = styled.div`
    text-align: left;
`;

export const OpportunityHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const Table = styled.table``;

const FilesContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const DocumentContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-direction: column;
`;


interface IProps {
    types: IQuoteType[],
    users: IUser[],
    customers: ICustomer[],
    editable?: boolean,
    onAddComplete: () => void
};

const OpportunityAdd = (props: IProps) => {
    const { state } = React.useContext(SessionContext);
    const { configuration } = React.useContext(RolesContext).state;
    const quoteTypes = props.types;
    const { customers, users } = props;
    const rbac = componentConfiguration("Opportunities", configuration);
    const [workRequired, setWorkRequired] = React.useState("");
    const [outOfScope, setOutOfScope] = React.useState("");
    const [contacts] = React.useState([] as ICustomerContactDetails[]);
    const [meetings, setMeetings] = React.useState([] as IMeetingInformation[]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [editingMeeting, setEditingMeeting] = React.useState(undefined as unknown as IMeetingInformation);
    const [freeText, setFreeText] = React.useState("");
    const [customerId, setCustomerId] = React.useState(null as unknown as number);
    const [meetingDate, setMeetingDate] = React.useState(undefined as unknown as Date);
    const [installationRequired, setInstallationRequired] = React.useState(false);
    const [source] = React.useState("");
    const [sourceText] = React.useState("");
    const [sourceUser] = React.useState("");
    const [documents, setDocuments] = React.useState(undefined as unknown as IDocument[]);
    const [opportunityId, setOpportunityId] = React.useState(-1);
    const [saving, setSaving] = React.useState(undefined as unknown as ISave);
    const [owner, setOwner] = React.useState(undefined as unknown as IUser);
    const none = -1;
    const now = new Date();
    const newQuote = {
        id: 0,
        orderOwner: owner ? owner.fullName : "",
        orderOwnerEmail: state.loggedInUser,
        quoteNumber: "",
        enteredDate: Date.now(),
        customerId: 0,
        customer: "",
        projectName: "",
        projectValue: "",
        orderEnteredBy: state.loggedInUser,
        relatedQuotes: [],
        relatedQuoteDetails: "",
        orderYear: 0,
        orderSequence: 0,
        archived: false,
        expiryDate: now.setMonth(now.getMonth() + 3),
        workAuthorisedBy: "",
        workAuthorisedDate: 0,
        winStatus: "",
        closed: false,
        status: "temp",
        closedDate: undefined,
        authorised: false,
        typeId: getTypeId("", quoteTypes)
    };

    const [quote, setQuote] = React.useState(newQuote as unknown as IQuote);
    const [customer, setCustomer] = React.useState(undefined as unknown as ICustomer);
    const [commitingOp, setCommitingOp] = React.useState(false);

    useEffectOnSome(() => {
        if (opportunityId === -1) {
            setLoading(true);
            getOpportunityId(state.webToken, (opportunity: IOpportunity) => {
                setLoading(false);
                setOpportunityId(opportunity.id);
                setError("");
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
            });
        }
        const ownerUser = getUserObjectById(state.loggedInUser, users);
        if (ownerUser) {
            setOwner(ownerUser);
            quote.orderOwner = ownerUser.id;
        }
    }, [opportunityId, quote, state.webToken]);

    useEffectOnSome(() => {
        if (customerId) {
            getCustomer(state.webToken, customerId, (c: ICustomer) => {
                setLoading(false);
                setError("");
                setCustomer(c);
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
                setCustomer(null as unknown as ICustomer);
            });
        }
    }, [customerId]);


    const calculateSource = () => {
        if (source === "Other") {
            return "Other - " + sourceText;
        }
        if (source === "Projects") {
            return "Projects - " + sourceUser;
        }
        return source;
    }

    const save = (commit: boolean) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        console.log("set saving: " + commit);
        setSaving({ commit: commit });
    };

    const executeSave = (token: string) => {
        setSaving(undefined as unknown as ISave);
        quote.orderOwner = owner ? owner.id : "";
        const opportunity: IOpportunity = {
            id: opportunityId,
            quote: quote,
            workRequired: workRequired,
            outOfScope: outOfScope,
            meetingInformation: meetings,
            status: saving.commit ? STATUS_COMMITED : 0,
            installationRequired: installationRequired,
            source: calculateSource(),
            closeReason: "",

        };
        setLoading(true);
        addOpportunity(token ? token : state.webToken, owner ? owner.initials : "", opportunity, () => {
            setLoading(false);
            if (saving.commit) {
                const trigger = createTriggerCommitOpportunity(opportunity);
                commitOpportunityNotification(trigger, state.webToken, () => { }, () => { },)
            }
            setError("");
            setSaving(undefined as unknown as ISave);
            props.onAddComplete();
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    };

    const addMeeting = () => {
        const meetingCopy = [...meetings];
        const newMeeting = {
            date: 0, freeText: "",
            id: none,
            contacts: []
        };
        meetingCopy.push(newMeeting);
        setMeetings(meetingCopy);
        setEditingMeeting(newMeeting);
        setMeetingDate(undefined as unknown as Date);
        setFreeText("");
    }


    const addContact = (meetings: IMeetingInformation[], index: number) => () => {
        const contactsCopy = [...editingMeeting.contacts];
        contactsCopy.push({
            id: none,
            name: "",
            email: "",
            jobTitle: "",
            phone: "",
            customerId: 0,
            visibleToDev: false,
            source: "",
            quoteRecipient: 0
        });
        const meetingCopy = [...meetings];
        meetingCopy[index].contacts = contactsCopy;
        setMeetings(meetingCopy);
        setEditingMeeting(meetings[index]);
    }

    const onEditMeeting = (meeting: IMeetingInformation) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        meeting.id = none;
        setMeetingDate(new Date(meeting.date));
        setFreeText(meeting.freeText);
        setEditingMeeting(meeting)
    };

    const onRemoveMeeting = (index: number) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        meetings.splice(index, 1);
        const meetingCopy = [...meetings];
        setMeetings(meetingCopy);
        setEditingMeeting(undefined as unknown as IMeetingInformation);
    };

    const onContactChange = (index: number) => (contactName: string) => {
        editingMeeting.contacts[index].name = contactName;
        editingMeeting.contacts[index].id = getContactIdByName(contactName, contacts);
        const editingMeetingCopy = { ...editingMeeting };
        setEditingMeeting(editingMeetingCopy);
    }

    const onRemoveContact = (index: number) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        editingMeeting.contacts.splice(index, 1);
        const editingMeetingCopy = { ...editingMeeting };
        setEditingMeeting(editingMeetingCopy);
    }

    const renderContact = (meetingId: number) => (contact: ICustomerContactDetails, index: number) => {
        if (contact.id === none) {
            return <ContactContainer>
                <Select placeholder="Select a contact" onChange={onContactChange(index)} childValues={contacts && contacts.map(option => option.name)} value={editingMeeting.contacts[index].name}>
                    {contacts.map((option, index) => <SelectItem height="20px" key={`add-contact-item-${index}`} value={option.name}>{option.name}</SelectItem>)}
                </Select>
            </ContactContainer >
        }
        return <ContactContainer>
            <ContactName>{contact.name}</ContactName>
            {editingMeeting !== undefined && meetingId === editingMeeting.id && <Button plain={true} onClick={onRemoveContact(index)}>x</Button>}
        </ContactContainer>
    }

    const onSaveMeeting = (index: number) => () => {
        editingMeeting.id = 1;
        if (meetingDate) {
            editingMeeting.date = meetingDate.getTime();
        }
        editingMeeting.freeText = freeText;
        meetings[index] = { ...editingMeeting };
        const meetingCopy = [...meetings];
        setMeetings(meetingCopy);
        setEditingMeeting(undefined as unknown as IMeetingInformation);
    }

    const onCancelMeeting = (index: number) => () => {
        meetings.splice(index, 1);
        const meetingCopy = [...meetings];
        setMeetings(meetingCopy);
        setEditingMeeting(undefined as unknown as IMeetingInformation);
    }

    const changeDate = (setter: (date: Date) => void) => (date: Date, event: React.SyntheticEvent) => {
        setter(date);
    };

    const onInstalationRequired = (checked: boolean): void => {
        setInstallationRequired(checked);
    }

    const onUpdateQuote = (update: IQuote) => {
        setQuote(update);
        if (update.customerId) {
            setCustomerId(update.customerId)
        }
    }

    const validateOpportunity = () => {
        if (quote && quote.customer && quote.customer.length > 0 && quote.projectName.length > 0) {
            return true;
        }
        return false;
    }

    const getDocuments = (type: string) => {
        getOpportunityDocuments(opportunityId, type, state.webToken, (files: IDocument[]) => {
            setLoading(false);
            setError("");
            setDocuments(files.length > 0 ? files : undefined as unknown as IDocument[]);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    }

    const onRemove = (id: number) => {
        deleteOpportunityFile(state.webToken, id, () => {
            setLoading(false);
            setError("");
            getDocuments(TYPE_DOCUMENT);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    }

    if (opportunityId === -1) {
        return <div>
            <LoadingIndicator type="Linear" show={loading} />
            {error && <ErrorText>{error}</ErrorText>}
        </div>
    }

    const onLoading = () => {
        setLoading(true);
    }

    const onFinishedLoading = () => {
        setLoading(false);
    }

    const onUpdateIntegrationEngine = (integrationEngine: IIntegrationEngine) => {
        quote.integrationEngine = integrationEngine.engine;
        quote.integrationEngineNotes = integrationEngine.notes;
        setQuote({ ...quote });
    }


    const onCloseCommitDialog = () => {
        setCommitingOp(false);
    }

    const onCommitingOp = () => {
        console.log("setCommitingOp");
        setCommitingOp(true);
    }


    return <OutsideContainer>
        <LoadingIndicator type="Linear" show={loading} />
        <Main>
            {error && <ErrorText>{error}</ErrorText>}
            {saving && <ImpersonatingRoadblock onCommit={executeSave}></ImpersonatingRoadblock>}
            <Dialog style={{ padding: "32px" }} open={commitingOp} onClose={onCloseCommitDialog}>
                <h3>This Opportunity will be passed to Architecture and you will not be able to make further changes.</h3>
                <ActionContainer>
                    <Button color={COLORS.SIXTH} onClick={onCloseCommitDialog} style={{ marginRight: "10px" }}>Cancel</Button>
                    <Button onClick={save(true)} color={"#e60000"}>Commit</Button>
                </ActionContainer>
            </Dialog>
            <OpportunityHeader>
                <ButtonContainer>
                    <Button disabled={!validateOpportunity()} color={COLORS.GREY_SLATE} submit={true} onClick={save(false)}>Save Opportunity</Button>
                    <Button disabled={!validateOpportunity() || !canCommitOpportunity(configuration)} color={COLORS.GREY_SLATE} style={{ marginLeft: "8px" }} onClick={onCommitingOp}>Save and commit opportunity</Button>
                    <Button color={COLORS.GREY_SLATE} plain={true} style={{ marginLeft: "8px" }} onClick={props.onAddComplete}>Cancel</Button>
                </ButtonContainer>
                <OpportunitiesSmallLogo />
            </OpportunityHeader>
            {quote && <QuoteDetails onLoading={onLoading} onFinishedLoading={onFinishedLoading} editable={true} types={quoteTypes} customers={customers} users={users} quote={quote} onUpdateQuote={onUpdateQuote} />}
            <SeparatorRow />
            <Row>
                <h3>Integration Engine Details</h3>
            </Row>
            <IntegrationEngineDetails editable={true} customer={customer} onUpdateIntegrationEngine={onUpdateIntegrationEngine} />
            <Row>
                <Label htmlFor="installationRequired">Installation Required</Label>
                <Checkbox checked={installationRequired} onChange={onInstalationRequired} ></Checkbox>
            </Row>
            <SeparatorRow />
            <Row>
                <h3>Work Required</h3>
            </Row>
            <Row>
                <ReactQuill style={{ width: "100%" }} value={workRequired} onChange={setWorkRequired} />
            </Row>
            <Row>
                <h3>Future Requirements and Out of Scope</h3>
            </Row>
            <Row>
                <ReactQuill style={{ width: "100%", marginBottom: "10px" }} value={outOfScope} onChange={setOutOfScope} />
            </Row>
            <SeparatorRow />

            <TitleAndButton>
                <h3 style={{ marginRight: "20px" }}>Meetings</h3>
                {(customerId && editingMeeting === undefined) && <Button color={COLORS.GREY_SLATE} onClick={addMeeting} submit={false}>Add meeting</Button>}
            </TitleAndButton>
            {
                meetings && <Table>
                    <tbody>
                        <tr style={{ minHeight: "48px", display: "flex" }} >
                            <TableCell width={"10%"} innerStyle={{ fontWeight: "bold" }}>Date</TableCell>
                            <TableCell width={"30%"} innerStyle={{ fontWeight: "bold" }}>Free text</TableCell>
                            <TableCell width={"40%"} innerStyle={{ fontWeight: "bold" }}>Contacts</TableCell>
                            <TableCell width={"20%"} innerStyle={{ display: "flex", justifyContent: "flex-end" }}></TableCell>
                        </tr>
                        {meetings.map((meeting, index) => {
                            const id = meeting.id ? meeting.id : none;
                            if (id === none) {
                                return <tr key={`contact-entry=${index}`} style={{ minHeight: "48px", display: "flex" }}>
                                    <TableCell width={"10%"}>
                                        <DatePicker required={true} dateFormat="dd-MMM-yyyy" selected={meetingDate} onChange={changeDate(setMeetingDate)} placeholderText={"select a date"} />
                                    </TableCell>
                                    <TableCell width={"30%"}>
                                        <ReactQuill style={{ width: "100%", marginBottom: "40px" }} value={freeText} onChange={setFreeText} />
                                    </TableCell>
                                    <TableCell width={"40%"}>
                                        <ContactsContainer>
                                            {contacts && contacts.length > 0 && <Button color={COLORS.SIXTH} onClick={addContact(meetings, index)} style={{ marginRight: "30px" }} submit={false}>Add contact</Button>}
                                            {meeting.contacts.map(renderContact(meeting.id))}
                                            {(contacts === null || contacts.length === 0) && <ContactContainer>No contacts available for this customer.</ContactContainer>}
                                        </ContactsContainer>
                                    </TableCell>
                                    <TableCell width={"20%"}>
                                        <ButtonContainer>
                                            {rbac.update && <Button plain={true} onClick={onSaveMeeting(index)} submit={false} style={{ marginRight: "10px" }}>Save</Button>}
                                            {rbac.update && <Button plain={true} onClick={onCancelMeeting(index)} submit={false}>Cancel</Button>}
                                        </ButtonContainer>
                                    </TableCell>
                                </tr>;
                            } else {
                                return <tr key={`contact-entry=${index}`} style={{ minHeight: "48px", display: "flex", flexDirection: "row" }}>
                                    <TableCell width={"10%"}>{dateConverter(meeting.date)}</TableCell>
                                    <TableCell width={"30%"}>{meeting.freeText}</TableCell>
                                    <TableCell width={"40%"}>
                                        <ContactsContainer>
                                            {meeting.contacts.map(renderContact(meeting.id))}
                                        </ContactsContainer>
                                    </TableCell>
                                    <TableCell width={"20%"}>
                                        {editingMeeting === undefined &&
                                            <ButtonContainer>
                                                {rbac.update && <Button plain={true} onClick={onEditMeeting(meeting)} style={{ marginRight: "10px" }}>Edit</Button>}
                                                {rbac.update && <Button plain={true} onClick={onRemoveMeeting(index)}>Remove</Button>}
                                            </ButtonContainer>
                                        }
                                    </TableCell>
                                </tr>;
                            }
                        })
                        }
                    </tbody>
                </Table>
            }
            <SeparatorRow />
            <Row>
                <h3>Attachments</h3>
            </Row>
            <Row>
                <DocumentContainer>
                    <FilesContainer style={{ width: "100%" }}>
                        <DocumentBox
                            color={COLORS.SIXTH}
                            documents={documents}
                            onRemove={onRemove}
                            onUpdate={getDocuments}
                            url={getAPIUrl() + `quotes/opportunities/${opportunityId}/file`}
                            token={state.webToken}
                            type={TYPE_DOCUMENT}
                        />
                    </FilesContainer>
                </DocumentContainer>
            </Row>
        </Main>
    </OutsideContainer >;
};

export default OpportunityAdd;
