import * as React from "react";
import styled from "styled-components";

interface ITextAreaStyleProps {
    height: string
};

const TextAreaContainer = styled.div<ITextAreaStyleProps>`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    min-height: ${props => props.height};
`;

const TextArea = styled.textarea`
    border: 1px solid #e5e5e5;
    flex: 1 1 auto;
    padding: 8px;
    margin: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    overflow: hidden;
`;

interface IProps {
    htmlId: string,
    placeholder: string,
    required?: boolean,
    text: string,
    setText: (newValue: string) => void
};

const GrowableTextArea = (props: IProps) => {
    const { htmlId, placeholder, required, setText, text } = props;
    const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
    const [parentHeight, setParentHeight] = React.useState("auto");

    const updateText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setParentHeight(`${textAreaRef.current!.scrollHeight}px`);
        setText(e.target.value);
    };

    React.useEffect(() => {
        setParentHeight(`${textAreaRef.current!.scrollHeight}px`);
	}, [text]);

    return <TextAreaContainer height={parentHeight}>
        <TextArea required={required} id={htmlId} ref={textAreaRef} rows={1} placeholder={placeholder} value={text} onChange={updateText} />
    </TextAreaContainer>;
};

export default GrowableTextArea;
