import { getHostUrl } from "../config";
import IOpportunity from "../ServerEntities/IOpportunity";
import IQuoteSummary from "../ServerEntities/IQuoteSummary";
import IWorkPackage from "../ServerEntities/IWorkPackage";

export const createTriggerCreatedQuote = (quoteId: string, quoteNumber: string, projectName: string) => {
    return {
        "name": "quote-created",
        "title": "New quote created",
        "message": `New quote, number ${quoteNumber} for project ${projectName}, more details <a href="${getHostUrl()}/Quote/${quoteId}">here</a>`
    }
};

export const createTriggerAuthorisedQuote = (quote: IQuoteSummary,) => {
    return {
        "name": "quote-authorised",
        "title": `Work authorised to begin for Quote Number ${quote.number} Project: ${quote.projectName}`,
        "message": `${quote.authorisedBy} has authorised work to begin on the project ${quote.projectName}. The Quote Number is ${quote.number}. Click <a href="${getHostUrl()}/Quote/${quote.id}">here</a> to view the quote.`,
        "recipients": [quote.authorisedByEmail, quote.quoteOwnerEmail]
    }
};

export const createTriggerCommitOpportunity = (opportunity: IOpportunity,) => {
    return {
        "name": "opportunity-committed",
        "title": `Sales opportunitity committed for quote: ${opportunity.quote.quoteNumber}`,
        "message": `Sales opportunitity committed for quote: ${opportunity.quote.quoteNumber}. Click <a href="${getHostUrl()}/Opportunities/${opportunity.id}">here</a> to view the sales opportunity.`,
        "recipients": []
    }
};

export const createWorkPackageCommitOpportunity = (workPackage: IWorkPackage,) => {
    return {
        "name": "package-committed",
        "title": `Work package ready for pricing: ${workPackage.id}`,
        "message": `Work package ready for pricing: ${workPackage.id}. Click <a href="${getHostUrl()}/WorkPackages/${workPackage.id}">here</a> to view the work packages.`,
        "recipients": [workPackage.opportunity.quote.orderOwner ? workPackage.opportunity.quote.orderOwner : ""]
    }
};