import axios, { AxiosError, AxiosResponse } from "axios";
import { getAPIUrl } from "../../../config";
import IOrder from "../../../ServerEntities/IOrder";
import IDocument from "../../../ServerEntities/IDocument";

export const getReceivedOrders = (webToken: string, successCallback: (orders: IOrder[]) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}quotes/orders`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IOrder[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getPendingdOrders = (webToken: string, successCallback: (orders: IOrder[]) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}quotes/orders`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IOrder[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getOrder = (id: number, webToken: string, successCallback: (order: IOrder) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}quotes/orders/${id}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IOrder>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getOrderDocuments = (orderId: number, type: string, webToken: string, successCallback: (files: IDocument[]) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}quotes/orders/${orderId}/file/${type}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IDocument[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const updateOrder = (webToken: string, order: IOrder, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.put(
        `${getAPIUrl()}quotes/orders`,
        order,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse) => {
        successCallback();
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};