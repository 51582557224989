import axios, { AxiosError, AxiosResponse } from "axios";
import { getAPIUrl } from "../../../config";
import IUser from "../../../ServerEntities/IUser";

export const getUsers = (webToken: string, successCallback: (Users: IUser[]) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}users`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IUser[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};