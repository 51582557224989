import { faBell } from '@fortawesome/free-regular-svg-icons';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import setFocus from '../UsefulFunctions/SetFocus';
import useRestAPI from '../CustomHooks/useRestAPI';
import RoleChangeDialog from '../Roles/RoleChangeDialog';
import { RolesContext } from '../Roles/RolesContext';
import INotification from '../ServerEntities/INotification';
import { COLORS } from '../config';

import Notification from '../SharedComponents/Notification';
import { SessionContext } from '../Views/SessionContext';
import Button from './Button';
import IconButton from './IconButton';
import LoadingIndicator from './LoadingIndicator';
import Menu from './Menu/Menu';
import UserMenu from './UserMenu';

import * as messageService from "../Views/MessageService";
import UserChangeDialog from './UserChangeDialog';
import useEffectOnSome from '../CustomHooks/useEffectOnSome';

interface IHeaderProps {
    $backgroundColor: string;
    $borderColor: string;
};

const HeaderBanner = styled.header<IHeaderProps>`
    background: ${props => props.$backgroundColor};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border-bottom: solid 10px ${props => props.$borderColor};
    `;

const RowPadding = styled.div`
    flex: 1 0 auto;
`;

const NotificationsMenuTitle = styled.h2`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e5e5e5;
    padding: 0px 12px 12px;
`;

const Username = styled.div`
    color: white;
`;

interface IProps {
    backgroundColor: string,
    previousPageUrl?: string,
    border: string,
    onBack?: () => void
};

const Header = (props: IProps) => {
    const { backgroundColor, previousPageUrl, onBack } = props;
    const navigate = useNavigate();
    const roleDialogRef = React.useRef(null as unknown as HTMLDivElement);
    const userDialogRef = React.useRef(null as unknown as HTMLDivElement);
    const notificationButtonRef = React.useRef(null as unknown as HTMLDivElement);
    const notificationsRef = React.useRef(null as unknown as HTMLDivElement);
    const [roleChangeDialogOpen, setRoleChangeDialogOpen] = React.useState(false);
    const [userChangeDialogOpen, setUserChangeDialogOpen] = React.useState(false);
    const [notificationMenuOpen, setNotificationMenuOpen] = React.useState(false);
    const sessionContext = React.useContext(SessionContext);
    const { state: rolesState, dispatch: rolesDispatch } = React.useContext(RolesContext);
    const { role } = rolesState;
    const messageDispatch = sessionContext.dispatch;
    const { refreshMessages } = sessionContext.state;
    const borderColor = props.border ? props.border : COLORS.HEATHER_SLATE;

    const goBack = (url: string) => () => {
        navigate(url);
        if (onBack) {
            onBack();
        }
    };

    const findNumberOfUnreadNotifications = () => {
        return data ? data.length : 0;
    };

    const closeRoleChangeDialog = () => {
        setRoleChangeDialogOpen(false);
    };

    const closeUserChangeDialog = () => {
        setUserChangeDialogOpen(false);
    };

    const closeNotificationMenu = () => {
        setNotificationMenuOpen(false);
    };

    const toggleNotificationMenu = () => {
        if (!notificationMenuOpen) {
            setNotificationMenuOpen(true);
            setFocus(notificationsRef);
        } else {
            setNotificationMenuOpen(false);
        }
    };

    const signOut = () => {
        sessionContext.dispatch({ type: "clearWebToken", payload: undefined });
        rolesDispatch({ type: "clearRole" });
    };

    const openRoleChangeDialog = () => {
        setRoleChangeDialogOpen(true);
        setFocus(roleDialogRef);
    };

    const openUserChangeDialog = () => {
        setUserChangeDialogOpen(true);
        setFocus(userDialogRef);
    };

    const downloadMessages = () => {
        messageDispatch({ type: "refreshMessages", payload: undefined });
    };

    useEffectOnSome(() => {
        downloadMessages();
    }, []);

    const { data, error } = useRestAPI("notifications", "GET", {}, sessionContext.state.webToken, refreshMessages) as { data: INotification[], loading: boolean, error: string };
    const [errorMessage, setErrorMessage] = React.useState("");

    const removeNotificationFromMenu = (index: number) => () => {
        data.splice(index, 1);
        downloadMessages();
    };

    const removeNotificationFailed = (errorMessageFromRequest: string) => {
        setErrorMessage(errorMessageFromRequest);
    };

    const removeMessage = (messageId: number, index: number) => () => {
        messageService.removeMessage(messageId, sessionContext.state.webToken, removeNotificationFromMenu(index), removeNotificationFailed);
    };

    const deleteMessages = () => {
        const messageIDsToDelete = data.map(item => item.id);
        if (messageIDsToDelete.length) {
            messageService.deleteMessages(messageIDsToDelete, sessionContext.state.webToken, downloadMessages);
        }
    };

    const handleNotificationKeyDown = (isFirst: boolean, isLast: boolean) => (event: any) => {
        const key = event.key || event.keyCode;
        if (key === "Tab") {
            if (event.shift && isFirst) {
                notificationButtonRef.current.focus();
                setNotificationMenuOpen(false);
            }
            if (!event.shift && isLast) {
                notificationButtonRef.current.focus();
                setNotificationMenuOpen(false);
            }
        }
    };

    const createNotification = (dataLength: number) => (item: INotification, index: number) => {
        return <Notification key={`Notification-${item.id}`}
            id={item.id}
            title={item.title || ""}
            text={item.text}
            created={item.created}
            seen={item.seen || false}
            handleKeyDown={handleNotificationKeyDown(index === 0, index === dataLength - 1)}
            onDelete={removeMessage(item.id, index)}
        />;
    };

    const onClickLogo = () => {
        navigate("/");
    }

    return <HeaderBanner $backgroundColor={backgroundColor} $borderColor={borderColor}>
        <RoleChangeDialog
            dialogRef={roleDialogRef}
            open={roleChangeDialogOpen}
            onClose={closeRoleChangeDialog}
            applicationName="InternalSystem" />
        <UserChangeDialog
            dialogRef={userDialogRef}
            open={userChangeDialogOpen}
            onClose={closeUserChangeDialog}
            applicationName="InternalSystem" />
        {previousPageUrl && <Button style={{ border: "0 none", fontSize: "1.1rem" }} plain={true} color="white" onClick={goBack(previousPageUrl)}>
            <FontAwesomeIcon icon={faArrowLeft} /> Back
        </Button>}
        <img onClick={onClickLogo} alt="Slate logo" className="logo" src="/images/slate-white.png" height={30} />
        <RowPadding />
        <Username>{sessionContext.state.loggedInUser}{sessionContext.state.impersonating ? " on behalf of " + sessionContext.state.impersonating : ""}</Username>
        <IconButton onClick={toggleNotificationMenu} refObject={notificationButtonRef} notificationCount={findNumberOfUnreadNotifications()} style={{ margin: "0 16px" }}>
            <FontAwesomeIcon color="white" icon={faBell} />
        </IconButton>
        {notificationMenuOpen && <Menu open={true} menuContainerRef={notificationsRef} onClose={closeNotificationMenu} anchorElement={notificationButtonRef} width={320}>
            <LoadingIndicator show={false} type="Linear" />
            {error && error}
            {errorMessage && errorMessage}
            <NotificationsMenuTitle>Notifications {data && data.length > 1 && <Button plain={true} onClick={deleteMessages}>Clear all</Button>}</NotificationsMenuTitle>
            {data ? data.map(createNotification(data.length)) : undefined}
            {!data || data.length === 0 ? <div style={{ padding: "12px", color: "#aaaaaa" }}>There are no notifications to show at this time.</div> : null}
        </Menu>}
        <UserMenu role={role} impersonating={sessionContext.state.impersonating} username={sessionContext.state.loggedInUser} onChangeRole={openRoleChangeDialog} onChangeUser={openUserChangeDialog} onSignOut={signOut} />
    </HeaderBanner>
};

export default Header;