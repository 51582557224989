import axios, { AxiosError, AxiosResponse } from 'axios';

import { getAPIUrl } from '../../config';
import IConfiguration from '../../ServerEntities/IConfiguration';
import { IErrorResponse, NETWORK_ERROR } from '../../UsefulFunctions/createErrorMessage';



export const getInternalConfigurationForRole = (roleName: string, webToken: string,
    successCallback: (configuration: IConfiguration) => void, failureCallback: (errorMessage: string) => void) => {

    axios.get(
        `${getAPIUrl()}dashboard/configuration/InternalSystem/${roleName}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-Type": "application/json" } }
    ).then((response: AxiosResponse<IConfiguration>) => {
        successCallback(response.data);
    }).catch((error: AxiosError) => {
        failureCallback(error.message);
    });
};



const attemptLogin = (code: string, state: string, successCallback: (webToken: string) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}session`,
        {
            auth: {
                "password": state,
                "username": code
            }
        }
    )
        .then((response: AxiosResponse<string>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorResponse, any>) => {
            let errorMessage = "Unknown error";
            if (error.response && error.response.status === 401) {
                errorMessage = "Incorrect username or password."
            } else if (error.response && error.response.status === 429) {
                errorMessage = "Timed out due to too many login attempts.";
            } else if (error.response && error.response.data) {
                errorMessage = error.response.data.message;
            }
            if (error.message === "Network Error") {
                errorMessage = NETWORK_ERROR;
            }
            failureCallback(errorMessage as string);
        });
};

export const getUsernameForToken = (jwt: string, successCallback: (username: string) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(`${getAPIUrl()}session/${jwt}/username`)
        .then((response: AxiosResponse<string>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<string, any>) => {
            let errorMessage = "Unknown error";
            if (error.response) {
                errorMessage = error.response.data
            }
            if (error.message === "Network Error") {
                errorMessage = NETWORK_ERROR;
            }
            failureCallback(errorMessage as string);
        });
};

export const sendForgotPassword = (username: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.post(`${getAPIUrl()}password/forgot/${username}`)
        .then((response: AxiosResponse<string>) => {
            successCallback();
        })
        .catch((error: AxiosError<IErrorResponse, any>) => {
            let errorMessage = "Unknown error";
            if (error.response) {
                if (error.response.status === 404) {
                    errorMessage = "Email not found."
                }
                else {
                    errorMessage = error.response.data.message;
                }

            }
            if (error.message === "Network Error") {
                errorMessage = NETWORK_ERROR;
            }
            failureCallback(errorMessage as string);
        });
};

export const sendResetPassword = (password: string, token: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.post(`${getAPIUrl()}password/reset/${token}`, password)
        .then((response: AxiosResponse<string>) => {
            successCallback();
        })
        .catch((error: AxiosError) => {
            let errorMessage = "Unknown error";
            if (error.response) {
                errorMessage = error.response.data + "";
            }
            if (error.message === "Network Error") {
                errorMessage = NETWORK_ERROR;
            }
            failureCallback(errorMessage as string);
        });
};

export const getAuthenticationUrl = (successCallback: (url: string) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(`${getAPIUrl()}authentication/url`)
        .then((response: AxiosResponse<string>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError<IErrorResponse, any>) => {
            let errorMessage = "Unknown error";
            if (error.response && error.response.status === 401) {
                errorMessage = "Incorrect username or password."
            } else if (error.response && error.response.status === 429) {
                errorMessage = "Timed out due to too many login attempts.";
            } else if (error.response && error.response.data) {
                errorMessage = error.response.data.message;
            }
            if (error.message === "Network Error") {
                errorMessage = NETWORK_ERROR;
            }
            failureCallback(errorMessage as string);
        });
};

export default attemptLogin;
