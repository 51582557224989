import React from "react";
import styled from "styled-components";
import { COLORS } from "../../../config";
import Button from "../../../SharedComponents/Button";
import Dialog from "../../../SharedComponents/Dialog/Dialog";
import { updateTextCallback } from "../../../SharedComponents/TextInput";
import SmallTextInput from "../../../SharedComponents/SmallTextInput";
import ReactQuill from "react-quill";
import ITask from "../../../ServerEntities/ITask";

const TaskContainer = styled.div`
    border-radius: 5px;
    border: 3px solid ${COLORS.TASKS};
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 0.8em;
    font-weight: bold;
    line-height: 24px;
    width: 100%;
    margin-bottom: 10px;
`;

const TaskInner = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    padding: 10px;
`;


const DescriptionContainer = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
       margin: 10px 0px 0px 10px;
`;

const TaskTitle = styled.div`
    background-color: ${COLORS.TASKS};
    color: #FFF;
    padding-left: 5px; 
    width: 100%;
    display: flex;
    align-items: center;
`;

const ActionContainer = styled.div`
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

const ButtonContainer = styled.td`
    padding: 10px 10px 0px 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 12px;
    font-weight: bold;
    justify-content: center;
`;

interface IProps {
    taskDetails: ITask;
    readOnly?: boolean,
    isEditing?: ITask;
    index: number;
    onUpdate?: (taskDetails: ITask, index: number) => void;
    onCancel?: (index: number) => void;
    onDelete?: (index: number) => void;
};

const TaskDetails = (props: IProps) => {
    const taskDetails = props.taskDetails;
    const index = props.index;
    const [name, setName] = React.useState(taskDetails.name);
    const [isDirty, setIsDirty] = React.useState(false);
    const [description, setDescription] = React.useState(taskDetails.description);

    const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
    const none = -1;

    const onSaveTask = (index: number) => () => {
        taskDetails.id = taskDetails.id > none ? taskDetails.id : 0;
        taskDetails.name = name;
        taskDetails.description = description;
        if (props.onUpdate) {
            props.onUpdate({ ...taskDetails }, index);
        }
        setIsDirty(false);
    };

    const onDeleteTask = () => {
        setShowDeleteDialog(true);
    };

    const onCloseDeleteDialog = () => {
        setShowDeleteDialog(false);
    };

    const onDelete = () => {
        if (props.onDelete) {
            props.onDelete(index);
        }
    };

    const setDirty = () => {
        setIsDirty(true);
    }

    if (showDeleteDialog) {
        return <Dialog key={`delete-dialog-task=${index}`} style={{ padding: "32px" }} open={showDeleteDialog} onClose={onCloseDeleteDialog}>
            <h3>Are you sure you want to delete?</h3>
            <ActionContainer>
                <Button color={COLORS.SIXTH} onClick={onCloseDeleteDialog} style={{ marginRight: "10px" }}>Cancel</Button>
                <Button onClick={onDelete} color={"#e60000"}>Delete</Button>
            </ActionContainer>
        </Dialog>;
    };

    const updateDescription = (description: string) => {
        setDescription(description);
        setDirty();
    }

    return <TaskContainer key={`interface-table-edit=${index}`}>
        <TaskTitle>{index + 1} <SmallTextInput textColor={"#fff"} backgroundColor={COLORS.TASKS} width="30%" value={name} onChange={updateTextCallback(setName, setDirty)} placeholder="enter task name" /></TaskTitle>
        <TaskInner>
            <DescriptionContainer>
                <ReactQuill style={{ width: "100%", marginBottom: "10px" }} value={description} onChange={updateDescription} />
                {isDirty && <ButtonContainer>
                    {isDirty && <Button color={COLORS.GREY_SLATE} onClick={onSaveTask(index)} submit={false} style={{ margin: "10px" }}>Save</Button>}
                    <Button color={COLORS.GREY_SLATE} onClick={onDeleteTask} submit={false} style={{ marginLeft: "10px" }}>Delete</Button>
                </ButtonContainer>}
            </DescriptionContainer>
        </TaskInner>
    </TaskContainer>
}

export default TaskDetails;