import * as React from 'react';
import { useState } from 'react';
import { useParams } from 'react-router';

import IRoleAndJwt from '../../ServerEntities/IRoleAndJwt';
import IConfiguration from '../../ServerEntities/IConfiguration';

import loginBackground from '../../images/loginBackground.png';

import ViewContainer from '../../SharedComponents/ViewContainer';

import { getPreferredRole } from '../../Roles/RolesService';
import attemptLogin, { getInternalConfigurationForRole, getUsernameForToken } from "../../Views/Login/loginService";

import { RolesContext } from '../../Roles/RolesContext';
import { SessionContext } from '../../Views/SessionContext';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Card from '../../SharedComponents/Card';
import Overlay from '../../SharedComponents/Overlay';
import ErrorBox from '../../SharedComponents/ErrorBox';
import Button from '../../SharedComponents/Button';
import useEffectOnSome from '../../CustomHooks/useEffectOnSome';

const backgroundStyle = {
    backgroundAttachment: "fixed",
    backgroundImage: `url("${loginBackground}")`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
};


const LoginBoxContainer = styled.div`
    position: relative;
    max-width: 500px;
`;

const SignInContainer = styled.div`
     padding: 30px;
    text-align: center;
`;


interface IProps {
    applicationName: string
};

const Login = (props: IProps) => {
    const [errorText, setErrorText] = useState("");
    const { jwt } = useParams<"jwt">();
    const [loggingIn, setLoggingIn] = useState(false);
    const { state, dispatch } = React.useContext(SessionContext);
    const rolesContext = React.useContext(RolesContext);
    const navigate = useNavigate();
    const params = new URLSearchParams(window.location.search);
    const requestedPath = localStorage.getItem('requestedPath');
    const code = params.get("code");
    const codeState = params.get("state");

    if (state.loggedInUser) {
        navigate("/");
    }

    useEffectOnSome(() => {
        if (!jwt && !loggingIn) {
            loginUser(code, codeState, props.applicationName)
        }
    }, [loggingIn]);

    React.useEffect(() => {
        if (jwt) {
            setLoggingIn(true);
            getUsernameForToken(jwt, (username: string) => {
                dispatch({ type: "setLoggedInUser", payload: username });
                dispatch({ type: "setWebToken", payload: jwt });
            }, (errorMessage: string) => {
                setErrorText(errorMessage);
                setLoggingIn(false);
            });
        }

    }, [jwt, dispatch]);

    const updateConfiguration = (configuration: IConfiguration) => {
        rolesContext.dispatch({ type: "setConfiguration", payload: configuration });
        setLoggingIn(false);
        navigate(requestedPath !== null ? requestedPath : "/");
        localStorage.getItem('/');
    };

    const handleConfigurationError = (serverError: string) => {
        setErrorText(serverError);
    };

    const goBack = () => {
        navigate("/");
    }

    function loginUser(username: string | null, password: string | null, applicationName: string) {
        if (username && !loggingIn) {
            setLoggingIn(true);
            attemptLogin(username, password ? password : "", (result: string) => {
                setTimeout(() => {
                    getPreferredRole(
                        applicationName,
                        result, // Webtoken
                        (preferredRole: IRoleAndJwt) => {
                            rolesContext.dispatch({ type: "setRole", payload: preferredRole.role });
                            dispatch({ type: "setWebToken", payload: preferredRole.jwt });
                            dispatch({ type: "setLoggedInUser", payload: preferredRole.username });
                            if (preferredRole.role) {
                                if (applicationName === "InternalSystem") {
                                    getInternalConfigurationForRole(preferredRole.role, preferredRole.jwt, updateConfiguration, handleConfigurationError);
                                }
                            }
                        }, (errorMessage: string) => {
                            setErrorText(errorMessage);
                            dispatch({ type: "setWebToken", payload: result });
                            dispatch({ type: "setLoggedInUser", payload: "" });
                        },
                    );
                }, 1000)
            }, (errorMessage: string) => {
                setTimeout(() => {
                    setErrorText(errorMessage);
                }, 1000);
            })
        }
    }

    return <ViewContainer className="Background" style={backgroundStyle}>
        <Overlay>
            <Card>
                <LoginBoxContainer className="LoginBoxContainer">
                    <SignInContainer>
                        {errorText && <ErrorBox>{errorText}</ErrorBox>}
                        {errorText && <Button onClick={goBack}>Back to authentication</Button>}
                        {loggingIn && !errorText &&
                            <h3>Authenticating...</h3>
                        }
                    </SignInContainer>
                </LoginBoxContainer>
            </Card>
        </Overlay>
    </ViewContainer>;
};

export default Login;
