import ICustomer from "../ServerEntities/ICustomer";

export const getCustomerIdBy = (customer: string, customers: ICustomer[]) => {
    if (customers) {
        const userObj = customers.filter(obj => {
            return obj.customerName === customer
        });
        return userObj && userObj[0].id ? userObj[0].id : 0;
    }
    return 0;
};

export const getCustomerBy = (customer: string, customers: ICustomer[]) => {
    if (customers) {
        const userObj = customers.filter(obj => {
            return obj.customerName === customer
        });
        return userObj[0];
    }
    return null as unknown as ICustomer;
};

export const calculateSource = (role: string) => {
    if (role.toLocaleLowerCase().includes("sales")) {
        return "Sales"
    }
    return "Project";
}
