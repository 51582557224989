import React from "react";
import styled from "styled-components";
import { COLORS, FONTSIZE } from "../../../config";
import { IEffortType } from "../../../ServerEntities/IEffort";
import Button from "../../../SharedComponents/Button";
import Dialog from "../../../SharedComponents/Dialog/Dialog";
import Select from "../../../SharedComponents/Select/Select";
import SelectItem from "../../../SharedComponents/Select/SelectItem";
import TextInput, { updateNumeric, updateText } from "../../../SharedComponents/TextInput";
import IInterfaceDetails from "../../../ServerEntities/IInterfaceDetails";
import ITask from "../../../ServerEntities/ITask";

const ActionContainer = styled.div`
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

const TableCell = styled.td`
    padding: 2px;
    font-size: ${FONTSIZE};
`;

const TableButtonCell = styled.td`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    font-size: ${FONTSIZE};
    font-weight: bold;
`;


export function isInterfaceDetails(effort: IInterfaceDetails | ITask): effort is IInterfaceDetails {
    return (effort as IInterfaceDetails).direction !== undefined
}

interface IProps {
    effort: IInterfaceDetails | ITask;
    readOnly?: boolean,
    isEditing?: IInterfaceDetails | ITask;
    index: number;
    onUpdate?: (effort: IInterfaceDetails | ITask, index: number) => void;
    onCancel?: (effort: IInterfaceDetails | ITask, index: number) => void;
    onDelete?: (effort: IInterfaceDetails | ITask, index: number) => void;
};


const EffortDetails = (props: IProps) => {

    const effort = props.effort;
    const index = props.index;
    const readOnly = props.readOnly;
    const [isEditing, setIsEditing] = React.useState(props.isEditing);
    const name = effort.name;
    const [arch, setArch] = React.useState(effort.arch);
    const [dev, setDev] = React.useState(effort.dev);
    const [test, setTest] = React.useState(effort.test);
    const [golive, setGolive] = React.useState(effort.golive);
    const [other, setOther] = React.useState(effort.other);
    const [type, setType] = React.useState(effort.interfaceType);
    const [notes, setNotes] = React.useState(effort.notes);
    const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
    const none = -1;

    const onSaveEffort = (index: number) => () => {
        effort.id = effort.id > none ? effort.id : 0;
        effort.arch = arch;
        effort.dev = dev;
        effort.test = test;
        effort.golive = golive;
        effort.other = other;
        effort.interfaceType = type
        effort.notes = notes;
        if (props.onUpdate) {
            props.onUpdate({ ...effort }, index)
        }
        setIsEditing(undefined as unknown as IInterfaceDetails | ITask);

    };

    const onCancelEffort = (effort: IInterfaceDetails | ITask, index: number) => () => {
        if (props.onCancel) {
            props.onCancel(effort, index)
        }
        setIsEditing(undefined as unknown as IInterfaceDetails | ITask);
    };

    const onEditEffort = (effort: IInterfaceDetails | ITask, index: number) => () => {
        setIsEditing(effort);
    };

    const onRemoveEffort = (effort: IInterfaceDetails | ITask, index: number) => () => {
        setShowDeleteDialog(true);
        setIsEditing(undefined as unknown as IInterfaceDetails | ITask);
    };

    const onCloseDeleteDialog = () => {
        setShowDeleteDialog(false);
    }

    const onDelete = () => {
        if (props.onDelete) {
            props.onDelete(effort, index);
        }
    };

    if (showDeleteDialog) {
        return <Dialog style={{ padding: "32px" }} open={showDeleteDialog} onClose={onCloseDeleteDialog}>
            <h3>Are you sure you want to delete?</h3>
            <ActionContainer>
                <Button color={COLORS.SIXTH} onClick={onCloseDeleteDialog} style={{ marginRight: "10px" }}>Cancel</Button>
                <Button onClick={onDelete} color={"#e60000"}>Delete</Button>
            </ActionContainer>
        </Dialog>;
    }

    const onUpdateType = (typeItem: string) => {
        setType(typeItem);
    };

    if (isEditing && effort.id === isEditing.id) {
        return <tr key={`effort-item=${index}`}>
            <TableCell style={{ width: EFFORT_NUMBER_LENGTH, fontWeight: "bold" }}>
                {index + 1}
            </TableCell>
            <TableCell style={{ width: EFFORT_INTERFACE_NAME_LENGTH }}>
                {name}
            </TableCell>
            <TableCell style={{ width: EFFORT_TYPE_LENGTH, textAlign: "center" }}>
                <TextInput maxLength={2} value={arch.toString()} onChange={updateNumeric(setArch)} placeholder="Arch" />
            </TableCell>
            <TableCell style={{ width: EFFORT_TYPE_LENGTH, textAlign: "center" }}>
                <TextInput value={dev.toString()} onChange={updateNumeric(setDev)} placeholder="Dev" />
            </TableCell>
            <TableCell style={{ width: EFFORT_TYPE_LENGTH, textAlign: "center" }}>
                <TextInput value={test.toString()} onChange={updateNumeric(setTest)} placeholder="Test" />
            </TableCell>
            <TableCell style={{ width: EFFORT_TYPE_LENGTH, textAlign: "center" }}>
                <TextInput value={golive.toString()} onChange={updateNumeric(setGolive)} placeholder="Golive" />
            </TableCell>
            <TableCell style={{ width: EFFORT_TYPE_LENGTH, textAlign: "center" }}>
                <TextInput value={other.toString()} onChange={updateNumeric(setOther)} placeholder="Other" />
            </TableCell>
            {isInterfaceDetails(effort) && <TableCell style={{ width: EFFORT_INTERFACE_TYPE_LENGTH }}>
                <Select
                    childValues={IEffortType}
                    placeholder="Choose type"
                    margin="4px 24px 0px 0px"
                    value={type || ""}
                    onChange={onUpdateType}
                >
                    {IEffortType.map((typeItem: string, index: number) => <SelectItem key={`select-item-${typeItem}`} value={typeItem}>
                        {typeItem}
                    </SelectItem>)}
                </Select>
            </TableCell>}
            <TableCell style={{ width: EFFORT_NOTES_LENGTH }}>
                <TextInput value={notes} onChange={updateText(setNotes)} placeholder="Notes" />
            </TableCell>
            <TableCell style={{ width: EFFORT_BUTTONS_LENGTH }}>
                <Button onClick={onSaveEffort(index)} plain={true}>Save</Button>
                <Button onClick={onCancelEffort(effort, index)} plain={true}>Cancel</Button>
            </TableCell>
        </tr>
    }
    else {
        return <tr key={`effort-item=${index}`} >
            <TableCell style={{ width: EFFORT_NUMBER_LENGTH, fontWeight: "bold" }}>{index + 1}</TableCell>
            <TableCell style={{ width: EFFORT_INTERFACE_NAME_LENGTH }}>{name}</TableCell>
            <TableCell style={{ width: EFFORT_TYPE_LENGTH, textAlign: "center" }}>{arch}</TableCell>
            <TableCell style={{ width: EFFORT_TYPE_LENGTH, textAlign: "center" }}>{dev}</TableCell>
            <TableCell style={{ width: EFFORT_TYPE_LENGTH, textAlign: "center" }}>{test}</TableCell>
            <TableCell style={{ width: EFFORT_TYPE_LENGTH, textAlign: "center" }}>{golive}</TableCell>
            <TableCell style={{ width: EFFORT_TYPE_LENGTH, textAlign: "center" }}>{other}</TableCell>
            {isInterfaceDetails(effort) && <TableCell style={{ width: EFFORT_INTERFACE_TYPE_LENGTH, textAlign: "center" }}>{type}</TableCell>}
            <TableCell style={{ width: EFFORT_NOTES_LENGTH }}>{notes}</TableCell>
            <TableCell style={{ width: EFFORT_BUTTONS_LENGTH }}>
                {!readOnly && <TableButtonCell>
                    <Button onClick={onEditEffort(effort, index)} plain={true}>Edit</Button>
                    <Button onClick={onRemoveEffort(effort, index)} plain={true}>Delete</Button>
                </TableButtonCell>}
            </TableCell>
        </tr >
    }

}

export default EffortDetails;

export const EFFORT_TYPE_LENGTH = "8%";
export const EFFORT_NUMBER_LENGTH = "2%";
export const EFFORT_INTERFACE_NAME_LENGTH = "10%";
export const EFFORT_INTERFACE_TYPE_LENGTH = "8%";
export const EFFORT_NOTES_LENGTH = "20%";
export const EFFORT_TASK_NOTES_LENGTH = "28%";
export const EFFORT_BUTTONS_LENGTH = "15%";