import * as React from "react";

import { styled } from "styled-components";
import TextInput, { updateText } from "../../../SharedComponents/TextInput";

import Diagram, { useSchema, createSchema } from 'beautiful-react-diagrams';
import 'beautiful-react-diagrams/styles.css';
import Button from "../../../SharedComponents/Button";
import { DiagramSchema, NodeCoordinates } from "beautiful-react-diagrams/@types/DiagramSchema";
import Select from "../../../SharedComponents/Select/Select";
import SelectItem from "../../../SharedComponents/Select/SelectItem";
import { addDiagram } from "./ProjectService";
import { SessionContext } from "../../SessionContext";
import { INode, ILink, IDiagram } from "../../../ServerEntities/IDiagram";
import LoadingIndicator from "../../../SharedComponents/LoadingIndicator";
import ErrorBox from "../../../SharedComponents/ErrorBox";

const Cell = styled.div`
    display: flex;
    flex-direction: row;
    margin: 5px;
`;

const ButtonContainer = styled.div`
    width: 150px;
`;

const DesignContainer = styled.div`
    width: 600px;
`;

const DiagramDesignerContainer = styled.div`
    padding: 10px;
`;
const DiagramColumn = styled.div`
`;
const DesignColumn = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

interface IProps {
    customerId: number,
    onSave: () => void,
}

const NODETYPES = ['Normal', 'TIE'];
const NODEPOSITIONS = ['Left', 'Center', 'Right'];


const DiagramAdd = (props: IProps) => {
    const { state } = React.useContext(SessionContext);
    const { customerId, onSave } = props;
    const [diagramName, setDiagramName] = React.useState("")
    const [nodes, setNodes] = React.useState([] as unknown as INode[])
    const [links, setLinks] = React.useState([] as unknown as ILink[])
    const [text, setText] = React.useState([] as unknown as string[]);
    const [validDiagram, setValidDiagram] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [initialSchema, setInitialSchema] = React.useState(undefined as unknown as DiagramSchema<unknown>)


    const turnIntoId = (value: string) => {
        return value.replaceAll(' ', '').toUpperCase();
    }

    const getNodeById = (id: string): INode | undefined => {
        return nodes.find(node => node.id === id);
    };

    const CustomNode = (props: any) => {
        const { inputs, content } = props;

        return (
            <div style={{ background: '#717EC3', borderRadius: '10px' }}>
                <div style={{ padding: '40px', color: 'white' }}>
                    {content}
                </div>
                <div style={{ marginTop: '20px' }}>
                    {inputs.map((port: any) => React.cloneElement(port, {
                        style: { width: '50px', height: '25px', background: '#1B263B' }
                    }))}
                </div>
            </div>
        );
    };

    const updateNodeName = (index: number, newValue: string) => {
        setNodes(prevNodes => {
            const updatedNodes = [...prevNodes];
            if (updatedNodes[index]) {
                updatedNodes[index].id = turnIntoId(newValue);
                updatedNodes[index].name = newValue;
            }
            return updatedNodes;
        });
    }
    const updateNodePosition = (index: number) => (newValue: string) => {
        setNodes(prevNodes => {
            const updatedNodes = [...prevNodes];
            if (updatedNodes[index]) {
                updatedNodes[index].position = newValue;

            }
            return updatedNodes;
        });
    }

    const updateNodeType = (index: number) => (newValue: string) => {
        setNodes(prevNodes => {
            const updatedNodes = [...prevNodes];
            if (updatedNodes[index]) {
                updatedNodes[index].type = newValue;

            }
            return updatedNodes;
        });
    }

    const removeNode = (index: number) => () => {
        const newNodes = nodes.filter((_, i) => i !== index);
        setNodes(newNodes);
    };

    const nodeToRow = (node: INode, index: number) => {
        return (
            <Cell key={index}>
                <TextInput
                    htmlId={`node-name-${index}`}
                    value={node.name}
                    onChange={(e) => updateNodeName(index, e.currentTarget.value)}
                    placeholder="Name"
                />
                <Select width="100px" height="34px" placeholder="Select a position" onChange={updateNodePosition(index)} childValues={NODEPOSITIONS.map(option => option)} value={node.position}>
                    {NODEPOSITIONS.map((option, index) => <SelectItem key={`add-position-item-${index}`} value={option}>{option}</SelectItem>)}
                </Select>
                <Select width="100px" height="34px" placeholder="Select a type" onChange={updateNodeType(index)} childValues={NODETYPES.map(option => option)} value={node.type}>
                    {NODETYPES.map((option, index) => <SelectItem key={`add-type-item-${index}`} value={option}>{option}</SelectItem>)}
                </Select>
                <Button style={{ marginLeft: "10px" }} onClick={removeNode(index)}>x</Button>
            </Cell>
        );
    }


    const updateLinkName = (index: number, newValue: string) => {
        setLinks(prevLinks => {
            const updatedLinks = [...prevLinks];
            if (updatedLinks[index]) {
                updatedLinks[index].label = newValue;
            }
            return updatedLinks;
        });
    }

    const updateLinkSource = (index: number) => (newValue: string) => {
        setLinks(prevLinks => {
            const updatedLinks = [...prevLinks];
            if (updatedLinks[index]) {
                updatedLinks[index].source = newValue;
            }

            return updatedLinks;
        });
    }

    const updateLinkTarget = (index: number) => (newValue: string) => {
        setLinks(prevLinks => {
            const updatedLinks = [...prevLinks];
            if (updatedLinks[index]) {
                updatedLinks[index].target = newValue;
            }
            return updatedLinks;
        });
    }

    const removeLink = (index: number) => () => {
        const newLinks = links.filter((_, i) => i !== index);
        setLinks(newLinks);
    };

    const linkToRow = (link: ILink, index: number) => {
        return (
            <Cell key={index}>
                <TextInput
                    htmlId={`link-name-${index}`}
                    value={link.label}
                    onChange={(e) => updateLinkName(index, e.currentTarget.value)}
                    placeholder="Name"
                />
                <Select width="180px" height="34px" placeholder="Select a source" onChange={updateLinkSource(index)} childValues={nodes.map(option => option.name)} value={link.source}>
                    {nodes.map((option, index) => <SelectItem key={`add-link-source-item-${index}`} value={option.name}>{option.name}</SelectItem>)}
                </Select>
                <Select width="180px" height="34px" placeholder="Select a target" onChange={updateLinkTarget(index)} childValues={nodes.map(option => option.name)} value={link.target}>
                    {nodes.map((option, index) => <SelectItem key={`add-link-target-item-${index}`} value={option.name}>{option.name}</SelectItem>)}
                </Select>
                <Button style={{ marginLeft: "10px" }} onClick={removeLink(index)}>x</Button>
            </Cell>
        );
    }

    const addNode = () => {
        let newNodes = [...nodes];
        const newNode: INode = {
            id: "",
            diagramId: 0,
            name: "",
            position: "Left",
            coordinates: [50, 50],
            type: ""
        };

        newNodes.push(newNode);
        setNodes(newNodes);
    }

    const addLink = () => {
        let newLinks = [...links];
        const newLink: ILink = {
            id: "",
            diagramId: 0,
            label: "",
            source: "",
            target: ""
        };

        newLinks.push(newLink);
        setLinks(newLinks);
    }

    const saveDiagram = () => {
        setLoading(true);
        const diagram: IDiagram = {
            name: diagramName,
            nodes: nodes,
            links: links,
            id: 0,
            customerId: customerId
        }

        addDiagram(state.webToken, diagram, (diagram: IDiagram) => {
            setLoading(false);
            setError("");
            onSave();
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    }

    const generateDiagram = () => {
        let leftY = 100;
        let centerY = 100;
        let rightY = 100;

        const schemaNodes = nodes.map(node => {
            let coordinates;

            switch (node.position) {
                case 'Left':
                    coordinates = [50, leftY] as NodeCoordinates;
                    leftY += 300; // Increase Y coordinate for the next 'Left' node
                    break;
                case 'Center':
                    coordinates = [600, centerY] as NodeCoordinates;
                    centerY += 300; // Increase Y coordinate for the next 'Center' node
                    break;
                case 'Right':
                    coordinates = [1200, rightY] as NodeCoordinates;
                    rightY += 300; // Increase Y coordinate for the next 'Right' node
                    break;
                default:
                    coordinates = node.coordinates;
            }
            if (node.type === 'TIE') {
                return {
                    id: node.id,
                    content: node.name,
                    coordinates: coordinates,
                    render: CustomNode
                };
            }
            return {
                id: node.id,
                content: node.name,
                coordinates: coordinates
            };
        });

        const schemaLinks = links.map(link => ({
            input: turnIntoId(link.source),
            output: turnIntoId(link.target),
            label: link.label,
        }));


        setInitialSchema(createSchema({
            nodes: schemaNodes,
            links: schemaLinks
        }));
        setValidDiagram(true);

        let textRepresentation = [] as unknown as string[];
        schemaLinks.forEach(link => {
            textRepresentation.push(`${link.label} from ${getNodeById(link.input)?.name} to ${getNodeById(link.output)?.name}`);
        });
        setText(textRepresentation);
    };

    const UncontrolledDiagram = () => {


        const [schema, { onChange }] = useSchema(initialSchema);
        return (
            <div style={{ height: '900px' }}>
                <Diagram schema={schema} onChange={onChange} />
            </div>
        );

    };

    return <DiagramDesignerContainer>
        <LoadingIndicator type={"Linear"} show={loading} />
        {error && <ErrorBox>{error}</ErrorBox>}
        <DesignColumn>
            <TextInput
                htmlId={`diagram-name`}
                value={diagramName}
                onChange={updateText(setDiagramName)}
                placeholder="Name"
            />
        </DesignColumn>
        <DesignColumn>
            <ButtonContainer>
                <Button style={{ margin: '5px' }} onClick={addNode}>Add node</Button>
                <Button style={{ margin: '5px' }} onClick={addLink}>Add link</Button>
                <Button style={{ margin: '5px' }} onClick={generateDiagram}>Generate diagram</Button>
                <Button style={{ margin: '5px' }} disabled={!validDiagram} onClick={saveDiagram}>Save diagram</Button>
            </ButtonContainer>
            <DesignContainer>
                {nodes.map(nodeToRow)}
            </DesignContainer>
            <DesignContainer>
                {links.map(linkToRow)}
            </DesignContainer>
        </DesignColumn>
        <DiagramColumn>
            {text.map((line) => { return <div>{line}</div> })}
            {validDiagram && <UncontrolledDiagram />}
        </DiagramColumn>
    </DiagramDesignerContainer >
};

export default DiagramAdd;