import * as React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";

import DataSorter from "../../../UsefulFunctions/DataSorter";
import Button from "../../../SharedComponents/Button";
import Dialog from "../../../SharedComponents/Dialog/Dialog";
import ErrorText from "../../../SharedComponents/ErrorText";
import LoadingIndicator from "../../../SharedComponents/LoadingIndicator";
import { SessionContext } from "../../SessionContext";
import TableSortLabel from "../../../SharedComponents/Table/TableSortLabel";

import IWorkPackage from "../../../ServerEntities/IWorkPackage";

import dateConverter from "../../../UsefulFunctions/dateConverter";
import "react-datepicker/dist/react-datepicker.css";
import { RolesContext } from "../../../Roles/RolesContext";
import { componentConfiguration } from "../../../UsefulFunctions/getComponentConfiguration";
import Select from "../../../SharedComponents/Select/Select";
import SelectItem from "../../../SharedComponents/Select/SelectItem";
import TextInput, { updateText } from "../../../SharedComponents/TextInput";
import { isEditableWorkPackage, STATUS, STATUS_COMMITED } from "../../../UsefulFunctions/workPackageUtils";
import { COLORS } from "../../../config";
import { deleteWorkPackage } from "./WorkPackageService";
import { ButtonContainer, PackageHeader } from "./WorkPackageEdit";
import { ArchitectureSmallLogo } from "../../MainMenu";
import AssignWorkPackageDialog from "./AssignWorkPackage";
import IUser from "../../../ServerEntities/IUser";

export const Main = styled.main`
    padding: 20px;
    display: flex;
    flex-direction: column;
    text-align: left;
`;

export const Title = styled.h1`
    font-size: 1.3rem;
    margin: 12px 0px 20px;
`;

const ActionContainer = styled.div`
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

const Table = styled.table`
    margin-top: 40px;
    border-collapse: collapse;
`;

const TableCell = styled.td`
    height: 24px;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

interface IProps {
    onAdd: () => void,
    onEdit: (WorkPackage: IWorkPackage) => void,
    onView: (WorkPackage: IWorkPackage) => void,
    onReview: (WorkPackage: IWorkPackage) => void,
    onDelete: () => void,
    onRefresh: () => void,
    users: IUser[],
    workPackages: IWorkPackage[]
};


const WorkPackagesTable = (props: IProps) => {
    const { state } = React.useContext(SessionContext);
    const { configuration } = React.useContext(RolesContext).state;
    const rbac = componentConfiguration("Architecture", configuration);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const users = props.users;
    const workPackages = props.workPackages ? props.workPackages : [];
    const [WorkPackageToDelete, setWorkPackageToDelete] = React.useState(0);
    const [sortByColumn, setSortByColumn] = React.useState(1);
    const [sortDescending, setSortDescending] = React.useState(true);
    const [quoteNumberFilter, setQuoteNumberFilter] = React.useState("");
    const [customerFilter, setCustomerFilter] = React.useState("");
    const [projectFilter, setProjectFilter] = React.useState("");
    const [statusFilter, setStatusFilter] = React.useState("");
    const [dateAddedFilter, setDateAddedFilter] = React.useState(undefined as unknown as Date);
    const [workPackageToAssign, setWorkPackageToAssign] = React.useState(undefined as unknown as number);


    const editWorkPackage = (workPackageId: number) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const workPackage = workPackages.find((WorkPackage: IWorkPackage) => {
            return WorkPackage.id === workPackageId;
        })
        if (workPackage) {
            props.onEdit(workPackage);
        }
    };

    const assignWorkPackage = (workPackageId: number) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setWorkPackageToAssign(workPackageId);
    };

    const reviewWorkPackage = (workPackageId: number) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const workPackage = workPackages.find((WorkPackage: IWorkPackage) => {
            return WorkPackage.id === workPackageId;
        });
        if (workPackage) {
            props.onReview(workPackage);
        }
    };

    const onCloseAssign = () => {
        setWorkPackageToAssign(undefined as unknown as number);
    };

    const onCloseDeleteDialog = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setWorkPackageToDelete(0);
    }

    const onShowDeleteDialog = (WorkPackageId: number) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setWorkPackageToDelete(WorkPackageId);
    }

    const onDelete = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setLoading(true);
        setWorkPackageToDelete(0);
        deleteWorkPackage(state.webToken, WorkPackageToDelete, () => {
            setLoading(false);
            setError("");
            props.onDelete();
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    const changeSortColumn = (columnIndex: number) => () => {
        if (columnIndex === sortByColumn) {
            setSortDescending(!sortDescending);
        } else {
            setSortDescending(true);
        }
        setSortByColumn(columnIndex);
    };

    const WORKPACKAGE_ID = 0;
    const WORKPACKAGE_DATE_ADDED = 1;
    const WORKPACKAGE_QUOTE_NUMBER = 2;
    const WORKPACKAGE_CUSTOMER = 3;
    const WORKPACKAGE_PROJECT_NAME = 4;
    const WORKPACKAGE_STATUS = 5;

    const WorkPackageToArray = (WorkPackage: IWorkPackage, index: number): [number, number, string, string, string, number, number] => [
        WorkPackage.id,
        WorkPackage.opportunity.quote.enteredDate,
        WorkPackage.opportunity.quote.quoteNumber,
        WorkPackage.opportunity.quote.customer,
        WorkPackage.opportunity.quote.projectName,
        WorkPackage.status,
        index
    ];

    const filterWorkPackages = (WorkPackage: IWorkPackage) => {
        if (quoteNumberFilter.length > 0 && !WorkPackage.opportunity.quote.quoteNumber.toLowerCase().includes(quoteNumberFilter.toLowerCase())) {
            return false;
        }
        if (dateAddedFilter && !dateConverter(WorkPackage.dateAdded).toLowerCase().includes(dateConverter(dateAddedFilter).toLowerCase())) {
            return false;
        }
        if (customerFilter.length > 0 && (WorkPackage.opportunity.quote.customer && !WorkPackage.opportunity.quote.customer.toLowerCase().includes(customerFilter.toLowerCase()))) {
            return false;
        }
        if (projectFilter.length > 0 && !WorkPackage.opportunity.quote.projectName.toLowerCase().includes(projectFilter.toLowerCase())) {
            return false;
        }
        if (statusFilter.length > 0 && !STATUS[WorkPackage.status].toLocaleLowerCase().includes(statusFilter.toLowerCase())) {
            return false;
        }
        return true;
    }

    const onClearFilters = () => {
        setQuoteNumberFilter("");
        setCustomerFilter("");
        setProjectFilter("");
        setStatusFilter("");
        setDateAddedFilter(undefined as unknown as Date);
    }

    const changeDate = (setter: (date: Date) => void) => (date: Date, event: React.SyntheticEvent) => {
        setter(date);
    };
    return <Main>
        <LoadingIndicator type="Linear" show={loading} />
        {workPackageToAssign && <AssignWorkPackageDialog workPackageId={workPackageToAssign} show={workPackageToAssign !== undefined} users={users} onClose={onCloseAssign} />}
        {error && <ErrorText>{error}</ErrorText>}
        <PackageHeader>
            <ButtonContainer>
            </ButtonContainer>
            <ArchitectureSmallLogo />
        </PackageHeader>
        <Dialog style={{ padding: "32px" }} open={WorkPackageToDelete > 0} onClose={onCloseDeleteDialog}>
            <h3>Are you sure you want to delete?</h3>
            <ActionContainer>
                <Button color={COLORS.SEVENTH} onClick={onCloseDeleteDialog} style={{ marginRight: "10px" }}>Cancel</Button>
                <Button onClick={onDelete} color={"#e60000"}>Delete</Button>
            </ActionContainer>
        </Dialog>
        <Table>
            <thead>
                <tr>
                    <th style={{ width: "10%" }}><TableSortLabel active={sortByColumn === WORKPACKAGE_QUOTE_NUMBER} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(WORKPACKAGE_QUOTE_NUMBER)}>Quote number</TableSortLabel></th>
                    <th style={{ width: "10%" }}><TableSortLabel active={sortByColumn === WORKPACKAGE_DATE_ADDED} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(WORKPACKAGE_DATE_ADDED)}>Date</TableSortLabel></th>
                    <th style={{ width: "20%" }}><TableSortLabel active={sortByColumn === WORKPACKAGE_CUSTOMER} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(WORKPACKAGE_CUSTOMER)}>Customer</TableSortLabel></th>
                    <th style={{ width: "20%" }}><TableSortLabel active={sortByColumn === WORKPACKAGE_PROJECT_NAME} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(WORKPACKAGE_PROJECT_NAME)}>Project</TableSortLabel></th>
                    <th style={{ width: "10%" }}><TableSortLabel active={sortByColumn === WORKPACKAGE_STATUS} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(WORKPACKAGE_STATUS)}>Status</TableSortLabel></th>
                    <th style={{ width: "10%" }}></th>
                </tr>
                <tr>
                    <th><TextInput value={quoteNumberFilter} onChange={updateText(setQuoteNumberFilter)}></TextInput></th>
                    <th><div style={{
                        border: "1px solid #e5e5e5",
                        borderRadius: "3px",
                        lineHeight: "30px"
                    }}>
                        <DatePicker dateFormat="dd-MMM-yyyy" selected={dateAddedFilter} onChange={changeDate(setDateAddedFilter)} />
                    </div>
                    </th>
                    <th><TextInput value={customerFilter} onChange={updateText(setCustomerFilter)}></TextInput></th>
                    <th><TextInput value={projectFilter} onChange={updateText(setProjectFilter)}></TextInput></th>
                    <th><Select onChange={setStatusFilter} childValues={STATUS.map(option => option)} value={statusFilter}>
                        {STATUS.map((option, index) => <SelectItem key={`select-op-status-item-${index}`} value={option}>{option}</SelectItem>)}
                    </Select>
                    </th>
                    <th style={{ textAlign: "center" }}> <Button plain={true} onClick={onClearFilters} >clear filters</Button></th>
                </tr>
            </thead>
            <tbody>
                {workPackages
                    .filter(filterWorkPackages)
                    .map(WorkPackageToArray)
                    .sort(DataSorter(sortByColumn, sortDescending))
                    .map((workPackage, index: any) => {
                        return <tr key={`WorkPackage-table-row-${index}`} >
                            <TableCell>{workPackage[WORKPACKAGE_QUOTE_NUMBER]}</TableCell>
                            <TableCell>{dateConverter(workPackage[WORKPACKAGE_DATE_ADDED])}</TableCell>
                            <TableCell style={{ maxHeight: "100px" }} >{workPackage[WORKPACKAGE_CUSTOMER]}</TableCell>
                            <TableCell style={{ maxHeight: "100px" }} >{workPackage[WORKPACKAGE_PROJECT_NAME]}</TableCell>
                            <TableCell>{STATUS[workPackage[WORKPACKAGE_STATUS]]}</TableCell>
                            <TableCell>
                                <ActionContainer>
                                    {rbac.assign && isEditableWorkPackage(workPackage[WORKPACKAGE_STATUS]) && <Button plain={true} onClick={assignWorkPackage(workPackage[WORKPACKAGE_ID])} style={{ marginRight: "10px" }}>Assign</Button>}
                                    {rbac.update && isEditableWorkPackage(workPackage[WORKPACKAGE_STATUS]) && <Button plain={true} onClick={editWorkPackage(workPackage[WORKPACKAGE_ID])} style={{ marginRight: "10px" }}>View</Button>}
                                    {rbac.management && workPackage[WORKPACKAGE_STATUS] === STATUS_COMMITED && <Button plain={true} onClick={reviewWorkPackage(workPackage[WORKPACKAGE_ID])} style={{ marginRight: "10px" }}>Review</Button>}
                                    {rbac.delete && isEditableWorkPackage(workPackage[WORKPACKAGE_STATUS]) && <Button plain={true} style={{ marginLeft: "8px" }} onClick={onShowDeleteDialog(workPackage[WORKPACKAGE_ID])}>Delete</Button>}
                                </ActionContainer>
                            </TableCell>
                        </tr>;
                    })}
            </tbody>
        </Table>
    </Main >
};

export default WorkPackagesTable;
