import styled from 'styled-components';

const EmptyStateSplashContainer = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1{
        font-size: 24px;
        font-weight: 600;
        color: #4c4c4c;
        margin: 6px 0;
    }
    h2 {
        font-size: 18px;
        font-weight: 400;
        color: #7f7f7f;
        margin: 6px 0;

    }
    img {
        width: 180px;
    }
`;

export default EmptyStateSplashContainer;