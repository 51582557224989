import * as React from 'react';
import styled from 'styled-components';
import { COLORS } from '../config';
import Banner from './Banner';
import Notification from './Notification';
import INotification from '../ServerEntities/INotification';
import useRestAPI from '../CustomHooks/useRestAPI';
import { SessionContext } from '../Views/SessionContext';
import * as messageService from "../Views/MessageService";
import useEffectOnSome from '../CustomHooks/useEffectOnSome';

const NotificationsContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 2.5 1 0;
    border: 3px solid grey;
    border-left: none;
    align-items: stretch;
    margin: 0;
    min-height: 0;
`;

const NotificationsScroller = styled.div`
    overflow-x: hidden;
    overflow-y: auto;
    flex: 1;
    min-height: 0;
`;

const Notifications = () => {

    const sessionContext = React.useContext(SessionContext);
    const messageDispatch = sessionContext.dispatch;
    const { refreshMessages } = sessionContext.state;
    const { data } = useRestAPI("notifications", "GET", {}, sessionContext.state.webToken, refreshMessages) as { data: INotification[], loading: boolean, error: string };

    const downloadMessages = () => {
        messageDispatch({ type: "refreshMessages", payload: undefined });
    };

    useEffectOnSome(() => {
        downloadMessages();
    }, []);

    const removeNotificationFromMenu = (index: number) => () => {
        data.splice(index, 1);
        downloadMessages();
    };

    const removeNotificationFailed = (errorMessageFromRequest: string) => {
        // setErrorMessage(errorMessageFromRequest);
    };

    const removeMessage = (messageId: number, index: number) => () => {
        messageService.removeMessage(messageId, sessionContext.state.webToken, removeNotificationFromMenu(index), removeNotificationFailed);
    };

    const createNotification = (dataLength: number) => (item: INotification, index: number) => {
        return <Notification key={`Notification-${item.id}`}
            id={item.id}
            title={item.title || ""}
            text={item.text}
            created={item.created}
            seen={item.seen || false}
            handleKeyDown={() => null}
            onDelete={removeMessage(item.id, index)
            }
        />;
    };

    return <NotificationsContainer>
        <Banner $backgroundColor={COLORS.GREY_SLATE}>Notifications</Banner>
        <NotificationsScroller>
            {data ? data.map(createNotification(data.length)) : undefined}
            {!data || data.length === 0 ? <div style={{ padding: "12px", color: "#aaaaaa" }}>There are no notifications to show at this time.</div> : null}
        </NotificationsScroller>
    </NotificationsContainer>
};

export default Notifications;