import * as React from "react";

import Button from '../../../SharedComponents/Button';
import ViewContainer from '../../../SharedComponents/ViewContainer';
import ManagerTitleBar from '../ManagerTitleBar';

import createErrorMessage from '../../../UsefulFunctions/createErrorMessage';
import * as accessManagementService from "./accessManagementService";

import IRole from '../../../ServerEntities/IRole';
import IConfigurationComponent from "../../../ServerEntities/IConfigurationComponent";
import styled from "styled-components";
import Checkbox from "../../../SharedComponents/Checkbox";
import SelectItem from "../../../SharedComponents/Select/SelectItem";
import IRoleMethod from "../../../ServerEntities/IRoleMethod";
import DynamicSelect from "../../../SharedComponents/Select/DynamicSelect";
import TextInput, { updateText } from "../../../SharedComponents/TextInput";
import useEffectOnSome from "../../../CustomHooks/useEffectOnSome";

interface IRoleDetailsProps {
    selectedRole: IRole
    webToken: string,
    refreshRoles: () => void,
    setErrorMessage: (errorMessage: string) => void,
    currentRole: string,
    currentUser: string
};

const HeadContainer = styled.tr`
    line-height: 48px;
`;

const MethodsContainer = styled.div`
    margin: 30px;
`;

const MethodContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 800px;
`;

const ButtonContainer = styled.div`
    text-align: right;
`;

const ComponentContainer = styled.tr`
    line-height: 32px;
`;

const ComponentCell = styled.td`
    text-align: center;
    margin:auto;
`;


const AccessDetails = (props: IRoleDetailsProps) => {
    const [refresh, setRefresh] = React.useState(0);
    const { webToken, selectedRole, setErrorMessage } = props;
    const [components, setComponents] = React.useState(undefined as unknown as IConfigurationComponent[]);
    const [methods, setMethods] = React.useState(undefined as unknown as IRoleMethod[]);
    const [method, setMethod] = React.useState(undefined as unknown as IRoleMethod);
    const [methodAction, setMethodAction] = React.useState("");
    const [methodMethod, setMethodMethod] = React.useState("");

    useEffectOnSome(() => {
        setErrorMessage("");
        accessManagementService.getComponentsForRole(
            selectedRole.id,
            webToken,
            (componentList: IConfigurationComponent[]) => {
                setComponents(componentList);
            },
            (serverError: string) => {
                setErrorMessage(createErrorMessage("getting components for role", serverError));
            });
        accessManagementService.getMethodsForRole(
            selectedRole.id,
            webToken,
            (roleMethods: IRoleMethod[]) => {
                setMethods(roleMethods);
            },
            (serverError: string) => {
                setErrorMessage(createErrorMessage("getting methods for role", serverError));
            });
    }, [selectedRole.id, setErrorMessage, refresh]);

    const refreshData = () => {
        setRefresh(refresh + 1);
    };

    const updateComponent = (index: number) => {
        accessManagementService.updateAccessComponent(
            selectedRole.id,
            components[index],
            webToken,
            () => {
                refreshData();
            },
            (serverError: string) => {
                setErrorMessage(createErrorMessage("enabling role", serverError));
            });
    }

    const onChangeCreate = (index: number) => (checked: boolean) => {
        components[index].create = checked;
        update(index);
    }

    const onChangeRead = (index: number) => (checked: boolean) => {
        components[index].read = checked;
        update(index);
    }

    const onChangeUpdate = (index: number) => (checked: boolean) => {
        components[index].update = checked;
        update(index);
    }

    const onChangeDelete = (index: number) => (checked: boolean) => {
        components[index].delete = checked;
        update(index);
    }

    const onChangeAuthorise = (index: number) => (checked: boolean) => {
        components[index].authorise = checked;
        update(index);
    }

    const onChangeUnarchive = (index: number) => (checked: boolean) => {
        components[index].unarchive = checked;
        update(index);
    }


    const update = (index: number) => {
        setComponents([...components]);
        updateComponent(index)
    }

    const addMethod = () => {
        setMethod({ role: selectedRole.id, action: "", method: "" })
        setMethodMethod("");
        setMethodAction("");
    };

    const saveMethod = () => {
        method.action = methodAction;
        method.method = methodMethod;
        accessManagementService.addRoleMethod(
            selectedRole.id,
            method,
            webToken,
            () => {
                setMethod(undefined as unknown as IRoleMethod);
                refreshData();
                setMethodMethod("");
                setMethodAction("");
            },
            (serverError: string) => {
                setErrorMessage(createErrorMessage("saving method", serverError));
            });
    }

    const cancelMethod = () => {
        setMethod(undefined as unknown as IRoleMethod);
        setMethodMethod("");
        setMethodAction("");
    }

    const removeMethod = () => {
        accessManagementService.removeRoleMethod(
            selectedRole.id,
            method,
            webToken,
            () => {
                setMethod(undefined as unknown as IRoleMethod);
                refreshData();
            },
            (serverError: string) => {
                setErrorMessage(createErrorMessage("removing method", serverError));
            });
    }

    const renderComponent = (component: IConfigurationComponent, index: number) => {
        return <ComponentContainer key={`component-item-key=${index}`}>
            <ComponentCell style={{ width: "20%", textAlign: "left" }}>{component.name}</ComponentCell>
            <ComponentCell style={{ width: "10%" }}><Checkbox checked={component.create} onChange={onChangeCreate(index)}></Checkbox>{component.create}</ComponentCell>
            <ComponentCell style={{ width: "10%" }}><Checkbox checked={component.read} onChange={onChangeRead(index)}></Checkbox>{component.read}</ComponentCell>
            <ComponentCell style={{ width: "10%" }}><Checkbox checked={component.update} onChange={onChangeUpdate(index)}></Checkbox>{component.update}</ComponentCell>
            <ComponentCell style={{ width: "10%" }}><Checkbox checked={component.delete} onChange={onChangeDelete(index)}></Checkbox>{component.delete}</ComponentCell>
            <ComponentCell style={{ width: "10%" }}><Checkbox checked={component.authorise} onChange={onChangeAuthorise(index)}></Checkbox>{component.authorise}</ComponentCell>
            <ComponentCell style={{ width: "10%" }}><Checkbox checked={component.unarchive} onChange={onChangeUnarchive(index)}></Checkbox>{component.unarchive}</ComponentCell>
        </ComponentContainer>
    };

    const onChangeMethod = (value: string) => {
        const values: string[] = value.split(";");
        setMethodAction(values[0]);
        setMethodMethod(values[1]);
        setMethod({ role: selectedRole.id, action: values[0], method: values[1] })
    }

    return <ViewContainer>
        <ManagerTitleBar viewName={`${selectedRole.id}`} viewDescription={`${selectedRole.description}`}>
        </ManagerTitleBar>
        <table style={{ margin: "30px" }}>
            <thead>
                <HeadContainer key={`component-item-key=header`}>
                    <th style={{ width: "20%", textAlign: "left" }}>Component</th>
                    <th style={{ width: "10%" }}>Create</th>
                    <th style={{ width: "10%" }}>Read</th>
                    <th style={{ width: "10%" }}>Update</th>
                    <th style={{ width: "10%" }}>Delete</th>
                    <th style={{ width: "10%" }}>Authorise</th>
                    <th style={{ width: "10%" }}>Archive</th>
                </HeadContainer>
            </thead>
            <tbody>
                {components && components.map(renderComponent)}
            </tbody>
        </table>

        <MethodsContainer>
            <h3>Method management</h3>
            {methods && method === undefined && <MethodContainer>
                <DynamicSelect
                    placeholder="Choose method"
                    margin="4px 24px 0px 0px"
                    value={method || ""}
                    onChange={onChangeMethod}
                    width="200px"
                    childValues={[]}>
                    {methods.map((option) => <SelectItem key={`select-item-${option.action + option.method}`} value={option.action + ";" + option.method}>{option.action} - {option.method}</SelectItem>)}
                </DynamicSelect>
                <Button onClick={addMethod} style={{ marginRight: "10px" }}>Add</Button>
            </MethodContainer>}

            {method && <MethodContainer>
                <TextInput value={methodAction} onChange={updateText(setMethodAction)}></TextInput>
                <TextInput value={methodMethod} width={"350px"} onChange={updateText(setMethodMethod)}></TextInput>
                <ButtonContainer>
                    <Button plain={true} onClick={saveMethod} style={{ marginRight: "10px" }}>Save</Button>
                    <Button plain={true} onClick={cancelMethod}>Cancel</Button>
                    <Button plain={true} onClick={removeMethod}>Remove</Button>
                </ButtonContainer>
            </MethodContainer>}
        </MethodsContainer>
    </ViewContainer >;
};

export default AccessDetails;
