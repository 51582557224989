import * as React from "react";
import { COLORS } from "../../../config";
import Header from "../../../SharedComponents/Header";
import Banner from "../../../SharedComponents/Banner";
import Tools from "../../../SharedComponents/Tools";
import { LeftColumn, RightColumn } from "../Quotes/QuotesIndex";

import { styled } from "styled-components";

import Diagram, { useSchema, createSchema, validateSchema, validateLinks, validateNodes } from 'beautiful-react-diagrams';
import 'beautiful-react-diagrams/styles.css';
import { DiagramSchema, NodeCoordinates } from "beautiful-react-diagrams/@types/DiagramSchema";
import SelectItem from "../../../SharedComponents/Select/SelectItem";
import { SessionContext } from "../../SessionContext";
import ICustomer from "../../../ServerEntities/ICustomer";
import { getCustomers } from "../Customers/CustomersService";
import useEffectOnSome from "../../../CustomHooks/useEffectOnSome";
import DynamicSelect from "../../../SharedComponents/Select/DynamicSelect";
import { Row, Label, ActionContainer } from "../Customers/CustomerEdit";
import { getCustomerIdBy } from "../../../UsefulFunctions/opportunityUtils";
import { IDiagram, INode } from "../../../ServerEntities/IDiagram";
import { deleteDiagram, getCustomerDiagrams } from "./ProjectService";
import Button from "../../../SharedComponents/Button";
import LoadingIndicator from "../../../SharedComponents/LoadingIndicator";
import DiagramAdd from "./DiagramAdd";
import ErrorBox from "../../../SharedComponents/ErrorBox";
import Dialog from "../../../SharedComponents/Dialog/Dialog";

const DiagramColumn = styled.div`
    width: 1400px;
`;



const ContextRow = styled.div`
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
`;

const DiagramContainer = styled.div`
    margin-top: 50px;
    display: flex;
    flex-direction: row;
`;

const DescriptionContainer = styled.div`
    padding: 10px;
`;

interface IDiagramSchema {
    [x: string]: any;
    text: string[],
    schema: DiagramSchema<unknown>,
    height: number
}

const turnIntoId = (value: string) => {
    return value.replace(/ /g, '').toUpperCase();
};

const getNodeById = (id: string, nodes: any) => {
    return nodes.find((node: { id: string; }) => node.id === id);
};

const DiagramsIndex = () => {
    const { state } = React.useContext(SessionContext);
    const [loading, setLoading] = React.useState(false);
    const [isAdding, setIsAdding] = React.useState(false);
    const [error, setError] = React.useState("");
    const [customers, setCustomers] = React.useState([] as unknown as ICustomer[])
    const [customer, setCustomer] = React.useState("")
    const [customerId, setCustomerId] = React.useState(undefined as unknown as number | undefined)
    const [customerDiagrams, setCustomerDiagrams] = React.useState([] as unknown as IDiagram[])
    const [diagramToRemove, setDiagramToRemove] = React.useState(undefined as unknown as number);

    const getBannerTitle = () => {
        return "Projects"
    }

    const onChangeCustomer = (customer: string) => {
        setCustomer(customer);
        const customerId = getCustomerIdBy(customer, customers);
        setCustomerId(customerId);
        updateCustomerDiagrams(customerId);
    };

    const updateCustomerDiagrams = (customerId: number | undefined) => {
        if (customerId) {
            setLoading(true);
            getCustomerDiagrams(state.webToken, customerId, (serverDiagrams: IDiagram[]) => {
                setLoading(false);
                setError("");
                setCustomerDiagrams(serverDiagrams);
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
                setCustomerDiagrams(null as unknown as IDiagram[]);
            });
        }
    };

    useEffectOnSome(() => {
        setLoading(true);
        getCustomers(state.webToken, (customers: ICustomer[]) => {
            setLoading(false);
            setError("");
            setCustomers(customers);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
            setCustomers(null as unknown as ICustomer[]);
        });

    }, []);



    const CustomNode = (props: any) => {
        const { inputs, content } = props;

        return (
            <div style={{ background: '#717EC3', borderRadius: '10px' }}>
                <div style={{ padding: '40px', color: 'white' }}>
                    {content}
                </div>
                <div style={{ marginTop: '20px' }}>
                    {inputs.map((port: any) => React.cloneElement(port, {
                        style: { width: '50px', height: '25px', background: '#1B263B' }
                    }))}
                </div>
            </div>
        );
    };



    const diagramToRow = (diagram: IDiagram, index: number) => {

        const UncontrolledDiagram = () => {
            const [schema, { onChange }] = useSchema(diagramSchemas[index].schema);
            return (
                <div style={{ height: diagramSchemas[index].height }}>
                    <Diagram schema={schema} onChange={onChange} />
                </div>
            );

        };

        const removeDiagram = (id: number) => () => {
            setDiagramToRemove(id);
        }


        return <DiagramContainer>
            <DiagramColumn>
                <ContextRow>
                    <h3>{diagram.name}</h3>
                    <Button style={{ marginLeft: "20px" }} onClick={removeDiagram(diagram.id)}>Remove</Button>
                </ContextRow>
                <DescriptionContainer>
                    {diagramSchemas[index].text.map((line) => { return <div>{line}</div> })}
                </DescriptionContainer>
                {<UncontrolledDiagram />}
            </DiagramColumn>
        </DiagramContainer>
    }
    const generateSchemaForDiagram = (diagram: IDiagram) => {
        let leftY = 50;
        let centerY = 50;
        let rightY = 50;

        const schemaNodes = diagram.nodes.map(node => {
            let coordinates;

            switch (node.position) {
                case 'Left':
                    coordinates = [50, leftY] as NodeCoordinates;
                    leftY += 300; // Increase Y coordinate for the next 'Left' node
                    break;
                case 'Center':
                    coordinates = [600, centerY] as NodeCoordinates;
                    centerY += 300; // Increase Y coordinate for the next 'Center' node
                    break;
                case 'Right':
                    coordinates = [1200, rightY] as NodeCoordinates;
                    rightY += 300; // Increase Y coordinate for the next 'Right' node
                    break;
                default:
                    coordinates = node.coordinates;
            }
            if (node.type === 'TIE') {
                return {
                    id: turnIntoId(node.name),
                    content: node.name,
                    coordinates: coordinates,
                    render: CustomNode
                };
            }
            return {
                id: turnIntoId(node.name),
                content: node.name,
                coordinates: coordinates
            };
        });

        const schemaLinks = diagram.links.map(link => ({
            input: turnIntoId(link.source),
            output: turnIntoId(link.target),
            label: link.label,
        }));

        let textRepresentation = [] as unknown as string[];
        schemaLinks.forEach(link => {
            textRepresentation.push(`${link.label} from ${getNodeById(link.input, schemaNodes)?.content} to ${getNodeById(link.output, schemaNodes)?.content}`);
        });

        const schema = createSchema({
            nodes: schemaNodes,
            links: schemaLinks
        })

        if (validateSchema(schema) && validateNodes(schema.nodes)) {
            if (schema.links && validateLinks(schema.links)) {
                diagramSchemas.push(
                    {
                        height: (Math.max(leftY, rightY, centerY) - 150),
                        text: textRepresentation,
                        schema: schema
                    });

            }

        }

    }

    let diagramSchemas: IDiagramSchema[] = [];
    customerDiagrams.map(generateSchemaForDiagram);

    const addDiagram = () => {
        setIsAdding(true);
    }

    const onAdding = () => {
        setIsAdding(false);
        updateCustomerDiagrams(customerId);
    }

    const onCloseDeleteDialog = () => {
        setDiagramToRemove(undefined as unknown as number);
    }

    const removeDiagram = () => {
        deleteDiagram(state.webToken, diagramToRemove, () => {
            setLoading(false);
            setError("");
            onCloseDeleteDialog();
            updateCustomerDiagrams(customerId);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
            onCloseDeleteDialog();
        });
    }

    return <div style={{ position: "absolute", height: "100%", width: "100%", display: "flex", alignItems: "stretch", flexDirection: "column" }}>
        <Dialog style={{ padding: "32px" }} open={diagramToRemove !== undefined} onClose={onCloseDeleteDialog}>
            <h3>Are you sure you want to delete?</h3>
            <ActionContainer>
                <Button color={COLORS.SIXTH} onClick={onCloseDeleteDialog} style={{ marginRight: "10px" }}>Cancel</Button>
                <Button onClick={removeDiagram} color={"#e60000"}>Delete</Button>
            </ActionContainer>
        </Dialog>
        <Header backgroundColor={COLORS.GREY_SLATE} border={COLORS.PROJECTS_GREEN} previousPageUrl={"/customers"} />
        <main style={{ flex: "1", display: "flex", alignItems: "stretch", overflow: "hidden" }}>
            <LeftColumn>
                <Tools bannerColor={COLORS.HEATHER_SLATE} />
            </LeftColumn>
            <RightColumn>
                <Banner $backgroundColor={COLORS.HEATHER_SLATE}>{getBannerTitle()}</Banner>
                <LoadingIndicator type={"Linear"} show={loading} />
                {error && <ErrorBox>{error}</ErrorBox>}
                {!isAdding && <Row style={{ padding: "20px" }}>
                    <Label htmlFor="customer">Customer</Label>
                    {<DynamicSelect placeholder="Select a customer" onChange={onChangeCustomer} childValues={customers && customers.map(option => option.customerName)} value={customer}>
                        {customers && customers.filter((obj: { status: boolean; }) => { return obj.status === true }).map((option, index) => <SelectItem height="20px" key={`add-quote-customer-item-${index}`} value={option.customerName}>{option.customerName}</SelectItem>)}
                    </DynamicSelect>}
                    {customerId && <Button style={{ width: "100px", marginLeft: "20px" }} onClick={addDiagram}>Add diagram</Button>}
                </Row>}
                {isAdding && customerId && <DiagramAdd customerId={customerId} onSave={onAdding} />}
                {!isAdding && <div style={{ padding: "20px", overflow: "auto" }}>
                    {customerDiagrams && customerDiagrams.map(diagramToRow)}
                </div>}
            </RightColumn>
        </main>
    </div >
};

export default DiagramsIndex;