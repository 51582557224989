import styled from 'styled-components';

interface IBannerProps {
    $backgroundColor: string,
};

const Banner = styled.header<IBannerProps>`
    background: ${props => props.$backgroundColor};
    color: white;
    padding: 10px;
    font-weight: bolder;
    font-size: 20px;
`;

export default Banner;