import * as React from 'react';
import styled from 'styled-components';
import { debounce } from 'throttle-debounce';

import IRole from "../../../ServerEntities/IRole";
import IUser from '../../../ServerEntities/IUser';

import { SessionContext } from '../../../Views/SessionContext';

import Button from '../../../SharedComponents/Button';
import Dialog from '../../../SharedComponents/Dialog/Dialog';
import DialogActions from '../../../SharedComponents/Dialog/DialogActions';
import ErrorBox from '../../../SharedComponents/ErrorBox';
import Label from '../../../SharedComponents/Label';
import ListItem from '../../../SharedComponents/List/ListItem';
import SectionList, { ISectionListData, ISectionListRenderItemInfo } from '../../../SharedComponents/List/SectionList';
import LoadingIndicator from '../../../SharedComponents/LoadingIndicator';
import TextInput from '../../../SharedComponents/TextInput';
import ManagerTitleBar from '../../../Views/Manager/ManagerTitleBar';

import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { addUsersToRole, getTopHundredUsersToAdd, searchForUsersToAdd } from "./roleManagementService";
import useEffectOnSome from '../../../CustomHooks/useEffectOnSome';

const UserSearchBoxContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 8px;
`;

const EmptyList = () => <div style={{ color: "#555", padding: "24px" }}>There are no more users to add to this role.</div>

const getSections = (users: IUser[]): Array<ISectionListData<{ title: string, description: string }>> => [
    { data: users.map((user: IUser) => ({ title: user.id, description: user.fullName })) }
];

interface IProps {
    role: IRole,
    show: boolean,
    onClose: () => void,
    onConfirmUserAdd: () => void
}

const userMatchesUser = (userToMatch: string) => (user: IUser) => userToMatch === user.id;

const AddUserToRoleDialog = (props: IProps) => {
    const { webToken } = React.useContext(SessionContext).state;
    const role = props.role;
    const [userFilterText, setUserFilterText] = React.useState("");
    const [usersToAdd, setUsersToAdd] = React.useState([] as IUser[]);
    const [users, setUsers] = React.useState([] as IUser[]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");

    const closeDialog = () => {
        setUsersToAdd([]);
        props.onClose();
    };

    const searchUsers = (searchText: string) => {
        if (searchText) {
            searchForUsersToAdd(role.id, searchText, webToken, (usersFromServer: IUser[]) => {
                setLoading(false);
                setUsers(usersFromServer);
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
            });
        } else {
            getTopHundredUsersToAdd(role.id, webToken, (usersFromServer: IUser[]) => {
                setLoading(false);
                setUsers(usersFromServer);
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
            });
        }
    };
    const delayedSearch = React.useRef(debounce(250, searchUsers)).current;

    const toggleUserToAdd = (user: IUser) => () => {
        const usersToAddCopy = [...usersToAdd];
        const userIndexInArray = usersToAddCopy.findIndex((userToAdd: IUser) => {
            return user.id === userToAdd.id;
        });
        if (userIndexInArray === -1) {
            usersToAddCopy.push(user);
        } else {
            usersToAddCopy.splice(userIndexInArray, 1);
        }
        setUsersToAdd(usersToAddCopy);
    };

    const addUsersConfirm = () => {
        addUsersToRole(
            usersToAdd,
            role.id,
            webToken,
            () => {
                setUsersToAdd([]);
                props.onConfirmUserAdd();
            },
            (errorMessage) => {
                return ""
            })
    };

    const changeUserFilterText = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        setLoading(true);
        delayedSearch(value);
        setUserFilterText(value);
    };

    useEffectOnSome(() => {
        searchUsers("");
    }, [searchUsers]);

    const renderUser = (info: ISectionListRenderItemInfo<{ title: string, description: string }>) =>
        <ListItem onClick={toggleUserToAdd(users[info.index])} key={`item${info.index}`}>
            <Label>
                <h2>{info.item.title}</h2>
                <h3>{info.item.description}</h3>
            </Label>
            {usersToAdd.findIndex(userMatchesUser(info.item.title)) !== -1 && <FontAwesomeIcon icon={faCheck} style={{ color: "#187fba" }} />}
        </ListItem>;

    return <Dialog open={props.show} onClose={closeDialog} style={{ width: "650px", height: "550px" }}>
        <ManagerTitleBar viewName="Add user(s)" viewDescription="Select users below to add to this role." />
        <LoadingIndicator show={loading} type="Linear" />
        <UserSearchBoxContainer>
            <TextInput margin="0 24px 0 0" value={userFilterText} onChange={changeUserFilterText} placeholder="Search" />
        </UserSearchBoxContainer>
        {error && <ErrorBox>{error}</ErrorBox>}
        {users.length === 0 && <EmptyList />}
        <SectionList sections={getSections(users)} renderItem={renderUser} />
        <DialogActions>
            <Button onClick={closeDialog}>Cancel</Button>
            <Button onClick={addUsersConfirm} margin="0px 16px 0px 16px" disabled={usersToAdd.length === 0}>Confirm</Button>
        </DialogActions>
    </Dialog>;
};

export default AddUserToRoleDialog;
