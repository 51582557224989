import * as React from 'react';
import styled from 'styled-components';

interface IProps {
    value: any,
    children: React.ReactNode,
    height?: string,
    fontSize?: string
};

const SelectItemContainer = styled.div<IProps>`
    line-height: ${props => props.height ? props.height : "30px"};
    font-size: ${props => props.fontSize ? props.fontSize : "1em"};
    padding: 0px 10px 0px 10px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &:hover {
        background-color: rgba(24,127,186,0.05);
    }
    cursor: pointer;
    white-space: nowrap;
`;

const SelectItem = (props: IProps) => {
    const { children, value, fontSize, height } = props;
    return <SelectItemContainer fontSize={fontSize} value={value} height={height} >
        {children}
    </SelectItemContainer>;
};

export default SelectItem;