import axios, { AxiosError, AxiosResponse } from "axios";

import { getAPIUrl } from '../../../config';
import IConfigurationComponent from "../../../ServerEntities/IConfigurationComponent";
import IRoleMethod from "../../../ServerEntities/IRoleMethod";
import { NETWORK_ERROR } from '../../../UsefulFunctions/createErrorMessage';

const handleError = (error: AxiosError, failureCallback: (errorMessage: string) => void) => {
    let errorMessage = "Unknown error";
    if (error.response) {
        if (error.response.status === 401 && error.response.data === undefined) {
            errorMessage = "Unauthorised to perform this request."
        }
    }
    if (error.message === "Network Error") {
        errorMessage = NETWORK_ERROR;
    }
    failureCallback(errorMessage as string);
};

export const getComponentsForRole = (roleId: string, webToken: string, successCallback: (components: IConfigurationComponent[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}access/${roleId}/components`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<IConfigurationComponent[]>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError) => {
            handleError(error, failureCallback);
        });
};

export const updateAccessComponent = (roleId: string, component: IConfigurationComponent, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.put(
        `${getAPIUrl()}access/${roleId}/components`,
        component,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then(() => {
            successCallback();
        })
        .catch((error: AxiosError) => {
            handleError(error, failureCallback);
        });
};

export const createAccessComponent = (roleId: string, component: IConfigurationComponent, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.post(
        `${getAPIUrl()}access/${roleId}/components`,
        component,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then(() => {
            successCallback();
        })
        .catch((error: AxiosError) => {
            handleError(error, failureCallback);
        });
};

export const getMethodsForRole = (roleId: string, webToken: string, successCallback: (methods: IRoleMethod[]) => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}access/${roleId}/methods`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then((response: AxiosResponse<IRoleMethod[]>) => {
            successCallback(response.data);
        })
        .catch((error: AxiosError) => {
            handleError(error, failureCallback);
        });
};

export const addRoleMethod = (roleId: string, roleMethod: IRoleMethod, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.post(
        `${getAPIUrl()}access/${roleId}/methods`,
        roleMethod,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then(() => {
            successCallback();
        })
        .catch((error: AxiosError) => {
            handleError(error, failureCallback);
        });
};

export const removeRoleMethod = (roleId: string, roleMethod: IRoleMethod, webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.post(
        `${getAPIUrl()}access/${roleId}/methods/remove`,
        roleMethod,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then(() => {
            successCallback();
        })
        .catch((error: AxiosError) => {
            handleError(error, failureCallback);
        });
};

export const refreshPermissions = (webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}access/reset`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then(() => {
            successCallback();
        })
        .catch((error: AxiosError) => {
            handleError(error, failureCallback);
        });
};

export const refreshConfiguration = (webToken: string, successCallback: () => void, failureCallback: (errorMessage: string) => void) => {
    axios.get(
        `${getAPIUrl()}dashboard/configuration/reset`,
        { headers: { Authorization: `Bearer ${webToken}` } }
    )
        .then(() => {
            successCallback();
        })
        .catch((error: AxiosError) => {
            handleError(error, failureCallback);
        });
};