import * as React from "react";
import styled from "styled-components";
import { SessionContext } from "../../SessionContext";
import Button from "../../../SharedComponents/Button";
import LoadingIndicator from "../../../SharedComponents/LoadingIndicator";
import TextInput, { updateText } from "../../../SharedComponents/TextInput";
import ICustomer from "../../../ServerEntities/ICustomer";
import { addDetails, deleteCustomerContactDetails, getCustomer, getCustomerContacts, getCustomers, updateCustomer, updateDetails } from "./CustomersService";
import ErrorText from "../../../SharedComponents/ErrorText";
import handleKeyboardSelect from "../../../CustomHooks/handleKeyboardSelect";
import Table from "../../../SharedComponents/Table/Table";
import TableCell from "../../../SharedComponents/Table/TableCell";
import ICustomerContactDetails from "../../../ServerEntities/ICustomerContactDetails";
import Checkbox from "../../../SharedComponents/Checkbox";
import Dialog from "../../../SharedComponents/Dialog/Dialog";
import { RolesContext } from "../../../Roles/RolesContext";
import { componentConfiguration } from "../../../UsefulFunctions/getComponentConfiguration";
import DynamicSelect from "../../../SharedComponents/Select/DynamicSelect";
import SelectItem from "../../../SharedComponents/Select/SelectItem";
import { COLORS } from "../../../config";
import { TitleAndButton } from "../Opportunities/OpportunityAdd";
import IUser from "../../../ServerEntities/IUser";
import Select from "../../../SharedComponents/Select/Select";
import { getUserById } from "../../../UsefulFunctions/quoteUtils";
import Header from "../../../SharedComponents/Header";
import { LeftColumn, RightColumn } from "../Quotes/QuotesIndex";
import Tools from "../../../SharedComponents/Tools";
import Banner from "../../../SharedComponents/Banner";
import Pages from "../../../SharedComponents/Pages";
import { useNavigate } from "react-router-dom";
import { getUsers } from "../Components/UsersService";
import { OpportunitiesSmallLogo } from "../../MainMenu";
import useEffectOnSome from "../../../CustomHooks/useEffectOnSome";

export const Main = styled.main`
    padding: 20px;
    display: flex;
    flex-direction: column;
    text-align: left;
`;

export const OutsideContainer = styled.div`
  width: 100%;
  margin: 0px;
  padding: 0px;
`;

const CustomerHeader = styled.div`
    display: flex;
    flex-direction: row;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
`;

const CustomerInformation = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
`;

const RestartContacts = styled.div`
display: flex;
flex-direction: column;
width: 30%;
border-radius: 5px;
background-color: #b6d7a8;
padding: 10px;
height: 140px;
width: 300px;
`;

const ContactRow = styled.div`
    line-height: 30px;
    font-size: 0.9em;
    display: flex;
    width: 100%;
    height: 30px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const ContactCell = styled.div`
    line-height: 24px;
    font-size: 0.8em;
`;

const ContactCellLabel = styled.div`
    line-height: 24px;
    font-size: 0.8em;
    font-weight: bold;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;


const ButtonContainer = styled.div`
    text-align: left;
`;

const ActionContainer = styled.div`
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 1 1 100%;
`;

const CustomerEdit = () => {
    const { state } = React.useContext(SessionContext);
    const { configuration } = React.useContext(RolesContext).state;
    const rbac = componentConfiguration("Opportunities", configuration);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [editing, setEditing] = React.useState(-1);
    const [contactName, setContactName] = React.useState("");
    const [contactTitle, setContactTitle] = React.useState("");
    const [contactEmail, setContactEmail] = React.useState("");
    const [contactPhone, setContactPhone] = React.useState("");
    const [contactVisibleToDev, setContactVisibleToDev] = React.useState(false);
    const [contactSource, setContactSource] = React.useState("");
    const [contactQuoteRecipient, setContactQuoteRecipient] = React.useState(false);
    const [contactId, setContactId] = React.useState(0);
    const [refresh, setRefresh] = React.useState(0);
    const [contactDetails, setContactDetails] = React.useState(null as unknown as ICustomerContactDetails[]);
    const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
    const [deleting, setDeleting] = React.useState(-1);

    const [customers, setCustomers] = React.useState(null as unknown as ICustomer[])
    const none = -1;
    const newItem = -2;
    const [salesUser, setSalesUser] = React.useState("");
    const [archUser, setArchUser] = React.useState("");
    const [managerUser, setManagerUser] = React.useState("");
    const [devUser, setDevUser] = React.useState("");
    const [contactCustomerId, setContactCustomerId] = React.useState(null as unknown as number)
    const [users, setUsers] = React.useState(null as unknown as IUser[])
    const [customer, setCustomer] = React.useState(null as unknown as ICustomer)
    const [customerName, setCustomerName] = React.useState("")
    const [isEditing, setIsEditing] = React.useState(false);
    const navigate = useNavigate();

    const getCustomerById = (customerName: string, customers: ICustomer[]) => {
        if (customers) {
            const userObj = customers.filter(obj => {
                return obj.customerName === customerName
            });
            return userObj ? userObj[0] : null;
        }
        return null;
    }

    useEffectOnSome(() => {
        setLoading(true);
        getCustomers(state.webToken, (customers: ICustomer[]) => {
            setLoading(false);
            setError("");
            setCustomers(customers);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
            setCustomers(null as unknown as ICustomer[]);
        });
        getUsers(state.webToken, (users: IUser[]) => {
            setLoading(false);
            setError("");
            setUsers(users);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
            setUsers(null as unknown as IUser[]);
        });
    }, []);

    useEffectOnSome(() => {
        setLoading(true);
        if (customer && customer.id) {
            getCustomerContacts(customer.id, state.webToken, (contacts: ICustomerContactDetails[]) => {
                setLoading(false);
                setError("");
                setContactDetails(contacts);
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
                setContactDetails(null as unknown as ICustomerContactDetails[]);
            });
        }
    }, [customer, refresh]);

    const onCustomerSelected = (customerName: string) => {
        setCustomerName(customerName);
        const customerSelected = getCustomerById(customerName, customers);

        if (customerSelected && customerSelected.id) {
            getCustomer(state.webToken, customerSelected.id, (c: ICustomer) => {
                setLoading(false);
                setError("");
                setCustomer(c);
                setCustomer(customerSelected);
                setContactCustomerId(customerSelected.id || 0)
                setContactId(customerSelected.id || 0);
                if (customerSelected.restartContacts) {
                    setSalesUser(customerSelected.restartContacts.sales);
                    setArchUser(customerSelected.restartContacts.architect);
                    setManagerUser(customerSelected.restartContacts.projectManager);
                    setDevUser(customerSelected.restartContacts.leadDeveloper);
                }
                setRefresh(refresh + 1);
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
                setContactDetails(null as unknown as ICustomerContactDetails[]);
            });

        }
    }


    const editContact = (contact: ICustomerContactDetails) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setEditing(contact.id)
        setContactName(contact.name);
        setContactTitle(contact.jobTitle);
        setContactEmail(contact.email);
        setContactPhone(contact.phone);
        setContactId(contact.id);
        setContactQuoteRecipient(contact.quoteRecipient ? true : false);
        setContactSource(contact.source);
        setContactVisibleToDev(contact.visibleToDev);
    };

    const saveContact = (contact: ICustomerContactDetails) => {
        if (editing === newItem) {
            addDetails(state.webToken, contact, () => {
                setLoading(false);
                setError("");
                setRefresh(refresh + 1);
                setEditing(none);
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
                setEditing(none);
            });
        }
        else {
            updateDetails(state.webToken, contact, () => {
                setLoading(false);
                setError("");
                setRefresh(refresh + 1);
                setEditing(none);
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
                setEditing(none);
            });
        }
    };

    const saveContactDetails = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setLoading(true);
        const contact: ICustomerContactDetails = {
            name: contactName,
            jobTitle: contactTitle,
            email: contactEmail,
            phone: contactPhone,
            id: contactId,
            customerId: contactCustomerId,
            visibleToDev: contactVisibleToDev,
            source: contactSource,
            quoteRecipient: contactQuoteRecipient ? 1 : 0
        };
        saveContact(contact);
    };

    const addContactDetails = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (e) {
            e.preventDefault();
        }

        setEditing(newItem);
        const newContact: ICustomerContactDetails = {
            name: "",
            jobTitle: "",
            email: "",
            phone: "",
            id: newItem,
            customerId: contactCustomerId,
            visibleToDev: false,
            source: "",
            quoteRecipient: 0
        };
        setEditing(newContact.id)
        setContactName(newContact.name);
        setContactTitle(newContact.jobTitle);
        setContactEmail(newContact.email);
        setContactPhone(newContact.phone);
        setContactId(newContact.id);
        setContactQuoteRecipient(newContact.quoteRecipient ? true : false);
        setContactSource(newContact.source);
        setContactVisibleToDev(newContact.visibleToDev);
        let contactDetailsCopy = [...contactDetails];
        contactDetailsCopy.push(newContact);
        setContactDetails(contactDetailsCopy);
    };

    const cancelEditContact = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setEditing(none);
        setRefresh(refresh + 1)
    };

    const onRemove = (id: number) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setShowDeleteDialog(true);
        setDeleting(id);
    };

    const onCloseDeleteDialog = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setShowDeleteDialog(false);
    };

    const onDelete = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setLoading(true);
        setShowDeleteDialog(false);
        deleteCustomerContactDetails(state.webToken, deleting, () => {
            setLoading(false);
            setError("");
            setDeleting(none);
            setRefresh(refresh + 1);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    };


    const onChangeSalesUser = (user: string) => {
        setSalesUser(user);
    }

    const onChangeProjectManagerUser = (user: string) => {
        setManagerUser(user);
    }

    const onChangeArchUser = (user: string) => {
        setArchUser(user);
    }

    const onChangeDevUser = (user: string) => {
        setDevUser(user);
    }


    const onChangeContactVisibleToDev = (checked: boolean) => {
        setContactVisibleToDev(checked);
    };

    const onChangeContactQuoteRecipient = (checked: boolean) => {
        setContactQuoteRecipient(checked);
    };


    const onViewAllOps = () => {
        navigate(`/Opportunities`);
    }

    const onViewUserOps = () => {
        navigate(`/MyOpportunities`);
    }

    const onViewArchivedOps = () => {
        navigate(`/ArchivedOpportunities`);
    }

    const onViewCustomers = () => {
        navigate(`/Customers`);
    }


    const onBack = () => {

    }

    const pages = [
        { page: "All Opportunities", onClick: onViewAllOps },
        { page: "My Opportunities", onClick: onViewUserOps },
        { page: "Archived Opportunities", onClick: onViewArchivedOps },
        { page: "Customers", onClick: onViewCustomers },
        { page: "Contacts", onClick: () => { } },
    ]

    const save = () => {
        setIsEditing(false);
        customer.restartContacts = { architect: archUser, leadDeveloper: devUser, sales: salesUser, projectManager: managerUser };
        updateCustomer(state.webToken, customer, () => {
            setLoading(false);
            setError("");
            setIsEditing(false);
        }, (errorMessage: string) => {
            setLoading(false);
            setIsEditing(false);
            setError(errorMessage);
        });
    }

    const editCustomer = () => {
        setIsEditing(true);
    }

    return <div style={{ position: "absolute", height: "100%", width: "100%", display: "flex", alignItems: "stretch", flexDirection: "column" }}>
        <Header border={COLORS.OPPORTUNITIES_YELLOW} backgroundColor={COLORS.GREY_SLATE} onBack={onBack} previousPageUrl={"/"} />
        <main style={{ flex: "1", display: "flex", alignItems: "stretch" }}>
            <LeftColumn>
                <Tools bannerColor={COLORS.HEATHER_SLATE} />
                <Pages bannerColor={COLORS.HEATHER_SLATE} backgroundColor={COLORS.OPPORTUNITIES_YELLOW} pages={pages} />
            </LeftColumn>
            <RightColumn>
                <Banner $backgroundColor={COLORS.HEATHER_SLATE}>Contacts</Banner>
                <OutsideContainer>
                    <LoadingIndicator type="Linear" show={loading} />
                    <Main>
                        {error && <ErrorText>{error}</ErrorText>}
                        <CustomerHeader>
                            <CustomerInformation>
                                <h3>Select Customer</h3>
                                <Row>
                                    <DynamicSelect width="60%" required={true} placeholder="Enter Customer Name" onChange={onCustomerSelected} childValues={customers && customers.map(option => option.customerName)} value={customerName}>
                                        {customers && customers.filter((obj: { status: boolean; }) => { return obj.status === true }).map((option, index) => <SelectItem height="20px" key={`add-quote-customer-item-${index}`} value={option.customerName}>{option.customerName}</SelectItem>)}
                                    </DynamicSelect>
                                    <ButtonContainer style={{ marginLeft: "20px" }}>
                                        {!isEditing && rbac.update && customer && <Button color={COLORS.FOURTH} onClick={editCustomer}>Edit Customer</Button>}
                                        {isEditing && rbac.update && <Button color={COLORS.FOURTH} onClick={save}>Save Customer</Button>}
                                    </ButtonContainer>
                                </Row>
                            </CustomerInformation>
                            {customer && <RestartContacts>
                                <ContactRow><span style={{ fontSize: "1.7em", fontWeight: "bold" }}>Main Restart Contacts</span></ContactRow>
                                <ContactRow style={{ marginTop: "10px" }}>
                                    <ContactCellLabel>Sales</ContactCellLabel>
                                    {!isEditing && <ContactCell>{getUserById(salesUser, users)}</ContactCell>}
                                    {isEditing && <Select height="24px" fontSize={"0.7em"} required={false} placeholder="Select a user" onChange={onChangeSalesUser} childValues={users && users.map(option => option.id)} value={getUserById(salesUser, users)}>
                                        {users && users.map((option, index) => <SelectItem fontSize="0.7em" height="24px" key={`restart-contacts-user-sales-${index}`} value={option.id}>{option.fullName}</SelectItem>)}
                                    </Select>}
                                </ContactRow>
                                <ContactRow>
                                    <ContactCellLabel>Project Manager</ContactCellLabel>
                                    {!isEditing && <ContactCell>{getUserById(managerUser, users)}</ContactCell>}
                                    {isEditing && <Select height="24px" fontSize={"0.7em"} required={false} placeholder="Select a user" onChange={onChangeProjectManagerUser} childValues={users && users.map(option => option.id)} value={getUserById(managerUser, users)}>
                                        {users && users.map((option, index) => <SelectItem height="20px" key={`restart-contacts-user-manager-${index}`} value={option.id}>{option.fullName}</SelectItem>)}
                                    </Select>}
                                </ContactRow>
                                <ContactRow>
                                    <ContactCellLabel>Architect</ContactCellLabel>
                                    {!isEditing && <ContactCell>{getUserById(archUser, users)}</ContactCell>}
                                    {isEditing && <Select height="24px" fontSize={"0.7em"} required={false} placeholder="Select a user" onChange={onChangeArchUser} childValues={users && users.map(option => option.id)} value={getUserById(archUser, users)}>
                                        {users && users.map((option, index) => <SelectItem height="20px" key={`restart-contacts-user-arch-${index}`} value={option.id}>{option.fullName}</SelectItem>)}
                                    </Select>}
                                </ContactRow>
                                <ContactRow>
                                    <ContactCellLabel>Lead developer</ContactCellLabel>
                                    {!isEditing && <ContactCell>{getUserById(devUser, users)}</ContactCell>}
                                    {isEditing && <Select height="24px" fontSize={"0.7em"} required={false} placeholder="Select a user" onChange={onChangeDevUser} childValues={users && users.map(option => option.id)} value={getUserById(devUser, users)}>
                                        {users && users.map((option, index) => <SelectItem height="20px" key={`restart-contacts-user-dev-${index}`} value={option.id}>{option.fullName}</SelectItem>)}
                                    </Select>}
                                </ContactRow>
                            </RestartContacts>}
                            <OpportunitiesSmallLogo />

                        </CustomerHeader>

                        <Dialog style={{ padding: "32px" }} open={showDeleteDialog} onClose={onCloseDeleteDialog}>
                            <h3>Are you sure you want to delete?</h3>
                            <ActionContainer>
                                <Button color={COLORS.FOURTH} onClick={onCloseDeleteDialog} style={{ marginRight: "10px" }}>Cancel</Button>
                                <Button onClick={onDelete} color={"#e60000"}>Delete</Button>
                            </ActionContainer>
                        </Dialog>

                        {customer && <div>
                            <Row>
                                <TitleAndButton>
                                    <h3 style={{ marginRight: "20px" }}>Contacts</h3>
                                    {isEditing && <Button color={COLORS.FOURTH} onClick={addContactDetails}>Add Contact</Button>}
                                </TitleAndButton>
                            </Row>
                            <Row>
                                {contactDetails && <Table>
                                    <tbody>
                                        <tr style={{ minHeight: "40px", display: "flex" }} >
                                            <TableCell noBorder={true} width={"20%"}>Name</TableCell>
                                            <TableCell noBorder={true} width={"10%"}>Job title</TableCell>
                                            <TableCell noBorder={true} width={"10%"}>Email</TableCell>
                                            <TableCell noBorder={true} width={"10%"}>Phone</TableCell>
                                            <TableCell noBorder={true} width={"10%"}>Visible to Dev</TableCell>
                                            <TableCell noBorder={true} width={"10%"}>Source</TableCell>
                                            <TableCell noBorder={true} width={"10%"}>Quote Recipients</TableCell>
                                            <TableCell noBorder={true} width={"20%"}></TableCell>
                                        </tr>
                                        {contactDetails.map((contact, index) => {
                                            const id = contact.id ? contact.id : none;
                                            if (editing === id) {
                                                return <tr key={`contact-entry=${index}`} style={{ minHeight: "40px", display: "flex" }}>
                                                    <TableCell noBorder={true} width={"20%"}>
                                                        <TextInput width="80" onKeyDown={handleKeyboardSelect(saveContactDetails, false)} value={contactName} onChange={updateText(setContactName)} placeholder="Name" />
                                                    </TableCell>
                                                    <TableCell noBorder={true} width={"10%"}>
                                                        <TextInput onKeyDown={handleKeyboardSelect(saveContactDetails, false)} value={contactTitle} onChange={updateText(setContactTitle)} placeholder="Job title" />
                                                    </TableCell>
                                                    <TableCell noBorder={true} width={"10%"}>
                                                        <TextInput onKeyDown={handleKeyboardSelect(saveContactDetails, false)} value={contactEmail} onChange={updateText(setContactEmail)} placeholder="Email" />
                                                    </TableCell>
                                                    <TableCell noBorder={true} width={"10%"}>
                                                        <TextInput onKeyDown={handleKeyboardSelect(saveContactDetails, false)} value={contactPhone} onChange={updateText(setContactPhone)} placeholder="Phone" />
                                                    </TableCell>
                                                    <TableCell noBorder={true} width={"10%"}>
                                                        <Checkbox checked={contactVisibleToDev} onChange={onChangeContactVisibleToDev} />
                                                    </TableCell>
                                                    <TableCell noBorder={true} width={"10%"}>
                                                        <TextInput onKeyDown={handleKeyboardSelect(saveContactDetails, false)} value={contactSource} onChange={updateText(setContactSource)} placeholder="Source" />
                                                    </TableCell>
                                                    <TableCell noBorder={true} width={"10%"}>
                                                        <Checkbox checked={contactQuoteRecipient} onChange={onChangeContactQuoteRecipient} />
                                                    </TableCell>
                                                    <TableCell noBorder={true} width={"20%"}>
                                                        <ButtonContainer>
                                                            {rbac.update && <Button plain={true} onClick={saveContactDetails} style={{ marginRight: "10px" }}>Save</Button>}
                                                            {rbac.update && <Button plain={true} onClick={cancelEditContact}>Cancel</Button>}
                                                        </ButtonContainer>
                                                    </TableCell>
                                                </tr>;
                                            } else {
                                                return <tr key={`contact-entry=${index}`} style={{ minHeight: "48px", display: "flex" }}>
                                                    <TableCell noBorder={true} width={"20%"}>{contact.name}</TableCell>
                                                    <TableCell noBorder={true} width={"10%"}>{contact.jobTitle}</TableCell>
                                                    <TableCell noBorder={true} width={"10%"}>{contact.email}</TableCell>
                                                    <TableCell noBorder={true} width={"10%"}>{contact.phone}</TableCell>
                                                    <TableCell noBorder={true} width={"10%"}>{contact.visibleToDev ? "Yes" : "No"}</TableCell>
                                                    <TableCell noBorder={true} width={"10%"}>{contact.source}</TableCell>
                                                    <TableCell noBorder={true} width={"10%"}>{contact.quoteRecipient ? "Primary" : "Additional"}</TableCell>
                                                    <TableCell noBorder={true} width={"20%"}>
                                                        {editing === none &&
                                                            <ButtonContainer>
                                                                {rbac.update && <Button plain={true} onClick={editContact(contact)} style={{ marginRight: "10px" }}>Edit</Button>}
                                                                {rbac.update && <Button plain={true} onClick={onRemove(id)}>Remove</Button>}
                                                            </ButtonContainer>
                                                        }
                                                    </TableCell>
                                                </tr>;
                                            }
                                        })}
                                    </tbody>
                                </Table>}
                            </Row>

                        </div>}
                    </Main>
                </OutsideContainer>
            </RightColumn>
        </main>
    </div >
};

export default CustomerEdit;
