import styled from "styled-components";

export default styled.div`
    display: flex;
    flex-direction: row;
    padding: 24px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
    flex: 0 0 auto;
`;
