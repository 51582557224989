const handleEnterDown = (onClick: (event: any) => void) => (event: any) => {
    const key = event.key || event.keyCode;
    if (key === "Enter" || key === " ") {
        event.stopPropagation();
        event.preventDefault();
        onClick(event);
    }
};

export default handleEnterDown;
