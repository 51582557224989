import * as React from "react";
import styled, { keyframes } from "styled-components";

type LoadingIndicatorType = "Linear" | "Radial";

interface IProps {
    type: LoadingIndicatorType
    color?: string,
    style?: React.CSSProperties,
    show: boolean,
    noSpace?: boolean
}

const LinearActivity = styled.div`
    overflow: hidden;
    width: 100%;
    height: 4px;
    background-color: #B3E5FC;
    flex: 0 0 auto;
`;

const indeterminateFirst = keyframes`
    0% {
        left: -100%;
        width: 100%;
    }
    100% {
        left: 100%;
        width: 10%;
    }
`;

const indeterminateSecond = keyframes`
    0% {
        left: -150%;
        width: 100%;
    }
    100% {
        left: 100%;
        width: 10%;
    }
`;

const rotateAnimation = keyframes`
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const spinAnimation = keyframes`
    0% {
        stroke-dashoffset: 268.60617px;
        transform: rotate(0);
    }

    12.5% {
        stroke-dashoffset: 56.54867px;
        transform: rotate(0);
    }

    12.5001% {
        stroke-dashoffset: 56.54867px;
        transform: rotateX(180deg) rotate(72.5deg);
    }


    25% {
        stroke-dashoffset: 268.60617px;
        transform: rotateX(180deg) rotate(72.5deg);
    }

    25.0001% {
        stroke-dashoffset: 268.60617px;
        transform: rotate(270deg);
    }

    37.5% {
        stroke-dashoffset: 56.54867px;
        transform: rotate(270deg);
    }

    37.5001% {
        stroke-dashoffset: 56.54867px;
        transform: rotateX(180deg) rotate(161.5deg);
    }

    50% {
        stroke-dashoffset: 268.60617px;
        transform: rotateX(180deg) rotate(161.5deg);
    }

    50.0001% {
        stroke-dashoffset: 268.60617px;
        transform: rotate(180deg);
    }

    62.5% {
        stroke-dashoffset: 56.54867px;
        transform: rotate(180deg);
    }

    62.5001% {
        stroke-dashoffset: 56.54867px;
        transform: rotateX(180deg) rotate(251.5deg);
    }

    75% {
        stroke-dashoffset: 268.60617px;
        transform: rotateX(180deg) rotate(251.5deg);
    }

    75.0001% {
        stroke-dashoffset: 268.60617px;
        transform: rotate(90deg);
    }

    87.5% {
        stroke-dashoffset: 56.54867px;
        transform: rotate(90deg);
    }

    87.5001% {
        stroke-dashoffset: 56.54867px;
        transform: rotateX(180deg) rotate(341.5deg);
    }

    100% {
        stroke-dashoffset: 268.60617px;
        transform: rotateX(180deg) rotate(341.5deg);
    }
`;

const Indeterminate = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    &:before{
        content: '';
        position: absolute;
        height: 100%;
        background-color: #03A9F4;
        animation: ${indeterminateFirst} 1.5s infinite ease-out;
    }
    &:after{
        content: '';
        position: absolute;
        height: 100%;
        background-color: #4FC3F7;
        animation: ${indeterminateSecond} 1.5s infinite ease-in;
    }
`;

interface ICircleStyleProps {
    color: string | undefined
};

const CircleBit = styled.circle<ICircleStyleProps>`
    animation-name: ${spinAnimation};
    stroke-width: 10%;
    fill: transparent;
    transform-origin: center;
    transition: stroke-dashoffset 225ms linear;
    transition-property: stroke;
    animation-duration: 4s;
    animation-timing-function: cubic-bezier(.35,0,.25,1);
    animation-iteration-count: infinite;
    stroke: ${props => props.color ? props.color : "#187fda"};
    stroke-dasharray: 282.743px;
`;

const Picture = styled.svg`
    transform: rotate(-90deg);
    top: 0;
    left: 0;
    transform-origin: center;
    overflow: visible;
    width: 50px; 
    height: 50px;
    animation:${rotateAnimation} 2s linear infinite;
`;

const LoadingIndicator = (props: IProps) => {
    if (props.type === "Linear") {
        if (props.show) {
            return <LinearActivity className="LinearLoadingIndicator" style={props.style}><Indeterminate /></LinearActivity>
        } else {
            if (props.noSpace) {
                return null
            }
            return <div style={{ lineHeight: "4px", width: "100%" }}>&nbsp;</div>
        }
    }
    if (props.show) {
        return <Picture className="RadialLoadingIndicator" focusable={false} preserveAspectRatio="xMidYMid meet" viewBox="0 0 100 100" style={props.style}>
            <CircleBit cx="50%" cy="50%" r="45" color={props.color} />
        </Picture >
    }
    return null;
}

export default LoadingIndicator;
