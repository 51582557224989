import * as React from "react";
import { RolesContext } from "../Roles/RolesContext";

export const useIsFinancialUser = () => {
    const rolesContext = React.useContext(RolesContext);
    const currentRole = rolesContext.state.role;

    if (currentRole && currentRole === "Financial User") {
        return true;
    }

    return false;
};
