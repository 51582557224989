import * as React from "react";
import TextInput, { updateText } from "../../../SharedComponents/TextInput";
import { COLORS } from "../../../config";
import Button from "../../../SharedComponents/Button";
import Dialog from "../../../SharedComponents/Dialog/Dialog";
import DialogActions from "../../../SharedComponents/Dialog/DialogActions";
import DialogContent from "../../../SharedComponents/Dialog/DialogContent";
import DialogContentText from "../../../SharedComponents/Dialog/DialogContentText";
import DialogTitle from "../../../SharedComponents/Dialog/DialogTitle";
import Select from "../../../SharedComponents/Select/Select";
import SelectItem from "../../../SharedComponents/Select/SelectItem";

interface IProps {
    show: boolean,
    onCommit: (reason: string, revisit: number | undefined) => void,
    onClose: () => void
};

const CloseOpportunityDialog = (props: IProps) => {
    const [reason, setReason] = React.useState("");
    const [revisit, setRevisit] = React.useState("");

    const onCommit = () => {
        let currentDate = new Date();
        let revisitDate: number | undefined;
        switch (revisit) {
            case "6 Months":
                currentDate.setMonth(currentDate.getMonth() + 6);
                revisitDate = currentDate.getTime();
                break;
            case "12 Months":
                currentDate.setMonth(currentDate.getMonth() + 12);
                revisitDate = currentDate.getTime();
                break;
        }

        props.onCommit(reason, revisitDate);
    };

    const closeDialog = () => {
        setReason("");
        setRevisit("");
        props.onClose();
    };

    const validate = () => {

        if (reason.length > 0 && revisit.length > 0) {
            return true;
        }

        return false;
    }

    return <Dialog open={props.show} style={{ height: "400px" }} onClose={closeDialog}>
        <DialogTitle className="DialogTitle">Close Opportunity</DialogTitle>
        <DialogContent className="DialogContent">
            <DialogContentText className="DialogContentText">You are closing this Opportunity, which will Archive it.</DialogContentText>
            <DialogContentText className="DialogContentText">Add a reason for closure and select the time period you want to wait before revisiting this Opportunity.</DialogContentText>
            <div style={{ marginTop: "30px" }}>
                <TextInput textArea={true} value={reason} onChange={updateText(setReason)} placeholder="Enter Reason" />
            </div>
            <div style={{ marginTop: "20px" }} />
            <Select onChange={setRevisit} childValues={[]} placeholder="Choose when to revisit" value={revisit}>
                <SelectItem key={`select-order-source-item-0`} value={"Never"}>Never</SelectItem>
                <SelectItem key={`select-order-source-item-1`} value={"6 Months"}>6 Months</SelectItem>
                <SelectItem key={`select-order-source-item-2`} value={"12 Months"}>12 Months</SelectItem>
            </Select>
        </DialogContent>
        <DialogActions className="DialogActions">
            <Button disabled={!validate()} margin="0 15px" color={COLORS.GREY_SLATE} onClick={onCommit}>Commit</Button>
            <Button margin="0 15px" color={COLORS.GREY_SLATE} onClick={closeDialog}>Cancel</Button>
        </DialogActions>
    </Dialog>
};

export default CloseOpportunityDialog;
