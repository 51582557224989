import * as React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";

import DataSorter from "../../../../UsefulFunctions/DataSorter";
import dateConverter from "../../../../UsefulFunctions/dateConverter";
import { componentConfiguration } from "../../../../UsefulFunctions/getComponentConfiguration";
import { canViewPricingDetail } from "../../../../UsefulFunctions/quoteUtils";

import Button from "../../../../SharedComponents/Button";
import Select from "../../../../SharedComponents/Select/Select";
import SelectItem from "../../../../SharedComponents/Select/SelectItem";
import TableSortLabel from "../../../../SharedComponents/Table/TableSortLabel";
import TextInput, { updateText } from "../../../../SharedComponents/TextInput";

import { RolesContext } from "../../../../Roles/RolesContext";
import { FONTSIZE } from "../../../../config";
import IUser from "../../../../ServerEntities/IUser";
import IPricing from "../../../../ServerEntities/IPricing";
import { QuotesSmallLogo } from "../../../MainMenu";
import { TopContainer } from "../QuotesTable";

export const Main = styled.main`
    padding: 20px;
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: ${FONTSIZE};
    display: flex;
    flex-direction: column;
    flex: 5 1 0;
`;

export const Title = styled.h1`
    font-size: 1.3rem;
    margin: 12px 0px 20px;
`;

const ActionContainer = styled.div`
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

const Table = styled.table`
    border-collapse: collapse;
`;

const TableCell = styled.td`
    height: 24px;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

interface IProps {
    onView: (pricing: IPricing) => void,
    onEdit: (pricing: IPricing) => void,
    users: IUser[],
    onDelete: () => void,
    onRefresh: () => void,
    pricing: IPricing[],
    allPricing: boolean,
};

export const STATUS = ["Ready For Pricing", "Priced", "Returned", "Authorised"];
export const STATUS_COMMITED = 1;

const PricingTable = (props: IProps) => {
    const { configuration } = React.useContext(RolesContext).state;
    const rbac = componentConfiguration("Quotes", configuration);
    const { allPricing } = props;
    const pricing = props.pricing ? props.pricing : [];
    const [sortByColumn, setSortByColumn] = React.useState(2);
    const [sortDescending, setSortDescending] = React.useState(true);
    const [quoteNumberFilter, setQuoteNumberFilter] = React.useState("");
    const [enteredDateFilter, setEnteredDateFilter] = React.useState(undefined as unknown as Date);
    const [customerFilter, setCustomerFilter] = React.useState("");
    const [projectNameFilter, setProjectNameFilter] = React.useState("");
    const [statusFilter, setStatusFilter] = React.useState("");

    const viewOrder = (pricingId: number) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const pricingItem = pricing.find((pricing: IPricing) => pricing.id === pricingId)
        if (pricingItem) {
            props.onView(pricingItem);
        }
    };

    const editOrder = (pricingId: number) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const pricingItem = pricing.find((pricing: IPricing) => pricing.id === pricingId)
        if (pricingItem) {
            props.onEdit(pricingItem);
        }
    };

    const changeSortColumn = (columnIndex: number) => () => {
        if (columnIndex === sortByColumn) {
            setSortDescending(!sortDescending);
        } else {
            setSortDescending(true);
        }
        setSortByColumn(columnIndex);
    };

    const filterPricing = (pricing: IPricing) => {
        if (quoteNumberFilter.length > 0 && !pricing.quote.quoteNumber.toLowerCase().includes(quoteNumberFilter.toLowerCase())) {
            return false;
        }
        if (enteredDateFilter && !dateConverter(pricing.quote.enteredDate).toLowerCase().includes(dateConverter(enteredDateFilter).toLowerCase())) {
            return false;
        }
        if (customerFilter.length > 0 && !pricing.quote.customer.toLowerCase().includes(customerFilter.toLowerCase())) {
            return false;
        }
        if (projectNameFilter.length > 0 && !pricing.quote.projectName.toLowerCase().includes(projectNameFilter.toLowerCase())) {
            return false;
        }
        if (statusFilter.length > 0 && !STATUS[pricing.status].toLocaleLowerCase().includes(statusFilter.toLowerCase())) {
            return false;
        }
        return true;
    }

    const pricingToArray = (pricing: IPricing, index: number): [number, string, number, string, string, number, number] => [
        pricing.id,
        pricing.quote ? pricing.quote.quoteNumber : "",
        pricing.quote ? pricing.quote.enteredDate : 0,
        pricing.quote ? pricing.quote.customer : "",
        pricing.quote ? pricing.quote.projectName : "",
        pricing.status,
        index
    ];

    const onClearFilters = () => {
        setQuoteNumberFilter("");
        setEnteredDateFilter(undefined as unknown as Date);
        setCustomerFilter("");
        setProjectNameFilter("");
        setStatusFilter("");
    };

    const changeDate = (setter: (date: Date) => void) => (date: Date, event: React.SyntheticEvent) => {
        setter(date);
    };

    const PRICING_ID = 0
    const PRICING_QUOTE_NUMBER = 1;
    const PRICING_ENTERED_DATE = 2;
    const PRICING_CUSTOMER = 3;
    const PRICING_PROJECT_NAME = 4;
    const PRICING_STATUS = 5;

    return <Main>
        <TopContainer style={{ justifyContent: "flex-end" }}>
            <QuotesSmallLogo />
        </TopContainer>
        <Table>
            <thead>
                <tr>
                    <th style={{ width: "10%" }}><TableSortLabel active={sortByColumn === PRICING_QUOTE_NUMBER} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(PRICING_QUOTE_NUMBER)}>Quote Number</TableSortLabel></th>
                    <th style={{ width: "10%" }}><TableSortLabel active={sortByColumn === PRICING_ENTERED_DATE} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(PRICING_ENTERED_DATE)}>Entered Date</TableSortLabel></th>
                    <th style={{ width: "30%" }}><TableSortLabel active={sortByColumn === PRICING_CUSTOMER} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(PRICING_CUSTOMER)}>Customer</TableSortLabel></th>
                    <th style={{ width: "30%" }}><TableSortLabel active={sortByColumn === PRICING_PROJECT_NAME} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(PRICING_PROJECT_NAME)}>Project Name</TableSortLabel></th>
                    <th style={{ width: "10%" }}><TableSortLabel active={sortByColumn === PRICING_STATUS} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(PRICING_STATUS)}>Status</TableSortLabel></th>
                    <th style={{ width: "10%" }}></th>
                </tr>
                <tr>
                    <th><TextInput value={quoteNumberFilter} onChange={updateText(setQuoteNumberFilter)}></TextInput></th>
                    <th>
                        <div style={{ border: "1px solid #e5e5e5", borderRadius: "3px", lineHeight: "30px" }}>
                            <DatePicker dateFormat="dd-MMM-yyyy" selected={enteredDateFilter} onChange={changeDate(setEnteredDateFilter)} />
                        </div>
                    </th>
                    <th><TextInput value={customerFilter} onChange={updateText(setCustomerFilter)}></TextInput></th>
                    <th><TextInput value={projectNameFilter} onChange={updateText(setProjectNameFilter)}></TextInput></th>
                    <th>
                        <Select onChange={setStatusFilter} childValues={STATUS.map(option => option)} value={statusFilter}>
                            {STATUS.map((option, index) => <SelectItem key={`select-op-status-item-${index}`} value={option}>{option}</SelectItem>)}
                        </Select>
                    </th>
                    <th style={{ textAlign: "center" }}> <Button plain={true} onClick={onClearFilters} >clear filters</Button></th>
                </tr>
            </thead>
            <tbody>
                {pricing
                    .filter(filterPricing)
                    .map(pricingToArray)
                    .sort(DataSorter(sortByColumn, sortDescending))
                    .map((pricing, index: any) => {
                        console.log(pricing[PRICING_QUOTE_NUMBER] + "-" + pricing[PRICING_STATUS]);
                        console.log(rbac.update);
                        console.log(!allPricing);
                        return <tr key={`order-table-row-${index}`} >
                            <TableCell>{pricing[PRICING_QUOTE_NUMBER]}</TableCell>
                            <TableCell>{dateConverter(pricing[PRICING_ENTERED_DATE])}</TableCell>
                            <TableCell>{pricing[PRICING_CUSTOMER]}</TableCell>
                            <TableCell>{pricing[PRICING_PROJECT_NAME]}</TableCell>
                            <TableCell>{STATUS[pricing[PRICING_STATUS]]}</TableCell>
                            <TableCell>
                                <ActionContainer>
                                    {rbac.read && <Button plain={true} onClick={viewOrder(pricing[PRICING_ID])} style={{ marginRight: "10px" }}>View</Button>}
                                    {rbac.update && pricing[PRICING_STATUS] !== STATUS_COMMITED && <Button plain={true} onClick={editOrder(pricing[PRICING_ID])} style={{ marginRight: "10px" }}>Edit</Button>}
                                </ActionContainer>
                            </TableCell>
                        </tr>;
                    })}
            </tbody>
        </Table>
    </Main >
};

export default PricingTable;
