import * as React from "react";
import styled from "styled-components";
import { SessionContext } from "../../SessionContext";
import Checkbox from "../../../SharedComponents/Checkbox";
import ICurrentStatus from "../../../ServerEntities/ICurrentStatus";
import { COLORS } from "../../../config";

interface IStatusBlockedContainer {
    backgroundColor: string
};

const StatusBlockedContainer = styled.div<IStatusBlockedContainer>`
    display: flex;
    background: ${props => props.backgroundColor};
    border-radius: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    align-items: stretch;
    margin-bottom: 10px;
`

const Status = styled.div`
    display: flex;
    width: 100%;
    justify-content: center
    align-items: stretch;
    flex: 4 1 0;
`

const StatusLabel = styled.label`
    flex: 1 1 0;
    margin-left: 16px;
    color: white; 
    font-weight: 600; 
    font-size: 0.9em;
    display: flex;
    width: 175px;
    padding: 2px;
    padding-left: 5px;
    padding-right: 5px;
    align-items: center;
`

const StatusValue = styled.input`
    height: 100%;
    justify-content: center; 
    display: flex;
    flex: 12 1 0;
    background: white; 
    border-radius: 5px; 
    padding: 0;
    padding-left: 10px;
    font-size: 0.9em;
`;

const Blocked = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex: 1 1 0;
`
const BlockedLabel = styled.label`
display: flex;
padding: 2px;
padding-left: 5px;
padding-right: 5px;
display: flex;
color: white;
font-weight: 600; 
margin-right: 10px; 
width: auto; 
font-size: 0.9em;            
`

interface IProps {
    status: ICurrentStatus
    onUpdateStatus: (status: ICurrentStatus) => void
};


const StatusBar = (props: IProps) => {
    const { state } = React.useContext(SessionContext);
    const [text, setText] = React.useState("");
    const [blocked, setBlocked] = React.useState(false);

    React.useEffect(() => {
        if (props.status) {
            setText(props.status.text);
            setBlocked(props.status.blocked);
        }
    }, [props.status]);

    const onToggleBlocked = (check: boolean) => {
        setBlocked(check);
        props.onUpdateStatus({
            text: text,
            blocked: check,
            updated: 0,
            updatedBy: state.loggedInUser
        })
    }

    return <StatusBlockedContainer backgroundColor={COLORS.GREY_SLATE}>
        <Status>
            <StatusLabel>Status</StatusLabel>
            <StatusValue></StatusValue>
        </Status>
        <Blocked>
            <BlockedLabel>Blocked</BlockedLabel>
            <Checkbox colors={["white", "white", "white"]}
                checked={blocked} disabled={false} onChange={onToggleBlocked}></Checkbox>
        </Blocked>
    </StatusBlockedContainer>
};

export default StatusBar;
