import IConfiguration from "../ServerEntities/IConfiguration";
import IConfigurationComponent from "../ServerEntities/IConfigurationComponent";
import IConfigurationPage from "../ServerEntities/IConfigurationPage";

export const componentConfiguration = (component: string, configuration?: IConfiguration) => {
    let config: IConfigurationComponent | undefined;
    if (configuration) {
        config = configuration.components.find(c => c.name === component);
    }
    if (config) {
        return config;
    }
    else {
        return { name: component, create: false, read: false, update: false, delete: false, authorise: false, archive: false, unarchive: false, assign: false, management: false }
    }
};

export const pageConfiguration = (page: string, configuration?: IConfiguration) => {
    let config: IConfigurationPage | undefined;
    if (configuration) {
        config = configuration.pages.find(c => c.name.includes(page));
    }
    if (config) {
        return config;
    }
    else {
        return undefined
    }
};

export const pageCRUD = (page: string, configuration?: IConfiguration) => {
    let config: IConfigurationPage | undefined;
    if (configuration) {
        config = configuration.pages.find(c => c.name.includes(page));
    }
    if (config) {
        return config
    }
    else {
        return { name: page, create: false, read: false, update: false, delete: false }
    }
};

