import * as React from "react";
import styled from "styled-components";
import { SessionContext } from "../../SessionContext";

import Button from "../../../SharedComponents/Button";
import ErrorText from "../../../SharedComponents/ErrorText";
import LoadingIndicator from "../../../SharedComponents/LoadingIndicator";
import DynamicSelect from "../../../SharedComponents/Select/DynamicSelect";
import Select from "../../../SharedComponents/Select/Select";
import SelectItem from "../../../SharedComponents/Select/SelectItem";
import TextInput, { updateText } from "../../../SharedComponents/TextInput";
import {
    Main, ActionContainer, ButtonContainer, Status, StatusBlockedContainer, StatusLabel,
    StatusValue, Blocked, BlockedLabel, Actions, FormHeader, Department, ColumnsContainer, ColumnContainer, Row,
    Label, Value
} from "./QuoteDetails";
import Dialog from "../../../SharedComponents/Dialog/Dialog";
import Checkbox from "../../../SharedComponents/Checkbox";
import { MenuItemTagOrName, MenuItem } from "../../MainMenu";

import { createQuoteNotification, getRelatedQuotes, saveQuote } from "./QuotesService";
import { getCustomers } from "../Customers/CustomersService";

import ICustomer from "../../../ServerEntities/ICustomer";
import IQuote from "../../../ServerEntities/IQuote";
import IQuoteType from "../../../ServerEntities/IQuoteType";
import { COLORS, } from "../../../config";
import { createTriggerCreatedQuote } from "../../../UsefulFunctions/createTriggers";
import { getCustomerIdBy, getUserById, getUserIdBy, getUserObjectById } from "../../../UsefulFunctions/quoteUtils";
import IUser from "../../../ServerEntities/IUser";
import { getTypeById } from "../../../UsefulFunctions/opportunityUtils";
import IRelatedQuote from "../../../ServerEntities/IRelatedQuote";
import useEffectOnSome from "../../../CustomHooks/useEffectOnSome";

const QuoteAddForm = styled.form`
    display: flex; 
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
    max-height: 100%;
    align-items: stretch;
    margin: 5px;
`

interface IProps {
    onAddComplete: () => void,
    types: IQuoteType[],
    users: IUser[],
    onBack: () => void
};

const QuoteAdd = (props: IProps) => {
    const { state } = React.useContext(SessionContext);
    const { types, users, onBack } = props;
    const [customer, setCustomer] = React.useState("");
    const [projectName, setProjectName] = React.useState("");
    const [orderOwner, setOrderOwner] = React.useState("");
    const [typeId, setTypeId] = React.useState(null as unknown as number);
    const [projectValue, setProjectValue] = React.useState("");
    const [relatedQuotes, setRelatedQuotes] = React.useState([] as unknown as IRelatedQuote[]);
    const [relatedQuote, setRelatedQuote] = React.useState("");
    const [quotes, setQuotes] = React.useState(null as unknown as string[]);
    const [workRequired, setWorkRequired] = React.useState("");
    const now = new Date();
    const enteredDate = Date.now();
    const expiryDate = now.setMonth(now.getMonth() + 3);

    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [customers, setCustomers] = React.useState(null as unknown as ICustomer[])
    const [quoteToAdd, setQuoteToAdd] = React.useState(false);
    const [quoteToCancel, setQuoteToCancel] = React.useState(false);
    const [quoteToCommitToScoping, setQuoteToCommitToScoping] = React.useState(false);
    const [currentStatusComment, setCurrentStatusComment] = React.useState("");
    const [blocked, setBlocked] = React.useState(false);
    const labelValueFontSize = "14px";

    useEffectOnSome(() => {
        setLoading(true);
        getCustomers(state.webToken, (customers: ICustomer[]) => {
            setLoading(false);
            setError("");
            setCustomers(customers);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
            setCustomers(null as unknown as ICustomer[]);
        });
    }, []);

    useEffectOnSome(() => {
        setLoading(true);
        const customerId = getCustomerIdBy(customer, customers);
        if (customerId !== undefined) {
            getRelatedQuotes(state.webToken, customerId, (quotes: string[]) => {
                setLoading(false);
                setError("");
                setQuotes(quotes);
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
                setQuotes([]);
            });
        }
    }, [customer]);

    const save = (saveType: string) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const owner = getUserObjectById(orderOwner, users);
        const quote: IQuote = {
            id: 0,
            orderOwner: getUserIdBy(orderOwner, users),
            quoteNumber: "",
            enteredDate: enteredDate,
            customerId: getCustomerIdBy(customer, customers),
            customer: customer,
            projectName: projectName,
            projectValue: projectValue,
            orderEnteredBy: state.loggedInUser,
            relatedQuotes: relatedQuotes,
            orderYear: 0,
            orderSequence: 0,
            archived: false,
            expiryDate: expiryDate,
            workAuthorisedBy: "",
            workAuthorisedDate: 0,
            winStatus: "",
            closed: false,
            closedDate: undefined,
            authorised: false,
            typeId: typeId,
            status: saveType,
            workRequired: workRequired,
            integrationEngine: "",
            integrationEngineNotes: "",
            currentStatus: {
                text: currentStatusComment,
                blocked: blocked,
                updated: Date.now(),
                updatedBy: getUserIdBy(state.loggedInUser, users)
            }
        };
        setLoading(true);
        saveQuote(state.webToken, owner ? owner.initials : "", quote, (createdQuote: IQuote) => {
            setLoading(false);
            setError("");
            props.onAddComplete();
            createQuoteNotification(
                createTriggerCreatedQuote(
                    createdQuote.id.toString(),
                    createdQuote.quoteNumber,
                    createdQuote.projectName), state.webToken, () => {

                    },
                () => {

                },
            )
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    const onChangeCustomer = (customer: string) => {
        setCustomer(customer);
    };

    const onChangeOrderOwner = (user: string) => {
        setOrderOwner(user);
    };

    const onChangeType = (typeId: number) => {
        setTypeId(typeId);
    };

    const validateForm = () => {
        if (customer.length > 0 && projectName.length > 0 && orderOwner.length > 0 && typeId > 0 && workRequired.length > 0) {
            return true;
        }
        return false;
    };

    const cancel = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        onBack()
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    const onOpenSaveDialog = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setQuoteToAdd(true);
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    const onCloseSaveDialog = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setQuoteToAdd(false);
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    const onOpenCancelDialog = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setQuoteToCancel(true);
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    const onCloseCancelDialog = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setQuoteToCancel(false);
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    const onOpenSaveAndCommitDialog = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setQuoteToCommitToScoping(true);
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    const onCloseSaveAndCommitDialog = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setQuoteToCommitToScoping(false);
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    const onChangeRelatedQuote = (quoteId: string) => {
        setRelatedQuote(quoteId)
        if ((quoteId !== "") && (relatedQuotes.filter((relQuote) => relQuote.quoteNumber === quoteId).length === 0)) {
            const newRelQuote: IRelatedQuote = { "id": 0, "parentQuoteId": 0, "quoteNumber": quoteId, "quoteDetails": "" }
            relatedQuotes.push(newRelQuote);
        }
    }

    const onChangeRelatedQuoteDetail = (quoteId: string) => (e: React.SyntheticEvent<HTMLInputElement>) => {
        const newRelatedQuotes: IRelatedQuote[] = relatedQuotes.map((relQuote: IRelatedQuote) => {
            if ((relQuote.quoteNumber) === quoteId) {
                return { ...relQuote, quoteDetails: e.currentTarget.value }
            } else { return relQuote }
        });
        setRelatedQuotes(newRelatedQuotes);
    }

    const onRemoveRelatedQuote = (quoteId: string) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const newRelatedQuotes: IRelatedQuote[] = relatedQuotes.filter((relQuote: IRelatedQuote) => relQuote.quoteNumber !== quoteId)
        setRelatedQuotes(newRelatedQuotes);
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    const onChangeBlocked = () => {
        blocked ? setBlocked(false) : setBlocked(true);
    }

    return <Main>
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorText>{error}</ErrorText>}
        <Dialog style={{ padding: "32px" }} open={quoteToAdd} onClose={onCloseSaveDialog}>
            <h3>Are you sure you want to save?</h3>
            <ActionContainer>
                <Button color={COLORS.FIFTH} onClick={onCloseSaveDialog} style={{ marginRight: "10px" }}>Cancel</Button>
                <Button disabled={!validateForm()} onClick={save("Saved")} color={"#e60000"}>Save</Button>
            </ActionContainer>
        </Dialog>
        <Dialog style={{ padding: "32px" }} open={quoteToCancel} onClose={onCloseCancelDialog}>
            <h3>Are you sure you want to cancel?</h3>
            <ActionContainer>
                <Button color={COLORS.FIFTH} onClick={onCloseCancelDialog} style={{ marginRight: "10px" }}>No</Button>
                <Button onClick={cancel} color={"#e60000"}>Yes</Button>
            </ActionContainer>
        </Dialog>
        <Dialog style={{ padding: "32px" }} open={quoteToCommitToScoping} onClose={onCloseSaveAndCommitDialog}>
            <h3>Are you sure you save and commit to scoping?</h3>
            <ActionContainer>
                <Button color={COLORS.FIFTH} onClick={onCloseSaveAndCommitDialog} style={{ marginRight: "10px" }}>Cancel</Button>
                <Button onClick={save("Committed")} color={"#e60000"}>Save and Commit</Button>
            </ActionContainer>
        </Dialog>
        <QuoteAddForm>
            <div style={{ display: "flex", flex: "1 1 0" }}>
                <div style={{ display: "flex", flexDirection: "column", flex: "7 1 0", alignItems: "stretch" }}>
                    <StatusBlockedContainer backgroundColor={COLORS.GREY_SLATE}>
                        <Status>
                            <StatusLabel>Status</StatusLabel>
                            <StatusValue value={currentStatusComment} onChange={updateText(setCurrentStatusComment)} placeholder="Status comment"></StatusValue>
                        </Status>
                        <Blocked>
                            <BlockedLabel>Blocked</BlockedLabel>

                            <Checkbox colors={["white", "white", "white"]}
                                checked={blocked} disabled={false} onChange={onChangeBlocked}></Checkbox>

                        </Blocked>
                    </StatusBlockedContainer>
                    <Actions>
                        <ButtonContainer>
                            {<Button disabled={!validateForm()} color={COLORS.GREY_SLATE} onClick={onOpenSaveDialog}>Save Quote</Button>}
                        </ButtonContainer>
                        {<ButtonContainer>
                            {<Button color={COLORS.GREY_SLATE} onClick={onOpenCancelDialog}>Cancel</Button>}
                        </ButtonContainer>}
                        {<ButtonContainer>
                            {<Button disabled={!validateForm()} color={COLORS.GREY_SLATE} onClick={onOpenSaveAndCommitDialog}>Save & Commit for Scoping</Button>}
                        </ButtonContainer>}
                    </Actions>
                </div>
                <div style={{ display: "flex", flex: "1 1 0", justifyContent: "center", alignItems: "center" }} >
                    <MenuItem style={{ maxHeight: "60px", maxWidth: "60px" }} $hover={false} key="expenses-menu-item" onClick={() => null} $backgroundColor={COLORS.GREY_SLATE} $borderColor={COLORS.QUOTES_PINK} $color="#ffffff">
                        <MenuItemTagOrName $fontSize="2em" $fontWeight="600">Qu</MenuItemTagOrName>
                    </MenuItem>
                </div>
            </div>
            <div style={{ alignItems: "center", display: "flex", width: "100%", flex: "1 1 0" }}>
                <Department style={{ marginLeft: "5px" }} backgroundColor="yellow">Sales</Department>
            </div>
            <ColumnsContainer>
                <ColumnContainer>
                    <Row>
                        <FormHeader>Quote Details</FormHeader>
                    </Row>
                    <Row>
                        <Label htmlFor="customer">Customer</Label>
                        <Value>
                            <DynamicSelect fontSize={labelValueFontSize} required={true} placeholder="Select a customer" onChange={onChangeCustomer} childValues={customers && customers.map(option => option.customerName)} value={customer}>
                                {customers && customers.filter((obj: { status: boolean; }) => { return obj.status === true }).map((option, index) => <SelectItem fontSize={labelValueFontSize} height="20px" key={`add-quote-customer-item-${index}`} value={option.customerName}>{option.customerName}</SelectItem>)}
                            </DynamicSelect>
                        </Value>
                    </Row>
                    <Row style={{ height: "40px" }} />
                    <Row>
                        <Label htmlFor="projectName">Project name</Label>
                        <Value>
                            <TextInput switchPaddingToInnerBox={true} fontSize={labelValueFontSize} required={true} htmlId="location" value={projectName} onChange={updateText(setProjectName)} placeholder="Project name" />
                        </Value>
                    </Row>
                    <Row style={{ height: "40px" }} />
                    <Row>
                        <Label htmlFor="projectValue">Project value</Label>
                        <Value>
                            <TextInput switchPaddingToInnerBox={true} fontSize={labelValueFontSize} required={false} htmlId="projectValue" value={projectValue} onChange={updateText(setProjectValue)} placeholder="Project value" />
                        </Value>
                    </Row>
                    <Row style={{ height: "15px" }} />
                    <Row>
                        <Label htmlFor="orderOwner">Order owner</Label>
                        <Value>
                            <Select fontSize={labelValueFontSize} required={true} placeholder="Select a user" onChange={onChangeOrderOwner} childValues={users && users.map(option => option.id)} value={getUserById(orderOwner, users)}>
                                {users && users.map((option, index) => <SelectItem fontSize={labelValueFontSize} height="20px" key={`add-quote-order-owner-item-${index}`} value={option.id}>{option.fullName}</SelectItem>)}
                            </Select>
                        </Value>
                    </Row>
                    <Row style={{ height: "20px" }} />
                    <Row>
                        <Label htmlFor="type">Type</Label>
                        <Value>
                            <Select fontSize={labelValueFontSize} required={true} placeholder="Select a type" onChange={onChangeType} childValues={types && types.map(option => option.description)} value={getTypeById(typeId, types)}>
                                {types && types.map((option, index) => <SelectItem fontSize={labelValueFontSize} height="20px" key={`add-quote-type-item-${index}`} value={option.id}>{option.description}</SelectItem>)}
                            </Select>
                        </Value>
                    </Row>
                    <Row style={{ height: "20px" }} />
                </ColumnContainer>
                <ColumnContainer>
                    <Row>
                        <FormHeader>Related Quotes</FormHeader>
                    </Row>
                    <Row>
                        <Value>
                            <DynamicSelect fontSize={labelValueFontSize} placeholder="Select a related quote" onChange={onChangeRelatedQuote} childValues={quotes && quotes.map(option => option)} value={relatedQuote}>
                                {quotes && quotes.map((option, index) => <SelectItem fontSize={labelValueFontSize} height="20px" key={`add-related-quote-item-${index}`} value={option}>{option}</SelectItem>)}
                            </DynamicSelect>
                        </Value>
                    </Row>
                    <Row style={{ height: "15px" }} />
                    {relatedQuotes.length === 0 &&
                        <Row><Value>No related quotes have been added. Select a customer to view related quotes.</Value></Row>
                    }
                    {relatedQuotes.length > 0 &&
                        <Row>
                            <Label style={{ flex: "1 1 0" }}>Quote Number</Label>
                            <Label style={{ flex: "4 1 0" }}>Reason for linking this quote</Label>
                            <Label style={{ flex: "1 1 0" }}></Label>
                        </Row>
                    }
                    {relatedQuotes.map((relQuote) => {
                        return <Row key={`${relQuote.quoteNumber}-related-quote`}><Label style={{ flex: "1 1 0", fontWeight: "400", alignItems: "center" }}>{relQuote.quoteNumber}</Label><Value style={{ flex: "4 1 0" }}><div style={{ display: "flex", height: "100%", width: "100%", alignItems: "center", textAlign: "center" }}><TextInput width="90%" fontSize={labelValueFontSize} required={false} value={relQuote.quoteDetails} onChange={onChangeRelatedQuoteDetail(relQuote.quoteNumber)} placeholder="Quote details" /></div>
                        </Value><Value style={{ flex: "1 1 0" }}><ButtonContainer>{<Button color={COLORS.GREY_SLATE} onClick={onRemoveRelatedQuote(relQuote.quoteNumber)}>Remove</Button>}
                        </ButtonContainer></Value></Row>
                    })}

                </ColumnContainer>
                <ColumnContainer style={{ width: "100%" }}>
                    <Row style={{ border: "grey solid 1px", marginLeft: "5px", width: "80%" }} />
                    <Row>
                        <FormHeader>Integration Engine Details</FormHeader>
                    </Row>
                    <Row style={{ width: "50%" }} >
                        <Label>Integration Engine</Label>
                        <Value></Value>
                    </Row>
                    <Row style={{ height: "15px" }} />
                    <Row style={{ width: "50%" }}>
                        <Label>Notes</Label>
                        <Value></Value>
                    </Row>
                </ColumnContainer>
                <ColumnContainer style={{ width: "80%" }}>
                    <Row style={{ border: "grey solid 1px", marginLeft: "5px" }} />
                    <Row>
                        <FormHeader>Work Required</FormHeader>
                    </Row>
                    <Row>
                        <Value>
                            <TextInput height="150px" textArea={true} fontSize={labelValueFontSize} required={true} htmlId="workRequired" value={workRequired} onChange={updateText(setWorkRequired)} placeholder="Description of work required" />
                        </Value></Row>
                </ColumnContainer>
            </ColumnsContainer>
        </QuoteAddForm>
    </Main >
};

export default QuoteAdd;
