import * as React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";

import { SessionContext } from "../../SessionContext";

import Button from "../../../SharedComponents/Button";
import LoadingIndicator from "../../../SharedComponents/LoadingIndicator";
import { updateNumeric, updateText } from "../../../SharedComponents/TextInput";
import ErrorText from "../../../SharedComponents/ErrorText";
import ICustomerContactDetails from "../../../ServerEntities/ICustomerContactDetails";
import dateConverter from "../../../UsefulFunctions/dateConverter";
import { TYPE_DOCUMENT } from "../../../UsefulFunctions/opportunityUtils"
import { getCustomerContacts } from "../Customers/CustomersService";

import { COLORS, getAPIUrl } from "../../../config";
import ICustomer from "../../../ServerEntities/ICustomer";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import IDocument from "../../../ServerEntities/IDocument";
import IUser from "../../../ServerEntities/IUser";
import DocumentBox from "../Components/DocumentBox";
import StatusBar from "../Components/StatusBar";
import ICurrentStatus from "../../../ServerEntities/ICurrentStatus";
import { MainNoteContainer, NoteContainer, NoteRow, NoteText, OutsideContainer, Row, UserAndDateContainer } from "../Customers/CustomerEdit";
import IOrder from "../../../ServerEntities/IOrder";
import { getOrder, getOrderDocuments, updateOrder } from "./FinanceService";
import IQuoteType from "../../../ServerEntities/IQuoteType";
import { SeparatorRow } from "../Components/ChangeHistory";
import { deleteOpportunityFile } from "../Opportunities/OpportunitiesService";
import { DocumentContainer, TitleAndButton, TitleLabel } from "../WorkPackages/WorkPackageEdit";
import { Main } from "./OrderTable";
import { getCustomerById } from "../../../UsefulFunctions/quoteUtils";
import IOrderNote from "../../../ServerEntities/IOrderNote";
import IOrderMilestone from "../../../ServerEntities/IOrderMilestone";
import { sumProperty } from "../../../UsefulFunctions/workPackageUtils";
import SourceTag from "../../../SharedComponents/SourceTag";
import { FinanceSmallLogo } from "../../MainMenu";
import SmallTextInput from "../../../SharedComponents/SmallTextInput";
import useEffectOnSome from "../../../CustomHooks/useEffectOnSome";

const QuoteHeader = styled.div`
    display: flex;
    flex-direction: column;
    width: 40%;
`;

const Label = styled.label`
    color: #333333;
    min-width: 140px;
`;

const LabelBold = styled.label`
    color: #333333;
    min-width: 140px;
    font-weight: bold;
`;

const QuoteSummaryContainer = styled.div`
    display: flex;
    flex-direction: row;
    background-color: #E3E3E3;
    margin-top: 20px;
    margin-bottom: 5px;
    padding: 10px;
    align-items: flex-start;
`;

const QuoteSummaryRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-height: normal;
    justify-content: space-between;
`;

const QuoteSummaryColumn = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-height: normal;
    justify-content: space-between;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 10%;
`;

const TotalsContatiner = styled.div`
    display: flex;
    flex-direction: column;
    width: 20%;
`;

const DiscountContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 70%;
    padding-left: 30px;
    padding-right: 10px;
`;


const QuoteDetailsContainer = styled.div`
    display: flex;
    flex-direction: row;
    border: 2px solid #E3E3E3;
    margin-bottom: 5px;
    padding: 10px;
    align-items: flex-start;
`;

const QuoteDetailsMain = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%
`;

const QuoteRecipients = styled.div`
    display: flex;
    flex-direction: row;
    border: 2px solid #E3E3E3;
    margin-bottom: 5px;
    padding: 10px;
    align-items: flex-start;
`;

const OrderHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const ButtonContainer = styled.div`
    text-align: left;
    width: 90 %;
`;

const FilesContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
`;

const Value = styled.div`
    font-size: 12px;
`;

const MilestoneTitle = styled.div`
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: flex-start;
`;

const MilestoneContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const MilestoneHeaderCell = styled.td`
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid #000000;
`;

const MilestoneHeaderCellNoBorder = styled.td`
    font-weight: bold;
    text-align: center;
`;

const MilestoneCell = styled.td`
    text-align: center;
    border-bottom: 1px solid #000000;
`;

const MilestoneCellNoBorder = styled.td`
    text-align: center;
`;

const MilestoneCellNoBorderEdit = styled.td`
    text-align: center;
`;


const ContainerWithLogoOnTheSide = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
`;

const ContainerWithoutLogo = styled.div`
    width: 90%;
`;

const ContainerWithLogo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;


const renderHtml = (html: string) => {
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
}

interface IProps {
    types: IQuoteType[],
    order: IOrder,
    users: IUser[],
    customers: ICustomer[],
    editable?: boolean,
    onAddComplete: () => void,
    onViewHistory: (order: IOrder) => void
};

const OrderEdit = (props: IProps) => {
    const { state } = React.useContext(SessionContext);
    const [loading, setLoading] = React.useState(false);
    const customers = props.customers;
    const [error, setError] = React.useState("");
    const [documents, setDocuments] = React.useState(undefined as unknown as IDocument[]);
    const [currentStatus, setCurrentStatus] = React.useState(undefined as unknown as ICurrentStatus);
    const [order, setOrder] = React.useState(null as unknown as IOrder);
    const [notes, setNotes] = React.useState([] as unknown as IOrderNote[]);
    const [noteDate, setNoteDate] = React.useState(null as unknown as Date);
    const [noteNote, setNoteNote] = React.useState("");
    const [milestones, setMilestones] = React.useState([] as unknown as IOrderMilestone[]);
    const [name, setName] = React.useState("");
    const [orderPercent, setOrderPercent] = React.useState(0);
    const [value, setValue] = React.useState(0);
    const [plannedInvoiceDate, setPlannedInvoiceDate] = React.useState(null as unknown as Date);
    const [milestoneReachedDate, setMilestoneReachedDate] = React.useState(null as unknown as Date);
    const [paymentReceivedDate, setPaymentReceivedDate] = React.useState(null as unknown as Date);
    const [primaryQuoteRecipient, setPrimaryQuoteRecipient] = React.useState("");
    const [additionalQuoteRecipient, setAdditionalQuoteRecipient] = React.useState("");

    const getDocuments = (type: string) => {
        getOrderDocuments(props.order.id, type, state.webToken, (files: IDocument[]) => {
            setLoading(false);
            setError("");
            setDocuments(files.length > 0 ? files : undefined as unknown as IDocument[]);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    };

    useEffectOnSome(() => {
        setLoading(true);
        getOrder(props.order.id, state.webToken, (o: IOrder) => {
            setLoading(false);
            setError("");
            setOrder(o);
            setNotes(o.orderNotes);
            setMilestones(o.orderMilestones);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
        getDocuments(TYPE_DOCUMENT);
        if (props.order.quote.customerId) {
            getCustomerContacts(props.order.quote.customerId, state.webToken, (c: ICustomerContactDetails[]) => {
                setLoading(false);
                setError("");
                setPrimaryQuoteRecipient(getQuoteRecipient(c, 1));
                setAdditionalQuoteRecipient(getQuoteRecipient(c, 0))

            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
            });
        }
    }, [props.order.id, props.order.quote.customerId]);

    const getQuoteRecipient = (customerContactDetails: ICustomerContactDetails[], flag: number) => {
        let emails = "";
        for (let i = 0; i < customerContactDetails.length; i++) {
            if (customerContactDetails[i].quoteRecipient === flag) {
                emails += (emails.length > 0 && customerContactDetails[i].email ? ", " + customerContactDetails[i].email : customerContactDetails[i].email);
            }
        }
        return emails;
    }

    const saveNote = (index: number) => () => {
        const date = noteDate ? noteDate.getTime() : 0;
        notes[index] = { note: noteNote, noteDate: date, dirty: false };
        let notesCopy = [...notes];
        setNotes(notesCopy);
    }

    const removeNote = (index: number) => () => {
        notes.splice(index, 1);
        let notesCopy = [...notes];
        setNotes(notesCopy);
    }

    const renderNotes = (note: IOrderNote, index: number) => {
        return <NoteRow>
            {!note.dirty && <MainNoteContainer>
                <UserAndDateContainer>
                    <NoteText>{dateConverter(note.noteDate, false, "")}</NoteText>
                    <Button plain={true} onClick={removeNote(index)}>Remove</Button>
                </UserAndDateContainer>
                <NoteContainer>
                    <NoteText style={{ fontWeight: "normal" }}>{renderHtml(note.note)}</NoteText>
                </NoteContainer>
            </MainNoteContainer>}
            {note.dirty && <MainNoteContainer style={{ marginBottom: "50px" }}>
                <UserAndDateContainer>
                    <NoteText><DatePicker dateFormat="dd-MMM-yyyy" placeholderText="Select a date" selected={noteDate} onChange={changeDate(setNoteDate)} /></NoteText>
                </UserAndDateContainer>
                <NoteContainer>
                    <ReactQuill style={{ width: "100%" }} value={noteNote} onChange={setNoteNote} />
                    <Button plain={true} onClick={saveNote(index)}>Save</Button>
                </NoteContainer>
            </MainNoteContainer>}
        </NoteRow>
    }

    const AddMilestone = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (e) {
            e.preventDefault();
        }

        const newMilestone: IOrderMilestone = {
            name: "",
            orderPercent: 0,
            value: 0,
            plannedInvoiceDate: 0,
            milestoneReachedDate: 0,
            paymentReceivedDate: 0,
            dirty: true
        };
        setName("");
        setOrderPercent(0);
        setValue(0);
        setPlannedInvoiceDate(null as unknown as Date);
        setMilestoneReachedDate(null as unknown as Date);
        setPaymentReceivedDate(null as unknown as Date);

        let milestonesCopy = [...milestones];
        milestonesCopy.push(newMilestone);
        setMilestones(milestonesCopy);
    };

    const removeMilestone = (index: number) => () => {
        milestones.splice(index, 1);
        let milestonesCopy = [...milestones];
        setMilestones(milestonesCopy);
    }

    const saveMilestone = (index: number) => () => {
        milestones[index] = {
            name: name,
            orderPercent: orderPercent,
            value: value,
            plannedInvoiceDate: plannedInvoiceDate ? plannedInvoiceDate.getTime() : 0,
            milestoneReachedDate: milestoneReachedDate ? milestoneReachedDate.getTime() : 0,
            paymentReceivedDate: paymentReceivedDate ? paymentReceivedDate.getTime() : 0,
            dirty: false
        };
        let milestonesCopy = [...milestones];
        setMilestones(milestonesCopy);
        order.orderMilestones = milestonesCopy;
    }

    const renderMilestones = (milestone: IOrderMilestone, index: number) => {
        if (!milestone.dirty) {
            return <tr style={{ width: "100%" }}>
                <MilestoneHeaderCellNoBorder style={{ width: "10%", textAlign: "left" }}>Milestone {index + 1}</MilestoneHeaderCellNoBorder>
                <MilestoneCell style={{ width: "15%", textAlign: "left" }}>{milestone.name}</MilestoneCell>
                <MilestoneCell style={{ width: "7%" }}>{milestone.orderPercent}</MilestoneCell>
                <MilestoneCell style={{ width: "8%" }}>&pound;{milestone.value}</MilestoneCell>
                <MilestoneCell style={{ width: "15%" }}>{dateConverter(milestone.plannedInvoiceDate)}</MilestoneCell>
                <MilestoneCell style={{ width: "15%" }}>{dateConverter(milestone.milestoneReachedDate)}</MilestoneCell>
                <MilestoneCell style={{ width: "15%" }}>{dateConverter(milestone.paymentReceivedDate)}</MilestoneCell>
                <MilestoneCellNoBorder style={{ width: "15%" }}><Button height={20} plain={true} onClick={removeMilestone(index)}>Remove</Button></MilestoneCellNoBorder>
            </tr>
        }
        return <tr style={{ width: "100%" }}>
            <MilestoneCellNoBorder></MilestoneCellNoBorder>
            <MilestoneCellNoBorderEdit style={{ textAlign: "left" }}><SmallTextInput width="80%" value={name} onChange={updateText(setName)} placeholder="Milestone Name" /></MilestoneCellNoBorderEdit>
            <MilestoneCellNoBorderEdit><SmallTextInput width="40px" maxLength={3} textAlign={"center"} value={orderPercent.toString()} onChange={updateNumeric(setOrderPercent)} placeholder="% of Order" /></MilestoneCellNoBorderEdit>
            <MilestoneCellNoBorderEdit><SmallTextInput width="80px" maxLength={8} textAlign={"center"} value={value.toString()} onChange={updateNumeric(setValue)} placeholder="Value" /></MilestoneCellNoBorderEdit>
            <MilestoneCellNoBorderEdit><div style={{ width: "80px" }}><DatePicker required={true} dateFormat="dd-MMM-yyyy" selected={plannedInvoiceDate} onChange={changeDate(setPlannedInvoiceDate)} placeholderText={"Select a date"} /></div></MilestoneCellNoBorderEdit>
            <MilestoneCellNoBorderEdit style={{ textAlign: "center" }}><DatePicker required={true} dateFormat="dd-MMM-yyyy" selected={milestoneReachedDate} onChange={changeDate(setMilestoneReachedDate)} placeholderText={"Select a date"} /></MilestoneCellNoBorderEdit>
            <MilestoneCellNoBorderEdit style={{ textAlign: "center" }}><DatePicker required={true} dateFormat="dd-MMM-yyyy" selected={paymentReceivedDate} onChange={changeDate(setPaymentReceivedDate)} placeholderText={"Select a date"} /></MilestoneCellNoBorderEdit>
            <MilestoneCellNoBorder><Button plain={true} onClick={saveMilestone(index)}>Save</Button></MilestoneCellNoBorder>
        </tr>
    }

    const changeDate = (setter: (date: Date) => void) => (date: Date, event: React.SyntheticEvent) => {
        setter(date);
    };

    const AddNote = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (e) {
            e.preventDefault();
        }

        const newNote: IOrderNote = {
            noteDate: 0,
            note: "",
            dirty: true
        };
        setNoteDate(null as unknown as Date);
        setNoteNote(newNote.note);

        let notesCopy = [...notes];
        notesCopy.push(newNote);
        setNotes(notesCopy);
    };


    const save = (commit: boolean) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        const newOrder: IOrder = {
            id: order.id,
            pricing: order.pricing,
            status: order.status,
            authorisedBy: "",
            authorisedDate: 0,
            type: order.type,
            totalCost: order.totalCost,
            totalCostInterfaces: order.totalCostInterfaces,
            totalCostTasks: order.totalCostInterfaces,
            discountApplied: order.discountApplied,
            discountReason: order.discountReason,
            fundingSource: order.fundingSource,
            prime: order.prime,
            primePercent: order.primePercent,
            framework: order.framework,
            frameworkPercent: order.frameworkPercent,
            quoteTemplate: order.quoteTemplate,
            quoteRecipient: order.quoteRecipient,
            quoteAdditionalRecipients: order.quoteAdditionalRecipients,
            source: order.source,
            orderNotes: notes,
            orderMilestones: milestones,
            quote: order.quote
        };
        setLoading(true);
        updateOrder(state.webToken, newOrder, () => {
            setLoading(false);
            setError("");
            if (commit) {
                // const trigger = createTriggerCommitOrder(order);
                // commitOpportunityNotification(trigger, state.webToken, () => { }, () => { },)
            }
            props.onAddComplete();
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    }

    const onRemove = (id: number) => {
        deleteOpportunityFile(state.webToken, id, () => {
            setLoading(false);
            setError("");
            getDocuments(TYPE_DOCUMENT);
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    }


    const navigateToHistory = () => {
        props.onViewHistory(props.order);
    }

    const onUpdateCurrentStatus = (status: ICurrentStatus) => {
        setCurrentStatus(status);
    }


    const sumMilestonePercent = order ? sumProperty(order.orderMilestones, "orderPercent") : 0;
    const sumMilestoneValue = order ? sumProperty(order.orderMilestones, "value") : 0;

    return <OutsideContainer>
        <LoadingIndicator type="Linear" show={loading} />
        <Main>
            {error && <ErrorText>{error}</ErrorText>}
            {order && <ContainerWithLogoOnTheSide>
                <ContainerWithoutLogo>
                    <StatusBar status={currentStatus} onUpdateStatus={onUpdateCurrentStatus} />
                    <OrderHeader>
                        {order.quote && <QuoteHeader>
                            <TitleLabel>Quote Number {order.quote.quoteNumber}</TitleLabel>
                            <Row style={{ height: "15px" }} />
                            <Row>
                                <Label>Customer </Label>
                                <Value>{order.quote.customerId && getCustomerById(order.quote.customerId, customers)}</Value>
                            </Row>
                            <Row>
                                <Label>Client ID</Label>
                                <Value>{order.quote.customerId}</Value>
                            </Row>
                            <Row>
                                <Label htmlFor="projectName">Project Name</Label>
                                <Value>{order.quote.projectName}</Value>
                            </Row>
                            <Row>
                                <Label htmlFor="projectValue">Project Value</Label>
                                <Value>{order.quote.projectValue}</Value>
                            </Row>
                        </QuoteHeader>}
                        <ButtonContainer>
                            <Button color={COLORS.GREY_SLATE} submit={true} onClick={save(false)}>Save Order</Button>
                            <Button color={COLORS.GREY_SLATE} style={{ marginLeft: "8px" }} onClick={save(true)}>Save & Commit Order</Button>
                            {/* <Button color={COLORS.GREY_SLATE} style={{ marginLeft: "8px" }} onClick={save(true)}>Generate Quote</Button> */}
                            <Button color={COLORS.GREY_SLATE} plain={true} style={{ marginLeft: "8px" }} onClick={props.onAddComplete}>Cancel</Button>
                            <Button color={COLORS.CHANGE_HISTORY} plain={false} style={{ marginLeft: "8px" }} onClick={navigateToHistory}>View Change History</Button>
                        </ButtonContainer>
                    </OrderHeader>
                </ContainerWithoutLogo>
                <ContainerWithLogo>
                    <FinanceSmallLogo />
                    <SourceTag sourceType={order.source} />
                </ContainerWithLogo>
            </ContainerWithLogoOnTheSide>}

            {order && <QuoteSummaryContainer>
                <TitleContainer>
                    <h3>Quote Summary</h3>
                </TitleContainer>
                <TotalsContatiner>
                    <QuoteSummaryRow style={{ borderBottom: "2px solid #CCC", marginBottom: "30px" }}>
                        <Label>Total Cost</Label>
                        <Value>&pound;{order.pricing.totalValue}</Value>
                    </QuoteSummaryRow>
                    <QuoteSummaryRow>
                        <Label>Total cost of interfaces</Label>
                        <Value>&pound;{order.pricing.totalCostInterfaces}</Value>
                    </QuoteSummaryRow>
                    <QuoteSummaryRow>
                        <Label>Total cost of tasks</Label>
                        <Value>&pound;{order.pricing.totalCostTasks}</Value>
                    </QuoteSummaryRow>
                </TotalsContatiner>
                <DiscountContainer>
                    <QuoteSummaryRow>
                        <QuoteSummaryColumn>
                            <Label>Discount Applied</Label>
                            <Value>{order.pricing.discountPercent}%</Value>
                        </QuoteSummaryColumn>
                        <QuoteSummaryColumn>
                            <Label>Prime</Label>
                            <Value>{order.pricing.primePercent}</Value>
                            <Value>{order.pricing.prime}</Value>
                        </QuoteSummaryColumn>
                        <QuoteSummaryColumn>
                            <Label>Framework</Label>
                            <Value>{order.pricing.frameworkPercent}</Value>
                            <Value>{order.pricing.framework}</Value>
                        </QuoteSummaryColumn>
                    </QuoteSummaryRow>
                    <QuoteSummaryRow style={{ display: "flex", justifyContent: "flex-start" }}>
                        <Label>Discount Reason</Label>
                        <Value>{order.pricing.discountReason}</Value>
                    </QuoteSummaryRow>
                </DiscountContainer>
            </QuoteSummaryContainer>}

            {order && <QuoteDetailsContainer>
                <TitleContainer>
                    <h3>Quote Details</h3>
                </TitleContainer>
                <QuoteDetailsMain>
                    <table style={{ width: "100%" }}>
                        <tr>
                            <td><LabelBold>Funding Source</LabelBold><Value>{order.fundingSource}</Value></td>
                            <td><LabelBold>Prime</LabelBold><Value>{order.prime}</Value></td>
                            <td><LabelBold>Framework</LabelBold><Value>{order.framework}</Value></td>
                            <td><LabelBold>Quote Template</LabelBold><Value>{order.quoteTemplate}</Value></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td><LabelBold>Prime %</LabelBold><Value>{order.primePercent}</Value></td>
                            <td><LabelBold>Framework %</LabelBold><Value>{order.frameworkPercent}</Value></td>
                            <td></td>
                        </tr>
                    </table>
                    <QuoteRecipients>
                        <table style={{ width: "100%" }}>
                            <tr>
                                <td><LabelBold>Quote recipients</LabelBold></td><td></td>
                            </tr>
                            <tr>
                                <td><LabelBold>Primary Recipient</LabelBold><Value>{primaryQuoteRecipient}</Value></td>
                                <td><LabelBold>Additional Recipients</LabelBold><Value>{additionalQuoteRecipient}</Value></td>
                            </tr>
                        </table>
                    </QuoteRecipients>
                </QuoteDetailsMain>
            </QuoteDetailsContainer>}
            <MilestoneContainer>
                <MilestoneTitle>
                    <h3 style={{ marginRight: "20px" }}>Milestones</h3>
                    <Button color={COLORS.GREY_SLATE} onClick={AddMilestone}>Add Milestone</Button>
                </MilestoneTitle>
                <table style={{ marginLeft: "100px", width: "100%", borderSpacing: "0px" }}>
                    <tbody>
                        <tr style={{ width: "100%" }}>
                            <MilestoneHeaderCellNoBorder style={{ width: "10%" }}></MilestoneHeaderCellNoBorder>
                            <MilestoneHeaderCell style={{ width: "15%", textAlign: "left" }}>Milestone Name</MilestoneHeaderCell>
                            <MilestoneHeaderCell style={{ width: "7%" }}>% of Order</MilestoneHeaderCell>
                            <MilestoneHeaderCell style={{ width: "8%" }}>Value</MilestoneHeaderCell>
                            <MilestoneHeaderCell style={{ width: "15%" }}>Planned Invoice Date</MilestoneHeaderCell>
                            <MilestoneHeaderCell style={{ width: "15%" }}>Milestone Reached Date</MilestoneHeaderCell>
                            <MilestoneHeaderCell style={{ width: "15%" }}>Payment Received Date</MilestoneHeaderCell>
                            <MilestoneHeaderCellNoBorder style={{ width: "15%" }}></MilestoneHeaderCellNoBorder>
                        </tr>
                        {milestones.map(renderMilestones)}
                        <tr style={{ width: "100%", backgroundColor: "#E3E3E3" }}>
                            <MilestoneHeaderCellNoBorder style={{ width: "10%", textAlign: "left" }}>Totals</MilestoneHeaderCellNoBorder>
                            <MilestoneCellNoBorder style={{ width: "15%" }}></MilestoneCellNoBorder>
                            <MilestoneCellNoBorder style={{ width: "7%" }}>{sumMilestonePercent}</MilestoneCellNoBorder>
                            <MilestoneCellNoBorder style={{ width: "8%" }}>&pound;{sumMilestoneValue}</MilestoneCellNoBorder>
                            <MilestoneCellNoBorder style={{ width: "15%" }}></MilestoneCellNoBorder>
                            <MilestoneCellNoBorder style={{ width: "15%" }}></MilestoneCellNoBorder>
                            <MilestoneCellNoBorder style={{ width: "15%" }}></MilestoneCellNoBorder>
                            <MilestoneHeaderCellNoBorder style={{ width: "15%", backgroundColor: "white" }}></MilestoneHeaderCellNoBorder>
                        </tr>
                    </tbody>
                </table>
            </MilestoneContainer>
            <SeparatorRow />
            <Row>
                <TitleAndButton>
                    <h3 style={{ marginRight: "20px" }}>Notes</h3>
                    <Button color={COLORS.GREY_SLATE} onClick={AddNote}>Add Note</Button>
                </TitleAndButton>
            </Row>
            <Row style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                {notes.map(renderNotes)}
            </Row>
            <SeparatorRow />
            <Row>
                <h3>Attachments</h3>
            </Row>
            <Row>
                <DocumentContainer>
                    <FilesContainer style={{ width: "100%" }}>
                        <DocumentBox
                            color={COLORS.SIXTH}
                            documents={documents}
                            onRemove={onRemove}
                            onUpdate={getDocuments}
                            url={getAPIUrl() + `quotes/orders/${props.order.id}/file`}
                            token={state.webToken}
                            type={TYPE_DOCUMENT}
                        />
                    </FilesContainer >
                </DocumentContainer >
            </Row >
        </Main >
    </OutsideContainer >
};

export default OrderEdit;
