import * as React from "react";
import styled from "styled-components";
import { submitExpenses } from "./ExpensesService";
import IExpenseForm from "../../ServerEntities/IExpenseForm";
import Button from "../../SharedComponents/Button";
import { GBP_FORMATTER } from "../../UsefulFunctions/currencyFunctions";
import dateConverter from "../../UsefulFunctions/dateConverter";
import { SessionContext } from "../SessionContext";
import LoadingIndicator from "../../SharedComponents/LoadingIndicator";
import ErrorText from "../../SharedComponents/ErrorText";

const Main = styled.main`
    padding: 24px;
    display: flex;
    flex-direction: column;
    text-align: left;
`;

const Title = styled.h1`
    font-size: 1.3rem;
    margin: 12px 0;
`;

const ButtonContainer = styled.div`
    margin: 12px 0;
`;

const Table = styled.table`
    border-collapse: collapse;
`;

const TableFooter = styled.tfoot`
    & td {
        border-top: 1px solid #333333;
        font-weight: 600;
    }
`;

interface IProps {
    onAdd: () => void,
    onOpenHistory: () => void,
    onSubmitComplete: () => void,
    expenseForm: IExpenseForm
};

const ExpensesTable = (props: IProps) => {
    const { state } = React.useContext(SessionContext);
    const expenses = props.expenseForm ? props.expenseForm.formEntries : [];
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");

    const sendForm = () => {
        setLoading(true);
        submitExpenses(state.webToken, props.expenseForm.id, () => {
            setLoading(false);
            setError("");
            props.onSubmitComplete();
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    };

    const redirectToSearch = () => {
        props.onOpenHistory();
    };

    return <Main>
        <Title>Expenses</Title>
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorText>{error}</ErrorText>}
        <div>
            <Button onClick={props.onOpenHistory}>View history</Button>
        </div>
        <Table>
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Total</th>
                    <th>Receipt reference</th>
                    <th>Description</th>
                    <th>Location</th>
                    <th>Type</th>
                    <th>Miles</th>
                    <th>Chargeable</th>
                </tr>
            </thead>
            <tbody>
                {expenses.map((expense, index) => {
                    return <tr key={`expense-entry=${index}`}>
                        <td>{dateConverter(expense.date)}</td>
                        <td>{GBP_FORMATTER.format(expense.total)}</td>
                        <td>{expense.recRef}</td>
                        <td>{expense.description}</td>
                        <td>{expense.location}</td>
                        <td>{expense.category}</td>
                        <td>{expense.miles}</td>
                        <td>{expense.chargeable ? "Yes" : "No"}</td>
                    </tr>;
                })}
                <tr>
                    <td><Button plain={true} onClick={props.onAdd}>Add new expense</Button></td>
                    <td colSpan={7} />
                </tr>
            </tbody>
            <TableFooter>
                <tr>
                    <td></td>
                    <td colSpan={7}>{GBP_FORMATTER.format(expenses.reduce((previous, current) => previous + current.total, 0))}</td>
                </tr>
            </TableFooter>
        </Table>
        {expenses.length > 0 && <ButtonContainer>
            <Button onClick={sendForm}>Submit expenses</Button>
        </ButtonContainer>}
        <ButtonContainer>
            <Button onClick={redirectToSearch}>Search expenses</Button>
        </ButtonContainer>

    </Main>;
};

export default ExpensesTable;