import IConfiguration from "../ServerEntities/IConfiguration";
import ICustomer from "../ServerEntities/ICustomer";
import ICustomerContactDetails from "../ServerEntities/ICustomerContactDetails";
import IQuote from "../ServerEntities/IQuote";
import IQuoteType from "../ServerEntities/IQuoteType";
import { pageConfiguration } from "./getComponentConfiguration";

export const TYPE_DOCUMENT = "document";
export const STATUS = ["In Progress", "Committed", "Revisit", "Order Received", "Archived"];
export const STATUS_CLOSED = 4;
export const STATUS_REVISIT = 2
export const STATUS_COMMITED = 1;
export const AUTHORISED = ["yes", "no"];

export const isEditableOpportunity = (status: number, owner: string, user: string) => {
    return (STATUS[status] !== "Committed" && owner === user);
};

export const getClientByQuoteNumber = (quoteNumber: string, quotes: IQuote[]) => {
    if (quotes) {
        const userObj = quotes.filter(obj => obj.quoteNumber === quoteNumber);
        return userObj.length && userObj[0].customerId ? userObj[0].customerId : 0;
    }
    return 0;
};

export const getContactIdByName = (contactName: string, quotes: ICustomerContactDetails[]) => {
    if (quotes) {
        const userObj = quotes.filter(obj => obj.name === contactName);
        return userObj.length && userObj[0].id ? userObj[0].id : 0;
    }
    return 0;
};

export const getTypeId = (type: string, quoteTypes?: IQuoteType[]) => {
    if (type && quoteTypes) {
        return quoteTypes.filter(obj => {
            return obj.description === type
        })[0].id;
    }
    return 0;
};

export const stripHtmlTags = (str: any) => {
    if (!str || typeof str !== "string") {
        return "";
    }
    return str.replace(/<[^>]*>/g, "");
}

export const getTypeById = (typeId: number, quoteTypes?: IQuoteType[]) => {
    if (typeId && quoteTypes) {
        return quoteTypes.filter(obj => {
            return obj.id === typeId
        })[0].description;
    }
    return "";
};

export const getCustomerIdBy = (customer: string, customers?: ICustomer[]) => {
    if (customers && customer) {
        const userObj = customers.filter(obj => {
            return obj.customerName === customer
        });
        return userObj ? userObj[0].id : 0;
    }
    return undefined;
};

export const canEditOpportunity = (allOpportunities: boolean, archivedOpportunities: boolean, configuration: IConfiguration | undefined) => {
    const pageConfig = pageConfiguration("Edit Opportunity (can only edit their own opportunities)", configuration);
    if (archivedOpportunities) {
        return false;
    }
    if (pageConfig && allOpportunities) {
        return false;
    }
    return true;
}

export const canCommitOpportunity = (configuration: IConfiguration | undefined) => {
    const pageConfig = pageConfiguration("Add Opportunity (can’t commit an opportunity)", configuration);
    if (pageConfig) {
        return false;
    }
    return true;
};
