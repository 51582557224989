import * as React from "react";
import styled, { keyframes } from "styled-components";
import useDimensions from "../../CustomHooks/useDimensions";
import useOnClickOutside from "../../CustomHooks/useOnClickOutside";
import Modal from "../Modal";

const defaultWidth = 200;

const openMenuAnimation = keyframes`
0% {
    opacity: 0;
    transform: scale(0.7);
}
100%{
    opacity: 1;
    transform: scale(1);
}
`;

const MenuContainer = styled.div`
    opacity: 1;
    outline: none;
    position: absolute;
    min-width: 16px;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 16px;
    box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
    border-radius: 4px;
    width: ${(props: any) => props.width ? props.width : defaultWidth}px;
    left: ${(props: any) => props.leftOffset ? props.leftOffset : ""}px;
    top: ${(props: any) => props.topOffset ? props.topOffset : ""}px;
    background-color: white;
    color: #4c4c4c;
    padding: 16px 0;
    max-height: calc(100% - 96px);
    animation: ${openMenuAnimation} 200ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
`;


interface IProps {
    children: React.ReactNode,
    open: boolean,
    width?: number,
    onClose: (event?: Event) => void,
    anchorElement: React.MutableRefObject<HTMLElement>,
    menuContainerRef?: any
};

const Menu = (props: IProps) => {
    const [menuContainerRefD, menuContainerDimensions] = useDimensions();
    const menuContainerRef = props.menuContainerRef || menuContainerRefD;

    useOnClickOutside(menuContainerRef, props.anchorElement, props.onClose);

    const calculateLeftOffset = () => {
        if (props.anchorElement.current) {
            const windowWidth = window.innerWidth;
            const elementWidth = props.width ? props.width : defaultWidth;
            const elementLeft = props.anchorElement.current.getBoundingClientRect().left;
            if (elementLeft + elementWidth > windowWidth) {
                return windowWidth - elementWidth - 16;
            } else {
                return elementLeft;
            }
        }
        return 0;
    };

    const calculateTopOffset = () => {
        if (props.anchorElement.current) {
            const windowHeight = window.innerHeight;
            const menuHeight = menuContainerDimensions.height;
            const elementBottom = props.anchorElement.current.getBoundingClientRect().bottom;
            if (elementBottom + menuHeight > windowHeight) {
                if ((elementBottom - menuHeight) < 0) {
                    // the menu is long, stick it somewhere near the top.
                    return 50;
                }
                return elementBottom - menuHeight - 16;
            }
            return elementBottom;
        }
        return 0;
    };

    const propsWithOffset = { ...props, leftOffset: calculateLeftOffset(), topOffset: calculateTopOffset() };

    if (!props.anchorElement.current) {
        return null;
    }

    const handleKeyDown = (event: any) => {
        const key = event.key || event.keyCode;
        if (key === "Escape") {
            props.onClose(event);
            props.anchorElement.current.focus();
        }
    };

    return <Modal show={props.open} style={{ backgroundColor: "transparent" }}>
        <MenuContainer
            tabIndex={-1}
            onKeyDown={handleKeyDown}
            className="MenuContainer"
            ref={menuContainerRef}
            {...propsWithOffset}>
            {props.children}
        </MenuContainer>
    </Modal>
}

export default Menu;
