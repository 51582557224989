import * as React from "react";
import IDocument from "../../../ServerEntities/IDocument";
import Button from "../../../SharedComponents/Button";
import styled from "styled-components";
import { faChevronCircleLeft, faChevronCircleRight, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dialog from "../../../SharedComponents/Dialog/Dialog";
import { FilePond, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)

const DocumentContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const UploadContainer = styled.div`
    width: 100%;
    margin-top: 20px;
`;


const DocumentHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
`;

const DocumentActions = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const DocumentCount = styled.div`
    align-items: center;
    text-align: center;
    width: 100px;
    font-size: 0.9em;
`;

const HeaderButton = styled.div`
`;

const ActionContainer = styled.div`
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 1 1 100%;
`;


interface IProps {
    documents: IDocument[],
    onRemove?: (id: number) => void,
    onUpdate?: (type: string) => void,
    color: string;
    token: string;
    url: string;
    type: string;
    max?: number;
}

const DocumentBox = (props: IProps) => {
    const documents = props.documents;
    const [index, setIndex] = React.useState(0 as number);
    const [numDocs, setNumDocs] = React.useState(0);
    const [currentDocument, setCurrentDocument] = React.useState(null as unknown as IDocument);
    const [fileIdToRemove, setFileIdToRemove] = React.useState(null as unknown as number);
    const uploadActive = (props.max && documents && documents.length < props.max) || !documents || props.max === undefined ? true : false;

    if (!currentDocument && documents && documents.length > 0) {
        setCurrentDocument(documents[index]);
    }

    const removeDocument = (doc: IDocument) => () => {
        setFileIdToRemove(doc.id);
    }

    const previousDocument = () => {
        if (index > 0) {
            setIndex(index - 1);
            setCurrentDocument(documents[index - 1]);
        }
    }

    const nextDocument = () => {
        if (index < documents.length - 1) {
            setIndex(index + 1);
            setCurrentDocument(documents[index + 1]);
        }
    }

    const onCloseDeleteDialog = () => {
        setFileIdToRemove(null as unknown as number);
    }

    const onDelete = () => {
        if (props.onRemove) {
            props.onRemove(fileIdToRemove);
            setIndex(0);
            setFileIdToRemove(null as unknown as number);
        }
    }

    if (documents && documents.length !== numDocs) {
        setNumDocs(documents.length);
        setCurrentDocument(documents[index]);
    }

    const getServerConfiguration: any = (type: string) => {
        return {
            url: props.url,
            process: {
                url: '/' + type,
                method: 'POST',
                withCredentials: false,
                headers: { Authorization: `Bearer ${props.token}`, },
                timeout: 7000,
                onload: (response: string) => {
                    if (props.onUpdate)
                        props.onUpdate(type);
                    setIndex(0);
                    setCurrentDocument(null as unknown as IDocument);
                    return JSON.parse(response).filename;
                },
                onerror: null,
                ondata: null,
            },
            revert: {
                url: '/' + type,
                method: 'DELETE',
                withCredentials: false,
                headers: { Authorization: `Bearer ${props.token}`, },
                timeout: 7000,
                onload: null,
                onerror: null,
                ondata: null,
            },
        }
    }

    return <div>
        {currentDocument && documents && <DocumentContainer>
            <Dialog style={{ padding: "32px" }} open={fileIdToRemove ? true : false} onClose={onCloseDeleteDialog}>
                <h3>Are you sure you want to delete?</h3>
                <ActionContainer>
                    <Button color={props.color} onClick={onCloseDeleteDialog} style={{ marginRight: "10px" }}>Cancel</Button>
                    <Button onClick={onDelete} color={"#e60000"}>Delete</Button>
                </ActionContainer>
            </Dialog>
            <DocumentHeader>
                <div style={{ fontSize: "0.9em" }}>{currentDocument.filename}</div>
                <DocumentActions>
                    <HeaderButton onClick={previousDocument}>
                        <FontAwesomeIcon style={{ color: "#4c4c4c" }} icon={faChevronCircleLeft} />
                    </HeaderButton>
                    <DocumentCount>{index + 1}/{documents.length}</DocumentCount>
                    <HeaderButton onClick={nextDocument}>
                        <FontAwesomeIcon style={{ color: "#4c4c4c" }} icon={faChevronCircleRight} />
                    </HeaderButton>

                    {props.onRemove && <HeaderButton color="red" style={{ marginLeft: "50px" }} onClick={removeDocument(currentDocument)}>
                        <FontAwesomeIcon style={{ color: "#4c4c4c" }} icon={faTrash} />
                    </HeaderButton>}
                </DocumentActions>
            </DocumentHeader>
            <iframe title="Document container" className={"roundedFrame"} src={currentDocument.url} width="100%" height="400px" ></iframe>


        </DocumentContainer >}
        {props.onRemove && uploadActive && <UploadContainer>
            <FilePond
                files={[]}
                credits={false}
                allowMultiple={true}
                maxFiles={10}
                server={getServerConfiguration(props.type)}
                name="files"
                labelIdle='Drag and drop your files or <span class="filepond--label-action">Browse</span>'
            />
        </UploadContainer>}
    </div>

};


export default DocumentBox;
