import * as React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { SessionContext, SessionContextProvider } from './SessionContext';

import Login from "./Login/Login";
import MainMenu from "./MainMenu";
import SessionManager from './SessionManager';
import { RolesContext, RolesContextProvider } from '../Roles/RolesContext';
import ExpensesIndex from "./Expenses/ExpensesIndex";
import CustomersIndex from "./Quotes/Customers/CustomersIndex";
import QuotesIndex from "./Quotes/Quotes/QuotesIndex";
import PreferredRolePicker from "./PreferredRolePicker";
import LoginForm from "./Login/LoginForm";
import ManagerIndex from "./Manager/ManagerIndex";
import OpportunitiesIndex from "./Quotes/Opportunities/OpportunitiesIndex";
import WorkPackagesIndex from "./Quotes/WorkPackages/WorkPackageIndex";
import Contacts from "./Quotes/Customers/Contacts";
import FinanceIndex from "./Quotes/Finance/FinanceIndex";
import PricingIndex from "./Quotes/Quotes/Pricing/PricingIndex";
import AdminIndex from "./Quotes/Admin/AdminIndex";
import DiagramsIndex from "./Quotes/Projects/DiagramIndex";


const PageIndex = () => <SessionContextProvider>
    <SessionManager />
    <RolesContextProvider>
        <IndexWithSession />
    </RolesContextProvider>
</SessionContextProvider>;

const IndexWithSession = () => {
    const { state } = React.useContext(SessionContext);
    const { role } = React.useContext(RolesContext).state;

    const renderRoute = (route: JSX.Element) => {
        return state.webToken ? route : <LoginForm />;
    };

    if (role === "") {
        return <PreferredRolePicker applicationName="InternalSystem" />
    }

    return <BrowserRouter>
        <Routes>
            <Route path="/Expenses" element={renderRoute(<ExpensesIndex />)} />
            <Route path="/code" element={<Login applicationName={"InternalSystem"} />} />
            <Route path="/" element={renderRoute(<MainMenu />)} />
            <Route path="/Finance" element={renderRoute(<FinanceIndex />)} />
            {/* <Route path="/Finances/Reminder" element={renderRoute(<FinanceReminders backUrl="/" isAdmin={isFinancialUser()} />)} />
            <Route path="/Expenses/Manage" element={renderRoute(<ExpenseHistory backUrl="/" isAdmin={isFinancialUser()} />)} /> */}
            <Route path="/Customers" element={renderRoute(<CustomersIndex />)} />
            <Route path="/Projects" element={renderRoute(<DiagramsIndex />)} />
            <Route path="/Contacts" element={renderRoute(<Contacts />)} />
            <Route path="/Quotes" element={renderRoute(<QuotesIndex />)} />
            <Route path="/Quotes/Pricing" element={renderRoute(<PricingIndex />)} />
            <Route path="/Quote/:quoteId" element={renderRoute(<QuotesIndex />)} />
            <Route key="ManagerBase" path="/Manager/*" element={renderRoute(<ManagerIndex />)} />
            <Route key="ManagerChild" path="/Manager/:pageName" element={renderRoute(<ManagerIndex />)} />
            <Route path="/Opportunities" element={renderRoute(<OpportunitiesIndex />)} />
            <Route path="/MyOpportunities" element={renderRoute(<OpportunitiesIndex mine={true} />)} />
            <Route path="/ArchivedOpportunities" element={renderRoute(<OpportunitiesIndex archived={true} />)} />
            <Route path="/Opportunities/:opportunityId" element={renderRoute(<OpportunitiesIndex />)} />
            {/* <Route path="/Anouncements" element={renderRoute(<AnnouncementIndex />)} /> */}
            <Route path="/Admin" element={renderRoute(<AdminIndex />)} />
            <Route path="/Architecture" element={renderRoute(<WorkPackagesIndex />)} />
            <Route path="/Architecture/:workPackageId" element={renderRoute(<WorkPackagesIndex />)} />
            <Route path="/WorkPackages" element={renderRoute(<WorkPackagesIndex />)} />
            <Route path="/WorkPackages/:workPackageId" element={renderRoute(<WorkPackagesIndex />)} />
        </Routes>
    </BrowserRouter>;
};

export default PageIndex;
