import * as React from "react";
import styled from "styled-components";
import { COLORS } from "../../../config";
import IUser from "../../../ServerEntities/IUser";
import Dialog from "../../../SharedComponents/Dialog/Dialog";
import Button from "../../../SharedComponents/Button";
import Select from "../../../SharedComponents/Select/Select";
import SelectItem from "../../../SharedComponents/Select/SelectItem";
import LoadingIndicator from "../../../SharedComponents/LoadingIndicator";
import ErrorText from "../../../SharedComponents/ErrorText";
import { SessionContext } from "../../SessionContext";
import { assignWorkPackage } from "./WorkPackageService";
import DialogTitle from "../../../SharedComponents/Dialog/DialogTitle";
import DialogContent from "../../../SharedComponents/Dialog/DialogContent";
import DialogContentText from "../../../SharedComponents/Dialog/DialogContentText";


const ActionContainer = styled.div`
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
`;

interface IProps {
    workPackageId: number,
    show: boolean,
    users: IUser[],
    onClose: () => void;
};

const getUserById = (id: string, users?: IUser[]) => {
    if (id && users) {
        const userObj = users.filter(obj => {
            return obj.id === id
        });
        return userObj[0] ? userObj[0] : null;
    }
    return null;
};

const getNameById = (id: string, users?: IUser[]) => {
    if (id && users) {
        const userObj = users.filter(obj => {
            return obj.id === id
        });
        return userObj[0] ? userObj[0].fullName : id;
    }
    return id;
};


const AssignWorkPackageDialog = (props: IProps) => {
    const { show, users, onClose } = props;
    const { state } = React.useContext(SessionContext);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [user, setUser] = React.useState("");

    const onChangeUser = (newUser: string) => {
        setUser(newUser);
    }

    const onAssign = () => {
        const assignedUser = getUserById(user, users);
        if (assignedUser) {
            assignWorkPackage(assignedUser.id, props.workPackageId, state.webToken, () => {
                setLoading(false);
                setError("");
                onClose();
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
            });
        }
    }
    return <Dialog open={show} style={{ width: "600px", height: "320px" }} onClose={onClose}>
        <DialogTitle className="DialogTitle">Assign Scoping</DialogTitle>
        <LoadingIndicator show={loading} type="Linear" />
        {error && <ErrorText>{error}</ErrorText>}
        <DialogContent className="DialogContent">
            <DialogContentText className="DialogContentText">Select a user to assign this scoping to</DialogContentText>
            <div style={{ marginTop: "30px" }}>
                <Select required={true} placeholder="Select a user" onChange={onChangeUser} childValues={users && users.map(option => option.id)} value={getNameById(user, users)}>
                    {users && users.map((option, index) => <SelectItem height="20px" key={`add-quote-order-owner-item-${index}`} value={option.id}>{option.fullName}</SelectItem>)}
                </Select>
            </div>
        </DialogContent>
        <ActionContainer>
            <Button onClick={onAssign} color={COLORS.GREY_SLATE}>Assign</Button>
            <Button color={COLORS.GREY_SLATE} onClick={onClose} style={{ marginRight: "10px" }}>Cancel</Button>
        </ActionContainer>
        <LoadingIndicator type="Linear" show={loading} />

    </Dialog>

}

export default AssignWorkPackageDialog;