import axios, { AxiosError, AxiosResponse } from "axios";
import { getAPIUrl } from "../../../config";
import IAnnouncement from "../../../ServerEntities/IAnnouncement";


export const saveAnnouncement = (webToken: string, announcement: IAnnouncement, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.post(
        `${getAPIUrl()}announcements`,
        announcement,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(successCallback).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};


export const getAnnouncements = (webToken: string, successCallback: (announcements: IAnnouncement[]) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}announcements/created`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IAnnouncement[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getTeams = (webToken: string, successCallback: (teams: string[]) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}announcements/teams`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<string[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getDepartments = (webToken: string, successCallback: (departments: string[]) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}announcements/departments`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<string[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getAudienceUsers = (webToken: string, allStaff: boolean, officeStaff: boolean, remoteStaff: boolean, teams: string[], departments: string[], successCallback: (users: string[]) => void, failureCallback: (error: string) => void) => {
    let teamQuery = "";
    teams.forEach(t => teamQuery = teamQuery + "&teams=" + encodeURIComponent(t));
    let departmentQuery = "";
    let allStaffQuery = allStaff ? "&all=true" : "";
    let officeStaffQuery = officeStaff ? "&office=true" : "";
    let remoteStaffQuery = remoteStaff ? "&remote=true" : "";
    departments.forEach(t => departmentQuery = departmentQuery + "&department=" + encodeURIComponent(t));
    axios.get(
        `${getAPIUrl()}announcements/users?${allStaffQuery}${officeStaffQuery}${remoteStaffQuery}${teamQuery}${departmentQuery}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<string[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const deleteAnnouncement = (webToken: string, id: number, successCallback: () => void, failureCallback: (error: string) => void) => {
    axios.delete(
        `${getAPIUrl()}announcements/${id}`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then(() => {
        successCallback();
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};

export const getUserAnnouncements = (webToken: string, successCallback: (announcements: IAnnouncement[]) => void, failureCallback: (error: string) => void) => {
    axios.get(
        `${getAPIUrl()}announcements`,
        { headers: { Authorization: `Bearer ${webToken}`, "Content-type": "application/json" } }
    ).then((response: AxiosResponse<IAnnouncement[]>) => {
        successCallback(response.data);
    }).catch((error: AxiosError<string>) => {
        failureCallback(error.message);
    });
};