import * as React from "react";
import { useState } from "react";
import styled, { keyframes } from "styled-components"

import useOnClickOutsideSelect from "../../CustomHooks/useOnClickOutsideSelect";

const errorColor = "red";

const openSelectAnimation = keyframes`
    0% {
        opacity: 0;
        transform: scale(0.7);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
`;

interface IContainerStyleProps {
    margin?: string,
    width?: string
};

const SelectContainer = styled.div<IContainerStyleProps>`
    outline: none;
    cursor: pointer;
    position: relative;
    border: 0 none;
    border-radius: 3px;
    padding: 0px 10px 0px 10px;
    margin: ${props => props.margin ? props.margin : ""};
    background-color: white;
    transition: border-color .15s cubic-bezier(.4, 0, .2, 1);
    &.focused {
        border-color: defaultColor;
    }
    &.error {
        border-color: ${errorColor};
    }
    &.open {
        border-radius: 3px 3px 0px 0px;
    }
    width: ${props => props.width};
    border: 1px solid #e5e5e5;
`;

interface IProps {
    value: any,
    onChange: (value: any) => void,
    margin?: string,
    width?: string,
    placeholder?: string,
    children: React.ReactNode[],
    childValues: string[],
    selectRef?: { current: HTMLDivElement },
    required?: boolean,
    fontSize?: string
};

interface IListStyleProps {
    leftOffset: number,
    topOffset: number,
    width: number
};

const OptionsContainer = styled.ul<IListStyleProps>`
    position: absolute;
    list-style: none;
    padding: 0;
    margin: 0;
    border-radius: 0px 0px 3px 3px;
    border 0px solid transparent;
    overflow-y: auto;
    overflow-x: hidden;
    max-height:400px;
    z-index: 2;
    left: ${props => props.leftOffset}px;
    top: ${props => props.topOffset}px;
    background-color: white;
    width: ${props => props.width - 1}px;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    animation:${openSelectAnimation} cubic-bezier(0, 0, .2, 1) 200ms forwards;
    will-change: transform, opacity;
    transform: scale(.8);
    z-index: -100;
    opacity: 0;
    &.open{
        transform: scale(1);
        opacity: 1;
        z-index: 2;
    }
    font-size: 0.9em;
`;

const ValueContainer = styled.div`
    line-height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
`;

const OptionWrapper = styled.span`
    &:hover {
        background-color: rgba(24, 127, 186, 0.05);
    }
    &.selected > div {
        background-color: rgba(24, 127, 186, 0.05);
    }
`;

const AssistiveTextRow = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    left: 0;
`;

interface IAssistiveTextProps {
    fontSize?: string,
    error?: boolean
};


const AssistiveText = styled.p<IAssistiveTextProps>`
    font-size: ${props => props.fontSize ? props.fontSize : "10px"};
    color: ${props => props.error ? errorColor : "#62676B"};
    margin: 2px 0;
`;

const DynamicSelect = (props: IProps) => {
    const { value, onChange, children, margin, placeholder, selectRef, required, fontSize } = props;
    const [leftOffset, setLeftOffset] = React.useState(0);
    const [topOffset, setTopOffset] = React.useState(0);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [focus, setFocus] = useState(false);
    const [input, setInput] = useState("");
    const [showInput, setShowInput] = useState(false);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    let ref = React.useRef(null as unknown as HTMLDivElement);
    if (selectRef) {
        ref = selectRef;
    }
    const optionsContainerRef = React.useRef(null as unknown as HTMLUListElement);

    const closeBox = () => {
        setOpen(false);
        setFocus(false);
        setHighlightedIndex(-1);
    };

    useOnClickOutsideSelect(ref, optionsContainerRef, closeBox);

    React.useEffect(() => {
        setLeftOffset(calculateLeftOffset());
        setTopOffset(32);
    }, [open]);

    const calculateLeftOffset = () => {
        if (ref.current) {
            const windowWidth = window.innerWidth;
            const elementWidth = ref.current.getBoundingClientRect().width;
            const elementLeft = ref.current.getBoundingClientRect().left;
            if (elementLeft + elementWidth > windowWidth) {
                return windowWidth - elementWidth;
            } else {
                return 0;
            }
        }
        return 0;
    };

    const toggleBoxOpen = () => {
        if (!showInput) {
            setShowInput(true);
        }
    };

    const registerFocus = () => {
        setFocus(true);
    };

    const onItemSelect = (selectValue: any) => () => {
        setError(false);
        setOpen(false);
        setInput("");
        setShowInput(false);
        setHighlightedIndex(-1);
        onChange(selectValue);
    };

    const calculateWidth = () => {
        if (ref.current && optionsContainerRef.current) {
            const boxWidth = ref.current.getBoundingClientRect().width;
            const optionsWidth = optionsContainerRef.current.clientWidth;
            if (boxWidth > optionsWidth) {
                return boxWidth;
            }
            return optionsWidth;
        }
        return 0;
    };

    const onChangeInput = (e: React.SyntheticEvent<HTMLInputElement, Event>) => {
        setInput(e.currentTarget.value);
        setOpen(true);
    }

    return <SelectContainer
        ref={ref}
        className={`SelectBox ${focus && "focused"} ${error && "error"} ${open && "open"}`}
        onClick={toggleBoxOpen}
        onFocus={registerFocus}
        tabIndex={0}
        margin={margin}
        width={props.width}
    >
        <ValueContainer className="ValueContainer">
            {showInput && <input style={{ width: "100%", lineHeight: "28px" }} autoFocus={true} value={input} onChange={onChangeInput} />}
            {!showInput && <span style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellispsis" }}>{value || placeholder || <span />}</span>}
        </ValueContainer>
        {open && <OptionsContainer
            ref={optionsContainerRef}
            className={`OptionsContainer ${open && "open"} `}
            width={calculateWidth()}
            leftOffset={leftOffset}
            topOffset={topOffset}
        >
            {
                children && children.length > 0 && React.Children.toArray(children)
                    .filter((child: any) => {
                        if (!child) {
                            return false;
                        }
                        const name = child.props.children;
                        return (input.length === 0 || name.toString().toLowerCase().includes(input.toString().toLowerCase()));
                    })
                    .map(
                        (child: any, index: number) => {
                            return <OptionWrapper
                                key={child.key ? child.key : `option${index} `}
                                className={highlightedIndex === index ? "select-item selected" : "select-item"}
                                onClick={onItemSelect(child.props.value)}>
                                {child}
                            </OptionWrapper>;
                        })
            }
        </OptionsContainer>}
        <AssistiveTextRow className="AssistiveTextRow">
            {(required) ? <AssistiveText fontSize={fontSize} className="AssistiveText" error={error}>*Required</AssistiveText> : null}
        </AssistiveTextRow>
    </SelectContainer>;
};

export default DynamicSelect;
