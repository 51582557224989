import * as React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled, { createGlobalStyle } from "styled-components";

import { createTriggerAuthorisedQuote } from "../../../UsefulFunctions/createTriggers";
import DataSorter from "../../../UsefulFunctions/DataSorter";
import dateConverter from "../../../UsefulFunctions/dateConverter";
import { componentConfiguration } from "../../../UsefulFunctions/getComponentConfiguration";
import { getUserById } from "../../../UsefulFunctions/quoteUtils";

import Button from "../../../SharedComponents/Button";
import Dialog from "../../../SharedComponents/Dialog/Dialog";
import ErrorText from "../../../SharedComponents/ErrorText";
import LoadingIndicator from "../../../SharedComponents/LoadingIndicator";
import Select from "../../../SharedComponents/Select/Select";
import SelectItem from "../../../SharedComponents/Select/SelectItem";
import TableSortLabel from "../../../SharedComponents/Table/TableSortLabel";
import TextInput, { updateText } from "../../../SharedComponents/TextInput";

import IPagedQuotes from "../../../ServerEntities/IPagedQuotes";
import IQuote from "../../../ServerEntities/IQuote";
import IQuoteSummary from "../../../ServerEntities/IQuoteSummary";
import IQuoteType from "../../../ServerEntities/IQuoteType";
import IUser from "../../../ServerEntities/IUser";

import { archiveQuote, authoriseQuote, createQuoteNotification, deleteQuote, getNotificationEmailList } from "./QuotesService";
import { RolesContext } from "../../../Roles/RolesContext";
import { SessionContext } from "../../SessionContext";

import { COLORS } from "../../../config";
import { MenuItem, MenuItemTagOrName } from "../../MainMenu";

export const Main = styled.main`
    display: flex;
    flex-direction: column;
    flex: 5 1 0;
    padding: 10px;
`;

export const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const AddAndExportContainer = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
height: 100%;
width: 100%;
`;

const ActionContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
`;


const Table = styled.table`
    border-collapse: collapse;
    table-layout: fixed;  
    width: 100%;
    display: block;
    overflow: auto;
    height: 500px;
    &::-webkit-scrollbar {
        display: block;
        width: 5px;
    };
    &::-webkit-scrollbar-track {
        background: transparent;
    };
    &::-webkit-scrollbar-thumb {
        background-color: grey;
        border-right: none;
        border-left: none;
        border-radius: 5px;
    };
    &::-webkit-scrollbar-track-piece:end {
        background: transparent;
    };
    &::-webkit-scrollbar-track-piece:start {
        background: transparent;
        margin-top: 85px;
    };
`;

const TableCell = styled.td`
    table-layout:fixed;
    height: 24px;
    font-size: 12px;
    text-align: left;
    padding: 5px;
`;

const TableHead = styled.thead`
    position: sticky;
    top: 0;
    background: white;
`;

const EmailList = styled.div`x;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 0.8em;
    margin-top: 10px;
    margin-bottom: 30px;
`;

const DatePickerWrapperStyles = createGlobalStyle`
.react-datepicker__input-container input {
    width: 90%;
 }
`;

interface IProps {
    onAdd: () => void,
    onEdit: (quote: IQuote) => void,
    onView: (quote: IQuote) => void,
    onDelete: () => void,
    onRefresh: () => void,
    pageNumber: number,
    onChangePageNumber: (pageNumber: number) => void,
    quotes: IPagedQuotes,
    users: IUser[],
    types: IQuoteType[],
    isAdmin: boolean,
    pageSize: number,
    viewType: string
};

export const winStatuses = ["Pending", "Won", "Lost", "Requoted"];
export const toggleStatus = ["Yes", "No"];
export const authorisedStatuses = ["Yes", "No"];

const QUOTE_TYPE_ID = 0;
const QUOTE_ORDER_OWNER = 1;
const QUOTE_NUMBER = 2;
const QUOTE_ENTERED_DATE = 3;
const QUOTE_CUSTOMER = 4;
const QUOTE_PROJECT = 5;
const QUOTE_EXPIRY_DATE = 6;
const QUOTE_WIN_STATUS = 7;
const QUOTE_AUTHORISED = 8;
const QUOTE_ID = 9;
const QUOTE_ARCHIVED = 10;
const QUOTE_AUTHORISED_BY = 11;
const QUOTE_SOURCE = 12;

const dateInRange = (date: Date, start: Date, end: Date) => {
    return date > start && date < end;
};

const QuotesTable = (props: IProps) => {
    const { state } = React.useContext(SessionContext);
    const { configuration } = React.useContext(RolesContext).state;
    const rbac = componentConfiguration("Quotes", configuration);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const quotes = props.quotes ? props.quotes.data : [];
    const [quoteToArchive, setQuoteToArchive] = React.useState(0);
    const [quoteToDelete, setQuoteToDelete] = React.useState(0);
    const [quoteToAuthorise, setQuoteToAuthorise] = React.useState(undefined as unknown as IQuoteSummary);
    const [sortByColumn, setSortByColumn] = React.useState(3);
    const [sortDescending, setSortDescending] = React.useState(true);
    const [typeFilter, setTypeFilter] = React.useState("");
    const [orderOwnerFilter, setOrderOwnerFilter] = React.useState("");
    const [quoteNumberFilter, setQuoteNumberFilter] = React.useState("");
    const [customerFilter, setCustomerFilter] = React.useState("");
    const [projectFilter, setProjectFilter] = React.useState("");
    const [statusFilter, setStatusFilter] = React.useState("");
    const [authorisedFilter, setAuthorisedFilter] = React.useState("");
    const [enteredDateFilter, setEnteredDateFilter] = React.useState(undefined as unknown as Date);
    const [expiryDateStartFilter, setExpiryDateStartFilter] = React.useState(undefined as unknown as Date);
    const [expiryDateEndFilter, setExpiryDateEndFilter] = React.useState(undefined as unknown as Date);
    const [archivedFilter, setArchivedFilter] = React.useState("");
    const [emailList, setEmailList] = React.useState([] as string[]);
    const { types, users, viewType } = props;

    React.useEffect(() => {
        if (viewType === "archived") {
            setArchivedFilter("Yes")
        } else if (viewType === "active") {
            setArchivedFilter("No")
        } else {
            setArchivedFilter("")
        }
    }, [viewType]);

    const viewQuote = (quoteId: number) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const quote = quotes.find(quote => quote.id === quoteId);
        if (quote) {
            props.onView(quote);
        }
    };

    const renderType = (typeId: number) => {
        if (types && types.length > 0) {
            for (let i = 0; i < types.length; i++) {
                if (types[i].id === typeId) {
                    return types[i].description;
                }
            }
        }
        return typeId;
    };

    const onCloseDeleteDialog = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setQuoteToDelete(0);
    };

    const onDelete = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setLoading(true);
        setQuoteToDelete(0);
        deleteQuote(state.webToken, quoteToDelete, () => {
            setLoading(false);
            setError("");
            props.onDelete();
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    const onCloseArchiveDialog = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setQuoteToArchive(0);
    };

    const onArchive = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setLoading(true);
        setQuoteToArchive(0);
        archiveQuote(state.webToken, quoteToArchive, () => {
            setLoading(false);
            setError("");
            props.onDelete();
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    const onCloseAuthoriseDialog = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setQuoteToAuthorise(undefined as unknown as IQuoteSummary);
    };

    const onShowAuthoriseDialog = (quote: IQuoteSummary) => (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setQuoteToAuthorise(quote);
        beforeAuthorise();
    };

    const beforeAuthorise = () => {
        setLoading(true);
        getNotificationEmailList("quote-authorised", state.webToken, (emailList: string[]) => {
            setLoading(false);
            setError("");
            setEmailList(emailList);
            props.onDelete();
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
    };

    const onAuthorise = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setLoading(true);
        authoriseQuote(state.webToken, quoteToAuthorise.id, state.loggedInUser, (quote: IQuote) => {
            setLoading(false);
            setError("");
            props.onDelete();
            quoteToAuthorise.authorisedBy = quote.workAuthorisedByName ? quote.workAuthorisedByName : "";
            quoteToAuthorise.authorisedByEmail = quote.workAuthorisedBy ? quote.workAuthorisedBy : "";
            const trigger = createTriggerAuthorisedQuote(quoteToAuthorise);
            createQuoteNotification(trigger, state.webToken, () => { }, () => { },)
        }, (errorMessage: string) => {
            setLoading(false);
            setError(errorMessage);
        });
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        setQuoteToAuthorise(undefined as unknown as IQuoteSummary);
    };

    const changeSortColumn = (columnIndex: number) => () => {
        if (columnIndex === sortByColumn) {
            setSortDescending(!sortDescending);
        } else {
            setSortDescending(true);
        }
        setSortByColumn(columnIndex);
    };

    const quoteToArray = (quote: IQuote, index: number): [number, string, string, number, string | undefined, string, number, string, boolean, number, boolean, string, string, number] => [
        quote.typeId,
        getUserById(quote.orderOwner, users),
        quote.quoteNumberFinal ? quote.quoteNumberFinal : quote.quoteNumber,
        quote.enteredDate,
        quote.customer,
        quote.projectName,
        quote.expiryDate,
        quote.winStatus,
        quote.authorised,
        quote.id,
        quote.archived ? quote.archived : false,
        getUserById(quote.workAuthorisedBy, users),
        quote.sourceType ? quote.sourceType : "",
        index
    ];

    const filterQuotes = (quote: IQuote) => {
        const stringType = renderType(quote.typeId);
        const type = stringType ? stringType.toString() : "";
        const authorised = quote.authorised ? "Yes" : "No";
        const archived = quote.archived ? "Yes" : "No";
        if (typeFilter.length > 0 && !type.toLowerCase().includes(typeFilter.toLowerCase())) {
            return false;
        }
        if (orderOwnerFilter.length > 0 && !quote.orderOwner.toLowerCase().includes(orderOwnerFilter.toLowerCase())) {
            return false;
        }
        if (quoteNumberFilter.length > 0 && !quote.quoteNumber.toLowerCase().includes(quoteNumberFilter.toLowerCase())) {
            return false;
        }
        if (customerFilter.length > 0 && (quote.customer && !quote.customer.toLowerCase().includes(customerFilter.toLowerCase()))) {
            return false;
        }
        if (projectFilter.length > 0 && !quote.projectName.toLowerCase().includes(projectFilter.toLowerCase())) {
            return false;
        }
        if (statusFilter.length > 0 && !quote.winStatus.toLowerCase().includes(statusFilter.toLowerCase())) {
            return false;
        }
        if (authorisedFilter.length > 0 && !authorised.toLowerCase().includes(authorisedFilter.toLowerCase())) {
            return false;
        }
        if (archivedFilter.length > 0 && !archived.toLowerCase().includes(archivedFilter.toLowerCase())) {
            return false;
        }
        if (enteredDateFilter && !dateConverter(quote.enteredDate).toLowerCase().includes(dateConverter(enteredDateFilter).toLowerCase())) {
            return false;
        }
        if (expiryDateStartFilter && expiryDateEndFilter && !dateInRange(new Date(quote.expiryDate), expiryDateStartFilter, expiryDateEndFilter)) {
            return false;
        }
        return true;
    };

    const onClearFilters = () => {
        setTypeFilter("");
        setOrderOwnerFilter("");
        setQuoteNumberFilter("");
        setCustomerFilter("");
        setProjectFilter("");
        setStatusFilter("");
        setAuthorisedFilter("");
        setArchivedFilter("");
        setEnteredDateFilter(undefined as unknown as Date);
        setExpiryDateStartFilter(undefined as unknown as Date);
        setExpiryDateEndFilter(undefined as unknown as Date);
    };

    const onChangeStatusFilter = (status: string) => {
        setStatusFilter(status);
    };

    const onChangeTypeFilter = (type: string) => {
        setTypeFilter(type);
    };

    const onChangeAuthorisedFilter = (authorised: string) => {
        setAuthorisedFilter(authorised);
    };

    const changeDate = (setter: (date: Date) => void) => (date: Date, event: React.SyntheticEvent) => {
        setter(date);
    };

    const changeExpiryRange = (dates: [Date | null, Date | null], event: React.SyntheticEvent) => {
        if (dates[0]) {
            setExpiryDateStartFilter(dates[0]);
        }
        if (dates[1]) {
            setExpiryDateEndFilter(dates[1]);
        }
    };

    const onChangeArchivedFilter = (archived: string) => {
        setArchivedFilter(archived);
    };

    const renderAuthorise = (quote: IQuoteSummary, authorised: boolean, archived: boolean) => {
        if (authorised) {
            return "yes"
        }
        if (rbac.authorise && !archived) {
            return <Button plain={true} style={{ marginLeft: "8px" }} onClick={onShowAuthoriseDialog(quote)}>Authorise</Button>
        }
        return "no";
    };

    const renderEmail = (email: string) => {
        return <div>{email}</div>;
    };

    return <Main>
        <TopContainer>
            <AddAndExportContainer>
                {rbac.create && <Button color={COLORS.GREY_SLATE} onClick={props.onAdd}>Add new quote</Button>}
                {/* <Button style={{ marginLeft: "10px" }} color={COLORS.GREY_SLATE} onClick={onExport}>Export CSV</Button> */}
            </AddAndExportContainer>
            <MenuItem style={{ height: "60px", width: "60px" }} $hover={false} key="expenses-menu-item" onClick={() => null} $backgroundColor={COLORS.GREY_SLATE} $borderColor={COLORS.QUOTES_PINK} $color="#ffffff">
                <MenuItemTagOrName $fontSize="2em" $fontWeight="600">Qu</MenuItemTagOrName>
            </MenuItem>
        </TopContainer>
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorText>{error}</ErrorText>}
        <Dialog style={{ padding: "32px" }} open={quoteToArchive > 0} onClose={onCloseArchiveDialog}>
            <h3>Are you sure you want to archive?</h3>
            <ActionContainer>
                <Button color={COLORS.FIFTH} onClick={onCloseArchiveDialog} style={{ marginRight: "10px" }}>Cancel</Button>
                <Button onClick={onArchive} color={"#e60000"}>Archive</Button>
            </ActionContainer>
        </Dialog>
        <Dialog style={{ padding: "32px" }} open={quoteToDelete > 0} onClose={onCloseDeleteDialog}>
            <h3>Are you sure you want to delete?</h3>
            <ActionContainer>
                <Button color={COLORS.FIFTH} onClick={onCloseDeleteDialog} style={{ marginRight: "10px" }}>Cancel</Button>
                <Button onClick={onDelete} color={"#e60000"}>Delete</Button>
            </ActionContainer>
        </Dialog>
        <Dialog style={{ padding: "32px" }} open={quoteToAuthorise !== undefined} onClose={onCloseAuthoriseDialog}>
            <h3>Are you sure you want to authorise?</h3>
            {emailList && emailList.length > 0 && <EmailList>
                <div>Emails will be sent to:</div>
                {emailList.map(renderEmail)}
            </EmailList>}
            <ActionContainer>
                <Button color={COLORS.FIFTH} onClick={onCloseAuthoriseDialog} style={{ marginRight: "10px" }}>Cancel</Button>
                <Button onClick={onAuthorise} color={"#14633e"}>Authorise</Button>
            </ActionContainer>
        </Dialog>
        <Table>
            <TableHead >
                <tr style={{ tableLayout: "fixed", width: "100%" }}>
                    <th style={{ width: "5%" }}><TableSortLabel active={sortByColumn === QUOTE_TYPE_ID} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(QUOTE_TYPE_ID)}>Type</TableSortLabel></th>
                    <th style={{ width: "10%" }}><TableSortLabel active={sortByColumn === QUOTE_ORDER_OWNER} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(QUOTE_ORDER_OWNER)}>Owner</TableSortLabel></th>
                    <th style={{ width: "10%" }}><TableSortLabel active={sortByColumn === QUOTE_NUMBER} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(QUOTE_NUMBER)}>Quote number</TableSortLabel></th>
                    <th style={{ width: "10%" }}><TableSortLabel active={sortByColumn === QUOTE_ENTERED_DATE} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(QUOTE_ENTERED_DATE)}>Entered</TableSortLabel></th>
                    <th style={{ width: "15%" }}><TableSortLabel active={sortByColumn === QUOTE_CUSTOMER} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(QUOTE_CUSTOMER)}>Customer</TableSortLabel></th>
                    <th style={{ width: "10%" }}><TableSortLabel active={sortByColumn === QUOTE_PROJECT} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(QUOTE_PROJECT)}>Project Name</TableSortLabel></th>
                    <th style={{ width: "10%" }}><TableSortLabel active={sortByColumn === QUOTE_EXPIRY_DATE} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(QUOTE_EXPIRY_DATE)}>Expiry</TableSortLabel></th>
                    <th style={{ width: "5%" }}><TableSortLabel active={sortByColumn === QUOTE_WIN_STATUS} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(QUOTE_WIN_STATUS)}>Status</TableSortLabel></th>
                    <th style={{ width: "10%" }}><TableSortLabel active={sortByColumn === QUOTE_AUTHORISED} direction={sortDescending === true ? "descending" : "ascending"} onClick={changeSortColumn(QUOTE_AUTHORISED)}>Authorised</TableSortLabel></th>
                    <th>Archived</th>
                    <th style={{ width: "5%" }}>Source</th>
                    <th></th>
                </tr>
                <tr>
                    <th style={{ fontWeight: "normal", fontSize: "14px" }}><Select placeholder="" onChange={onChangeTypeFilter} childValues={types && types.map(option => option.type)} value={typeFilter}>
                        {types && types.map((option, index) => <SelectItem height="20px" key={`filter-quote-type-item-${index}`} value={option.description}>{option.description}</SelectItem>)}
                    </Select></th>
                    <th><TextInput value={orderOwnerFilter} onChange={updateText(setOrderOwnerFilter)}></TextInput></th>
                    <th><TextInput value={quoteNumberFilter} onChange={updateText(setQuoteNumberFilter)}></TextInput></th>
                    <th><div style={{
                        border: "1px solid #e5e5e5",
                        borderRadius: "3px",
                        lineHeight: "30px",
                        width: "100%",
                    }}>
                        <DatePicker dateFormat="dd-MMM-yyyy" selected={enteredDateFilter} onChange={changeDate(setEnteredDateFilter)} />
                        <DatePickerWrapperStyles />
                    </div>
                    </th>
                    <th><TextInput value={customerFilter} onChange={updateText(setCustomerFilter)}></TextInput></th>
                    <th><TextInput value={projectFilter} onChange={updateText(setProjectFilter)}></TextInput></th>
                    <th>
                        <div style={{
                            border: "1px solid #e5e5e5",
                            borderRadius: "3px",
                            lineHeight: "30px"
                        }}>
                            <DatePicker selectsRange={true} dateFormat="dd-MMM-yyyy" startDate={expiryDateStartFilter} endDate={expiryDateEndFilter}
                                selected={expiryDateStartFilter} onChange={changeExpiryRange} />
                        </div>
                    </th>
                    <th style={{ fontWeight: "normal", fontSize: "14px" }}>
                        <Select placeholder="" onChange={onChangeStatusFilter} childValues={winStatuses && winStatuses.map(option => option)} value={statusFilter}>
                            {winStatuses && winStatuses.map((option, index) => <SelectItem height="20px" key={`filter-status-item-${index}`} value={option}>{option}</SelectItem>)}
                        </Select>
                    </th>
                    <th style={{ fontWeight: "normal", fontSize: "14px" }}>
                        <Select placeholder="" onChange={onChangeAuthorisedFilter} childValues={authorisedStatuses && authorisedStatuses.map(option => option)} value={authorisedFilter}>
                            {authorisedStatuses && authorisedStatuses.map((option, index) => <SelectItem height="20px" key={`filter-authorised-item-${index}`} value={option}>{option}</SelectItem>)}
                        </Select>
                    </th>
                    <th style={{ fontWeight: "normal", fontSize: "14px" }}>
                        <Select placeholder="" onChange={onChangeArchivedFilter} childValues={toggleStatus && toggleStatus.map(option => option)} value={archivedFilter}>
                            {toggleStatus && toggleStatus.map((option, index) => <SelectItem height="20px" key={`filter-authorised-item-${index}`} value={option}>{option}</SelectItem>)}
                        </Select>
                    </th>
                    <th><TextInput value={""} onChange={() => null}></TextInput></th>

                    <th style={{ textAlign: "left", paddingLeft: "5px" }}> <Button plain={true} onClick={onClearFilters} >Clear</Button></th>
                </tr>
            </TableHead>
            <tbody>
                {quotes
                    .filter(filterQuotes)
                    .map(quoteToArray)
                    .sort(DataSorter(sortByColumn, sortDescending))
                    .sort(DataSorter(QUOTE_ARCHIVED, false)) // always sort archived quotes to the bottom
                    .map((quote, index) => {
                        const quoteSummary: IQuoteSummary = { id: quote[QUOTE_ID], number: quote[QUOTE_NUMBER], projectName: quote[QUOTE_PROJECT], quoteOwnerEmail: quote[QUOTE_ORDER_OWNER], authorisedBy: getUserById(quote[QUOTE_AUTHORISED_BY], users), authorisedByEmail: quote[QUOTE_AUTHORISED_BY] };

                        return <tr key={`quote-table-row-${index}`} style={{ backgroundColor: quote[QUOTE_ARCHIVED] ? "#ccc" : "", height: "50px" }}>
                            <TableCell >{renderType(quote[QUOTE_TYPE_ID])}</TableCell>
                            <TableCell >{quote[QUOTE_ORDER_OWNER]}</TableCell>
                            <TableCell >{quote[QUOTE_NUMBER]}</TableCell>
                            <TableCell>{dateConverter(quote[QUOTE_ENTERED_DATE])}</TableCell>
                            <TableCell >{quote[QUOTE_CUSTOMER]}</TableCell>
                            <TableCell >{quote[QUOTE_PROJECT]}</TableCell>
                            <TableCell >{dateConverter(quote[QUOTE_EXPIRY_DATE])}</TableCell>
                            <TableCell >{quote[QUOTE_WIN_STATUS]}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{renderAuthorise(quoteSummary, quote[QUOTE_AUTHORISED], quote[QUOTE_ARCHIVED])}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{quote[QUOTE_ARCHIVED] ? "yes" : "no"}</TableCell>
                            <TableCell>{quote[QUOTE_SOURCE]}</TableCell>
                            <TableCell>
                                <ActionContainer>
                                    {rbac.read && <Button plain={true} onClick={viewQuote(quote[QUOTE_ID])} style={{ fontSize: "12px", marginRight: "10px" }}>View</Button>}
                                </ActionContainer>
                            </TableCell>
                        </tr>;
                    })}
            </tbody>
        </Table>
    </Main >;
};

export default QuotesTable;
