import React from "react";
import styled from "styled-components";
import { COLORS } from "../../../config";
import IInterfaceDetails from "../../../ServerEntities/IInterfaceDetails";
import Button from "../../../SharedComponents/Button";
import Dialog from "../../../SharedComponents/Dialog/Dialog";
import { updateTextCallback } from "../../../SharedComponents/TextInput";
import Select from "../../../SharedComponents/Select/Select";
import SelectItem from "../../../SharedComponents/Select/SelectItem";
import SmallTextInput from "../../../SharedComponents/SmallTextInput";
import ReactQuill from "react-quill";

const InterfaceContainer = styled.div`
    border-radius: 5px;
    border: 3px solid ${COLORS.INTERFACE};
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 0.8em;
    font-weight: bold;
    line-height: 24px;
    width: 100%;
    margin-bottom: 10px;
`;

const InterfaceInner = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
`;


const DescriptionContainer = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    padding: 0px 10px 0px 0px;
`;

const InterfaceTitle = styled.div`
    background-color: ${COLORS.INTERFACE};
    width: 100%;
    color: #FFF;
    padding-left: 5px;
    display: flex;
    align-items: center;
`;

const ActionContainer = styled.div`
    height: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

const TableCell = styled.td`
    padding: 0px 10px 0px 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 0.8em;
    font-weight: bold;
    width: 200px;
    line-height: 24px;
`;

const DescriptionLabel = styled.div`
    display: flex;
    align-items: center;
    font-size: 0.8em;
    font-weight: bold;
    width: 100%;
    line-height: 24px;
`;

const TableButtonCell = styled.td`
    padding: 10px 10px 0px 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 12px;
    font-weight: bold;
    justify-content: center;
`;

interface IProps {
    interfaceDetails: IInterfaceDetails;
    readOnly?: boolean,
    isEditing?: IInterfaceDetails;
    index: number;
    onUpdate?: (interfaceDetails: IInterfaceDetails, index: number) => void;
    onCancel?: (index: number) => void;
    onDelete?: (index: number) => void;
};

const InterfaceDetails = (props: IProps) => {
    const interfaceDetails = props.interfaceDetails;
    const index = props.index;
    const [name, setName] = React.useState(interfaceDetails.name);
    const [sourceSystem, setSourceSystem] = React.useState(interfaceDetails.sourceSystem);
    const [sourceDataType, setSourceDataType] = React.useState(interfaceDetails.sourceDataType);
    const [sourceMessageType, setSourceMessageType] = React.useState(interfaceDetails.sourceMessageType);
    const [sourceDeliveryMechanism, setSourceDeliveryMechanism] = React.useState(interfaceDetails.sourceDeliveryMechanism);
    const [inboundPort, setInboundPort] = React.useState(interfaceDetails.inboundPort);
    const [destinationSystem, setDestinationSystem] = React.useState(interfaceDetails.destinationSystem);
    const [destinationDataType, setDestinationDataType] = React.useState(interfaceDetails.destinationDataType);
    const [destinationMessageType, setDestinationMessageType] = React.useState(interfaceDetails.destinationMessageType);
    const [destinationAddressPort, setDestinationAddressPort] = React.useState(interfaceDetails.destinationAddressPort);
    const transformationRequired = interfaceDetails.transformationRequired;
    const [direction, setDirection] = React.useState(interfaceDetails.direction);
    const [isDirty, setIsDirty] = React.useState(false);
    const [description, setDescription] = React.useState(interfaceDetails.description);

    const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
    const none = -1;

    const onSaveInterface = (index: number) => () => {
        interfaceDetails.id = interfaceDetails.id > none ? interfaceDetails.id : 0;
        interfaceDetails.name = name;
        interfaceDetails.direction = direction;
        interfaceDetails.sourceSystem = sourceSystem;
        interfaceDetails.sourceDataType = sourceDataType;
        interfaceDetails.sourceMessageType = sourceMessageType;
        interfaceDetails.sourceDeliveryMechanism = sourceDeliveryMechanism;
        interfaceDetails.inboundPort = inboundPort;
        interfaceDetails.destinationSystem = destinationSystem;
        interfaceDetails.destinationDataType = destinationDataType;
        interfaceDetails.destinationMessageType = destinationMessageType;
        interfaceDetails.destinationAddressPort = destinationAddressPort;
        interfaceDetails.transformationRequired = transformationRequired;
        interfaceDetails.description = description;
        if (props.onUpdate) {
            props.onUpdate({ ...interfaceDetails }, index);
        }
        setIsDirty(false);
    };

    const onDeleteInterface = () => {
        setShowDeleteDialog(true);
    };

    const onCloseDeleteDialog = () => {
        setShowDeleteDialog(false);
    };

    const onDelete = () => {
        if (props.onDelete) {
            props.onDelete(index);
        }
    };

    const onChangeDirection = (d: string) => {
        setDirection(d);
        setIsDirty(true);
    }

    const setDirty = () => {
        setIsDirty(true);
    }

    if (showDeleteDialog) {
        return <Dialog key={`delete-dialog-interface=${index}`} style={{ padding: "32px" }} open={showDeleteDialog} onClose={onCloseDeleteDialog}>
            <h3>Are you sure you want to delete?</h3>
            <ActionContainer>
                <Button color={COLORS.SIXTH} onClick={onCloseDeleteDialog} style={{ marginRight: "10px" }}>Cancel</Button>
                <Button onClick={onDelete} color={"#e60000"}>Delete</Button>
            </ActionContainer>
        </Dialog>;
    };

    const LABEL_WIDTH = "10%";
    const VALUE_WIDTH = "30%";

    const updateDescription = (description: string) => {
        setDescription(description);
        setDirty();
    }

    return <InterfaceContainer key={`interface-table-edit=${index}`}>
        <InterfaceTitle>{index + 1} <SmallTextInput textColor={"#fff"} backgroundColor={COLORS.INTERFACE} width="30%" value={name} onChange={updateTextCallback(setName, setDirty)} placeholder="enter interface name" /></InterfaceTitle>
        <InterfaceInner>
            <table className="interfaceTable">
                <tbody>
                    <tr key={`interface-direction=${index}`} style={{ display: "flex" }} >
                        <TableCell width={LABEL_WIDTH}>Choose direction</TableCell>
                        <TableCell width={LABEL_WIDTH}>
                            <Select placeholder="" onChange={onChangeDirection} childValues={[]} value={direction}>
                                <SelectItem height="20px" key={`item-direction-inbound`} value={"Inbound"}>Inbound</SelectItem>
                                <SelectItem height="20px" key={`item-direction-outbound`} value={"Outbound"}>Outbound</SelectItem>
                                <SelectItem height="20px" key={`item-direction-outbound`} value={"End to End"}>End to End</SelectItem>
                            </Select>
                        </TableCell>
                    </tr>
                    {direction && (direction === "Inbound" || direction === "End to End") && <tr key={`interface-detail=${index}`} style={{ display: "flex" }}>
                        <TableCell width={LABEL_WIDTH}>Source system</TableCell>
                        <TableCell width={VALUE_WIDTH}>
                            <SmallTextInput width="90%" value={sourceSystem} onChange={updateTextCallback(setSourceSystem, setDirty)} placeholder="Source system" />
                        </TableCell>
                    </tr>}
                    {direction && (direction === "Inbound" || direction === "End to End") && <tr style={{ display: "flex" }}>
                        <TableCell width={LABEL_WIDTH}>Source data type</TableCell>
                        <TableCell width={VALUE_WIDTH}>
                            <SmallTextInput width="90%" value={sourceDataType} onChange={updateTextCallback(setSourceDataType, setDirty)} placeholder="Source data type" />
                        </TableCell>
                    </tr>}
                    {direction && (direction === "Inbound" || direction === "End to End") && <tr style={{ display: "flex" }}>
                        <TableCell width={LABEL_WIDTH}>Source message type</TableCell>
                        <TableCell width={VALUE_WIDTH}>
                            <SmallTextInput width="90%" value={sourceMessageType} onChange={updateTextCallback(setSourceMessageType, setDirty)} placeholder="Source message type" />
                        </TableCell>
                    </tr>}
                    {direction && (direction === "Inbound" || direction === "End to End") && <tr style={{ display: "flex" }}>
                        <TableCell width={LABEL_WIDTH}>Source delivery mechanism</TableCell>
                        <TableCell width={VALUE_WIDTH}>
                            <SmallTextInput width="90%" value={sourceDeliveryMechanism} onChange={updateTextCallback(setSourceDeliveryMechanism, setDirty)} placeholder="Source delivery mechanism" />
                        </TableCell>
                    </tr>}
                    {direction && (direction === "Inbound" || direction === "End to End") && <tr style={{ display: "flex" }}>
                        <TableCell width={LABEL_WIDTH}>Inbound port</TableCell>
                        <TableCell width={VALUE_WIDTH}>
                            <SmallTextInput width="90%" value={inboundPort} onChange={updateTextCallback(setInboundPort, setDirty)} placeholder="Inbound port" />
                        </TableCell>
                    </tr>}
                    {direction && (direction === "Outbound" || direction === "End to End") && <tr style={{ display: "flex" }}>
                        <TableCell width={LABEL_WIDTH}>Destination system</TableCell>
                        <TableCell width={VALUE_WIDTH}>
                            <SmallTextInput width="90%" value={destinationSystem} onChange={updateTextCallback(setDestinationSystem, setDirty)} placeholder="Destination system" />
                        </TableCell>
                    </tr>}
                    {direction && (direction === "Outbound" || direction === "End to End") && <tr style={{ display: "flex" }}>
                        <TableCell width={LABEL_WIDTH}>Destination data type</TableCell>
                        <TableCell width={VALUE_WIDTH}>
                            <SmallTextInput width="90%" value={destinationDataType} onChange={updateTextCallback(setDestinationDataType, setDirty)} placeholder="Destination data type" />
                        </TableCell>
                    </tr>}
                    {direction && (direction === "Outbound" || direction === "End to End") && <tr style={{ display: "flex" }}>
                        <TableCell width={LABEL_WIDTH}>Destination message type</TableCell>
                        <TableCell width={VALUE_WIDTH}>
                            <SmallTextInput width="90%" value={destinationMessageType} onChange={updateTextCallback(setDestinationMessageType, setDirty)} placeholder="Destination message type" />
                        </TableCell>
                    </tr>}
                    {direction && (direction === "Outbound" || direction === "End to End") && <tr style={{ display: "flex" }}>
                        <TableCell width={LABEL_WIDTH}>Destination address port</TableCell>
                        <TableCell width={VALUE_WIDTH}>
                            <SmallTextInput width="90%" value={destinationAddressPort} onChange={updateTextCallback(setDestinationAddressPort, setDirty)} placeholder="Destination address port" />
                        </TableCell>
                    </tr>}
                    {direction && (direction === "Outbound" || direction === "End to End") && <tr style={{ display: "flex" }}>
                        <TableCell width={LABEL_WIDTH}>&nbsp;</TableCell>
                        <TableCell width={VALUE_WIDTH}>&nbsp;
                        </TableCell>
                    </tr>}
                    {isDirty && <tr key={`interface-source-buttons=${index}`}>
                        <TableButtonCell>
                            {isDirty && <Button color={COLORS.GREY_SLATE} onClick={onSaveInterface(index)} submit={false} style={{ marginRight: "10px" }}>Save</Button>}
                            <Button color={COLORS.GREY_SLATE} onClick={onDeleteInterface} submit={false} style={{ marginLeft: "10px" }}>Delete</Button>
                        </TableButtonCell>
                    </tr>}
                </tbody>
            </table>
            <DescriptionContainer>
                <DescriptionLabel>Description</DescriptionLabel>
                <ReactQuill style={{ width: "100%", marginBottom: "10px" }} value={description} onChange={updateDescription} />
            </DescriptionContainer>

        </InterfaceInner>

    </InterfaceContainer>
}

export default InterfaceDetails;