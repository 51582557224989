import * as React from "react";
import { SessionContext } from "../../SessionContext";
import LoadingIndicator from "../../../SharedComponents/LoadingIndicator";
import ErrorText from "../../../SharedComponents/ErrorText";
import IChangeHistory from "../../../ServerEntities/IChangeHistory";
import ChangeHistory from "../Components/ChangeHistory";
import { COLORS } from "../../../config";
import IUser from "../../../ServerEntities/IUser";
import { getCustomerHistory } from "./CustomersService";
import ICustomer from "../../../ServerEntities/ICustomer";
import { Main } from "./CustomersTable";
import { OutsideContainer } from "./CustomerEdit";
import useEffectOnSome from "../../../CustomHooks/useEffectOnSome";

interface IProps {
    customer: ICustomer
    users: IUser[]
};

const CustomerHistory = (props: IProps) => {
    const { state } = React.useContext(SessionContext);
    const customerId = props.customer.id ? props.customer.id.toString() : "";
    const [history, setHistory] = React.useState(null as unknown as IChangeHistory[]);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");

    useEffectOnSome(() => {
        if (customerId) {
            getCustomerHistory(customerId, state.webToken, (ch: IChangeHistory[]) => {
                setLoading(false);
                setError("");
                setHistory(ch);

            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
            });
        }
    }, [customerId]);

    return <OutsideContainer>
        <LoadingIndicator type="Linear" show={loading} />
        <Main>
            {error && <ErrorText>{error}</ErrorText>}
            {history && <ChangeHistory users={props.users} color={COLORS.SIXTH} id={customerId} changeHistory={history} />}
        </Main>
    </OutsideContainer>
};

export default CustomerHistory;
