import styled from "styled-components";

const Label = styled.div`
    padding: 10px 8px 10px 0;
    display:flex;
    flex-direction:column;
    flex: 1 1 auto;
    overflow: hidden;

    &.ellipsis {
        white-space: nowrap; 
        text-overflow: ellipsis;
        h2 {
            white-space: nowrap; 
            overflow: hidden;
            text-overflow: ellipsis;
        }

        h3 {
            white-space: nowrap !important; 
            overflow: hidden !important;
            text-overflow: ellipsis !important;
        }
        p {
            white-space: nowrap; 
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    
    h2 {
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
        margin: 0;
    }
    h3 {
        font-size: 15px !important;
        font-weight: 400 !important;
        line-height: normal !important;
        margin: 0 !important;
        margin-bottom: 0 !important; 
        color: #666666 !important;
    }
    p {
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        margin: 0;
        color: #A9A9A9;
    }
`;

export default Label;
